import React, { useEffect, useMemo, useState } from 'react';
// libs
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
// components
import LoadingSpinner from 'components/LoadingSpinner';
import RemovePositionModal from './RemovePositionModal';
import ReviewModal from './ReviewModal';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
// hooks
import useDetails from './hooks/useDetails';
// helpers
import { roundTo2Digits } from 'helpers/renderHelpers';
// store
import { useAppDispatch } from 'store';
import { itemMetaSelector } from 'store/nftItem';
import { fetchMarketplaceItemById, marketplaceMetaSelector } from 'store/marketplace';
import { getListingBids, postBid } from 'store/bid';
import { Bid, MakeBidPayload } from 'store/types';
// styled
import { Wrapper, Container } from './styled';
import AcceptBidModal from './AcceptBidModal';
import DeclineBidModal from './DeclineBidModal';
import DeclineAllBidsModal from './DeclineAllBidsModal';
import PayModal from './PayModal';
import { setNotification } from '../../store/notification';
import { FormattedMessage, useIntl } from 'react-intl';
import { IntlKeys } from '../../localization/keys';

type GetValidationSchemaParams = {
  bidStepSize: number;
  startPrice: number;
  highestBid?: number;
};

const getValidationSchema = ({ bidStepSize, startPrice, highestBid }: GetValidationSchemaParams) => {
  const currentBidPrice = highestBid ? highestBid : startPrice;

  return Yup.object().shape({
    price: Yup.number()
      .required(() => <FormattedMessage id={IntlKeys.validationRequired} />)
      .test('moreThanBidStepSize', '', (value, context) => {
        const minBid = currentBidPrice + bidStepSize;
        if (value && value < minBid) {
          return context.createError({
            path: context.path,
            message: () => (
              <FormattedMessage
                id={IntlKeys.validationBidShouldBeEqualOrBigger}
                values={{ value: roundTo2Digits(minBid) }}
              />
            ),
          });
        }
        return true;
      }),
  });
};

export interface ItemDetailsPageProps {
  isMarketplaceItem?: boolean;
}

const ItemDetailsPage: React.FC<ItemDetailsPageProps> = ({ isMarketplaceItem }) => {
  const [isRemovePosModalOpened, setIsRemovePosModalOpened] = useState(false);
  const [isBidButtonClicked, setIsBidButtonClicked] = useState(false);
  const [isReviewModalOpened, setIsReviewModalOpened] = useState(false);
  const [isAcceptBidModalOpened, setIsAcceptBidModalOpened] = useState(false);
  const [isDeclineBidModalOpened, setIsDeclineBidModalOpened] = useState(false);
  const [isDeclineAllBidsOpened, setIsDeclineAllBidsOpened] = useState(false);
  const [isPayModalOpened, setIsPayModalOpened] = useState(false);
  const [selectedBid, setSelectedBid] = useState<Bid | null>(null);

  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();
  const { id } = useParams();

  const itemMeta = useSelector(itemMetaSelector);
  const marketplaceItemMeta = useSelector(marketplaceMetaSelector);

  const { item, profile } = useDetails(isMarketplaceItem);
  const { activeListing, ownerId } = item;
  const { userId } = profile;
  const { topBid, price, bidStepSize } = activeListing ?? {};

  const isOwner = useMemo(() => userId === ownerId, [ownerId, userId]);

  const handleRemovePosModalClose = () => setIsRemovePosModalOpened(false);

  const handleReviewModalClose = () => setIsReviewModalOpened(false);

  const handleAcceptBidModalClose = () => setIsAcceptBidModalOpened(false);

  const handleDeclineBidModalClose = () => setIsDeclineBidModalOpened(false);

  const handleDeclineAllBidsClose = () => setIsDeclineAllBidsOpened(false);

  const handlePayModalOpened = () => setIsPayModalOpened(false);

  useEffect(() => {
    if (isOwner && activeListing) {
      dispatch(getListingBids({ listingId: activeListing.id }));
    }
  }, [dispatch, isOwner, activeListing]);

  const initialValues = useMemo(
    () => ({
      listingId: activeListing?.id as unknown as number,
      price: '' as unknown as number,
    }),
    [activeListing?.id],
  );

  const formik = useFormik<MakeBidPayload>({
    initialValues,
    enableReinitialize: true,
    validationSchema: getValidationSchema({
      bidStepSize: bidStepSize || 0,
      startPrice: price || 0,
      highestBid: topBid?.price,
    }),
    onSubmit: (values) => {
      return dispatch(postBid(values))
        .unwrap()
        .then(() => {
          setIsBidButtonClicked(false);
          handleReviewModalClose();
          dispatch(setNotification({ message: formatMessage({ id: IntlKeys.toastBidSubmitted }), type: 'success' }));
          if (id) {
            dispatch(fetchMarketplaceItemById(id));
          }
        })
        .catch(() =>
          dispatch(
            setNotification({ message: formatMessage({ id: IntlKeys.toastSomethingWentWrong }), type: 'error' }),
          ),
        );
    },
  });

  return (
    <FormikProvider value={formik}>
      {itemMeta.isLoading || marketplaceItemMeta.isLoading ? (
        <LoadingSpinner />
      ) : (
        <Wrapper>
          <Container>
            <LeftColumn item={item} isOwner={isOwner} isMarketplaceItem={isMarketplaceItem} />

            <RightColumn
              item={item}
              profile={profile}
              isOwner={isOwner}
              isBidButtonClicked={isBidButtonClicked}
              setIsRemovePosModalOpened={setIsRemovePosModalOpened}
              setIsBidButtonClicked={setIsBidButtonClicked}
              setIsReviewModalOpened={setIsReviewModalOpened}
              setIsAcceptBidModalOpened={setIsAcceptBidModalOpened}
              setIsDeclineBidModalOpened={setIsDeclineBidModalOpened}
              setIsDeclineAllBidsOpened={setIsDeclineAllBidsOpened}
              setIsPayModalOpened={setIsPayModalOpened}
              setSelectedBid={setSelectedBid}
            />
          </Container>

          <RemovePositionModal isOpen={isRemovePosModalOpened} onClose={handleRemovePosModalClose} item={item} />
          <ReviewModal isOpen={isReviewModalOpened} item={item} onClose={handleReviewModalClose} />
          <AcceptBidModal
            isOpen={isAcceptBidModalOpened}
            onClose={handleAcceptBidModalClose}
            selectedBid={selectedBid}
            item={item}
          />
          <DeclineBidModal
            isOpen={isDeclineBidModalOpened}
            onClose={handleDeclineBidModalClose}
            selectedBid={selectedBid}
            item={item}
          />
          <DeclineAllBidsModal isOpen={isDeclineAllBidsOpened} onClose={handleDeclineAllBidsClose} item={item} />
          <PayModal isOpen={isPayModalOpened} onClose={handlePayModalOpened} selectedBid={selectedBid} item={item} />
        </Wrapper>
      )}
    </FormikProvider>
  );
};

export default ItemDetailsPage;
