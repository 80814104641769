import React, { useEffect, useState } from 'react';
// libs
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
// components
import LoadingSpinner from 'components/LoadingSpinner';
import Accordion from './Accordion';
import NewCollectionModal from './NewCollectionModal';
// store
import { useAppDispatch } from 'store';
import {
  collectionMetaSelector,
  collectionsSelector,
  fetchCollections,
  setInitialCollectionMeta,
} from 'store/collection';
// styled
import {
  AccordionsContainer,
  Container,
  NewCollectionButton,
  HeaderContainer,
  HeaderTitle,
  Wrapper,
  NoCollectionFoundText,
} from './styled';
import { IntlKeys } from '../../localization/keys';

const MyCollectionsPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();
  const collections = useSelector(collectionsSelector);
  const collectionMeta = useSelector(collectionMetaSelector);

  const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);
  const handleCollectionModalClose = () => setIsCollectionModalOpen(false);
  const handleCollectionButtonClick = () => {
    dispatch(setInitialCollectionMeta());
    setIsCollectionModalOpen(true);
  };

  useEffect(() => {
    dispatch(fetchCollections());
  }, [dispatch]);

  return (
    <Wrapper>
      {collectionMeta.isLoading && <LoadingSpinner />}
      <Container>
        <HeaderContainer>
          <HeaderTitle>{formatMessage({ id: IntlKeys.myCollectionsCustomCollectionsTitle })}</HeaderTitle>

          <NewCollectionButton fullWidth size="large" onClick={handleCollectionButtonClick}>
            {formatMessage({ id: IntlKeys.myCollectionsNewCustomCollection })}
          </NewCollectionButton>
        </HeaderContainer>

        <AccordionsContainer>
          {!!collections?.length ? (
            collections?.map((collection, index) => (
              <Accordion
                key={collection.id}
                collectionId={collection.id}
                title={collection.name}
                link={collection.id.toString()}
                defaultExpanded={index === 0}
              />
            ))
          ) : (
            <NoCollectionFoundText>
              {formatMessage({ id: IntlKeys.myCollectionsNoCollectionsFound })}
            </NoCollectionFoundText>
          )}
        </AccordionsContainer>
      </Container>

      <NewCollectionModal isOpen={isCollectionModalOpen} onClose={handleCollectionModalClose} />
    </Wrapper>
  );
};

export default MyCollectionsPage;
