import React from 'react';
import { useIntl } from 'react-intl';
// material-ui
import { InputAdornment } from '@mui/material';
// components
import Modal from 'components/Modal';
import Accordion from '../Accordion';
import CheckboxesGroup from '../CheckboxesGroup';
// hooks
import useFilters from '../hooks/useFilters';
// constants
import { CATEGORIES_FILTER_VALUES, FILTERS, ITEM_TYPE_FILTER_VALUES, STATUS_FILTER_VALUES } from 'constants/filters';
// icons
import { FilterAlt } from '@mui/icons-material';
// others
import { IntlKeys } from 'localization/keys';
// styled
import { Wrapper, CloseButton } from './styled';
import { PriceWrapper, PriceText, PriceTextField, FilterTitle, FilterTitleWrapper } from '../styled';

type MobileFilterModalProps = {
  isOpen: boolean;
  isMyCollection?: boolean;
  handleClose: () => void;
};

const PRICE_INPUT_PROPS = {
  startAdornment: <InputAdornment position="start">€</InputAdornment>,
};

// TODO: refactor mobile filter component
const MobileFilterModal: React.FC<MobileFilterModalProps> = ({ isOpen, isMyCollection = false, handleClose }) => {
  const { formatMessage } = useIntl();
  const { values, handlers } = useFilters();

  const { searchParams, priceMin, priceMax } = values;
  const { handleFilterCheckboxChange, handleFilterPriceMinChange, handleFilterPriceMaxChange } = handlers;

  return (
    <Modal open={isOpen} onClose={handleClose} fullScreenBreakpoint="md">
      <Wrapper>
        <FilterTitleWrapper>
          <FilterAlt />
          <FilterTitle>{formatMessage({ id: IntlKeys.marketplaceCollectionFilterTitle })}</FilterTitle>
        </FilterTitleWrapper>

        {isMyCollection && (
          <Accordion
            summaryTitle={formatMessage({ id: IntlKeys.marketplaceCollectionFilterTitle })}
            defaultExpanded
            detailsContent={
              <CheckboxesGroup
                label={IntlKeys.filterStatusTitle}
                tagStyleCheckbox
                showAllCheckbox={false}
                name={FILTERS.statuses}
                onChange={handleFilterCheckboxChange}
                checked={searchParams.getAll(FILTERS.statuses)}
                checkboxes={STATUS_FILTER_VALUES}
              />
            }
          />
        )}

        <Accordion
          summaryTitle={formatMessage({ id: IntlKeys.filterPriceTitle })}
          defaultExpanded
          detailsContent={
            <PriceWrapper>
              <PriceTextField
                size="small"
                name={FILTERS.priceMin}
                InputProps={PRICE_INPUT_PROPS}
                value={priceMin}
                onChange={handleFilterPriceMinChange}
                type="number"
              />
              <PriceText>{formatMessage({ id: IntlKeys.filterPriceTo })}</PriceText>
              <PriceTextField
                size="small"
                name={FILTERS.priceMax}
                InputProps={PRICE_INPUT_PROPS}
                onChange={handleFilterPriceMaxChange}
                value={priceMax}
                type="number"
              />
            </PriceWrapper>
          }
        />

        <Accordion
          summaryTitle={formatMessage({ id: IntlKeys.filterCategoriesTitle })}
          defaultExpanded
          detailsContent={
            <CheckboxesGroup
              label={IntlKeys.filterAllTitle}
              name={FILTERS.categories}
              onChange={handleFilterCheckboxChange}
              checked={searchParams.getAll(FILTERS.categories)}
              checkboxes={CATEGORIES_FILTER_VALUES}
            />
          }
        />

        <Accordion
          summaryTitle={formatMessage({ id: IntlKeys.filterTypesTitle })}
          defaultExpanded
          detailsContent={
            <CheckboxesGroup
              label={IntlKeys.filterAllTitle}
              name={FILTERS.itemTypes}
              onChange={handleFilterCheckboxChange}
              checked={searchParams.getAll(FILTERS.itemTypes)}
              checkboxes={ITEM_TYPE_FILTER_VALUES}
            />
          }
        />

        <CloseButton onClick={handleClose}>{formatMessage({ id: IntlKeys.commonClose })}</CloseButton>
      </Wrapper>
    </Modal>
  );
};

export default MobileFilterModal;
