import styled from '@emotion/styled/macro';
import { Menu, MenuItem } from '@mui/material';

export const Wrapper = styled.div``;

export const StyledMenu = styled(Menu)`
  & .MuiMenu-paper {
    border-radius: 2px;
    width: 86px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 16px;
  font-family: 'VisbyRoundCF Medium', sans-serif;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
`;

export const MoreIconWrapper = styled.div`
  cursor: pointer;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
`;
