import React, { useEffect } from 'react';
// libs
import { useFormik, Field, FormikProvider } from 'formik';
// material-ui
import { LoadingButton } from '@mui/lab';
// components
import Modal from 'components/Modal';
import FormSelect from 'components/formik/FormSelect';
import FormTextField from 'components/formik/FormTextField';
// store
import { useAppDispatch } from 'store';
import { collectionMetaSelector, createCollection } from 'store/collection';
import { Collection } from 'store/types';
// styled
import { Wrapper, Title, FieldsContainer, CreateButtonWrapper } from './styled';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { IntlKeys } from '../../../localization/keys';
import { setNotification } from '../../../store/notification';

export interface NewCollectionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BLOCKCHAIN_OPTIONS = [{ value: 'polygon', label: 'Blockchain(Poligon)' }];

function NewCollectionModal({ isOpen, onClose }: NewCollectionModalProps) {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();
  const collectionMeta = useSelector(collectionMetaSelector);

  const formik = useFormik({
    initialValues: {
      name: '',
      blockchainType: 'polygon' as Collection['blockChainType'],
    },
    onSubmit: (values) => {
      dispatch(createCollection(values))
        .unwrap()
        .then(() =>
          dispatch(
            setNotification({ message: formatMessage({ id: IntlKeys.toastCollectionCreated }), type: 'success' }),
          ),
        )
        .catch(() =>
          dispatch(
            setNotification({ message: formatMessage({ id: IntlKeys.toastSomethingWentWrong }), type: 'error' }),
          ),
        );
    },
  });

  useEffect(() => {
    if (collectionMeta.isLoaded) {
      onClose();
    }
  }, [collectionMeta.isLoaded, dispatch, onClose]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <FormikProvider value={formik}>
        <Wrapper>
          <form onSubmit={formik.handleSubmit}>
            <Title>{formatMessage({ id: IntlKeys.myCollectionsNewCustomCollection })}</Title>
            <FieldsContainer>
              <Field
                name="name"
                component={FormTextField}
                label={formatMessage({ id: IntlKeys.myCollectionsNameOfCustomCollection })}
                required
              />
              {/*<Field*/}
              {/*  name="blockchainType"*/}
              {/*  component={FormSelect}*/}
              {/*  options={BLOCKCHAIN_OPTIONS}*/}
              {/*  label={formatMessage({ id: IntlKeys.myCollectionsBlockchain })}*/}
              {/*  required*/}
              {/*/>*/}
            </FieldsContainer>
            <CreateButtonWrapper>
              <LoadingButton
                fullWidth
                loading={collectionMeta.isLoading}
                disabled={collectionMeta.isLoading}
                size="large"
                type="submit"
              >
                {formatMessage({ id: IntlKeys.commonCreate })}
              </LoadingButton>
            </CreateButtonWrapper>
          </form>
        </Wrapper>
      </FormikProvider>
    </Modal>
  );
}

export default NewCollectionModal;
