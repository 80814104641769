import React from 'react';
// material-ui
import { ButtonProps, Tooltip } from '@mui/material';
// icons
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
// styled
import { ActionButton, Amount, Container, TitleContainer, TopContainer, Wrapper, Title } from './styled';

export interface InfoCard {
  icon: JSX.Element;
  title: string;
  buttonTitle: string;
  onButtonClick?: () => void;
  tooltipText?: string;
  amount?: number | string;
  buttonColor?: ButtonProps['color'];
}

const InfoCard: React.FC<InfoCard> = ({
  icon,
  title,
  buttonTitle,
  onButtonClick,
  tooltipText,
  amount = '0,00',
  buttonColor = 'secondary',
}) => {
  return (
    <Wrapper>
      <Container>
        <TopContainer>
          <TitleContainer>
            {icon}
            <Title>{title}</Title>
            {tooltipText && (
              <Tooltip title={tooltipText}>
                <InfoIcon />
              </Tooltip>
            )}
          </TitleContainer>
          <Amount>€{amount}</Amount>
        </TopContainer>
        <ActionButton color={buttonColor} onClick={onButtonClick}>
          {buttonTitle}
        </ActionButton>
      </Container>
    </Wrapper>
  );
};

export default InfoCard;
