import React, { useCallback, useEffect, useMemo, useState } from 'react';
// libs
import ReactCountdown, { CountdownRenderProps, CountdownProps as ReactCountdownProps } from 'react-countdown';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import { useIntl } from 'react-intl';
// components
import InfoMessage from './InfoMessage';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import { CountdownContainer, CountdownNumber, CountdownTitle, CountdownValueContainer } from './styled';

export interface CountdownProps extends ReactCountdownProps {
  date: Date | number;
  isEnded?: boolean;
}

export enum BidStatus {
  OnSale = 'OnSale',
  Ending = 'Ending',
  Ended = 'Ended',
}

const Countdown: React.FC<CountdownProps> = ({ date, isEnded }) => {
  const { formatMessage } = useIntl();
  const [bidStatus, setBidStatus] = useState(BidStatus.OnSale);

  const updateBidStatus = useCallback(() => {
    if (isEnded) {
      return setBidStatus(BidStatus.Ended);
    }
    if (differenceInSeconds(date, Date.now()) < 0) {
      return setBidStatus(BidStatus.Ended);
    }
    if (differenceInMinutes(date, Date.now()) < 5) {
      return setBidStatus(BidStatus.Ending);
    }

    return setBidStatus(BidStatus.OnSale);
  }, [date, isEnded]);

  useEffect(() => {
    updateBidStatus();
    const updateBidStatusInterval = setInterval(() => updateBidStatus(), 1000);
    return () => {
      clearInterval(updateBidStatusInterval);
    };
  }, [updateBidStatus]);

  const renderCountdown = useCallback(
    ({ days, hours, minutes, seconds }: CountdownRenderProps) => {
      const countdownValues = [
        { id: 1, title: IntlKeys.countdownDays, value: days },
        { id: 2, title: IntlKeys.countdownHours, value: hours },
        { id: 3, title: IntlKeys.countdownMinutes, value: minutes },
        { id: 4, title: IntlKeys.countdownSeconds, value: seconds },
      ];

      return (
        <CountdownContainer>
          {countdownValues.map(({ title, value, id }) => (
            <CountdownValueContainer key={id}>
              <CountdownNumber>{value}</CountdownNumber>
              <CountdownTitle bidStatus={bidStatus}>{formatMessage({ id: title })}</CountdownTitle>
            </CountdownValueContainer>
          ))}
        </CountdownContainer>
      );
    },
    [bidStatus, formatMessage],
  );

  const infoMessage = useMemo(() => {
    if (bidStatus === BidStatus.Ended) {
      return <InfoMessage message={formatMessage({ id: IntlKeys.countdownThisSaleIsOver })} />;
    }

    if (bidStatus === BidStatus.Ending) {
      return <InfoMessage message={formatMessage({ id: IntlKeys.countdownFiveMinutesLeft })} />;
    }
  }, [bidStatus, formatMessage]);

  return (
    <>
      <ReactCountdown date={date} renderer={renderCountdown} />
      {infoMessage}
    </>
  );
};

export default Countdown;
