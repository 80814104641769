import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 180px;

  ${({ theme }) => theme.media.sm`
    margin-top: 116px;
  `}
`;

export const Container = styled.div`
  max-width: 768px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 48px;
  line-height: 36px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};

  ${({ theme }) => theme.media.sm`
    font-size: 36px;
    line-height: 43.2px;
    text-align: center;
  `}
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;
  margin-top: 120px;
  justify-items: flex-start;

  ${({ theme }) => theme.media.sm`
    margin-top: 56px;
  `}
`;
