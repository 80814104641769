import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: calc(33.33% - 24px);
  height: fit-content;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  ${({ theme }) => theme.media.sm`
    width: calc(38% - 24px);
    height: 300px;
  `}

  ${({ theme }) => theme.media.xs`
    width: calc(46% - 24px);
    height: 200px;
  `}
`;

export const Image = styled.img`
  width: 100%;
`;

export const CategoryNameWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

export const CategoryName = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  white-space: nowrap;

  ${({ theme }) => theme.media.xs`
    font-size: 16px;
  `}
`;
