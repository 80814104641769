import React, { useCallback, useEffect, useMemo, useState } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// components
import Modal from 'components/Modal';
// hooks
import useFetchNftItemThumbnail from 'hooks/useFetchNftItemThumbnail';
// store
import { Bid, Item } from 'store/types';
import { uiLocaleSelector } from 'store/ui';
// helpers
import { roundTo2Digits } from 'helpers/renderHelpers';
// constants
import { IntlKeys } from 'localization/keys';
import { EURO_SIGN } from 'constants/general';
// styled
import {
  BigTitle,
  BigValue,
  ButtonsContainer,
  CommonContainer,
  Container,
  Image,
  ItemContainer,
  ItemInfoContainer,
  ItemName,
  Label,
  PrimaryButton,
  SecondaryButton,
  SmallDescription,
  SmallTitle,
  SmallValue,
  Wrapper,
} from '../shared/styles/modals';
import { Divider, InfoContainer } from './styled';
import { NavLink } from '../styled';
import { collectionsSelector, fetchCollections } from '../../../store/collection';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import Select from '../../../components/Select';
import { profileWalletBalanceSelector } from '../../../store/profile';

export interface DeclineBidModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedBid: Bid | null;
  item: Item;
}

export const PayModal: React.FC<DeclineBidModalProps> = ({ isOpen, onClose, item }) => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();
  const collections = useSelector(collectionsSelector);
  const locale = useSelector(uiLocaleSelector);
  const navigate = useNavigate();
  const walletBalance = useSelector(profileWalletBalanceSelector);

  const { price, id } = item;

  const [selectedCollection, setSelectedCollection] = useState(collections.length ? collections[0].id : null);

  const collectionIdOptions = useMemo(() => {
    if (!collections || !collections?.length) {
      return [];
    }
    return collections?.map(({ id, name }) => ({ value: id.toString(), label: name }));
  }, [collections]);

  const { image } = useFetchNftItemThumbnail({ itemId: id, thumbnailType: 'show', isMarketplaceItem: true });

  const handleChangeSelectedCollection = useCallback((value: string) => {
    setSelectedCollection(value);
  }, []);

  useEffect(() => {
    if (!collections?.length) {
      dispatch(fetchCollections());
    }
  }, [collections, dispatch, locale, navigate]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Wrapper>
        <Container>
          <ItemContainer>
            <Image src={image} />
            <ItemInfoContainer>
              <ItemName>{item?.name}</ItemName>
              <NavLink to={`/${locale}/my-collections/${item?.collection?.id}`}>{item?.collection?.name}</NavLink>
            </ItemInfoContainer>
          </ItemContainer>
          <InfoContainer>
            <CommonContainer>
              <Label>{formatMessage({ id: IntlKeys.itemDetailsPayModalSelectCollectionLabel })}</Label>
              <Select
                options={collectionIdOptions}
                value={selectedCollection}
                // onChange={handleChangeRange}
                onMenuItemClick={handleChangeSelectedCollection}
              />
            </CommonContainer>
            <CommonContainer>
              <BigTitle>{formatMessage({ id: IntlKeys.itemDetailsPurchasePrice })}</BigTitle>
              <BigValue>
                {EURO_SIGN}
                {roundTo2Digits(Number(price))}
              </BigValue>
              <Divider />
            </CommonContainer>
            <CommonContainer>
              <SmallTitle>
                {formatMessage({ id: IntlKeys.itemDetailsYourBalanceAfterPurchase })}
                <SmallDescription>
                  {formatMessage({ id: IntlKeys.itemDetailsYourBalanceAfterPurchaseDescription })}
                </SmallDescription>
              </SmallTitle>
              <SmallValue>
                {EURO_SIGN}
                {roundTo2Digits(walletBalance - Number(price))}
              </SmallValue>
            </CommonContainer>
          </InfoContainer>
        </Container>
        <ButtonsContainer>
          <PrimaryButton size="large">{formatMessage({ id: IntlKeys.itemDetailsPayNow })}</PrimaryButton>
          <SecondaryButton size="large" onClick={onClose}>
            {formatMessage({ id: IntlKeys.commonCancel })}
          </SecondaryButton>
        </ButtonsContainer>
      </Wrapper>
    </Modal>
  );
};

export default PayModal;
