import React from 'react';
// material-ui
import { Collapse, Stack } from '@mui/material';
// icons
import { ReactComponent as AppleStoreIcon } from 'assets/icons/apple-store.svg';
import { ReactComponent as GooglePlayIcon } from 'assets/icons/google-play.svg';
// styled
import { QRCodeImage, Wrapper, IconLink } from './styled';

export interface GetAppPopoverProps {
  isOpened: boolean;
}

// TODO change QRCoderImage src
function GetAppPopover({ isOpened }: GetAppPopoverProps) {
  return (
    <Collapse in={isOpened} unmountOnExit>
      <Wrapper>
        <Stack direction="column" spacing={1}>
          <IconLink href="#" tabIndex={1}>
            <AppleStoreIcon />
          </IconLink>
          <IconLink href="#" tabIndex={1}>
            <GooglePlayIcon />
          </IconLink>
        </Stack>
        <QRCodeImage src="https://coinmerce.io/assets/images/qrcode-store@2x.png" alt="qr-code" />
      </Wrapper>
    </Collapse>
  );
}

export default GetAppPopover;
