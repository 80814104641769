import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { http } from 'utils/http';
import { RootState } from '../index';
import { Collection, CreateCollectionPayload, Item } from '../types';
import { SLICE_NAMES } from '../constants';
import { FILTERS } from '../../constants/filters';

type CollectionState = {
  collections: Collection[];
  collectionsItems: { items: Item[]; collectionId: string | number }[];
  collection: Collection;
  meta: {
    isLoading: boolean;
    isLoaded: boolean;
  };
};

const initialState: CollectionState = {
  meta: { isLoading: false, isLoaded: false },
  collections: [],
  collectionsItems: [],
  collection: {} as Collection,
};

export const createCollection = createAsyncThunk(
  'collection/createCollection',
  async (payload: CreateCollectionPayload) => {
    return http.post<CreateCollectionPayload>('collections', payload).catch((error) => {
      return error;
    });
  },
);

export const fetchCollections = createAsyncThunk('collection/fetchCollections', async () => {
  return http.get('collections').catch((error) => error);
});

export const fetchCollection = createAsyncThunk('collection/fetchCollection', async (id: string | number) => {
  return http.get(`collections/${id}`).catch((error) => error);
});

export const fetchCollectionsItems = createAsyncThunk(
  'collection/fetchCollectionIntoCollections',
  async (collectionId: number | string) => {
    return http.get(`items?${FILTERS.collectionId}=${collectionId}`).catch((error) => error);
  },
);

const collectionSlice = createSlice({
  name: SLICE_NAMES.COLLECTION,
  initialState,
  reducers: {
    setInitialCollectionMeta: (state) => {
      state.meta = initialState.meta;
    },
  },
  extraReducers: {
    [createCollection.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [createCollection.fulfilled.type]: (state) => {
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [createCollection.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },

    [fetchCollections.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [fetchCollections.fulfilled.type]: (state, { payload }) => {
      const { data }: { data: Collection[] } = payload;
      state.collections = data;
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [fetchCollections.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },

    [fetchCollection.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [fetchCollection.fulfilled.type]: (state, { payload }) => {
      const { data }: { data: Collection } = payload;
      state.collection = data;
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [fetchCollection.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },
    //
    [fetchCollectionsItems.pending.type]: (state) => {
      state.meta = initialState.meta;
    },
    [fetchCollectionsItems.fulfilled.type]: (state, { payload, meta }) => {
      const { data: fetchedCollectionItems }: { data: Item[] } = payload;

      const fetchedCollectionId = meta.arg;

      const hasFetchedCollection = state.collectionsItems.some(
        (collection) => collection.collectionId === fetchedCollectionId,
      );

      if (hasFetchedCollection) {
        state.collectionsItems
          .filter((collection) => collection.collectionId !== fetchedCollectionId)
          .push({ items: fetchedCollectionItems, collectionId: fetchedCollectionId });
      }

      if (!hasFetchedCollection) {
        state.collectionsItems = [
          ...state.collectionsItems,
          { items: fetchedCollectionItems, collectionId: fetchedCollectionId },
        ];
      }
    },
    [fetchCollectionsItems.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },
  },
});

const collectionStateSelector = (state: RootState): CollectionState => state[SLICE_NAMES.COLLECTION];
export const collectionMetaSelector = createSelector(collectionStateSelector, (collection) => collection.meta);
export const collectionsSelector = createSelector(collectionStateSelector, (collection) => collection.collections);
export const collectionsItemsSelector = createSelector(
  collectionStateSelector,
  (collection) => collection.collectionsItems,
);
export const collectionSelector = createSelector(collectionStateSelector, (collection) => collection.collection);

export const { setInitialCollectionMeta } = collectionSlice.actions;

export default collectionSlice.reducer;
