// libs
import { Link } from 'react-router-dom';
// styled
import styled from '@emotion/styled/macro';

export const LogoLink = styled.a`
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: #212931;
  padding: 15px 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const CloseButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
  font-family: 'VisbyRoundCF SemiBold', Arial, sans-serif;

  & > svg {
    font-size: 32px;
  }
`;

export const NavLink = styled.a`
  cursor: pointer;
  display: flex;
  color: ${({ theme }) => theme.palette.common.white};
  align-items: center;
  font-size: 20px;
  line-height: 25px;
  font-family: 'VisbyRoundCF SemiBold', Arial, sans-serif;
  padding: 10px 0;
  margin-top: 10px;
  transition: color 0.2s ease-in-out;

  & > svg {
    margin-left: 10px;
    font-size: 20px;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.common.galacticTint};
  }
`;

export const NavButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 25px;
  padding: 10px 0;
  margin-top: 10px;
  color: ${({ theme }) => theme.palette.common.white};
  font-family: 'VisbyRoundCF SemiBold', Arial, sans-serif;
  transition: color 0.2s ease-in-out;
  width: 100%;

  & > svg {
    margin-left: 6px;
    font-size: 22px;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.common.galacticTint};
  }
`;

export const CollapseNavLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
`;

export const CollapseRouterLink = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
`;

export const CollapseNavLinkText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.common.white};
  font-family: 'VisbyRoundCF SemiBold';
  margin-left: 10px;
`;

export const CollapseNavLinkDescription = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.3);
  margin-top: 2px;
  font-family: 'VisbyRoundCF Regular';
`;

export const Section = styled.div`
  border-bottom: 1px solid #464b50;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
`;
