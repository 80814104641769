import styled from '@emotion/styled/macro';
import Img from 'components/Img';
import { Button as MaterialButton } from '@mui/material';

export const Wrapper = styled.div`
  padding: 40px;
  width: 560px;
  min-height: 340px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 40px 24px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
`;

export const Title = styled.div`
  font-size: 36px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ItemContainer = styled.div`
  display: flex;
  gap: 40px;
`;

export const ItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`;

export const SmallTitle = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const Warning = styled.div`
  font-size: 11px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.miamiMarmalade};
`;

export const SmallValue = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF Regular', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const InfoText = styled.div`
  font-size: 11px;
  font-family: 'VisbyRoundCF Regular', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const ItemName = styled.div`
  font-size: 20px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BigTitle = styled.div`
  font-size: 24px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const BigValue = styled.div`
  font-size: 24px;
  font-family: 'VisbyRoundCF Regular', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0 32px 0;
  background-color: ${({ theme }) => theme.palette.common.brilliantLicorice50};
`;

export const CommonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Image = styled(Img)`
  width: 120px;
  height: 120px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
  margin: 56px 0;
`;

export const PrimaryButton = styled(MaterialButton)`
  min-width: 180px;
`;

export const SecondaryButton = styled(MaterialButton)`
  min-width: 180px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.snowBank};
  }
`;

export const ErrorButton = styled(MaterialButton)`
  min-width: 180px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.common.boulevardier};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.incision};
  }
`;

export const SmallDescription = styled.div`
  font-size: 11px;
  font-family: 'VisbyRoundCF Regular', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Description = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF Regular', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const Label = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;
