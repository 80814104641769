import styled from '@emotion/styled/macro';
import { BidStatus } from './index';
import { css } from '@emotion/react';
import { Colors } from '../../themes/colors';

const getCountdownTitleColor = (bidStatus?: BidStatus) => {
  switch (bidStatus) {
    case BidStatus.Ending:
      return css`
        color: ${Colors.miamiMarmalade};
      `;
    case BidStatus.Ended:
      return css`
        color: ${Colors.boulevardier};
      `;
    case BidStatus.OnSale:
    default:
      return css`
        color: rgba(83, 83, 83, 0.495766);
      `;
  }
};

export const CountdownContainer = styled.div`
  display: flex;

  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};

  & > div {
    border-right: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  }

  & > div:last-child {
    border-right: 0;
  }
`;

export const CountdownValueContainer = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const CountdownNumber = styled.div`
  font-size: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 29px;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const CountdownTitle = styled.div<{ bidStatus?: BidStatus }>`
  font-size: 16px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 19px;
  letter-spacing: 0.8px;
  ${({ bidStatus }) => getCountdownTitleColor(bidStatus)};
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const Message = styled.div`
  font-size: 12px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 14px;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;
