import React from 'react';
// libs
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
// material-ui
import { Stack } from '@mui/material';
// components
import LoadingSpinner from 'components/LoadingSpinner';
import PassiveTag, { TagVariants } from 'components/PassiveTag';
import ListingForm from '../ListingForm';
// hooks
import useFetchListingImage from '../hooks/useFetchListingImage';
// store
import { uiLocaleSelector } from 'store/ui';
import { Item, Listing } from 'store/types';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import {
  ContentWrapper,
  Image,
  ItemName,
  NavLink,
  TagsTitle,
  Wrapper,
  Container,
  DescriptionTitle,
  DescriptionText,
  LinkTitle,
  ExternalLink,
  ImagePlaceholder,
} from '../styled';

export type Tag = {
  label: string;
  variant: TagVariants;
};

export interface ContentProps {
  isLoading: boolean;
  item: Item;
  tags: Tag[];
  itemId?: number;
  listing?: Listing;
}

const Content: React.FC<ContentProps> = ({ isLoading, item, tags, itemId, listing }) => {
  const { formatMessage } = useIntl();

  const locale = useSelector(uiLocaleSelector);
  const { image, isLoading: isImageLoading } = useFetchListingImage(item?.id);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Wrapper>
          <Container>
            <ContentWrapper>
              {isImageLoading ? <ImagePlaceholder /> : <Image src={image} />}

              <TagsTitle>{formatMessage({ id: IntlKeys.listingTags })}</TagsTitle>
              <Stack direction="row" spacing={1}>
                {tags.map(({ label, variant }, index) => (
                  <PassiveTag key={`${label}-${index}`} label={label} variant={variant} />
                ))}
              </Stack>

              <DescriptionTitle>{formatMessage({ id: IntlKeys.listingDescription })}</DescriptionTitle>
              <DescriptionText>{item?.description}</DescriptionText>

              <LinkTitle>{formatMessage({ id: IntlKeys.listingLink })}</LinkTitle>
              <ExternalLink href={item?.externalLink}>{item?.externalLink}</ExternalLink>
            </ContentWrapper>

            <ContentWrapper>
              <NavLink to={`/${locale}/my-collections/${item?.collection?.id}`}>{item?.collection?.name}</NavLink>
              <ItemName>{item?.name}</ItemName>

              <ListingForm listing={listing} itemId={itemId} item={item} />
            </ContentWrapper>
          </Container>
        </Wrapper>
      )}
    </>
  );
};

export default Content;
