import React, { useCallback, useMemo, useState } from 'react';
// material-ui
import { InputAdornment } from '@mui/material';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import { ReactComponent as StakingRewardIcon } from 'assets/icons/staking-reward.svg';
import { ReactComponent as VRAIcon } from 'assets/icons/cryptos/vra.svg';
// styled
import {
  Wrapper,
  Title,
  Container,
  InputsContainer,
  CurrencyTextField,
  CryptoTextField,
  TitleContainer,
  YearButton,
  YearButtonsContainer,
  RewardContainer,
  RewardPriceContainer,
  RewardPrice,
  Asterisk,
  RewardCryptoPrice,
  RewardNote,
} from './styled';

const CURRENCY_INPUT_PROPS = {
  startAdornment: <InputAdornment position="start">€</InputAdornment>,
};

const CRYPTO_INPUT_PROPS = {
  startAdornment: (
    <InputAdornment position="start">
      <VRAIcon width={20} height={20} />
    </InputAdornment>
  ),
};

const StakingRewardForm: React.FC = () => {
  const { formatMessage } = useIntl();

  const [currencyValue, setCurrencyValue] = useState('');
  const [cryptoValue, setCryptoValue] = useState('');
  const [selectedYear, setSelectedYear] = useState(0);

  const handleCurrencyChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyValue(event.target.value);
  }, []);

  const handleCryptoChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCryptoValue(event.target.value);
  }, []);

  const yearButtons = useMemo(
    () => [
      { content: IntlKeys.websiteSinglecoinEarn1Year },
      { content: IntlKeys.websiteSinglecoinEarn2Year },
      { content: IntlKeys.websiteSinglecoinEarn5Year },
    ],
    [],
  );

  return (
    <Wrapper>
      <TitleContainer>
        <StakingRewardIcon />
        <Title>{formatMessage({ id: IntlKeys.websiteSinglecoinEarnCalculateRewards })}</Title>
      </TitleContainer>
      <Container>
        <InputsContainer>
          <CurrencyTextField
            value={currencyValue}
            autoComplete="off"
            size="small"
            type="number"
            InputProps={CURRENCY_INPUT_PROPS}
            onChange={handleCurrencyChange}
          />
          <CryptoTextField
            value={cryptoValue}
            autoComplete="off"
            size="small"
            type="number"
            InputProps={CRYPTO_INPUT_PROPS}
            onChange={handleCryptoChange}
          />
          <YearButtonsContainer>
            {yearButtons.map(({ content }, index) => (
              <YearButton
                selected={selectedYear === index}
                onClick={() => setSelectedYear(index)}
                key={index.toString()}
              >
                {formatMessage({ id: content })}
              </YearButton>
            ))}
          </YearButtonsContainer>
        </InputsContainer>
        <RewardContainer>
          <RewardPriceContainer>
            <RewardPrice>
              €1.700,04 <Asterisk>*</Asterisk>
            </RewardPrice>
            <RewardCryptoPrice>23.483.48,98 VRA</RewardCryptoPrice>
          </RewardPriceContainer>
          <RewardNote>{formatMessage({ id: IntlKeys.websiteSinglecoinEarnAsterisk })}</RewardNote>
        </RewardContainer>
      </Container>
    </Wrapper>
  );
};

export default StakingRewardForm;
