import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { http } from 'utils/http';
import { RootState } from '../index';
import { Profile } from '../types';
import { SLICE_NAMES } from '../constants';

export type ProfileState = {
  firstName: string | null;
  lastName: string | null;
  userId: number | null;
  walletBalance: number;
};

const initialState: ProfileState = { firstName: null, lastName: null, userId: null, walletBalance: 9999.99 };

export const fetchProfile = createAsyncThunk('profile/fetch', async () => {
  return http.get('users/profile').catch((error) => error);
});

const profileSlice = createSlice({
  name: SLICE_NAMES.PROFILE,
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProfile.pending.type]: (state) => {
      state.firstName = initialState.firstName;
      state.lastName = initialState.lastName;
      state.userId = initialState.userId;
    },
    [fetchProfile.fulfilled.type]: (state, { payload }) => {
      const { data }: { data: Profile } = payload;
      state.firstName = data?.firstName;
      state.lastName = data?.lastName;
      state.userId = data.id;
    },
    [fetchProfile.rejected.type]: (state) => {
      state.firstName = initialState.firstName;
      state.lastName = initialState.lastName;
      state.userId = initialState.userId;
    },
  },
});

const profileSelector = (state: RootState): ProfileState => state[SLICE_NAMES.PROFILE];

export const profileUserIdSelector = createSelector(profileSelector, (profile) => profile.userId);
export const profileWalletBalanceSelector = createSelector(profileSelector, (profile) => profile.walletBalance);

export default profileSlice.reducer;
