import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CardInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 6px 0 6px;
  transition: all 0.3s ease-out;
`;

export const ImageSkeleton = styled(Skeleton)`
  height: 240px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const CreatorNameSkeleton = styled(Skeleton)`
  height: 10px;
  width: 100px;
  margin-bottom: 10px;
`;

export const ItemNameSkeleton = styled(Skeleton)`
  height: 10px;
  width: 80px;
`;

export const PriceTitleSkeleton = styled(Skeleton)`
  height: 10px;
  width: 100px;
  margin-bottom: 10px;
`;

export const PriceValueSkeleton = styled(Skeleton)`
  height: 10px;
  width: 80px;
`;

export const Wrapper = styled.div<{ isSmall: boolean }>`
  position: relative;
  width: 240px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 8px 20px 0 rgba(0, 35, 68, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-out;
  pointer-events: none;
  ${({ isSmall }) =>
    isSmall &&
    css`
      width: calc(16.66% - 20px);
      height: 238px;
      ${ImageSkeleton} {
        height: calc(100% - 80px);
      }
      ${PriceTitleSkeleton} {
        display: none;
      }
      ${PriceValueSkeleton} {
        margin-top: 18px;
      }
      ${CardInfo} {
        flex-direction: column;
        align-items: flex-start;
      }
    `}

  ${({ isSmall, theme }) => theme.media.lg`
    ${
      isSmall
        ? `
        width: calc(20.09% - 20px);
        height: 228px;
      `
        : `
        width: calc(33.73% - 30px);
      `
    }
  `}

  ${({ isSmall, theme }) => theme.media.md`
    ${
      isSmall
        ? `
        width: calc(20.08% - 20px);
        height: 238px;
      `
        : `
        width: calc(33.68% - 30px);
      `
    }
  `}

  ${({ isSmall, theme }) => theme.media.sm`
    ${
      isSmall
        ? `
        width: calc(25% - 18px);
      `
        : `
        height: 278px;
      `
    }

    ${ImageSkeleton} {
      height: calc(100% - 80px);
    }

    ${PriceTitleSkeleton} {
      display: none;
    }

    ${PriceValueSkeleton} {
      margin-top: 4px;
    }

    ${CardInfo} {
      flex-direction: column;
    }
  `}

  ${({ isSmall, theme }) => theme.media.xs`
    ${
      isSmall
        ? `
        width: calc(50% - 12px);
      `
        : `
        width: calc(50% - 20px);
      `
    }

    height: 238px;
  `}
`;
