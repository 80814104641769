import React from 'react';
// libs
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// components
import Routes from './Routes';
import FilterProvider from '../Filter/FilterProvider';
import DashboardProvider from '../../providers/DashboardProvider';

export const history = createBrowserHistory({ window });

function Router() {
  return (
    <HistoryRouter history={history}>
      <FilterProvider>
        <DashboardProvider>
          <Routes />
        </DashboardProvider>
      </FilterProvider>
    </HistoryRouter>
  );
}

export default Router;
