import React, { useCallback } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// components
import Modal from 'components/Modal';
// hooks
import useFetchNftItemThumbnail from 'hooks/useFetchNftItemThumbnail';
// store
import { uiLocaleSelector } from 'store/ui';
import { Item } from 'store/types';
import { declineAllBids, listingBidsSelector } from 'store/bid';
import { useAppDispatch } from 'store';
import { setNotification } from 'store/notification';
import { fetchNFTItem } from 'store/nftItem';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import {
  ButtonsContainer,
  Container,
  Description,
  ErrorButton,
  Image,
  ItemContainer,
  ItemInfoContainer,
  ItemName,
  SecondaryButton,
  Wrapper,
} from '../shared/styles/modals';
import { NavLink } from '../styled';

export interface DeclineAllBidsModalProps {
  isOpen: boolean;
  onClose: () => void;
  item: Item;
}

const DeclineAllBidsModal: React.FC<DeclineAllBidsModalProps> = ({ isOpen, onClose, item }) => {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const locale = useSelector(uiLocaleSelector);

  const { image } = useFetchNftItemThumbnail({ itemId: item?.id, thumbnailType: 'show', isMarketplaceItem: false });

  const bids = useSelector(listingBidsSelector);

  const { activeListing, id: itemId } = item;
  const { id: listingId } = activeListing ?? {};

  const handleDeclineAllClick = useCallback(() => {
    if (bids.length && listingId) {
      dispatch(declineAllBids(listingId))
        .unwrap()
        .then((data) => {
          if (itemId) {
            dispatch(
              setNotification({ message: formatMessage({ id: IntlKeys.toastAllBidsDeclined }), type: 'success' }),
            );
            dispatch(fetchNFTItem(data.itemId));
            onClose();
          }
          return data;
        })
        .then(() => {
          if (itemId) {
            navigate(`/${locale}/item-details/${itemId}`);
          }
        })
        .catch(() =>
          dispatch(
            setNotification({ message: formatMessage({ id: IntlKeys.toastSomethingWentWrong }), type: 'error' }),
          ),
        );
    }
  }, [bids.length, dispatch, formatMessage, itemId, listingId, locale, navigate, onClose]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Wrapper>
        <Container>
          <ItemContainer>
            <Image src={image} />
            <ItemInfoContainer>
              <ItemName>{item?.name}</ItemName>
              <NavLink to={`/${locale}/my-collections/${item?.collection?.id}`}>{item?.collection?.name}</NavLink>
            </ItemInfoContainer>
          </ItemContainer>
          <Description>{formatMessage({ id: IntlKeys.listingDeclineAllBidsDescription })}</Description>
        </Container>
        <ButtonsContainer>
          <ErrorButton size="large" onClick={handleDeclineAllClick}>
            {formatMessage({ id: IntlKeys.listingDeclineAllBidsDeclineAllButton })}
          </ErrorButton>
          <SecondaryButton size="large" onClick={onClose}>
            {formatMessage({ id: IntlKeys.commonCancel })}
          </SecondaryButton>
        </ButtonsContainer>
      </Wrapper>
    </Modal>
  );
};

export default DeclineAllBidsModal;
