import { IntlKeys } from '../keys';

export const nl = {
  // common
  [IntlKeys.commonClose]: 'Close',
  [IntlKeys.commonLorem]: 'Lorem ipsum',
  [IntlKeys.commonAccept]: 'Accepteer',
  [IntlKeys.commonCancel]: 'Annuleer',
  [IntlKeys.commonCreate]: 'Creëer',

  // header
  [IntlKeys.headerHome]: 'Home',
  [IntlKeys.headerCoins]: 'Coins',
  [IntlKeys.headerNfts]: 'NFTs',
  [IntlKeys.headerWallets]: 'Wallets',
  [IntlKeys.headerOrders]: 'Orders',
  [IntlKeys.headerExplore]: 'Verkennen',
  [IntlKeys.headerExplorePopoverTitle]: 'Een alles-in-één platform voor de toekomst van geld',
  [IntlKeys.headerExplorePopoverProducts]: 'Producten',
  [IntlKeys.headerExplorePopoverAbout]: 'Over',
  [IntlKeys.headerExplorePopoverProductItemsProofTitle]: 'Proof of Stake',
  [IntlKeys.headerExplorePopoverProductItemsBotsTitle]: 'COINMERCE Bots',
  [IntlKeys.headerExplorePopoverProductItemsBeginnersTitle]: 'Handelen voor beginners',
  [IntlKeys.headerExplorePopoverProductItemsAdvancedTitle]: 'Geavanceerde tools',
  [IntlKeys.headerExplorePopoverProductItemsGiftcardsTitle]: 'Cadeaubonnen',
  [IntlKeys.headerExplorePopoverProductItemsProofDescription]: 'Laat je bezit groeien',
  [IntlKeys.headerExplorePopoverProductItemsBotsDescription]: 'AI-ondersteunde algoritmen',
  [IntlKeys.headerExplorePopoverProductItemsBeginnersDescription]: 'Begin met Bitcoin en Crypto',
  [IntlKeys.headerExplorePopoverProductItemsAdvancedDescription]: 'Limieten, herhalende orders, meer',
  [IntlKeys.headerExplorePopoverProductItemsGiftcardsDescription]: 'Geef crypto cadeau!',
  [IntlKeys.headerExplorePopoverAboutItemsAboutTitle]: 'Over Coinmerce',
  [IntlKeys.headerExplorePopoverAboutItemsCryptoTitle]: 'Crypto Information Center',
  [IntlKeys.headerExplorePopoverAboutItemsNewsTitle]: 'Nieuws',
  [IntlKeys.headerExplorePopoverAboutItemsLearnTitle]: 'Leren',
  [IntlKeys.headerExplorePopoverAboutItemsHelpTitle]: 'Hulp nodig?',
  [IntlKeys.headerExplorePopoverAboutItemsVacanciesTitle]: 'Vacatures',
  [IntlKeys.headerExplorePopoverAboutItemsAboutDescription]: 'Team, waarden, doelen',
  [IntlKeys.headerExplorePopoverAboutItemsCryptoDescription]: 'Onze cryptowinkel',
  [IntlKeys.headerExplorePopoverAboutItemsNewsDescription]: 'Het laatste nieuws',
  [IntlKeys.headerExplorePopoverAboutItemsLearnDescription]: 'Tutorials en Protips',
  [IntlKeys.headerExplorePopoverAboutItemsHelpDescription]: 'Ondersteuning en contact',
  [IntlKeys.headerExplorePopoverAboutItemsVacanciesDescription]: 'Beschikbare banen',
  [IntlKeys.headerAccountPopoverTitle]: 'Mijn Account',
  [IntlKeys.headerAccountPopoverProfile]: 'Profiel',
  [IntlKeys.headerAccountPopoverAccountLevel]: 'Accountniveau',
  [IntlKeys.headerAccountPopoverSecurity]: 'Veiligheid',
  [IntlKeys.headerAccountPopoverSettings]: 'Instellingen',
  [IntlKeys.headerAccountPopoverFriends]: 'Vrienden',
  [IntlKeys.headerAccountPopoverWithdrawAddresses]: 'Externe Adresse',
  [IntlKeys.headerAccountPopoverLogout]: 'Uitloggen',
  [IntlKeys.headerNFTPopoverCreate]: 'Creëren',
  [IntlKeys.headerNFTPopoverMarketplace]: 'Marktplaats',
  [IntlKeys.headerNFTPopoverMyCollections]: 'Mijn collectie',
  [IntlKeys.headerNFTPopoverLaunchpad]: 'Launchpad',

  // footer
  [IntlKeys.footerLoginTitle]: 'Crypto is nu gemakkelijk.',
  [IntlKeys.footerLoginButton]: 'Log In',
  [IntlKeys.footerSignupButton]: 'Inschrijven',
  [IntlKeys.footerListGeneral]: 'Algemeen',
  [IntlKeys.footerListGeneralAboutUs]: 'Over ons',
  [IntlKeys.footerListGeneralCareers]: 'Carrières',
  [IntlKeys.footerListGeneralCoins]: 'Munten',
  [IntlKeys.footerListGeneralFraudPrevention]: 'Fraudepreventie',
  [IntlKeys.footerListGeneralBugFound]: 'Bug gevonden?',
  [IntlKeys.footerListGeneralApi]: 'API',
  [IntlKeys.footerListGeneralSitemap]: 'Sitemap',
  [IntlKeys.footerListPopular]: 'Populair',
  [IntlKeys.footerListPopularBuyBitcoin]: 'Bitcoin kopen',
  [IntlKeys.footerListPopularBitcoinPrice]: 'Bitcoin prijs',
  [IntlKeys.footerListPopularBuyEthereum]: 'Ethereum kopen',
  [IntlKeys.footerListPopularEthereumPrice]: 'Ethereum prijs',
  [IntlKeys.footerListPopularBuyRipple]: 'Ripple kopen',
  [IntlKeys.footerListPopularRipplePrice]: 'Ripple prijs',
  [IntlKeys.footerListPopularBuyLota]: 'lota kopen',
  [IntlKeys.footerListPopularLotaPrice]: 'lota prijs',
  [IntlKeys.footerListLegal]: 'Legaal',
  [IntlKeys.footerListLegalTermsAndConditions]: 'Algemene Voorwaarden',
  [IntlKeys.footerListLegalPrivacyPolicy]: 'Privacybeleid',
  [IntlKeys.footerListLegalRiskDisclaimer]: 'Risicodisclaimer',
  [IntlKeys.footerListJumpTo]: 'Spring naar',
  [IntlKeys.footerListJumpToRegister]: 'Register',
  [IntlKeys.footerListJumpToLogin]: 'Login',
  [IntlKeys.footerListJumpToNeedHelp]: 'Hulp nodig?',
  [IntlKeys.footerListJumpToLearn]: 'Leren',
  [IntlKeys.footerListJumpToNewsAndBlog]: 'Nieuws & Blog',
  [IntlKeys.footerStore]: 'Op te slaan',
  [IntlKeys.footerOffice]: 'Kantoor',

  // single coin view
  [IntlKeys.websiteSinglecoinBreadcrumbCoins]: 'Coins',
  [IntlKeys.websiteSinglecoinIntro]: 'Veilig {coin} kopen met iDEAL, Bancontact, SEPA, Sofort of creditcard via Coinmerce. ',
  [IntlKeys.websiteSinglecoinUsp1]: 'Veilig 200+ cryptocurrencies kopen',
  [IntlKeys.websiteSinglecoinUsp2]: 'Nederlands platform opgericht in 2017',
  [IntlKeys.websiteSinglecoinUsp3]: '100.000+ mensen gingen je voor',
  [IntlKeys.websiteSinglecoinUsp4]: 'Behulpzame supportafdeling',
  [IntlKeys.websiteSinglecoinH1]: '{coin} kopen met iDEAL',
  [IntlKeys.websiteSinglecoinH2Price]: '{coin} koers',
  [IntlKeys.websiteSinglecoinH2PriceCoin]: '{coin} prijs in euro',
  [IntlKeys.websiteSinglecoinH2PriceVolume]: '{coin} volume (24 uur)',
  [IntlKeys.websiteSinglecoinH2PriceMarketcap]: 'Marktkapitalisatie',
  [IntlKeys.websiteSinglecoinEarnH2]: 'Earn {coin}',
  [IntlKeys.websiteSinglecoinEarnRewards]: 'Gesch. jaarlijkse beloning',
  [IntlKeys.websiteSinglecoinEarnMinimum]: 'Minimaal in bezit',
  [IntlKeys.websiteSinglecoinEarnFrequencyTitle]: 'Betalingsfrequentie',
  [IntlKeys.websiteSinglecoinEarnFrequencyTime]: 'Wekelijks',
  [IntlKeys.websiteSinglecoinEarnCalculateRewards]: 'Bereken Earn rewards',
  [IntlKeys.websiteSinglecoinEarn1Year]: '1 jaar',
  [IntlKeys.websiteSinglecoinEarn2Year]: '2 jaar',
  [IntlKeys.websiteSinglecoinEarn5Year]: '5 jaar',
  [IntlKeys.websiteSinglecoinEarnAsterisk]: '*Rewards worden per week berekend en de percentages zijn niet gegarandeerd.',
  [IntlKeys.websiteSinglecoinEarnButton]: 'Start nu met Earn',
  [IntlKeys.websiteSinglecoinStakingH2]: 'Staking {amount}',
  [IntlKeys.websiteSinglecoinStakingRewards]: 'Gesch. jaarlijkse beloning',
  [IntlKeys.websiteSinglecoinStakingMinimum]: 'Minimaal in bezit',
  [IntlKeys.websiteSinglecoinStakingFrequencyTitle]: 'Betalingsfrequentie',
  [IntlKeys.websiteSinglecoinStakingFrequencyTime]: 'Wekelijks',
  [IntlKeys.websiteSinglecoinStakingCalculateRewards]: 'Bereken staking rewards',
  [IntlKeys.websiteSinglecoinStaking1Year]: '1 jaar',
  [IntlKeys.websiteSinglecoinStaking2Year]: '2 jaar',
  [IntlKeys.websiteSinglecoinStaking5Year]: '5 jaar',
  [IntlKeys.websiteSinglecoinStakingAsterisk]: '*Rewards worden per week berekend en de percentages zijn niet gegarandeerd.',
  [IntlKeys.websiteSinglecoinStakingButton]: 'Start nu met flexibel staken',
  [IntlKeys.websiteSinglecoinAboutTitle]: 'Over {coin}',
  [IntlKeys.websiteSinglecoinAboutWhat]: 'Wat is {coin}?',
  [IntlKeys.websiteSinglecoinReadmore]: 'Lees meer',
  [IntlKeys.websiteSinglecoinAblutHow]: 'Hoe werkt {coin}?',
  [IntlKeys.websiteSinglecoinAblutBuy]: 'Hoe {coin} kopen?',
  [IntlKeys.websiteSinglecoinPopularTitle]: 'Populaire assets',
  [IntlKeys.websiteSinglecoinPopularDescription]: 'Vind hieronder de meestverhandelde assets op Coinmerce of bekijk hier {link}.',
  [IntlKeys.websiteSinglecoinPopularDescriptionAllCrypto]: 'alle cryptocurrencies',
  [IntlKeys.websiteSinglecoinPopularAsset]: 'Asset',
  [IntlKeys.websiteSinglecoinPopularBalance]: 'Balans',
  [IntlKeys.websiteSinglecoinPopularPrice]: 'Koers',
  [IntlKeys.websiteSinglecoinPopularChange]: 'Verandering',
  [IntlKeys.websiteSinglecoinPopularType]: 'Type',
  [IntlKeys.websiteSinglecoinPopularActiveEarn]: 'Activeer Earn',
  [IntlKeys.websiteSinglecoinPopularActiveStaking]: 'Activeer Staking',
  [IntlKeys.websiteSinglecoinPopularBuy]: 'Kopen',
  [IntlKeys.websiteSinglecoinPopularSell]: 'Verkopen',
  [IntlKeys.websiteSinglecoinPopularDeposit]: 'Storten',
  [IntlKeys.websiteSinglecoinPopularWithdraw]: 'Opnemen',
  [IntlKeys.websiteSinglecoinPopularViewAllCrypto]: 'Bekijk alle cryptocurrencies',
  [IntlKeys.websiteSinglecoinBuyButtonMobile]: 'View all cryptocurrencies',
  [IntlKeys.websiteSinglecoinBuyItem]: 'Koop',
  [IntlKeys.websiteSinglecoinSellItem]: 'Verkoop',
  [IntlKeys.websiteSinglecoinBuyButton]: 'Koop nu',
  [IntlKeys.websiteSinglecoinSellButton]: 'Verkoop nu',
  [IntlKeys.websiteSinglecoinBuyButtonCoin]: 'Koop {coin} nu',
  [IntlKeys.websiteSinglecoinOrderLimit]: 'Stoplimiet order',
  [IntlKeys.websiteSinglecoinRepeatOrder]: 'Herhaalorder',
  [IntlKeys.websiteSinglecoinSwapItem]: 'Swap',
  [IntlKeys.websiteSinglecoinSwapButton]: 'Swap nu',

  // single bundle view
  [IntlKeys.websiteBundleviewBreadcrumbBundles]: 'Bundels',
  [IntlKeys.websiteBundleviewDescription]: 'Veilig {bundle} kopen met iDEAL, Bancontact, SEPA, Sofort of creditcard via Coinmerce.',
  [IntlKeys.websiteBundleviewBuyItem]: 'Koop',
  [IntlKeys.websiteBundleviewSellItem]: 'Verkoop',
  [IntlKeys.websiteBundleviewBuyButton]: 'Koop nu',
  [IntlKeys.websiteBundleviewSellButton]: 'Verkoop nu',
  [IntlKeys.websiteBundleviewBuyButtonMobile]: 'Koop {bundle} nu',
  [IntlKeys.websiteBundleviewMoreButtonMobile]: 'Meer',
  [IntlKeys.websiteBundleviewMax]: 'Max',
  [IntlKeys.websiteBundleviewUsp1]: 'Gediversifieerd investeren',
  [IntlKeys.websiteBundleviewUsp2]: 'Automatisch herbalanseren',
  [IntlKeys.websiteBundleviewUsp3]: '4.2/5 op basis van {link}',
  [IntlKeys.websiteBundleviewUsp3Link]: '350 reviews',
  [IntlKeys.websiteBundleviewUsp4]: '100.000+ mensen gingen je voor',
  [IntlKeys.websiteBundleviewOtherBundlesTitle]: 'Andere bundels',
  [IntlKeys.websiteBundleviewOtherBundlesDescription]: 'Hieronder vind je een selectie van beschikbare bundels bij Coinmerce. Met bundels verminder je risico\'s door diversificatie. Investeer eenvoudig in een selectie van een specifieke cryptocategorie. ',
  [IntlKeys.websiteBundleviewOtherBundlesBundle]: 'Bundel',
  [IntlKeys.websiteBundleviewOtherBundlesChange]: 'Verandering',
  [IntlKeys.websiteBundleviewOtherBundlesCoins]: 'Coins in de bundel',
  [IntlKeys.websiteBundleviewOtherBundlesBuyButton]: 'Koop nu',
  [IntlKeys.websiteBundleviewOtherBundlesOtherCoins]: '+{amount} andere coins',
  [IntlKeys.websiteBundleviewH1]: '{bundle} bundle rate',
  [IntlKeys.websiteBundleviewH2]: 'Redistribute Bundles',

  // item details page
  [IntlKeys.itemDetailsTags]: 'Tags',
  [IntlKeys.itemDetailsDescription]: 'Beschrijving',
  [IntlKeys.itemDetailsLink]: 'Link',
  [IntlKeys.itemDetailsBuyNowPrice]: 'Koop nu prijs',
  [IntlKeys.itemDetailsBuyNowPriceDescription]: '(Wanneer deze hoeveelheid is bereikt wordt de verkoper automatisch op de hoogte gebracht)',
  [IntlKeys.itemDetailsThisSaleEndsOn]: 'Deze verkoop eindigt op {value}',
  [IntlKeys.itemDetailsHighestBid]: 'Hoogste bod',
  [IntlKeys.itemDetailsHighestBidHoorayMessage]: 'Hoera! Jouw bod is het hoogste bod!',
  [IntlKeys.itemDetailsThisItemIsNotListed]: 'Dit item is niet gelist',
  [IntlKeys.itemDetailsLastPriceSold]: 'Laatst verkochte prijs',
  [IntlKeys.itemDetailsEditSale]: 'Wijzig de verkoop',
  [IntlKeys.itemDetailsRemovePosition]: 'Verwijder positie',
  [IntlKeys.itemDetailsSell]: 'Verkoop',
  [IntlKeys.itemDetailsWithdraw]: 'Withdraw',
  [IntlKeys.itemDetailsAcceptHighestBid]: 'Accepteer hoogste bod',
  [IntlKeys.itemDetailsDeclineAllBids]: 'Verwijder alle biedingen',
  [IntlKeys.itemDetailsPlaceBid]: 'Plaats bod',
  [IntlKeys.itemDetailsCancelBid]: 'Verwijder bod',
  [IntlKeys.itemDetailsBids]: 'Biedingen',
  [IntlKeys.itemDetailsYourBid]: 'Jouw bod*',
  [IntlKeys.itemDetailsYourBidDescription]: '(Dit is de hoeveelheid die jij betaald inclusief extra kosten, wanneer jouw bod wordt geaccepteerd)',
  [IntlKeys.itemDetailsReviewOffer]: 'Review Offer',
  [IntlKeys.itemDetailsBidsTablePriceHeader]: 'Prijs',
  [IntlKeys.itemDetailsBidsTableDateHeader]: 'Datum',
  [IntlKeys.itemDetailsBidsTableStatusHeader]: 'Status',
  [IntlKeys.itemDetailsRemovePositionModalTitle]: 'Verwijder positie',
  [IntlKeys.itemDetailsRemovePositionModalDescription]: 'Deze verkoop wordt beëindigd. Het item is nog steeds zichtbaar in jouw collectie, en het is weer mogelijk om deze opnieuw te verkopen of te importeren naar een externe wallet.',
  [IntlKeys.itemDetailsRemovePositionModalButtonRemove]: 'Verwijder positie',
  [IntlKeys.itemDetailsRemovePositionModalButtonClose]: 'Close',
  [IntlKeys.itemDetailsReviewModalBuyNowPrice]: 'Koop nu prijs',
  [IntlKeys.itemDetailsReviewModalYourBid]: 'Jouw bod',
  [IntlKeys.itemDetailsReviewModalBalanceAfterBid]: 'Jouw balans na jouw bod',
  [IntlKeys.itemDetailsReviewModalBalanceAfterBidDescription]: '(Dit is een schatting van jouw Coinmerce coins balans na de aankoop)',
  [IntlKeys.itemDetailsReviewModalButtonSubmitOffer]: 'Plaats bod',
  [IntlKeys.itemDetailsReviewModalButtonCancel]: 'Annuleer',
  [IntlKeys.itemDetailsReviewModalYouDontHaveFunds]: 'Jouw euro balans is {balance}. Je hebt momenteel niet genoeg fondsen',
  [IntlKeys.itemDetailsPayNow]: 'Betaal nu',
  [IntlKeys.itemDetailsPurchasePrice]: 'Aankoopprijs',
  [IntlKeys.itemDetailsYourBidAccepted]: 'Goed nieuws! Jouw bod is geaccepteerd!',
  [IntlKeys.itemDetailsPayModalSelectCollectionLabel]: 'Selecteer custom collectie*',
  [IntlKeys.itemDetailsYourBalanceAfterPurchase]: 'Jouw balans na de aankoop',
  [IntlKeys.itemDetailsYourBalanceAfterPurchaseDescription]: '(Dit is een schatting van jouw Coinmerce coins balans na de aankoop)',

  // contdown
  [IntlKeys.countdownSeconds]: 'Seconden',
  [IntlKeys.countdownMinutes]: 'Minuten',
  [IntlKeys.countdownHours]: 'Uren',
  [IntlKeys.countdownDays]: 'Dagen',
  [IntlKeys.countdownThisSaleIsOver]: 'Deze verkoop is beëindigd',
  [IntlKeys.countdownFiveMinutesLeft]: 'When bids are made in the last minute of the sale the timer automatically adds 5 minutes',

  // listing page
  [IntlKeys.listingTags]: 'Tags',
  [IntlKeys.listingDescription]: 'Beschrijving',
  [IntlKeys.listingLink]: 'Link',
  [IntlKeys.listingFormStartingPrice]: 'Startprijs*',
  [IntlKeys.listingFormStartingPriceDescription]: '(Dit is de verkoopprijs wanneer jouw item wordt gelist op de marktplaats)',
  [IntlKeys.listingFormBidIncrement]: 'Minimale bod verhoging*',
  [IntlKeys.listingFormBidIncrementDescription]: '(Dit is het minimale bedrag dat moet worden geboden)',
  [IntlKeys.listingFormBuyPrice]: 'Buy Price',
  [IntlKeys.listingFormBuyPriceDescription]: '(Wanneer een bod dit bedrag bereikt wordt de verkoop automatisch uitgevoerd, en wordt je op de hoogte gebracht)',
  [IntlKeys.listingFormDuration]: 'Lengte',
  [IntlKeys.listingFormFees]: 'Fees',
  [IntlKeys.listingFormCoinmerceFee]: 'Coinmerce Fee',
  [IntlKeys.listingFormReviewAndSubmitButton]: 'Bekijk en plaats',
  [IntlKeys.listingDurationModalTitle]: 'Lengte',
  [IntlKeys.listingDurationModalDateRange]: 'Datum bereik',
  [IntlKeys.listingDurationModalDateRangeThreeMonths]: '3 maanden',
  [IntlKeys.listingDurationModalDateRangeSixMonths]: '6 maanden',
  [IntlKeys.listingDurationModalDateRangeNineMonths]: '9 maanden',
  [IntlKeys.listingDurationModalDateRangeTwelveMonths]: '12 maanden',
  [IntlKeys.listingDurationModalDateRangeCustom]: 'Custom',
  [IntlKeys.listingDurationModalStartDate]: 'Startdatum',
  [IntlKeys.listingDurationModalEndDate]: 'Einddatum',
  [IntlKeys.listingDurationModalStartTime]: 'Starttijd',
  [IntlKeys.listingDurationModalEndTime]: 'Eindtijd',
  [IntlKeys.listingDurationModalDoneButton]: 'Klaar',
  [IntlKeys.listingReviewModalDateRangeThreeMonths]: '3 maanden',
  [IntlKeys.listingReviewModalDateRangeSixMonths]: '6 maanden',
  [IntlKeys.listingReviewModalDateRangeNineMonths]: '9 maanden',
  [IntlKeys.listingReviewModalDateRangeTwelveMonths]: '12 maanden',
  [IntlKeys.listingReviewModalDateRangeCustomRange]: 'Custom bereik',
  [IntlKeys.listingReviewModalBuyNowPrice]: 'Koop nu prijs',
  [IntlKeys.listingReviewModalStartingPrice]: 'Starting Price',
  [IntlKeys.listingReviewModalBidIncrement]: 'Bid Increment',
  [IntlKeys.listingReviewModalDuration]: 'Lengte',
  [IntlKeys.listingReviewModalDateRangeInfo]: '{dateRange}, starts on {dateStart}, ends on {dateEnd}',
  [IntlKeys.listingReviewModalSubmitListingButton]: 'Plaats verkoop',
  [IntlKeys.listingReviewModalCancelButton]: 'Annuleer',
  [IntlKeys.listingAcceptBidModalSettlement]: 'Regeling',
  [IntlKeys.listingAcceptBidModalSettlementDescription]: '(Dit is de hoeveelheid Coinmerce coins die jij ontvangt in jouw wallet)',
  [IntlKeys.listingAcceptBidModalBidAmount]: 'Hoogte bod',
  [IntlKeys.listingAcceptBidModalBidAmountDescription]: '(Dit is de hoeveelheid geboden door de koper)',
  [IntlKeys.listingAcceptBidModalCoinmerceFee]: 'Coinmerce Fee',
  [IntlKeys.listingAcceptBidModalCoinmerceFeeDescription]: '(Transactiekosten zijn {percentage} van de verkoopprijs)',
  [IntlKeys.listingDeclineBidDescription]: 'Dit bod wordt geweigerd en de koper wordt bericht. Deze actie is onomkeerbaar, wanneer het bod wordt geweigerd, kan deze niet meer worden geaccepteerd.',
  [IntlKeys.listingDeclineBidDeclineBidButton]: 'Weiger bod',
  [IntlKeys.listingDeclineAllBidsDescription]: 'Alle biedingen worden geweigerd en de kopers worden op de hoogte gebracht. Deze actie is onomkeerbaar, wanneer een bod wordt geweigerd kan deze niet meer worden geaccepteerd.',
  [IntlKeys.listingDeclineAllBidsDeclineAllButton]: 'Verwijder alle biedingen',

  // launchpad
  [IntlKeys.launchpadHeaderCoins]: 'Coins',
  [IntlKeys.launchpadHeaderProducts]: 'Products',
  [IntlKeys.launchpadHeaderAbout]: 'About',
  [IntlKeys.launchpadHeaderGetTheApp]: 'Get the app',
  [IntlKeys.launchpadNftLaunchpad]: 'NFT Launchpad',
  [IntlKeys.launchpadDescription]: "Welkom bij de NFT Launchpad van Coinmerce, de plek waar jij nieuwe NFT collecties als eerste kan ontdekken & kopen. Klaar om te investeren in NFT's? Ontdek direct alle opkomende projecten.",
  [IntlKeys.launchpadDiscoverButton]: 'Ontdekken',
  [IntlKeys.launchpadNavigateCategories]: 'Navigeer door de categorieën',
  [IntlKeys.launchpadFaqTitle]: "Veelgestelde vragen - NFT's",
  [IntlKeys.launchpadItemsTitle]: "Uitgelichte NFT's",
  [IntlKeys.launchpadItemsTitleDescription]: "Bekijk de top collecties van deze week.",

  // categories
  [IntlKeys.categoryArt]: 'Kunst',
  [IntlKeys.categoryCollectibles]: 'Collectibles',
  [IntlKeys.categoryDomainNames]: 'Domeinnamen',
  [IntlKeys.categoryMusic]: 'Muziek',
  [IntlKeys.categoryPhotography]: 'Fotografie',
  [IntlKeys.categorySports]: 'Sport',
  [IntlKeys.categoryTradingCards]: 'Trading Cards',
  [IntlKeys.categoryUtility]: 'Utility',
  [IntlKeys.categoryVirtualWorlds]: 'Virtuele wereld',

  // types
  [IntlKeys.typesAudio]: 'Audio',
  [IntlKeys.typesDocument]: 'Documents',
  [IntlKeys.typesImage]: 'Image',
  [IntlKeys.typesModel]: 'Model',
  [IntlKeys.typesVideo]: 'Video',

  // filters
  [IntlKeys.filtersOnSaleOnly]: 'Te koop',
  [IntlKeys.filterSearchBoxPlaceholder]: 'Zoek item of collectie',
  [IntlKeys.filterStatusTitle]: 'Status',
  [IntlKeys.filterPriceTitle]: 'Prijs',
  [IntlKeys.filterCategoriesTitle]: 'Categorieën',
  [IntlKeys.filterTypesTitle]: 'Types',
  [IntlKeys.filterAllTitle]: 'All',
  [IntlKeys.filterPriceTo]: 'Naar',
  [IntlKeys.filterPriceFrom]: 'Van',

  // marketplace collection page
  [IntlKeys.marketplaceCollectionNumberOfItems]: 'items',
  [IntlKeys.marketplaceCollectionOrderingNewest]: 'Nieuwste',
  [IntlKeys.marketplaceCollectionOrderingOldest]: 'Oudste',
  [IntlKeys.marketplaceCollectionOrderingLowToHigh]: 'Prijs laag naar hoog',
  [IntlKeys.marketplaceCollectionOrderingHighToLow]: 'Prijs hoog naar laag',
  [IntlKeys.marketplaceCollectionFilterTitle]: 'Filter',
  [IntlKeys.marketplaceCollectionFilterClearAll]: 'Wis alles',
  [IntlKeys.marketplaceCollectionOnSale]: 'Te koop',
  [IntlKeys.marketplaceCollectionOwn]: 'Eigen',
  [IntlKeys.marketplaceCollectionPrice]: 'Prijs',
  [IntlKeys.marketplaceCollectionView]: 'Bekijk',
  [IntlKeys.marketplaceCollectionNoItemsFound]: 'Geen items gevonden!',

  // my-collections page
  [IntlKeys.myCollectionsTitle]: 'Mijn collecties',
  [IntlKeys.myCollectionsNewCollection]: 'Nieuwe collectie',
  [IntlKeys.myCollectionsNoCollectionsFound]: 'No collections found! Click "New Collection" button to create one',
  [IntlKeys.myCollectionsNameOfCollection]: 'Name of collection',
  [IntlKeys.myCollectionsBlockchain]: 'Blockchain',
  [IntlKeys.myCollectionsCustomCollectionsTitle]: 'Mijn custom collecties',
  [IntlKeys.myCollectionsNewCustomCollection]: 'Mijn custom collecties',
  [IntlKeys.myCollectionsGoToCustomCollection]: 'Ga naar custom collectie',
  [IntlKeys.myCollectionsNoItemsYet]: 'Er zijn nog geen artikelen!',
  [IntlKeys.myCollectionsNameOfCustomCollection]: 'Naam van custom collectie',

  // toasts
  [IntlKeys.toastBidSubmitted]: 'Bod is succesvol geplaatst',
  [IntlKeys.toastSomethingWentWrong]: 'Er is iets misgegaan',
  [IntlKeys.toastBidCancelled]: 'Bod is succesvol geannuleerd',
  [IntlKeys.toastListingCreated]: 'Listing is succesvol aangemaakt',
  [IntlKeys.toastListingUpdated]: 'List is succesvol geupdate',
  [IntlKeys.toastBidApproved]: 'Bod is geaccepteerd',
  [IntlKeys.toastAllBidsDeclined]: 'Alle actieve biedingen zijn geannuleerd',
  [IntlKeys.toastCollectionCreated]: 'Collectie is aangemaakt',
  [IntlKeys.toastCollectionUpdated]: 'Collectie is geupdate',
  [IntlKeys.toastCollectionNameUpdated]: 'Collectie naam is geupdate',
  [IntlKeys.toastListingRemoved]: 'List is succesvol vergelgen',

  // validation
  [IntlKeys.validationRequired]: 'Benodigd',
  [IntlKeys.validationBidShouldBeEqualOrBigger]: 'Bod moet gelijk of hoger zijn aan {value}',
  [IntlKeys.validationShouldBeBiggerThanStartingPrice]: 'Moet groter zijn dan startprijs',
};
