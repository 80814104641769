export const FAQ_FASE_ONE = [
  {
    id: '1',
    title: 'Wat is een NFT?',
    details:
      "NFT is de afkorting voor non-fungible token. Een non-fungible token is een cryptografische token dat iets unieks vertegenwoordigt, de Nederlandse vertaling is dan ook niet-vervangbare token. NFT's kunnen digitale activa vertegenwoordigen, maar ook tokenized-versies van objecten in de echte wereld.\n" +
      '\n' +
      "In tegenstelling tot cryptocurrencies, ook wel fungible tokens, waar alle tokens gelijk zijn aan elkaar, is elk non-fungible token uniek en beperkt in aantal. Bijvoorbeeld: Als jij 1 ETH verstuurt naar persoon X en persoon X daarna 1 ETH terugstuurt naar jou, is er geen mogelijkheid om te weten of dit dezelfde ETH is. Bij NFT's is er wel een onderscheid te maken. Net als Ethereum is een NFT dus een digitaal activum, alleen is aan elke eenheid een unieke identificatie toegevoegd. Dit is ook precies wat NFT's zo interessant maakt, omdat elke NFT uniek is kunnen ze fungeren als bewijs van authenticiteit en eigendom in de digitale wereld. Inmiddels wordt er door meerdere projecten al geëxperimenteerd met NFT's voor verschillende gebruiksdoeleinden, zoals digitale identiteit, diploma‘s, licenties, gaming, luxegoederen, kunst en meer.\n",
    expanded: true,
    href: '',
  },
  {
    id: '2',
    title: "Hoe werken NFT's?",
    details:
      "NFT's maken gebruik van het ERC-721 en ERC-1155 protocol, in tegenstelling tot “normale” tokens die gebruik maken van het ERC-20 protocol. Het meest vooraanstaande protocol voor NFT's is tot nu toe ERC-721, dé standaard voor uitgifte en handel van niet-vervangbare activa op de Ethereum blockchain.\n" +
      '\n' +
      'Toch is het recentere, maar verbeterde ERC-1155 in opkomst. Het geeft de mogelijkheid om een enkel contract zowel fungible als non-fungible tokens te laten bevatten, waardoor er een heleboel nieuwe mogelijkheden ontstaan. Dit protocol is vooral handig voor wat complexere gebruiksdoeleinden, zoals games.\n' +
      '\n' +
      "NFT's kunnen ook verhandeld worden. Zo is er de mogelijkheid om bijvoorbeeld een schilderij ter waarde van €1.000.000 te laten tokenizen en de waarde te verdelen over 1000 NFT's. 1 NFT kan je dan zien als een aandeel in het schilderij ter waarde van €1.000. Dit maakt het dus ook interessant voor handelaren om NFT's aan te schaffen als investering. Deze tokens kunnen verhandeld worden op open markten, zoals OpenSea. Hier komen kopers en verkopers bij elkaar voor allerlei Crypto Collectibles, zeldzame digitale verzamelgoederen van soms hele grote waarde. Het is overigens wel belangrijk om te melden dat ook NFT's gevoelig zijn voor prijsfluctuaties door de werking van vraag en aanbod op de markt.Uiteindelijk is het nog maar de vraag of de NFT's voor digitale activa zoals CryptoKitties en in-game items, hun waarde zullen behouden, het blijven immers digitale verzamelgoederen waar je in het echte leven niet zoveel mee kan.",
    expanded: false,
    href: '',
  },
  {
    id: '3',
    title: "Waarvoor worden NFT's gebruikt?",
    details:
      'Non-fungible tokens hebben een wijd scala aan gebruiksmogelijkheden. Zo wordt het gebruikt in de gaming-industrie, waarbij je kan denken aan Sorare om voetbalplaatjes te sparen en CryptoKitties om eigenaar te worden van een digitale kat.\n' +
      "Het wordt echter ook gebruikt voor serieuzere situaties, zoals het opslaan van geboorteaktes, diploma‘s of identiteitsbewijzen. Dit zijn voorbeelden van unieke objecten die te tokenizen zijn naar een NFT. Maar ook het eerder besproken voorbeeld over een schilderij is een vorm van tokenizen naar NFT's.\n" +
      '\n' +
      "In het artikel op Coinmerce over de eerste VIDT NFT use case: luxegoederen, en de samenwerking met Amsterdam Vintage Watches wordt weer een andere use-case voor non-fungible tokens besproken. Hierin wordt verteld hoe VIDT Datalink NFT's gebruikt als bewijs van authenticiteit en als bewijs van oorsprong. Omdat elke NFT uniek is, is het ook makkelijk om op de blockchain te vinden wie de vorige eigenaars zijn geweest, dit kan dus dienen als bewijs van oorsprong. Verder worden de echtheidscertificaten met macrofoto‘s van de specifieke onderdelen van een horloge in dit geval opgeslagen in zijn bijbehorende NFT en dit dient op zijn beurt dus als bewijs van authenticiteit.",
    expanded: false,
    href: '',
  },
  {
    id: '4',
    title: "Waarom NFT's?",
    details:
      "Omdat NFT's per definitie niet te repliceren zijn zorgt dit ten eerste voor digitale schaarste. Als jij een NFT bezit, dan is dit jouw unieke NFT die niemand kan kopiëren Als iemand anders deze NFT wilt hebben zal die jouw NFT moeten overkopen.\n" +
      '\n' +
      "Het tweede punt wat NFT's bruikbaar en uniek maakt is dat ze decentraal beheerd worden. Als de NFTs een centrale beheerder hadden, dan was er altijd een mogelijkheid geweest om jouw NFT af te pakken. Een NFT beheer je zelf en zolang jouw wachtwoorden veilig zijn kan niemand er ooit bij. Verder zorgt het tokenizen van real-world objects naar NFT's weer voor meer liquiditeit in de niche markten, zoals die van beeldende kunst. Omdat het nu mogelijk is om bijvoorbeeld een gedeelte van een schilderij te kopen via NFT's zal het een stuk gemakkelijker worden voor verkopers.\n" +
      '\n' +
      "Het gebruik van NFT's als bewijs van authenticiteit en als bewijs van oorsprong kan niet vergeten worden en is ook een hele waardevolle toevoeging aan onze digitale economie. Dit is een grote troef als het gaat om het bestrijden van fraude en manipulatie van digitale-en real-world assets, certificaten, licenties en meer.",
    expanded: false,
    href: '',
  },
  { id: '5', title: 'Hoe koop je een NFT?', details: "Ga naar 'NFT's' op de website en klik op", expanded: false },
  {
    id: '6',
    title: 'Hoe bied ik een NFT collectie aan op Coinmerce?',
    details:
      "Ga naar 'NFTs' en kies Launchpad en selecteer 'New collection'. Vervolgens klik je op de blauwe Sell knop. Op het volgende scherm vul je de start prijs in, de stappen waamee de biedingen verhoogt kunnen worden en koop prijs. Bij het bereiken van de koop prijs krijg je een melding in je email. Je kunt ook de verloop tijd invullen voor hoe lang je wilt dat de collectie online staat. Daarna selecteer je review en indienen en dan zie je een overzicht van je listing. Als je op indienen klikt wordt de collectie definitief geplaatst.",
    expanded: false,
    href: '',
  },
  {
    id: '7',
    title: 'Wat bepaalt de waarde van een NFT?',
    details:
      'De waarde van een NFT wordt bepaald door vraag en aanbod. Aangezien elke NFT uniek is, kan er heel veel vraag ontstaan voor een specifieke NFT. Hierdoor kan de prijs dus flink stijgen!',
    expanded: false,
    href: '',
  },
  {
    id: '8',
    title: 'Wat is de meerwaarde van een NFT?',
    details:
      "Er zijn verschillende pluspunten aan een NFT. NFTs worden decentraal beheerd. Als jouw NFT in een game zit met een centrale beheerder, is er altijd een mogelijkheid geweest voor de game-developers om jouw NFT af te pakken. Een NFT beheer je zelf en zolang jouw wachtwoorden veilig zijn kan niemand er ooit bij. Ook zorgt het tokenizen van real-world objects naar NFT's weer voor meer liquiditeit in de niche markten, zoals die van beeldende kunst. Omdat het nu mogelijk is om bijvoorbeeld een gedeelte van een schilderij te kopen via NFT's zal het een stuk gemakkelijker worden voor verkopers.",
    expanded: false,
    href: '',
  },
  {
    id: '9',
    title: 'Wie bezit de NFT?',
    details:
      'NFTs worden decentraal beheerd. Als jouw NFT in een game zou zitten met een centrale beheerder, was er altijd een mogelijkheid geweest voor de game-developers om jouw NFT af te pakken. Een NFT beheer je zelf en zolang jouw wachtwoorden veilig zijn kan niemand er ooit bij. ',
    expanded: false,
    href: '',
  },
  {
    id: '10',
    title: 'Op welk netwerk wordt een NFT verhandeld?',
    details:
      'Er zijn verschillende netwerken die NFTs ondersteunen. Het meest gebruikte netwerk is Ethereum. Zo is MetaMask gebouwd op het Ethereum netwerk, en gebruikt ook OpenSea het Ethereum netwerk.\n' +
      '\n' +
      "Bij Coinmerce ondersteunen wij de volgende netwerken voor NFT'S: Ethereum en Polygon.",
    expanded: false,
    href: '',
  },
  {
    id: '11',
    title: 'Waarom zou ik een NFT kopen?',
    details:
      'Er zijn verschillende voordelen aan het bezitten van een NFT. NFTs zijn decentraal beheerd, waardoor zodra je de keys en wachtwoorden veilig houdt, de NFT altijd in jouw bezit blijft. Daarnaast zijn NFTs een toegankelijke manier om digitale goederen te verhandelen met iedereen ter wereld. ',
    expanded: false,
    href: '',
  },
  {
    id: '12',
    title: 'Is elke NFT uniek?',
    details:
      'Dezelfde of vergelijkbare technologie die wordt gebruikt voor cryptocurrencies als Bitcoin en Ethereum wordt gebruikt om de uniekheid van elke NFT te garanderen en om te bewijzen wie de eigenaar is. ',
    expanded: false,
    href: '',
  },
  {
    id: '13',
    title: 'Hoe verkoop ik een NFT op Coinmerce als creator?',
    details:
      'Je stuurt een e-mail naar ........ om jouw project aan te melden. Het team van Coinmerce zal het project vervolgens beoordelen of het aangeboden wordt op de marktplaats.',
    expanded: false,
    href: '',
  },
  {
    id: '14',
    title: "Hebben NFT's en de Metaverse iets met elkaar te maken?",
    details:
      "Items, accounts en avatars kunnen in de Metaverse worden uitgegeven als NFT. Hierdoor kunnen spullen in de Metaverse worden verhandeld voor real-life geld. En kunnen NFT's uit de Metaverse, door de uniekheid en de schaarste van een NFT veel geld waard worden. ",
    expanded: false,
    href: '',
  },
  {
    id: '15',
    title: 'In welke vorm kan een NFT bestaan?',
    details:
      "Er zijn verschillende manieren waarop NFT's kunnen bestaan. Zo kunnen Avatars en profielfoto's worden verhandeld, kunst kan in kleine stukjes worden gedeeld (of juist als 1 grote NFT worden verhandeld!), collectibles zoals items uit spellen en verzamelkaarten. De mogelijkheden zijn eindeloos!",
    expanded: false,
    href: '',
  },
  {
    id: '16',
    title: 'Wat zijn de kosten?',
    details:
      'De koper betaald een percentage aan Coinmerce. Als de verkoper een creator fee heeft ingesteld, betaald de koper deze fee ook.',
    expanded: false,
    href: '',
  },
  {
    id: '17',
    title: 'Kan ik een aankoop of verkoop annuleren?',
    details: 'Nee, aankopen en verkopen zijn bindend.',
    expanded: false,
    href: '',
  },
  {
    id: '18',
    title: 'Op welke manieren kan de verkoper zijn NFT te koop aanbieden?',
    details:
      "De verkoper kan zijn NFT's op twee manieren aanbieden. De NFT kan met een onbepaalde looptijd worden aangeboden, of kan als veiling ingesteld worden.",
    expanded: false,
    href: '',
  },
  {
    id: '19',
    title: 'Op welke manieren kan de koper een NFT kopen?',
    details:
      "Dat kan door het hoogste bod te bieden of, als de verkoper dit heeft ingesteld, kiezen voor de  'buy now' optie.",
    expanded: false,
    href: '',
  },
  {
    id: '20',
    title: 'Wanneer is de veiling afgelopen?',
    details:
      'De veiling is afgelopen als de countdown timer (resterende tijd) op 0 staat, dan wint het hoogste bod. Als in de laatste minuut een hoger bod word gedaan, dan wordt de veiling verlengd met vijf minuten.',
    expanded: false,
    href: '',
  },
  {
    id: '21',
    title: 'Op welke manier kan ik de NFT betalen?',
    details:
      'Dat kan met Coinmerce Coins of met IDEAL. Als je een bod doet moet je hiervoor genoeg Coinmerce Coins in je wallet hebben staan.',
    expanded: false,
    href: '',
  },
  {
    id: '22',
    title: 'Binnen hoeveel tijd nadat de verkoper mijn bod heeft geaccepteerd moet ik betalen?',
    details: 'Wanneer een bod wordt geaccepteerd heeft de bieder 24 uur om aan de betaling te voldoen.',
    expanded: false,
    href: '',
  },
  {
    id: '23',
    title: 'Wat gebeurt er als de koper niet binnen 24 uur de betaling heeft voldaan?',
    details:
      'Dan kan de verkoper ervoor kiezen om de verkoop te verlengen, een ander bod te accepteren, of het item helemaal van de NFT marktplaats af te halen.',
    expanded: false,
    href: '',
  },
  {
    id: '24',
    title: 'Worden de NFT projecten door Coinmerce onderzocht voordat deze op het platform komen? ',
    details: 'Ja, Coinmerce doet vooraf onderzoek om een NFT project te listen, hierbij maken wij gebruik van KYC.',
    expanded: false,
    href: '',
  },
  {
    id: '25',
    title: 'Welke NFT projecten zijn er beschikbaar op de Coinmerce marktplaats?',
    details:
      'In de eerste fase zullen alleen NFT projecten beschikbaar zijn die gelanceerd zijn op de Coinmerce NFT launchpad',
    expanded: false,
    href: '',
  },
  {
    id: '26',
    title: 'Kan ik mijn gekochte NFT versturen naar een andere wallet?',
    details:
      'In de eerste fase is het mogelijk om via het Ethereum of Polygon netwerk je NFT te versturen naar een andere wallet. Deze wallet moet wel eerst geverifieerd worden. Zie daarvoor dit artikel.',
    expanded: false,
    href: 'https://coinmerce.io/nl/need-help/aanvullende-verificatie-voor-het-versturen-van-crypto/',
  },
  {
    id: '27',
    title: 'Hoe verifieer ik een externe NFT wallet?',
    details: 'Klik hier om te lezen waar de wallet verificatie aan moet voldoen.',
    expanded: false,
    href: 'https://coinmerce.io/nl/need-help/aanvullende-verificatie-voor-het-versturen-van-crypto/',
  },
];
