import React, { ReactNode } from 'react';
// material-ui
import { AccordionProps as MuiAccordionProps } from '@mui/material';
// icons
import { ExpandMore } from '@mui/icons-material';
// styled
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './styled';

export interface AccordionProps extends Omit<MuiAccordionProps, 'children'> {
  summaryTitle: string;
  detailsContent: ReactNode | null;
}

function Accordion({ summaryTitle, detailsContent, ...rest }: AccordionProps) {
  return (
    <StyledAccordion {...rest}>
      <StyledAccordionSummary expandIcon={<ExpandMore />}>{summaryTitle}</StyledAccordionSummary>
      <StyledAccordionDetails>{detailsContent}</StyledAccordionDetails>
    </StyledAccordion>
  );
}

export default Accordion;
