import React from 'react';
// styled
import { Input, Label, Wrapper } from './styled';

export type TagVariants = 'primary' | 'secondary' | 'success';

export interface TagProps {
  label: string;
  variant: TagVariants;
}

function PassiveTag({ label, variant = 'secondary' }: TagProps) {
  return (
    <Wrapper isPassive={true}>
      <Label control={<Input />} label={label} checked={true} variant={variant} />
    </Wrapper>
  );
}

export default PassiveTag;
