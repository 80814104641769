import React, { useCallback, useMemo, useState } from 'react';
// libs
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Field, FormikProvider, useFormik } from 'formik';
import { omit } from 'lodash';
import { useIntl, FormattedMessage } from 'react-intl';
// material-ui
import { Button, InputAdornment } from '@mui/material';
// components
import Select from 'components/Select';
import DurationModal from '../DurationModal';
import ReviewModal from '../ReviewModal';
// store
import { useAppDispatch } from 'store';
import { createListing, updateListing } from 'store/listing';
import { CreateListingPayload, Item, Listing, Status } from 'store/types';
import { uiLocaleSelector } from 'store/ui';
// constants
import { DATE_RANGE_OPTIONS } from '../constants';
import { COINMERCE_FEE_PERCENT } from 'constants/general';
import { IntlKeys } from 'localization/keys';
// styled
import {
  CompleteButtonWrapper,
  DurationContainer,
  DurationFieldLabel,
  FeesContainer,
  FeesTitle,
  FeeText,
  FeeWrapper,
  PriceField,
  PriceFieldLabel,
  PriceFieldsContainer,
  PriceLabelInfo,
} from '../styled';
import { setNotification } from '../../../store/notification';
import { fetchNFTItem } from '../../../store/nftItem';

const PRICE_INPUT_PROPS = {
  startAdornment: <InputAdornment position="start">€</InputAdornment>,
};

const INITIAL_VALUES = {
  priceType: 'auction',
  price: '' as unknown as number,
  buyoutPrice: '' as unknown as number,
  bidStepSize: '' as unknown as number,
  dateTimeStart: '',
  dateTimeEnd: '',
  isReserved: false,
  tags: '',
  reservedForId: undefined,
  status: Status.PUBLISHED,
  itemId: undefined,
  dateRange: '3',
};

const validationSchema = Yup.object().shape({
  price: Yup.number().required(() => <FormattedMessage id={IntlKeys.validationRequired} />),
  buyoutPrice: Yup.number().moreThan(Yup.ref('price'), () => (
    <FormattedMessage id={IntlKeys.validationShouldBeBiggerThanStartingPrice} />
  )),
  bidStepSize: Yup.string().required(() => <FormattedMessage id={IntlKeys.validationRequired} />),
});

export interface ListingFormProps {
  itemId?: number;
  listing?: Listing;
  item: Item;
}

function ListingForm({ itemId, listing, item }: ListingFormProps) {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locale = useSelector(uiLocaleSelector);

  const [isDurationModalOpened, setIsDurationModalOpened] = useState(false);
  const [isReviewModalOpened, setIsReviewModalOpened] = useState(false);

  const handleDurationModalClose = useCallback(() => setIsDurationModalOpened(false), []);
  const handleReviewModalClose = useCallback(() => setIsReviewModalOpened(false), []);

  const listingId = useMemo(() => listing?.id, [listing]);

  const formikValues = useMemo(() => {
    if (listing) {
      const { owner, item, ...rest } = listing;
      return { ...rest, dateRange: 'custom' } as CreateListingPayload;
    }
    return INITIAL_VALUES;
  }, [listing]);

  const formik = useFormik<CreateListingPayload>({
    initialValues: formikValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      const submitValues = {
        ...omit(values, ['dateRange']),
        itemId: listing ? values.itemId : (itemId as number),
      };
      if (listing && listingId) {
        return dispatch(updateListing({ submitValues, listingId }))
          .unwrap()
          .then((listing) => {
            dispatch(
              setNotification({ message: formatMessage({ id: IntlKeys.toastListingUpdated }), type: 'success' }),
            );
            dispatch(fetchNFTItem(listing.itemId));
            return listing;
          })
          .then((listing) => navigate(`/${locale}/item-details/${listing.itemId}`))
          .catch((error) => error);
      }
      return dispatch(createListing(submitValues))
        .unwrap()
        .then((listing) => {
          dispatch(setNotification({ message: formatMessage({ id: IntlKeys.toastListingCreated }), type: 'success' }));
          dispatch(fetchNFTItem(listing.itemId));
          return listing;
        })
        .then((listing) => navigate(`/${locale}/item-details/${listing.itemId}`))
        .catch(() =>
          dispatch(
            setNotification({ message: formatMessage({ id: IntlKeys.toastSomethingWentWrong }), type: 'error' }),
          ),
        );
    },
  });

  const handleChangeRange = useCallback(
    (value: string) => {
      setIsDurationModalOpened(true);
      formik.setFieldValue('dateRange', value);
    },
    [formik],
  );

  const validateForm = useCallback(() => {
    formik.validateForm().then((errors) => {
      const fieldsWithErrors = Object.keys(errors).map((fieldName) => fieldName);
      return fieldsWithErrors.length
        ? fieldsWithErrors.forEach((fieldName) => formik.setFieldTouched(fieldName, true, true))
        : setIsReviewModalOpened(true);
    });
  }, [formik]);

  const mappedDateRangeOptions = useMemo(
    () =>
      DATE_RANGE_OPTIONS.map(({ value, label }) => ({
        value,
        label: formatMessage({ id: label }),
      })),
    [formatMessage],
  );

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <PriceFieldsContainer>
          <PriceFieldLabel>
            {formatMessage({ id: IntlKeys.listingFormStartingPrice })}{' '}
            <PriceLabelInfo>{formatMessage({ id: IntlKeys.listingFormStartingPriceDescription })}</PriceLabelInfo>
            <Field name="price" component={PriceField} InputProps={PRICE_INPUT_PROPS} type="number" required />
          </PriceFieldLabel>

          <PriceFieldLabel>
            {formatMessage({ id: IntlKeys.listingFormBidIncrement })}{' '}
            <PriceLabelInfo>{formatMessage({ id: IntlKeys.listingFormBidIncrementDescription })}</PriceLabelInfo>
            <Field name="bidStepSize" component={PriceField} InputProps={PRICE_INPUT_PROPS} type="number" required />
          </PriceFieldLabel>

          <PriceFieldLabel>
            {formatMessage({ id: IntlKeys.listingFormBuyPrice })}{' '}
            <PriceLabelInfo>{formatMessage({ id: IntlKeys.listingFormBuyPriceDescription })}</PriceLabelInfo>
            <Field name="buyoutPrice" component={PriceField} InputProps={PRICE_INPUT_PROPS} type="number" required />
          </PriceFieldLabel>
        </PriceFieldsContainer>

        <DurationContainer>
          <DurationFieldLabel>{formatMessage({ id: IntlKeys.listingFormDuration })}</DurationFieldLabel>

          <Select
            options={mappedDateRangeOptions}
            value={formik.values.dateRange}
            // onChange={handleChangeRange}
            onMenuItemClick={handleChangeRange}
          />
        </DurationContainer>

        <FeesContainer>
          <FeesTitle>{formatMessage({ id: IntlKeys.listingFormFees })}</FeesTitle>

          <FeeWrapper>
            <FeeText>{formatMessage({ id: IntlKeys.listingFormCoinmerceFee })}</FeeText>
            <FeeText>{COINMERCE_FEE_PERCENT}%</FeeText>
          </FeeWrapper>
        </FeesContainer>

        <CompleteButtonWrapper>
          <Button fullWidth size="large" onClick={validateForm}>
            {formatMessage({ id: IntlKeys.listingFormReviewAndSubmitButton })}
          </Button>
        </CompleteButtonWrapper>
      </form>

      <DurationModal isOpen={isDurationModalOpened} onClose={handleDurationModalClose} />
      <ReviewModal isOpen={isReviewModalOpened} onClose={handleReviewModalClose} item={item} />
    </FormikProvider>
  );
}

export default ListingForm;
