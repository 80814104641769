import React, { useEffect, useState } from 'react';
// material-ui
import { Checkbox } from '@mui/material';
// components
import Tag from 'components/Tags/Tag';
// styled
import { StyledFormControlLabel } from './styled';
import { useIntl } from 'react-intl';

type CheckboxesGroupProps = {
  label: string;
  name: string;
  onChange: (filterName: string, filterValues: string[]) => void;
  checkboxes: { name: string; label: string }[];
  checked: string[];
  showAllCheckbox?: boolean;
  tagStyleCheckbox?: boolean;
};

const CheckboxesGroup: React.FC<CheckboxesGroupProps> = ({
  label,
  checkboxes,
  name,
  checked,
  onChange,
  showAllCheckbox = true,
  tagStyleCheckbox = false,
}) => {
  const { formatMessage } = useIntl();

  const [selected, setSelected] = useState<string[]>((checked as [string]) || []);

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = checkboxes.map((n) => n.name);
      setSelected(newSelected);
      onChange(name, newSelected);
      return;
    }
    setSelected([]);
    onChange(name, []);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: string) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
    onChange(name, newSelected);
  };

  useEffect(() => {
    setSelected(checked || []);
  }, [checked]);

  return (
    <div>
      {showAllCheckbox && (
        <StyledFormControlLabel
          label={formatMessage({ id: label })}
          control={
            <Checkbox
              indeterminate={selected.length > 0 && selected.length < checkboxes.length}
              checked={checkboxes.length > 0 && selected.length === checkboxes.length}
              onChange={handleSelectAllChange}
            />
          }
        />
      )}

      {checkboxes.map(({ name, label }) =>
        tagStyleCheckbox ? (
          <Tag
            label={formatMessage({ id: label })}
            key={name}
            name={name}
            checked={selected.includes(name)}
            onChange={(event) => handleChange(event, name)}
          />
        ) : (
          <StyledFormControlLabel
            key={name}
            label={formatMessage({ id: label })}
            control={
              <Checkbox name={name} checked={selected.includes(name)} onChange={(event) => handleChange(event, name)} />
            }
          />
        ),
      )}
    </div>
  );
};

export default CheckboxesGroup;
