import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
// libs
import {
  addMonths,
  startOfDay,
  endOfDay,
  formatISO,
  differenceInMinutes,
  parseISO,
  differenceInDays,
  addMinutes,
  isValid,
} from 'date-fns';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
// material-ui
import { TextField, MenuItem, TextFieldProps } from '@mui/material';
// components
import Modal from 'components/Modal';
// store
import { CreateListingPayload } from 'store/types';
// constants
import { DATE_RANGE_OPTIONS } from '../constants';
import { IntlKeys } from 'localization/keys';
// styled
import {
  Wrapper,
  Title,
  FieldsContainer,
  TextFieldWrapper,
  TextFieldLabel,
  DoneButton,
  DateRangeLabel,
} from './styled';

export interface DurationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type DateTime = Date | null;

function DurationModal({ isOpen, onClose }: DurationModalProps) {
  const { formatMessage } = useIntl();

  const { values, setFieldValue } = useFormikContext<CreateListingPayload & { dateRange: string }>();

  const handleChangeRange = (event: ChangeEvent<HTMLInputElement>) => setFieldValue('dateRange', event.target.value);

  const handleDateTimeStartChange = (dateTime: DateTime) => {
    if (isValid(dateTime)) {
      if (dateTime instanceof Date) {
        return setFieldValue('dateTimeStart', formatISO(dateTime));
      }
      return setFieldValue('dateTimeStart', dateTime);
    }
  };

  const handleDateTimeEndChange = (dateTime: DateTime) => {
    if (isValid(dateTime)) {
      if (dateTime instanceof Date) {
        return setFieldValue('dateTimeEnd', formatISO(dateTime));
      }
      return setFieldValue('dateTimeEnd', dateTime);
    }
  };

  const setDateRange = useCallback(
    (monthsGap: number) => {
      const today = new Date();
      const start = startOfDay(today);
      const end = endOfDay(addMonths(today, monthsGap));

      setFieldValue('dateTimeStart', formatISO(start));
      setFieldValue('dateTimeEnd', formatISO(end));
    },
    [setFieldValue],
  );

  const updateDateTime = useCallback(
    (range: string | undefined) => {
      switch (range) {
        case '3':
          return setDateRange(3);
        case '6':
          return setDateRange(6);
        case '9':
          return setDateRange(9);
        case '12':
          return setDateRange(12);
      }
    },
    [setDateRange],
  );

  useEffect(() => {
    updateDateTime(values.dateRange);
  }, [values.dateRange, updateDateTime]);

  // TODO: refactor this mess
  const timeEndMinTime = useMemo(() => {
    const parsedDateTimeStart = parseISO(values.dateTimeStart);
    const parsedDateTimeEnd = parseISO(values.dateTimeEnd);
    if (
      differenceInDays(parsedDateTimeEnd, Date.now()) <= 0 &&
      differenceInMinutes(parsedDateTimeEnd, Date.now()) < 5 &&
      differenceInDays(parsedDateTimeEnd, parsedDateTimeStart) <= 0 &&
      differenceInMinutes(parsedDateTimeEnd, parsedDateTimeStart) < 5
    ) {
      return parseISO(formatISO(addMinutes(Date.now(), 5)));
    }
    if (
      differenceInDays(parsedDateTimeEnd, Date.now()) <= 0 &&
      differenceInMinutes(parsedDateTimeEnd, Date.now()) < 5
    ) {
      return parseISO(formatISO(addMinutes(Date.now(), 5)));
    }
    if (
      differenceInDays(parsedDateTimeEnd, parsedDateTimeStart) <= 0 &&
      differenceInMinutes(parsedDateTimeEnd, parsedDateTimeStart) < 5
    ) {
      return parseISO(formatISO(addMinutes(parsedDateTimeStart, 5)));
    }
  }, [values.dateTimeEnd, values.dateTimeStart]);

  // const handleTimeEndError = useCallback(() => {
  //   const parsedDateTimeStart = parseISO(values.dateTimeStart);
  //   const parsedDateTimeEnd = parseISO(values.dateTimeEnd);
  //   if (
  //     (differenceInYears(parsedDateTimeStart, Date.now()) <= 0 &&
  //       differenceInDays(parsedDateTimeStart, Date.now()) <= 0 &&
  //       differenceInMinutes(parsedDateTimeStart, Date.now()) < 5) ||
  //     (differenceInYears(parsedDateTimeEnd, parsedDateTimeStart) <= 0 &&
  //       differenceInDays(parsedDateTimeEnd, parsedDateTimeStart) <= 0 &&
  //       differenceInMinutes(parsedDateTimeEnd, parsedDateTimeStart) < 5)
  //   ) {
  //     setFieldValue('dateTimeEnd', formatISO(addMinutes(Date.now(), 6)));
  //   }
  // }, [setFieldValue, values.dateTimeEnd, values.dateTimeStart]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Wrapper>
        <Title>{formatMessage({ id: IntlKeys.listingDurationModalTitle })}</Title>
        <TextFieldWrapper>
          <DateRangeLabel>{formatMessage({ id: IntlKeys.listingDurationModalDateRange })}</DateRangeLabel>
          <TextField size="small" select value={values.dateRange} onChange={handleChangeRange}>
            {DATE_RANGE_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value} divider>
                {formatMessage({ id: option.label })}
              </MenuItem>
            ))}
          </TextField>
        </TextFieldWrapper>
        <FieldsContainer>
          <TextFieldWrapper>
            <TextFieldLabel>{formatMessage({ id: IntlKeys.listingDurationModalStartDate })}</TextFieldLabel>
            <DatePicker
              inputFormat="dd-MM-yyyy"
              value={values.dateTimeStart}
              onChange={handleDateTimeStartChange}
              renderInput={(props: TextFieldProps) => <TextField size="small" {...props} />}
              disabled={values.dateRange !== 'custom'}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <TextFieldLabel>{formatMessage({ id: IntlKeys.listingDurationModalEndDate })}</TextFieldLabel>
            <DatePicker
              disablePast
              inputFormat="dd-MM-yyyy"
              value={values.dateTimeEnd}
              onChange={handleDateTimeEndChange}
              renderInput={(props: TextFieldProps) => <TextField size="small" {...props} />}
              disabled={values.dateRange !== 'custom'}
            />
          </TextFieldWrapper>
        </FieldsContainer>
        <FieldsContainer>
          <TextFieldWrapper>
            <TextFieldLabel>{formatMessage({ id: IntlKeys.listingDurationModalStartTime })}</TextFieldLabel>
            <TimePicker
              inputFormat="HH:mm"
              value={values.dateTimeStart}
              onChange={handleDateTimeStartChange}
              renderInput={(props: TextFieldProps) => (
                <TextField size="small" disabled={!values.dateTimeStart} {...props} />
              )}
              disabled={!values.dateTimeStart}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <TextFieldLabel>{formatMessage({ id: IntlKeys.listingDurationModalEndTime })}</TextFieldLabel>
            <TimePicker
              // onError={handleTimeEndError}
              minTime={timeEndMinTime}
              inputFormat="HH:mm"
              value={values.dateTimeEnd}
              onChange={handleDateTimeEndChange}
              renderInput={(props: TextFieldProps) => (
                <TextField size="small" disabled={!values.dateTimeEnd} {...props} />
              )}
              disabled={!values.dateTimeEnd}
            />
          </TextFieldWrapper>
        </FieldsContainer>
        <DoneButton fullWidth size="large" onClick={onClose}>
          {formatMessage({ id: IntlKeys.listingDurationModalDoneButton })}
        </DoneButton>
      </Wrapper>
    </Modal>
  );
}

export default DurationModal;
