import React, { useCallback, useMemo, useRef, useState } from 'react';
// libs
import { Line } from 'react-chartjs-2';
import { CrosshairPlugin } from 'chartjs-plugin-crosshair';
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  LogarithmicScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  TooltipModel,
} from 'chart.js';
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  format,
  subDays,
  subHours,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
// components
import CustomTooltip from './CustomTooltip';
// helpers
import { roundTo2Digits } from 'helpers/renderHelpers';
// constants
import { Colors } from 'themes/colors';
// styled
import {
  ChangeContainer,
  ChartContainer,
  ChartHeader,
  ChartTitle,
  Divider,
  LeftColumn,
  PercentChangeLabel,
  PriceChangeLabel,
  PriceLabel,
  Tab,
  TabsContainer,
  Wrapper,
} from './styled';
// chartjs-adapter
import 'chartjs-adapter-date-fns';
import { DashboardState, useDashboardContext } from '../../../../providers/DashboardProvider';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LogarithmicScale,
  CrosshairPlugin,
);

const hourLabels = Array.from({ length: 13 }).map((_, index) => {
  const time = subHours(addMinutes(Date.now(), (index + 1) * 5), 1);
  return `${format(time, 'HH:mm')}`;
});

const dayLabels = Array.from({ length: 25 }).map((_, index) => {
  const time = subDays(addHours(Date.now(), index), 1);
  return `${format(time, 'HH:mm')}`;
});

const weekLabels = Array.from({ length: 8 }).map((_, index) => {
  const time = subWeeks(addDays(Date.now(), index), 1);
  return `${format(time, 'MMM dd')}`;
});

const monthLabels = Array.from({ length: 30 }).map((_, index) => {
  const time = subMonths(addDays(Date.now(), index), 1);
  return `${format(time, 'MMM dd')}`;
});

const yearLabels = Array.from({ length: 13 }).map((_, index) => {
  const time = subYears(addMonths(Date.now(), index), 1);
  return `${format(time, 'MMM dd')}`;
});

const all = Array.from({ length: 13 }).map((_, index) => {
  const time = subYears(addMonths(Date.now(), index), 1);
  return `${format(time, 'MMM dd')}`;
});

const labelsArray = [hourLabels, dayLabels, weekLabels, monthLabels, yearLabels, all];

const TABS = ['1h', '24h', '1w', '1m', '1y', 'All'];

const crosshairPlugin = {
  line: {
    color: Colors.brescianBlue,
    width: 2,
    dashPattern: [2, 2],
  },
  zoom: { enabled: false },
};

export const staticDatasetOptions = {
  borderColor: Colors.brescianBlue,
  borderWidth: 2,
  showLine: true,
  fill: false,
  pointStyle: 'circle',
  pointBackgroundColor: Colors.brescianBlue,
  pointHoverBackgroundColor: Colors.brescianBlue,
  pointBorderWidth: 7.5,
  pointBorderColor: 'rgba(0,130,255,0.2)',
  pointHoverBorderWidth: 7.5,
  lineTension: 0.1,
  interpolate: true,
};

const getRandomArbitrary = (min: number, max: number) => Math.random() * (max - min) + min;

function PortfolioChart() {
  const { dashboardState } = useDashboardContext();

  const [activeTab, setActiveTab] = useState(0);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState<TooltipModel<'line'> | null>(null);
  const [tooltipPos, setTooltipPos] = useState({ top: 0, left: 0 });

  const chartRef = useRef<ChartJS<'line', number[], string>>(null);

  const onTabClick = (index: number) => () => setActiveTab(index);

  const labels = useMemo(() => labelsArray[activeTab], [activeTab]);

  const customTooltip = useCallback(
    (context: { tooltip: TooltipModel<'line'> }) => {
      const chart = chartRef.current;
      const canvas = chart?.canvas;

      if (chart && canvas) {
        if (chart.tooltip?.opacity == 0) {
          setTooltipVisible(false);
          return;
        }

        if (canvas) {
          const left = canvas.offsetLeft + (chart?.tooltip?.caretX || 0);
          const top = canvas.offsetTop;

          if (tooltipPos?.left != left) {
            setTooltipPos({ top: top, left: left });
            if (chart.tooltip) {
              setTooltipData(context.tooltip);
            }
          }

          setTooltipVisible(true);
        }
      }
    },
    [tooltipPos?.left],
  );

  const datasetOptions = useMemo(() => {
    const labelsLength = labels.length;
    const pointRadiusArray = Array.from({ length: labelsLength }).map((_, index) =>
      index === labelsLength - 1 ? 5 : 0,
    );

    return {
      ...staticDatasetOptions,
      pointRadius: pointRadiusArray,
      pointHoverRadius: pointRadiusArray,
    };
  }, [labels.length]);

  const data = useMemo(
    () =>
      ({
        labels,
        datasets: [
          {
            label: '€',
            data: Array.from({ length: labels.length }).map(() =>
              dashboardState === DashboardState.InUse ? getRandomArbitrary(1.04, 2.05).toFixed(2) : 0,
            ),
            ...datasetOptions,
          },
        ],
      } as unknown as ChartData<'line'>),
    [dashboardState, datasetOptions, labels],
  );

  const options = useMemo(
    () =>
      ({
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          intersect: false,
        },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            titleFont: {
              family: 'VisbyRoundCF SemiBold',
            },
            bodyFont: {
              family: 'VisbyRoundCF SemiBold',
            },
            footerFont: {
              family: 'VisbyRoundCF SemiBold',
            },
            displayColors: false,
            enabled: false,
            external: customTooltip,
          },
          crosshair: crosshairPlugin,
        },
        scales: {
          x: {
            distribution: 'linear',
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              source: 'auto',
              autoSkip: true,
              maxTicksLimit: 6,
              minRotation: 0,
              maxRotation: 0,
              align: 'center',
              sampleSize: 10,
              color: Colors.ultimateGrey,
              font: {
                family: 'VisbyRoundCF SemiBold',
                size: 12,
              },
            },
          },
          y: {
            beginAtZero: true,
            display: true,
            position: 'right',
            scaleLabel: { display: false },
            grid: {
              drawBorder: false,
              color: Colors.snowBank,
            },
            ticks: {
              padding: 12,
              color: Colors.ultimateGrey,
              maxTicksLimit: 4,
              font: {
                family: 'VisbyRoundCF SemiBold',
                size: 12,
              },
              callback: function (value) {
                return `€${roundTo2Digits(Number(value))}`;
              },
            },
          },
        },
      } as ChartOptions<'line'>),
    [customTooltip],
  );

  const tabs = useMemo(
    () =>
      TABS.map((item, index) => (
        <Tab key={item} isActive={index === activeTab} onClick={onTabClick(index)}>
          {item}
        </Tab>
      )),
    [activeTab],
  );

  return (
    <Wrapper>
      <ChartHeader>
        <LeftColumn>
          <ChartTitle>Jouw portfolio</ChartTitle>
          <PriceLabel>{dashboardState === DashboardState.InUse ? '€21.316,92' : '€0,00'}</PriceLabel>
          <ChangeContainer>
            <PercentChangeLabel>{dashboardState === DashboardState.InUse ? '+3,20%' : '+0,00%'}</PercentChangeLabel>
            <Divider />
            <PriceChangeLabel>{dashboardState === DashboardState.InUse ? '+€3.012,49' : '+€0,00'}</PriceChangeLabel>
          </ChangeContainer>
        </LeftColumn>
        <TabsContainer>{tabs}</TabsContainer>
      </ChartHeader>
      <ChartContainer>
        <Line options={options} data={data} ref={chartRef} />
        {tooltipPos && <CustomTooltip data={tooltipData} position={tooltipPos} visible={tooltipVisible} />}
      </ChartContainer>
    </Wrapper>
  );
}

export default PortfolioChart;
