import styled from '@emotion/styled/macro';
import { focusVisibleStyles } from 'components/styled/common';

export const Wrapper = styled.div`
  width: 340px;
  margin-top: 0;
  margin-bottom: 0;
  background-color: ${({ theme }) => theme.palette.common.infinity};
  padding: 20px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
`;

export const IconLink = styled.a`
  display: inline-block;
  width: fit-content;
  &:focus-visible {
    ${({ theme }) => focusVisibleStyles(theme)}
  }
`;

export const QRCodeImage = styled.img`
  width: 92px;
  height: 92px;
`;
