import React from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { Message, MessageContainer } from '../styled';

export interface InfoMessageProps {
  message: string;
}

const InfoMessage: React.FC<InfoMessageProps> = ({ message }) => {
  return (
    <MessageContainer>
      <InfoOutlined width={20} height={20} />
      <Message>{message}</Message>
    </MessageContainer>
  );
};

export default InfoMessage;
