import { useCallback, useEffect, useState } from 'react';
import { http } from 'utils/http';

const useFetchListingImage = (id: string | number | undefined) => {
  const [image, setImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchNftShowItemThumbnail = async (id: string | number) => {
    const response = await http.get(`items/${id}/file/show`, {
      headers: { 'Content-Type': 'image' },
      responseType: 'blob',
    });
    const { data } = await response;
    return URL.createObjectURL(data);
  };

  const getListingItemImage = useCallback(async (id: string | number) => {
    return await fetchNftShowItemThumbnail(id);
  }, []);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getListingItemImage(id).then((image) => {
        setImage(image);
        setIsLoading(false);
      });
    }
  }, [getListingItemImage, id]);

  return { image, isLoading };
};

export default useFetchListingImage;
