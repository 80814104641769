import { en } from './dictionary/en';
import { nl } from './dictionary/nl';
import { at } from './dictionary/at';

export enum Languages {
  en = 'en',
  nl = 'nl',
  at = 'at',
}

export default {
  en,
  nl,
  at,
} as Record<keyof typeof Languages, Record<string, string>>;
