import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { BidStatus } from 'store/types';

const getStatusTagStyles = (theme: Theme, status: BidStatus) => {
  switch (status) {
    case BidStatus.REJECTED:
      return css`
        background-color: ${theme.palette.common.incision20};
        color: ${theme.palette.common.incision};
      `;
    case BidStatus.CANCELED:
      return css`
        background-color: ${theme.palette.common.blackSpace10};
      `;
    case BidStatus.ACTIVE:
    default:
      return css`
        background-color: ${theme.palette.common.waystoneGreen10};
        color: ${theme.palette.common.waystoneGreen};
      `;
  }
};

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  gap: 16px;
`;

export const CellValue = styled.div`
  font-size: 12px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;

export const StatusTag = styled.div<{ status: BidStatus }>`
  text-transform: capitalize;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 2px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  font-size: 10px;
  line-height: 12px;

  ${({ theme, status }) => getStatusTagStyles(theme, status)};
`;

export const THead = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  text-align: left;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  padding-bottom: 8px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  & > div {
    display: inline-block;
    position: relative;
    top: 2px;
    margin-left: 8px;
  }
`;

const sortingIconWrapperStyled = (isSortedDesc: boolean | undefined, theme: Theme) => {
  if (isSortedDesc === undefined) return '';
  if (isSortedDesc) {
    return css`
      & > svg {
        path:nth-of-type(1) {
          fill: ${theme.palette.common.ultimateGrey};
        }
      }
    `;
  }
  if (!isSortedDesc) {
    return css`
      & > svg {
        path:nth-of-type(2) {
          fill: ${theme.palette.common.ultimateGrey};
        }
      }
    `;
  }
};

export const SortingIconWrapper = styled.div<{ isSortedDesc?: boolean }>`
  ${({ isSortedDesc, theme }) => sortingIconWrapperStyled(isSortedDesc, theme)}
`;

export const THeaderContainer = styled.div``;

export const TCell = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  margin: 0;
  padding: 13px 0;

  :last-child {
    border-right: 0;
  }
`;

export const TRow = styled.div`
  :last-child {
    ${TCell} {
    }
  }
`;

export const THeadRow = styled(TRow)``;

export const TBody = styled.div``;

export const FlexibleTable = styled.div`
  border-spacing: 0;
  max-width: 100%;
`;
