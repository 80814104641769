import React, { useCallback, useMemo } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// store
import { dashboardItemsSelector } from 'store/nftItem';
import { uiLocaleSelector } from 'store/ui';
import { Status } from 'store/types';
// constants
import { EURO_SIGN } from 'constants/general';
import { breakpoints } from 'themes/mediaQueries';
import { IntlKeys } from 'localization/keys';
// styled
import { CardsContainer, Wrapper, Title, Description, StyledCard } from './styled';

const FeaturedSection: React.FC = () => {
  const navigate = useNavigate();
  const locale = useSelector(uiLocaleSelector);

  const { formatMessage } = useIntl();

  const { recentItems } = useSelector(dashboardItemsSelector);

  const isTablet = useMediaQuery({ maxWidth: breakpoints.md });

  const handleCardClick = useCallback(
    (id: string | number) => () => navigate(`/${locale}/item-details/${id}`),
    [locale, navigate],
  );

  const cards = useMemo(
    () =>
      recentItems?.map((item) => {
        const { activeListing, id, name } = item;
        const { topBid, price, status } = activeListing ?? {};

        const saleStatuses = [Status.PUBLISHED, Status.ACTIVE];
        const isOnSale = status && saleStatuses.includes(status);
        const cardPrice = topBid?.price ? topBid.price : price;

        return (
          <StyledCard
            thumbnailProps={{ itemId: id, thumbnailType: 'list', isMarketplaceItem: true }}
            key={id}
            onClick={handleCardClick(id)}
            itemName={name}
            price={cardPrice}
            isSmall={isTablet}
            isOnSale={isOnSale}
            currencySymbol={EURO_SIGN}
          />
        );
      }),
    [recentItems, handleCardClick, isTablet],
  );

  return (
    <Wrapper>
      <Title>{formatMessage({ id: IntlKeys.launchpadItemsTitle })}</Title>
      <Description>{formatMessage({ id: IntlKeys.launchpadItemsTitleDescription })}</Description>
      <CardsContainer>{cards}</CardsContainer>
    </Wrapper>
  );
};

export default FeaturedSection;
