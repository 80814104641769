import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding-bottom: 240px;
`;

export const TableSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 160px;

  ${({ theme }) => theme.media.lg`
    padding-left: 16px;
    padding-right: 16px;
  `}

  ${({ theme }) => theme.media.sm`
    margin-top: 104px;
  `}
`;

export const TableSectionContainer = styled.div`
  max-width: 1004px;
  width: 100%;
`;
