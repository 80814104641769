import React from 'react';
// components
import Info from './Info';
import Contacts from './Contacts';
// styled
import { Wrapper, Divider } from './styled';

function Footer() {
  return (
    <Wrapper>
      <Info />
      <Divider />
      <Contacts />
    </Wrapper>
  );
}

export default Footer;
