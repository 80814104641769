import styled from '@emotion/styled/macro';
import { TextField } from '@mui/material';
import { css } from '@emotion/react';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  border-radius: 2px;
  padding: 24px;
`;

export const Title = styled.div`
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.8px;
  font-family: 'VisbyRoundCF SemiBold';
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Container = styled.div`
  display: flex;
  column-gap: 24px;
  width: 100%;
  margin-top: 30px;
  @media (min-width: 991px) and (max-width: 1422px) {
    flex-direction: column;
    column-gap: 0;
    row-gap: 16px;
  }
  @media (max-width: 856px) {
    flex-direction: column;
    column-gap: 0;
    row-gap: 16px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;

  & .MuiOutlinedInput-input {
    height: auto;
    padding: 12px 0;
    font-size: 16px;
    line-height: 26px;
    color: ${({ theme }) => theme.palette.common.blackSpace};
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  }

  & .MuiInputAdornment-root > p {
    margin-right: 8px;
    font-size: 21px;
    color: ${({ theme }) => theme.palette.common.blackSpace};
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  }
`;

export const CryptoTextField = styled(StyledTextField)`
  margin-bottom: 20px;
  margin-right: 16px;
  & .MuiInputAdornment-root > p {
    margin-bottom: 3.5px;
  }
`;

export const CurrencyTextField = styled(StyledTextField)`
  margin-bottom: 20px;
  & .MuiInputAdornment-root > p {
    margin-bottom: 1.5px;
  }
`;

export const YearButton = styled.button<{ selected?: boolean }>`
  font-size: 16px;
  line-height: 19px;
  padding: 8px 15px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  border: 1px solid transparent;
  cursor: pointer;
  white-space: nowrap;
  ${({ selected, theme }) =>
    selected &&
    css`
      border: 1px solid ${theme.palette.common.snowBank};
      border-radius: 3px;
      color: ${theme.palette.common.brescianBlue};
    `}
`;

export const YearButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  @media (min-width: 991px) and (max-width: 1422px) {
    justify-content: center;
  }
  @media (max-width: 856px) {
    justify-content: center;
  }
`;

export const RewardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 77px;
  @media (min-width: 991px) and (max-width: 1422px) {
    gap: 0;
    row-gap: 32px;
  }
  @media (max-width: 856px) {
    gap: 0;
    row-gap: 32px;
  }
`;

export const RewardPrice = styled.div`
  font-size: 24px;
  line-height: 28px;
  font-family: 'VisbyRoundCF SemiBold';
  color: ${({ theme }) => theme.palette.common.brescianBlue};
`;

export const Asterisk = styled.span`
  font-size: 24px;
  line-height: 28px;
  font-family: 'VisbyRoundCF SemiBold';
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
`;

export const RewardCryptoPrice = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-family: 'VisbyRoundCF SemiBold';
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
`;

export const RewardPriceContainer = styled.div`
  gap: 5px;
  margin-top: 16px;
  @media (min-width: 991px) and (max-width: 1422px) {
    margin-top: 36px;
  }
  @media (max-width: 856px) {
    margin-top: 36px;
  }
`;

export const RewardNote = styled.div`
  font-size: 11px;
  line-height: 17px;
  font-family: 'VisbyRoundCF SemiBold';
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
`;
