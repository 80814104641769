// material-ui
import { FormControlLabel } from '@mui/material';
// styled
import styled from '@emotion/styled/macro';

export const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  margin: 0;

  &:not(:last-of-type) {
    margin: 0 0 16px;
  }

  & .MuiCheckbox-root {
    padding: 0;
  }

  & .MuiFormControlLabel-label {
    margin-left: 8px;
    color: ${({ theme }) => theme.palette.common.blackSpace};
    font-size: 14px;
    line-height: 18px;
    font-family: 'VisbyRoundCF SemiBold', Arial, sans-serif;
  }
`;
