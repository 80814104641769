import React, { useCallback, useMemo, useRef, useState } from 'react';
// libs
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// components
import MenuPopover from './popovers/MenuPopover';
import GetAppPopover from './popovers/GetAppPopover';
import MobileExploreNav from '../../MainLayout/Header/MobileExploreNav';
// hooks
import useOnMouseLeave from 'hooks/useOnMouseLeave';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { COINMERCE_BASE_URL } from 'constants/general';
// icons
import { ArrowDropDown } from '@mui/icons-material';
import { ReactComponent as LogoIcon } from 'assets/icons/logo-alt.svg';
import { ReactComponent as MobileMenuIcon } from 'assets/icons/mobile-menu-icon.svg';
// others
import { IntlKeys } from 'localization/keys';
import {
  AboutPopoverWrapper,
  ButtonsContainer,
  GetAppNavButton,
  GetAppPopoverWrapper,
  LoginButton,
  LogoLink,
  MobileMenuIconWrapper,
  NavButton,
  NavContainer,
  Navigation,
  NavLink,
  ProductsPopoverWrapper,
  SignUpButton,
} from './styled';

const UnauthHeader: React.FC = () => {
  const [isAboutMenuOpened, setIsAboutMenuOpened] = useState(false);
  const [isProductsMenuOpened, setIsProductsMenuOpened] = useState(false);
  const [isGetAppMenuOpened, setIsGetAppMenuOpened] = useState(false);

  const [isMobileExploreNavOpened, setMobileExploreNavOpened] = useState(false);

  const navigate = useNavigate();

  const locale = useSelector(uiLocaleSelector);
  const { formatMessage } = useIntl();

  const navRef = useRef<HTMLDivElement>(null);
  const productsRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const appRef = useRef<HTMLDivElement>(null);

  const handleMouseOver = () => {
    setIsAboutMenuOpened(false);
    setIsProductsMenuOpened(false);
    setIsGetAppMenuOpened(false);
  };

  const handleOnAboutMouseOver = () => {
    setIsProductsMenuOpened(false);
    setIsAboutMenuOpened(true);
  };

  const handleOnProductsMouseOver = () => {
    setIsAboutMenuOpened(false);
    setIsProductsMenuOpened(true);
  };

  const handleOnGetAppMouseOver = () => {
    setIsGetAppMenuOpened(true);
  };

  const toggleMobileExploreNav = useCallback(
    () => setMobileExploreNavOpened(!isMobileExploreNavOpened),
    [isMobileExploreNavOpened],
  );

  const handleLoginButtonClick = () => navigate(`/${locale}/login`);

  const menuItems = useMemo(
    () => [
      {
        title: IntlKeys.launchpadHeaderCoins,
        url: `${COINMERCE_BASE_URL}${locale}/coins`,
        popover: null,
      },
      {
        title: IntlKeys.launchpadHeaderProducts,
        popover: (
          <ProductsPopoverWrapper ref={productsRef}>
            <MenuPopover isOpened={isProductsMenuOpened} listType="products" />
          </ProductsPopoverWrapper>
        ),
        onMouseOver: handleOnProductsMouseOver,
      },
      {
        title: IntlKeys.launchpadHeaderAbout,
        popover: (
          <AboutPopoverWrapper ref={aboutRef}>
            <MenuPopover isOpened={isAboutMenuOpened} listType="about" />
          </AboutPopoverWrapper>
        ),
        onMouseOver: handleOnAboutMouseOver,
      },
    ],
    [isAboutMenuOpened, isProductsMenuOpened, locale],
  );

  useOnMouseLeave([navRef, aboutRef, productsRef, appRef], handleMouseOver);

  return (
    <Navigation ref={navRef}>
      <LogoLink href={`${COINMERCE_BASE_URL}${locale}`}>
        <LogoIcon />
      </LogoLink>

      <NavContainer>
        {menuItems.map(({ title, url, popover, ...props }, index) =>
          url ? (
            <NavLink key={index} href={url}>
              {formatMessage({ id: title })}
            </NavLink>
          ) : (
            <NavButton {...props} key={index} type="button">
              {popover} {formatMessage({ id: title })} <ArrowDropDown fontSize="small" />
            </NavButton>
          ),
        )}

        <GetAppNavButton type="button" onMouseOver={handleOnGetAppMouseOver}>
          <GetAppPopoverWrapper ref={appRef}>
            <GetAppPopover isOpened={isGetAppMenuOpened} />
          </GetAppPopoverWrapper>
          {formatMessage({ id: IntlKeys.launchpadHeaderGetTheApp })} <ArrowDropDown fontSize="small" />
        </GetAppNavButton>

        <NavButton type="button">
          EN <ArrowDropDown fontSize="small" />
        </NavButton>
      </NavContainer>

      {/*TODO: add landing mobile menu*/}
      <MobileExploreNav onClose={toggleMobileExploreNav} isOpen={isMobileExploreNavOpened} />

      <ButtonsContainer>
        <LoginButton tabIndex={1} onClick={handleLoginButtonClick}>
          {formatMessage({ id: IntlKeys.footerLoginButton })}
        </LoginButton>
        <SignUpButton tabIndex={1}>{formatMessage({ id: IntlKeys.footerSignupButton })}</SignUpButton>
      </ButtonsContainer>

      <MobileMenuIconWrapper onClick={toggleMobileExploreNav}>
        <MobileMenuIcon />
      </MobileMenuIconWrapper>
    </Navigation>
  );
};

export default UnauthHeader;
