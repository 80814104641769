import React from 'react';
// libs
import { useIntl } from 'react-intl';
// components
import Accordion from './Accordion';
// constants
import { FAQ_FASE_ONE } from './constants';
import { IntlKeys } from 'localization/keys';
// styled
import { AccordionsContainer, Container, Title, Wrapper } from './styled';

const FAQSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Container>
        <Title>{formatMessage({ id: IntlKeys.launchpadFaqTitle })}</Title>
        <AccordionsContainer>
          {FAQ_FASE_ONE.map(({ title, details, expanded, href, id }) => (
            <Accordion title={title} link={href} details={details} defaultExpanded={expanded} key={id} />
          ))}
        </AccordionsContainer>
      </Container>
    </Wrapper>
  );
};

export default FAQSection;
