import React, { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from 'react';
// libs
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import { ToggleButton } from '@mui/material';
// components
import { CardWithThumbnail } from 'components/Card';
import Filter from 'components/Filter';
import FilterTag from 'components/FilterButton';
import MobileFilterModal from 'components/Filter/MobileFilterModal';
import CardSkeleton from 'components/Card/CardSkeleton';
import Select from 'components/Select';
import { useFilterContext } from 'components/Filter/FilterProvider';
// hooks
import useFetchMarketplaceCollectionData from './hooks/useFetchMarketplaceCollectionData';
import useFilterTags from './hooks/useFilterTags';
// store
import { useAppDispatch } from 'store';
import { setIsSmallCard, uiIsSmallCardSelector, uiLocaleSelector } from 'store/ui';
import { Status } from 'store/types';
// constants
import { FILTERS, ORDERING, Ordering, ORDERING_OPTIONS, ORDERING_TYPES } from 'constants/filters';
import { EURO_SIGN } from 'constants/general';
// icons
import { ReactComponent as GridIcon } from 'assets/icons/grid.svg';
import { ReactComponent as GridLargeIcon } from 'assets/icons/grid-large.svg';
// styled
import {
  CardsWrapper,
  ClearButton,
  CollectionTitle,
  CommonWrapper,
  FilterTagsWrapper,
  MainContentWrapper,
  MobileFilterButton,
  NoItemsFoundText,
  NumberOfItems,
  NumberOfItemsCollectionContainer,
  SelectContainer,
  ToggleCardGridButtons,
  Wrapper,
} from './styled';
import { useIntl } from 'react-intl';
import { IntlKeys } from '../../localization/keys';

export interface MarketplaceCollectionPageProps {
  isMyCollection?: boolean;
}

// TODO refactor MarketplaceCollectionPage(split MarketplaceCollectionPage into separate components(hooks))
const MarketplaceCollectionPage = ({ isMyCollection }: MarketplaceCollectionPageProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { formatMessage } = useIntl();

  const locale = useSelector(uiLocaleSelector);
  const isSmallCard = useSelector(uiIsSmallCardSelector);

  const { handleFilterValuesChange, clearGivenFilters } = useFilterContext();

  const filterTags = useFilterTags();

  const { marketplaceItems, collectionMeta, itemsMeta, collectionName } = useFetchMarketplaceCollectionData();

  const orderingValue = useMemo(() => {
    const searchString = location.search.substring(1);
    const parsedSearchString = qs.parse(searchString);
    const { orderBy } = parsedSearchString;
    if (orderBy) {
      const orderByParams = Object.entries(orderBy).map(([key, value]) => `${key}-${value}`);
      return ORDERING_TYPES.includes(orderByParams[0]) && orderByParams[0];
    }
  }, [location.search]);

  const [ordering, setOrdering] = useState<string>(orderingValue || ORDERING_TYPES[Ordering.CreatedAtDesc]);
  const [isSmall, setIsSmall] = useState(isSmallCard || false);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);

  const toggleModalFilter = useCallback(() => setFilterModalOpen(!isFilterModalOpen), [isFilterModalOpen]);

  const handleChangeCardView = useCallback(
    (event: MouseEvent<HTMLElement>, newCardView: any) => {
      setIsSmall(newCardView);
      dispatch(setIsSmallCard(newCardView));
    },
    [dispatch],
  );

  const getOrderSelectBoxValue = useCallback((value: string) => {
    switch (value) {
      case ORDERING_TYPES[Ordering.PriceAsc]:
        return { name: ORDERING.price, orderType: 'asc' };
      case ORDERING_TYPES[Ordering.PriceDesc]:
        return { name: ORDERING.price, orderType: 'desc' };
      case ORDERING_TYPES[Ordering.CreatedAtAsc]:
        return { name: ORDERING.createdAt, orderType: 'asc' };
      case ORDERING_TYPES[Ordering.CreatedAtDesc]:
      default:
        return { name: ORDERING.createdAt, orderType: 'desc' };
    }
  }, []);

  const handleChangeOrderingSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setOrdering(event.target.value);
      const { name, orderType } = getOrderSelectBoxValue(event.target.value);
      if (name === ORDERING.price) {
        handleFilterValuesChange(name, orderType, [ORDERING.createdAt]);
      }
      if (name === ORDERING.createdAt) {
        handleFilterValuesChange(name, orderType, [ORDERING.price]);
      }
    },
    [getOrderSelectBoxValue, handleFilterValuesChange],
  );

  const handleCardClick = useCallback(
    (id: string | number) => () => navigate(`/${locale}/marketplace/${id}`),
    [locale, navigate],
  );

  const handleCollectionCardClick = useCallback(
    (id: string | number) => () => navigate(`/${locale}/item-details/${id}`, { state: { isOwner: true } }),
    [locale, navigate],
  );

  const handleClearAllTagsClick = useCallback(
    (filtersToClear: string[]) => () => clearGivenFilters(filtersToClear),
    [clearGivenFilters],
  );

  const cards = useMemo(() => {
    if (collectionMeta.isLoading || itemsMeta.isLoading) {
      return Array.from({ length: 18 }).map((_, index) => <CardSkeleton isSmall={isSmall} key={index} />);
    }

    if (!marketplaceItems.length) {
      return <NoItemsFoundText>{formatMessage({ id: IntlKeys.marketplaceCollectionNoItemsFound })}</NoItemsFoundText>;
    }

    return marketplaceItems.map((item) => {
      const { activeListing, id, name } = item;
      const { status, topBid } = activeListing ?? {};
      const saleStatuses = [Status.PUBLISHED, Status.ACTIVE];
      const isOnSale = status && saleStatuses.includes(status);
      return (
        <CardWithThumbnail
          key={id}
          onClick={isMyCollection ? handleCollectionCardClick(item.id) : handleCardClick(item.id)}
          itemName={name}
          price={topBid?.price ?? activeListing?.price}
          isSmall={isSmall}
          isOnSale={isOnSale}
          currencySymbol={EURO_SIGN}
          thumbnailProps={{ itemId: id, thumbnailType: 'list', isMarketplaceItem: !isMyCollection }}
        />
      );
    });
  }, [
    collectionMeta.isLoading,
    handleCardClick,
    handleCollectionCardClick,
    isMyCollection,
    isSmall,
    itemsMeta.isLoading,
    marketplaceItems,
  ]);

  const mappedOrderingOptions = useMemo(
    () => ORDERING_OPTIONS.map(({ value, label }) => ({ value, label: formatMessage({ id: label }) })),
    [formatMessage],
  );

  return (
    <Wrapper>
      <Filter isMyCollection={isMyCollection} />

      <MainContentWrapper>
        <CommonWrapper>
          <NumberOfItemsCollectionContainer>
            {collectionName && <CollectionTitle>{collectionName}</CollectionTitle>}

            <NumberOfItems>
              {marketplaceItems.length} {formatMessage({ id: IntlKeys.marketplaceCollectionNumberOfItems })}
            </NumberOfItems>
          </NumberOfItemsCollectionContainer>

          <SelectContainer>
            <Select
              options={mappedOrderingOptions}
              onChange={handleChangeOrderingSelect}
              name="orderBy"
              value={ordering}
            />
          </SelectContainer>
        </CommonWrapper>

        <CommonWrapper>
          <MobileFilterButton onClick={toggleModalFilter}>
            {formatMessage({ id: IntlKeys.marketplaceCollectionFilterTitle })}
          </MobileFilterButton>

          <FilterTagsWrapper>
            {filterTags.map(({ title, onClick }) => (
              <FilterTag key={title} onClick={onClick}>
                {title}
              </FilterTag>
            ))}
            {!!filterTags.length ? (
              <ClearButton onClick={handleClearAllTagsClick(Object.values(FILTERS).map((filterName) => filterName))}>
                {formatMessage({ id: IntlKeys.marketplaceCollectionFilterClearAll })}
              </ClearButton>
            ) : null}
          </FilterTagsWrapper>

          <ToggleCardGridButtons size="small" color="primary" value={isSmall} onChange={handleChangeCardView} exclusive>
            <ToggleButton value={false} key="false">
              <GridIcon />
            </ToggleButton>

            <ToggleButton value={true} key="true">
              <GridLargeIcon />
            </ToggleButton>
          </ToggleCardGridButtons>
        </CommonWrapper>

        <CardsWrapper isSmall={isSmall}>{cards}</CardsWrapper>
      </MainContentWrapper>

      <MobileFilterModal isOpen={isFilterModalOpen} handleClose={toggleModalFilter} />
    </Wrapper>
  );
};

export default MarketplaceCollectionPage;
