import React from 'react';
// material-ui
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@mui/material';
// styled
import styled from '@emotion/styled/macro';

export const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))`
  &:before {
    display: none;
  }

  ${({ theme }) => theme.media.md`
    display: none;
  `}
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 40px;
  padding: 0 8px 0 40px;
  border-top: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.snowBank};

  & .MuiAccordionSummary-content {
    margin: 0;
    font-size: 14px;
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
    color: ${({ theme }) => theme.palette.common.brilliantLicorice};
  }

  & .MuiAccordionSummary-expandIconWrapper {
    color: rgba(83, 83, 83, 0.5);
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  border-top: 0;
  padding: 8px 8px 8px 40px;
  background-color: ${({ theme }) => theme.palette.common.drWhite};
`;
