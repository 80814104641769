// material-ui
import { TextField } from '@mui/material';
// styled
import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 280px;
  padding: 40px 0;
  border-right: 1px solid ${({ theme }) => theme.palette.common.snowBank};

  ${({ theme }) => theme.media.md`
    border: 0;
    padding: 16px 16px 8px;
    max-width: 100%;
    background: linear-gradient(270deg, #fbfbfb 0%, #ffffff 99.06%);
  `}
`;

export const SearchInput = styled(TextField)`
  width: 100%;
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.palette.common.white};

  & .MuiOutlinedInput-root {
    padding-left: 10px;
  }

  & .MuiSvgIcon-root {
    font-size: 20px;
  }

  & .MuiOutlinedInput-input {
    height: auto;
    padding: 12px 0;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.palette.common.ultimateGrey};
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  }

  & .MuiOutlinedInput-notchedOutline {
    border-radius: 2px;
    border-color: ${({ theme }) => theme.palette.common.bleachedSilk};
  }

  & .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.common.snowBank};
  }

  & .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
    color: ${({ theme }) => theme.palette.common.blackSpace};
  }

  &:hover .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.common.snowBank};
  }

  & .MuiInputAdornment-root {
    color: ${({ theme }) => theme.palette.common.blackSpace};
  }

  ${({ theme }) => theme.media.md`
    margin-bottom: 0;
  `}
`;

export const FilterTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;

  & > svg {
    color: ${({ theme }) => theme.palette.common.blackSpace};
    margin-right: 8px;
  }

  ${({ theme }) => theme.media.md`
    display: none;
  `}
`;

export const FilterTitle = styled.span`
  font-size: 14px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PriceText = styled.div`
  align-self: center;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const PriceTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.common.white};
  min-width: 80px;
  max-width: 80px;

  & .MuiInputAdornment-root > p {
    font-size: 14px;
  }

  & .MuiOutlinedInput-input {
    text-align: left;
    font-size: 18px;
    line-height: 22px;
    font-family: 'VisbyRoundCF SemiBold', Arial, sans-serif;
    padding: 11px 8px 11px 0;
    height: auto;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-radius: 2px;
  }
`;
