import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
// libs
import { Column, useFlexLayout, useSortBy, useTable } from 'react-table';
import { useMediaQuery } from 'react-responsive';
// providers
import { DashboardState, useDashboardContext } from 'providers/DashboardProvider';
// helpers
import { roundTo2Digits, roundTo5Digits } from 'helpers/renderHelpers';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import { ReactComponent as SortingIcon } from 'assets/icons/sorting.svg';
import { ReactComponent as SignalIcon } from 'assets/icons/signal.svg';
import { ReactComponent as MarketIcon } from 'assets/icons/market.svg';
import { ReactComponent as BotIcon } from 'assets/icons/bot.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { ReactComponent as AlphaIcon } from 'assets/icons/table/alpha.svg';

import { ReactComponent as BTCIcon } from 'assets/icons/cryptos/btc.svg';
import { ReactComponent as XRPIcon } from 'assets/icons/cryptos/xrp.svg';
import { ReactComponent as ETHIcon } from 'assets/icons/cryptos/eth.svg';
import { ReactComponent as ICXIcon } from 'assets/icons/cryptos/icx.svg';
import { ReactComponent as ADAIcon } from 'assets/icons/cryptos/ada.svg';
// styled
import {
  ActionsContainer,
  Amount,
  AssetCellContainer,
  AssetIconWrapper,
  AssetName,
  AssetNamesContainer,
  AssetSubName,
  BalanceContainer,
  BuyButton,
  Course,
  DepositButton,
  EarnButton,
  FlexibleTable,
  MoreIconWrapper,
  NegativePercentage,
  PositivePercentage,
  Rate,
  ShowMore,
  SortingIconWrapper,
  TBody,
  TCell,
  THead,
  THeaderContainer,
  THeadRow,
  TRow,
  Type,
  TypeContainer,
} from './styled';
import { breakpoints } from '../../../../themes/mediaQueries';

enum Action {
  Buy = 'Buy',
  Sell = 'Sell',
  Deposit = 'Deposit',
  More = 'More',
  Earn = 'Earn',
}

export interface WalletsTableProps {
  showOnlyFullWallets?: boolean;
}

// TODO: change translation keys

const WalletsTable: React.FC<WalletsTableProps> = ({ showOnlyFullWallets }) => {
  const { dashboardState } = useDashboardContext();

  const [showedMore, setShowedMore] = useState(false);

  const { formatMessage } = useIntl();

  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isSmallTablet = useMediaQuery({ maxWidth: 800 });
  const isSmallLaptop = useMediaQuery({ maxWidth: 1200 });
  const isSm = useMediaQuery({ maxWidth: breakpoints.sm });

  const tableData = useMemo(
    () => [
      {
        asset: { icon: <BTCIcon width={30} height={30} />, name: 'Bitcoin', subName: 'BTC' },
        balance: { amount: 16750.1, rate: 0.99332 },
        course: 22732.84,
        change: 1.24,
        type: { icon: <SignalIcon />, percentage: 0.8, actions: [] },
        actions: [Action.Buy, Action.Sell, Action.Deposit, Action.More],
      },
      {
        asset: { icon: <XRPIcon width={30} height={30} />, name: 'Ripple', subName: 'XRP' },
        balance: { amount: 3183.48, rate: 0.99332 },
        course: 0.28721,
        change: 5.15,
        type: { icon: <MarketIcon />, percentage: 1.5, actions: [Action.Earn] },
        actions: [Action.Buy, Action.Sell, Action.Deposit, Action.More],
      },
      {
        asset: { icon: <ETHIcon width={30} height={30} />, name: 'Ethereum', subName: 'ETH' },
        balance: { amount: 883.38, rate: 0.99332 },
        course: 928.24,
        change: -29.18,
        type: { icon: <MarketIcon />, percentage: 2.02, actions: [] },
        actions: [Action.Buy, Action.Sell, Action.Deposit, Action.More],
      },
      {
        asset: { icon: <ICXIcon width={30} height={30} />, name: 'Icon', subName: 'ICX' },
        balance: { amount: 595.83, rate: 0.99332 },
        course: 22732.84,
        change: -9.38,
        type: { icon: null, percentage: null, actions: [] },
        actions: [Action.Buy, Action.Sell, Action.Deposit, Action.More],
      },
      {
        asset: {
          icon: <AlphaIcon width={30} height={30} />,
          name: 'Boosting Alpha Aggressive',
          subName: 'BA-BOT-03',
        },
        balance: { amount: 99.38, rate: null },
        course: 0,
        change: 8.24,
        type: { icon: <BotIcon />, percentage: 0, actions: [] },
        actions: [Action.Buy, Action.Sell, Action.More],
      },
      {
        asset: { icon: <ADAIcon width={30} height={30} />, name: 'Icon', subName: 'ADA' },
        balance: { amount: 0.0, rate: 0.99332 },
        course: 22732.84,
        change: -9.38,
        type: { icon: null, percentage: null, actions: [] },
        actions: [Action.Buy, Action.Sell, Action.Deposit, Action.More],
      },
    ],
    [],
  );

  const sortedTableData = useMemo(() => {
    if (showOnlyFullWallets && !isSm) {
      return tableData.filter((item) => item.balance.amount !== 0);
    }

    return tableData;
  }, [isSm, showOnlyFullWallets, tableData]);

  const data = React.useMemo(() => {
    if (dashboardState !== DashboardState.InUse) {
      return [];
    }

    if ((dashboardState === DashboardState.InUse && showedMore) || (dashboardState === DashboardState.InUse && !isSm)) {
      return sortedTableData;
    }

    if (dashboardState === DashboardState.InUse && isSm && !showedMore) {
      const newData = [...sortedTableData];
      return newData.slice(0, 4);
    }

    if (dashboardState === DashboardState.InUse && isSm && showedMore) {
      return sortedTableData;
    }

    return [];
  }, [dashboardState, isSm, showedMore, sortedTableData]);

  const renderActionButtons = useCallback(
    (action: Action) => {
      switch (action) {
        case Action.Buy:
          return <BuyButton>{formatMessage({ id: IntlKeys.websiteSinglecoinPopularBuy })}</BuyButton>;
        case Action.Deposit:
          return <DepositButton>{formatMessage({ id: IntlKeys.websiteSinglecoinPopularDeposit })}</DepositButton>;
        case Action.More:
          return (
            <MoreIconWrapper>
              <MoreIcon />
            </MoreIconWrapper>
          );
        case Action.Sell:
          return <DepositButton>Verkopen</DepositButton>;
        case Action.Earn:
          return <EarnButton>Activeer Earn</EarnButton>;
        default:
          return null;
      }
    },
    [formatMessage],
  );

  const { assetWidth, changeWidth, balanceWidth, courseWidth, actionsWidth, typeWidth, mobileActionsWidth } =
    useMemo(() => {
      return {
        assetWidth: isSmallTablet ? 80 : 120,
        changeWidth: 60,
        balanceWidth: 60,
        courseWidth: 60,
        typeWidth: isSmallTablet ? 80 : 120,
        actionsWidth: 160,
        mobileActionsWidth: 80,
      };
    }, [isSmallTablet]);

  const hiddenColumns = useMemo(() => {
    // if (isSmallLaptop || (isLargeMobile && !isMobile)) {
    //   return ['actions'];
    // }
    if (isMobile) {
      return ['change', 'type', 'actions', 'course'];
    }

    if (isSmallLaptop) {
      return ['change', 'actions'];
    }

    return ['mobileActions'];
  }, [isMobile, isSmallLaptop]);

  const initialState = { hiddenColumns };

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: formatMessage({ id: IntlKeys.websiteSinglecoinPopularAsset }),
        accessor: 'asset',
        width: assetWidth,
        Cell: (props) => (
          <AssetCellContainer>
            <AssetIconWrapper>{props.value.icon}</AssetIconWrapper>
            <AssetNamesContainer>
              <AssetName>{props.value.name}</AssetName>
              <AssetSubName>{props.value.subName}</AssetSubName>
            </AssetNamesContainer>
          </AssetCellContainer>
        ),
        sortType: (a, b) => {
          if (a.values.asset.name < b.values.asset.name) {
            return -1;
          }
          if (a.values.asset.name > b.values.asset.name) {
            return 1;
          }
          return 0;
        },
      },
      {
        Header: 'Balans',
        accessor: 'balance',
        width: balanceWidth,
        Cell: (props) => (
          <BalanceContainer>
            <Amount isBig={!props.value.rate}>€{roundTo2Digits(props.value.amount)}</Amount>
            {props.value.rate && <Rate>{roundTo5Digits(props.value.rate)}</Rate>}
          </BalanceContainer>
        ),
        sortType: (a, b) => Number(a.values.balance.amount) - Number(b.values.balance.amount),
      },
      {
        Header: formatMessage({ id: IntlKeys.websiteSinglecoinPopularPrice }),
        accessor: 'course',
        width: courseWidth,
        Cell: (props) => (props.value ? <Course>€{roundTo5Digits(props.value)}</Course> : null),
        sortType: (a, b) => Number(a.values.course) - Number(b.values.course),
      },
      {
        Header: formatMessage({ id: IntlKeys.websiteSinglecoinPopularChange }),
        accessor: 'change',
        width: changeWidth,
        Cell: (props) => {
          const isPositive = props.value >= 0;
          return isPositive ? (
            <PositivePercentage>+{roundTo2Digits(props.value)}%</PositivePercentage>
          ) : (
            <NegativePercentage>{roundTo2Digits(props.value)}%</NegativePercentage>
          );
        },
        sortType: (a, b) => Number(a.values.change) - Number(b.values.change),
      },
      {
        Header: formatMessage({ id: IntlKeys.websiteSinglecoinPopularType }),
        accessor: 'type',
        width: typeWidth,
        Cell: (props) => (
          <TypeContainer>
            {props.value.icon || props.value.percentage ? (
              <Type>
                {props.value.icon}
                {props.value.percentage ? roundTo2Digits(props.value.percentage) + '%' : ''}
              </Type>
            ) : null}
            {!isSmallTablet && props.value.actions.map((action: Action) => renderActionButtons(action))}
          </TypeContainer>
        ),
        sortType: (a, b) => {
          if (a.values.asset.name < b.values.asset.name) {
            return -1;
          }
          if (a.values.asset.name > b.values.asset.name) {
            return 1;
          }
          return 0;
        },
      },
      {
        Header: '',
        accessor: 'actions',
        disableSortBy: true,
        width: actionsWidth,
        Cell: (props) => (
          <ActionsContainer>{props.value.map((action: Action) => renderActionButtons(action))}</ActionsContainer>
        ),
      },
      {
        Header: '',
        accessor: 'mobileActions',
        disableSortBy: true,
        width: mobileActionsWidth,
        Cell: () => (
          <ActionsContainer>
            <BuyButton>Koop nu</BuyButton>
          </ActionsContainer>
        ),
      },
    ],
    [
      formatMessage,
      renderActionButtons,
      mobileActionsWidth,
      actionsWidth,
      assetWidth,
      courseWidth,
      changeWidth,
      typeWidth,
      hiddenColumns,
      balanceWidth,
      isSmallTablet,
    ],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useFlexLayout,
    useSortBy,
  );

  const renderSortingIcon = useCallback((isSortedDesc: boolean | undefined) => {
    return (
      <SortingIconWrapper isSortedDesc={isSortedDesc}>
        <SortingIcon />
      </SortingIconWrapper>
    );
  }, []);

  const isShowMoreButtonVisible = useMemo(
    () =>
      isSm &&
      !showedMore &&
      sortedTableData.length > 4 &&
      sortedTableData.length > data.length &&
      dashboardState === DashboardState.InUse,
    [dashboardState, data.length, isSm, showedMore, sortedTableData.length],
  );

  return (
    <>
      <FlexibleTable {...getTableProps()}>
        <THeaderContainer>
          {headerGroups.map((headerGroup, index) => (
            <THeadRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, i) => (
                <THead {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                  {column.render('Header')}
                  {!column.disableSortBy && renderSortingIcon(column.isSortedDesc)}
                </THead>
              ))}
            </THeadRow>
          ))}
        </THeaderContainer>

        <TBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TRow {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <TCell {...cell.getCellProps()} key={index}>
                      {cell.render('Cell')}
                    </TCell>
                  );
                })}
              </TRow>
            );
          })}
        </TBody>

        {isShowMoreButtonVisible && <ShowMore onClick={() => setShowedMore(true)}>Zie alle assets</ShowMore>}
      </FlexibleTable>
    </>
  );
};

export default WalletsTable;
