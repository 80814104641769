import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
// material
import { Checkbox, FormControlLabel, Theme } from '@mui/material';
// other
import { TagVariants } from './index';
import { focusVisibleStyles } from 'components/styled/common';

const labelVariantStyles = (theme: Theme, variant?: TagVariants) => {
  switch (variant) {
    case 'primary':
      return css`
        background-color: ${theme.palette.primary.light};
        color: ${theme.palette.primary.main};
      `;

    case 'success':
      return css`
        background-color: ${theme.palette.common.waystoneGreen10};
        color: ${theme.palette.common.waystoneGreen};
      `;
    case 'secondary':
    default:
      return css`
        background-color: rgba(222, 227, 230, 0.5);
        color: ${theme.palette.common.blackSpace};
      `;
  }
};

export const Input = styled(Checkbox)`
  display: none;
`;

export const Label = styled(FormControlLabel)<{ variant?: TagVariants }>`
  margin: 0;

  & .MuiTypography-root {
    display: inline-block;
    border-radius: 2px;
    padding: 8px;
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    background-color: rgba(222, 227, 230, 0.5);
    color: ${({ theme }) => theme.palette.common.blackSpace};
  }

  & .Mui-checked + .MuiTypography-root {
    ${({ variant, theme }) => labelVariantStyles(theme, variant)};
  }

  &:focus-visible {
    ${({ theme }) => focusVisibleStyles(theme)}
  }
`;

export const Wrapper = styled.div<{ isPassive: boolean }>`
  pointer-events: ${({ isPassive }) => (isPassive ? 'none' : 'auto')};
`;
