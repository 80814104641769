import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 40px 80px 80px;
  margin-bottom: 60px;

  ${({ theme }) => theme.media.lg`
    padding: 8px 16px;
  `}
  ${({ theme }) => theme.media.sm`
    padding: 8px 16px;
  `}
  ${({ theme }) => theme.media.xs`
    padding: 8px 8px;
  `}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    gap: 16px;
  `}
`;

export const WalletsTableWrapper = styled.div`
  width: 100%;
  min-height: 165px;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 24px;
  padding-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  border-radius: 2px;
  justify-self: center;
  margin-top: 40px;

  ${({ theme }) => theme.media.md`
    margin-top: 16px;
  `}
`;

export const WalletsTableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const WalletsTableTitle = styled.div`
  font-size: 24px;
  line-height: 29px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const CheckboxesContainer = styled.div`
  display: flex;
  gap: 32px;
`;

export const ConvertButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const ConvertButton = styled(Button)`
  height: 43px;
  font-size: 16px;
  line-height: 19px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;

  background-color: ${({ theme }) => theme.palette.common.blackSpace};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.nightOut};
  }
`;
