import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
// libs
import { Column, useFlexLayout, useSortBy, useTable } from 'react-table';
import { useMediaQuery } from 'react-responsive';
// helpers
import { roundTo2Digits, roundTo5Digits } from 'helpers/renderHelpers';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import { ReactComponent as SortingIcon } from 'assets/icons/sorting.svg';
import { ReactComponent as SignalIcon } from 'assets/icons/signal.svg';
import { ReactComponent as MarketIcon } from 'assets/icons/market.svg';
import { ReactComponent as BotIcon } from 'assets/icons/bot.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { ReactComponent as AlphaIcon } from 'assets/icons/table/alpha.svg';

import { ReactComponent as BTCIcon } from 'assets/icons/cryptos/btc.svg';
import { ReactComponent as XRPIcon } from 'assets/icons/cryptos/xrp.svg';
import { ReactComponent as ETHIcon } from 'assets/icons/cryptos/eth.svg';
import { ReactComponent as ICXIcon } from 'assets/icons/cryptos/icx.svg';
// styled
import {
  ActionsContainer,
  AssetCellContainer,
  AssetIconWrapper,
  AssetName,
  AssetNamesContainer,
  AssetSubName,
  BuyButton,
  Course,
  DepositButton,
  FlexibleTable,
  MoreIconWrapper,
  NegativePercentage,
  PositivePercentage,
  SortingIconWrapper,
  TBody,
  TCell,
  THead,
  THeaderContainer,
  THeadRow,
  TRow,
  Type,
} from './styled';

enum Action {
  Buy = 'Buy',
  Deposit = 'Deposit',
  More = 'More',
}

function PopularAssetsTable() {
  const { formatMessage } = useIntl();

  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isLargeMobile = useMediaQuery({ minWidth: 501, maxWidth: 768 });
  const isSmallTablet = useMediaQuery({ maxWidth: 800 });
  const isSmallLaptop = useMediaQuery({ minWidth: 991, maxWidth: 1420 });

  const data = React.useMemo(
    () => [
      {
        asset: { icon: <BTCIcon width={30} height={30} />, name: 'Bitcoin', subName: 'BTC' },
        course: 22732.84,
        change: 1.24,
        type: { icon: <SignalIcon />, percentage: 0.8 },
        actions: [Action.Buy, Action.Deposit, Action.More],
      },
      {
        asset: { icon: <XRPIcon width={30} height={30} />, name: 'Ripple', subName: 'XRP' },
        course: 0.28721,
        change: 5.15,
        type: { icon: <MarketIcon />, percentage: 1.5 },
        actions: [Action.Buy, Action.Deposit, Action.More],
      },
      {
        asset: { icon: <ETHIcon width={30} height={30} />, name: 'Ethereum', subName: 'ETH' },
        course: 928.24,
        change: -29.18,
        type: { icon: <MarketIcon />, percentage: 2.02 },
        actions: [Action.Buy, Action.Deposit, Action.More],
      },
      {
        asset: { icon: <ICXIcon width={30} height={30} />, name: 'Icon', subName: 'ICX' },
        course: 22732.84,
        change: -9.38,
        type: { icon: null, percentage: null },
        actions: [Action.Buy, Action.Deposit, Action.More],
      },
      {
        asset: { icon: <AlphaIcon width={30} height={30} />, name: 'Boosting Alpha Aggressive', subName: 'BA-BOT-03' },
        course: 0,
        change: 8.24,
        type: { icon: <BotIcon />, percentage: 0 },
        actions: [Action.Buy, Action.More],
      },
    ],
    [],
  );

  const renderActionButtons = useCallback(
    (action: Action) => {
      switch (action) {
        case Action.Buy:
          return <BuyButton>{formatMessage({ id: IntlKeys.websiteSinglecoinPopularBuy })}</BuyButton>;
        case Action.Deposit:
          return <DepositButton>{formatMessage({ id: IntlKeys.websiteSinglecoinPopularDeposit })}</DepositButton>;
        case Action.More:
          return (
            <MoreIconWrapper>
              <MoreIcon />
            </MoreIconWrapper>
          );
        default:
          return null;
      }
    },
    [formatMessage],
  );

  const { assetWidth, changeWidth, courseWidth, actionsWidth, typeWidth, mobileActionsWidth } = useMemo(() => {
    return {
      assetWidth: isSmallLaptop || isMobile || isSmallTablet ? 80 : 120,
      changeWidth: 86,
      courseWidth: 70,
      typeWidth: 70,
      actionsWidth: 148,
      mobileActionsWidth: 80,
    };
  }, [isMobile, isSmallLaptop, isSmallTablet]);

  const hiddenColumns = useMemo(() => {
    if (isSmallLaptop || (isLargeMobile && !isMobile)) {
      return ['actions'];
    }
    if (isMobile || (isMobile && isLargeMobile)) {
      return ['change', 'type', 'actions'];
    }
    return ['mobileActions'];
  }, [isMobile, isLargeMobile, isSmallLaptop]);

  const initialState = { hiddenColumns };

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: formatMessage({ id: IntlKeys.websiteSinglecoinPopularAsset }),
        accessor: 'asset',
        width: assetWidth,
        Cell: (props) => (
          <AssetCellContainer>
            <AssetIconWrapper>{props.value.icon}</AssetIconWrapper>
            <AssetNamesContainer>
              <AssetName>{props.value.name}</AssetName>
              <AssetSubName>{props.value.subName}</AssetSubName>
            </AssetNamesContainer>
          </AssetCellContainer>
        ),
        sortType: (a, b) => {
          if (a.values.asset.name < b.values.asset.name) {
            return -1;
          }
          if (a.values.asset.name > b.values.asset.name) {
            return 1;
          }
          return 0;
        },
      },
      {
        Header: formatMessage({ id: IntlKeys.websiteSinglecoinPopularPrice }),
        accessor: 'course',
        width: courseWidth,
        Cell: (props) => (props.value ? <Course>€{roundTo5Digits(props.value)}</Course> : null),
        sortType: (a, b) => Number(a.values.change) - Number(b.values.change),
      },
      {
        Header: formatMessage({ id: IntlKeys.websiteSinglecoinPopularChange }),
        accessor: 'change',
        width: changeWidth,
        Cell: (props) => {
          const isPositive = props.value >= 0;
          return isPositive ? (
            <PositivePercentage>+{roundTo2Digits(props.value)}%</PositivePercentage>
          ) : (
            <NegativePercentage>{roundTo2Digits(props.value)}%</NegativePercentage>
          );
        },
        sortType: (a, b) => Number(a.values.change) - Number(b.values.change),
      },
      {
        Header: formatMessage({ id: IntlKeys.websiteSinglecoinPopularType }),
        accessor: 'type',
        width: typeWidth,
        Cell: (props) =>
          props.value.icon || props.value.percentage ? (
            <Type>
              {props.value.icon}
              {props.value.percentage ? roundTo2Digits(props.value.percentage) + '%' : ''}
            </Type>
          ) : null,
        sortType: (a, b) => {
          if (a.values.asset.name < b.values.asset.name) {
            return -1;
          }
          if (a.values.asset.name > b.values.asset.name) {
            return 1;
          }
          return 0;
        },
      },
      {
        Header: '',
        accessor: 'actions',
        disableSortBy: true,
        width: actionsWidth,
        Cell: (props) => (
          <ActionsContainer>{props.value.map((action: Action) => renderActionButtons(action))}</ActionsContainer>
        ),
      },
      {
        Header: '',
        accessor: 'mobileActions',
        disableSortBy: true,
        width: mobileActionsWidth,
        Cell: () => (
          <ActionsContainer>
            <BuyButton>Kopen</BuyButton>
          </ActionsContainer>
        ),
      },
    ],
    [
      formatMessage,
      renderActionButtons,
      mobileActionsWidth,
      actionsWidth,
      assetWidth,
      courseWidth,
      changeWidth,
      typeWidth,
      hiddenColumns,
    ],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useFlexLayout,
    useSortBy,
  );

  const renderSortingIcon = useCallback((isSortedDesc: boolean | undefined) => {
    return (
      <SortingIconWrapper isSortedDesc={isSortedDesc}>
        <SortingIcon />
      </SortingIconWrapper>
    );
  }, []);

  return (
    <>
      <FlexibleTable {...getTableProps()}>
        <THeaderContainer>
          {headerGroups.map((headerGroup, index) => (
            <THeadRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, i) => (
                <THead {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                  {column.render('Header')}
                  {!column.disableSortBy && renderSortingIcon(column.isSortedDesc)}
                </THead>
              ))}
            </THeadRow>
          ))}
        </THeaderContainer>

        <TBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TRow {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <TCell {...cell.getCellProps()} key={index}>
                      {cell.render('Cell')}
                    </TCell>
                  );
                })}
              </TRow>
            );
          })}
        </TBody>
      </FlexibleTable>
    </>
  );
}

export default PopularAssetsTable;
