import React, { useEffect } from 'react';
// libs
import { useSelector } from 'react-redux';
// components
import CarouselSection from './CarouselSection';
import FeaturedSection from './FeaturedSection';
import CategoriesSection from './CategoriesSection';
import FAQSection from './FAQSection';
// store
import { dashboardItemsSelector, fetchNFTDashboardItems } from 'store/nftItem';
import { useAppDispatch } from 'store';
// styled
import { Wrapper } from './styled';

const LandingPage: React.FC = () => {
  const dashboardItems = useSelector(dashboardItemsSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const shouldFetch = !dashboardItems.recentItems.length || !dashboardItems.randomItems.length;

    if (shouldFetch) {
      dispatch(fetchNFTDashboardItems());
    }
  }, [dashboardItems, dispatch]);

  return (
    <Wrapper>
      <CarouselSection />
      <FeaturedSection />
      <CategoriesSection />
      <FAQSection />
    </Wrapper>
  );
};

export default LandingPage;
