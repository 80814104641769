import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authAccessTokenSelector } from 'store/auth';
import { uiLocaleSelector } from '../../../store/ui';

export interface UnauthorizedRouteProps {}

function UnauthorizedRoute() {
  const accessToken = useSelector(authAccessTokenSelector);
  const localeFromStore = useSelector(uiLocaleSelector);

  if (!accessToken) {
    return <Outlet />;
  }

  return <Navigate to={`/${localeFromStore}/marketplace`} replace />;
}

export default UnauthorizedRoute;
