// styled
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
// material-ui
import { Button as MaterialButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { Colors } from 'themes/colors';
// components
import FormTextField from 'components/formik/FormTextField';
import Img from 'components/Img';

const LinkStyles = css`
  padding: 12px 8px;
  line-height: 19px;
  text-decoration: underline;
  font-family: 'VisbyRoundCF Regular', Arial, sans-serif;
  color: ${Colors.brescianBlue};
  word-break: break-all;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 99px 16px 80px;
  min-height: 100vh;

  ${({ theme }) => theme.media.sm`
    padding: 24px 8px 48px;
    background-color: ${theme.palette.common.white};
  `}
`;

export const ContentWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;

  &:not(:first-of-type) {
    width: 100%;
  }

  & > a:last-of-type {
    padding: 0 8px;
  }

  ${({ theme }) => theme.media.sm`
    padding: 16px;

    & .MuiFormControl-root {
      padding: 0 8px;
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1048px;
  padding: 4px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  background-color: ${({ theme }) => theme.palette.common.white};

  & > div:first-of-type {
    max-width: 400px;
  }

  ${({ theme }) => theme.media.sm`
    padding: 0;
    border: 0;
    flex-direction: column;
    
    & > div:first-of-type {
      max-width: 100%;
    }
  `}
`;

export const Image = styled(Img)`
  width: 360px;
  height: 360px;
  margin-bottom: 28px;
  object-fit: cover;

  ${({ theme }) => theme.media.sm`
    width: 100%;
    height: 480px;
    border-radius: 10px;
    margin-bottom: 24px;
    filter: drop-shadow(0px 8px 20px rgba(0, 35, 68, 0.1));
  `}

  ${({ theme }) => theme.media.xs`
    height: auto;
  `}
`;

export const ImagePlaceholder = styled.div`
  width: 360px;
  height: 360px;
  margin-bottom: 28px;

  ${({ theme }) => theme.media.sm`
    width: 100%;
    height: 480px;
    border-radius: 10px;
    margin-bottom: 24px;
    filter: drop-shadow(0px 8px 20px rgba(0, 35, 68, 0.1));
  `}

  ${({ theme }) => theme.media.xs`
    height: auto;
  `}
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const TagsTitle = styled(Title)``;

export const DescriptionTitle = styled(Title)`
  margin-top: 40px;
`;

export const LinkTitle = styled(Title)`
  margin-top: 8px;
`;

export const DescriptionText = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF Regular', sans-serif;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const ItemName = styled.div`
  font-size: 24px;
  line-height: 29px;
  padding: 7px 8px 7px 0 !important;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const PriceTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  margin-top: 42px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};

  ${({ theme }) => theme.media.sm`
    margin-top: 32px;
  `}
`;

export const PriceTitleHelper = styled.div`
  font-family: 'VisbyRoundCF Regular', sans-serif;
  font-size: 11px;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const PriceValue = styled.div`
  font-size: 32px;
  line-height: 38px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 44px;
`;

export const Button = styled(MaterialButton)`
  width: 100%;
  max-width: 162px;
  padding: 12px 6px;
  font-size: 16px;
  line-height: 20px;
  border: 1px solid transparent;

  ${({ theme }) => theme.media.sm`
    max-width: 100%;
  `}
`;

export const SecondaryButton = styled(MaterialButton)`
  width: 100%;
  max-width: 162px;
  padding: 12px 6px;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.snowBank};
  }

  ${({ theme }) => theme.media.sm`
    max-width: 100%;
  `}
`;

export const NavLink = styled(Link)`
  ${LinkStyles};
  padding: 0 2px !important;

  ${({ theme }) => theme.media.sm`
    padding-left: 8px;
  `}
`;

export const ExternalLink = styled.a`
  ${LinkStyles};
  padding: 0 !important;

  ${({ theme }) => theme.media.sm`
    padding-left: 8px;
  `}
`;

export const SaleDateEnd = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  line-height: 19.2px;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice50};
  margin-top: 8px;
`;

export const CountdownWrapper = styled.div`
  margin-top: 46px;
`;

export const YourBidLabel = styled.label`
  font-size: 24px;
  line-height: 29px;
  margin-top: 46px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const HelperText = styled.span`
  font-family: 'VisbyRoundCF Regular', sans-serif;
  font-size: 11px;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const StyledField = styled(FormTextField)`
  & .MuiOutlinedInput-root .MuiInputAdornment-root .MuiTypography-root {
    font-size: 28px;
  }
  & .MuiOutlinedInput-input {
    text-align: left;
    font-family: 'VisbyRoundCF Medium', sans-serif;
  }

  ${({ theme }) => theme.media.sm`
    max-width: 100%;
  `}
`;

export const ReviewButton = styled(Button)`
  margin-top: 44px;
`;

export const SuccessText = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.waystoneGreen};
`;

export const HoorayText = styled(SuccessText)`
  margin-top: 8px;
`;

export const YourBidAccepted = styled(SuccessText)`
  margin: 44px 0 4px 0;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 50px;
  background-color: ${({ theme }) => theme.palette.common.snowBank};
`;

export const BidsTableTitle = styled.div`
  font-size: 21px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  margin-top: 50px;
  margin-bottom: 44px;
`;

export const BidsTableWrapper = styled.div`
  width: 100%;
  margin-bottom: 44px;
`;
