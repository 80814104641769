import styled from '@emotion/styled/macro';
import { TextField as MuiTextField } from '@mui/material';

export const TextFieldLabel = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  margin-bottom: 10px;
`;

export const TextFieldContainer = styled.label`
  display: flex;
  flex-direction: column;
`;

export const TextFiled = styled(MuiTextField)`
  & .MuiFormHelperText-root {
    margin-left: 0;
    font-family: 'VisbyRoundCF SemiBold', sans-serif;
    font-size: 11px;
  }
`;
