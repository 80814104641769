import React, { useCallback, useMemo } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// material-ui
import { Collapse } from '@mui/material';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { PRODUCTS_ITEMS, ABOUT_ITEMS } from '../../../../constants';
import { COINMERCE_BASE_URL } from 'constants/general';
// styled
import { Wrapper, ListItem, ListItemDescription, ListItemTitle, ListItemLink, ListItemIconWrapper } from './styled';

export type ListType = 'about' | 'products';

export interface PopoverProps {
  isOpened: boolean;
  listType: ListType;
}

const lists = {
  about: ABOUT_ITEMS,
  products: PRODUCTS_ITEMS,
};

function MenuPopover({ isOpened, listType }: PopoverProps) {
  const { formatMessage } = useIntl();

  const locale = useSelector(uiLocaleSelector);

  const getCoinMerceLink = useCallback((subLink: string) => `${COINMERCE_BASE_URL}${locale}/${subLink}`, [locale]);

  const list = useMemo(() => {
    return lists[listType].map(({ title, description, icon, link }) => {
      const Icon = icon;
      return (
        <ListItem key={title}>
          <ListItemLink href={getCoinMerceLink(link)} tabIndex={1}>
            <ListItemIconWrapper>
              <Icon />
            </ListItemIconWrapper>
            <ListItemTitle>
              {formatMessage({ id: title })}
              <ListItemDescription>{formatMessage({ id: description })}</ListItemDescription>
            </ListItemTitle>
          </ListItemLink>
        </ListItem>
      );
    });
  }, [formatMessage, getCoinMerceLink, listType]);

  return (
    <Collapse in={isOpened} timeout={{ enter: 250, exit: 150 }} unmountOnExit>
      <Wrapper>{list}</Wrapper>
    </Collapse>
  );
}

export default MenuPopover;
