import React from 'react';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import { Description, Link, Title, Wrapper } from './styled';

export interface CoinDescriptionCardProps {
  icon: JSX.Element;
  title: string;
  description: string;
  link: string;
}

const CoinDescriptionCard: React.FC<CoinDescriptionCardProps> = ({ icon, title, description, link }) => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      {icon}
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Link href={link}>{formatMessage({ id: IntlKeys.websiteSinglecoinReadmore })}</Link>
    </Wrapper>
  );
};

export default CoinDescriptionCard;
