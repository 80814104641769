import storage from 'redux-persist/lib/storage';
import { configureStore, combineReducers, Reducer } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { useDispatch } from 'react-redux';
// reducers
import uiSliceReducer from './ui';
import marketplaceSliceReducer from './marketplace';
import authSliceReducer from './auth';
import collectionSliceReducer from './collection';
import notificationSliceReducer from './notification';
import nftItemSliceReducer from './nftItem';
import listingSliceReducer from './listing';
import profileReducer from './profile';
import bidReducer from './bid';
// constants
import { IGNORED_ACTIONS, PERSIST_CONFIG_KEY_NAME, SLICE_NAMES } from './constants';

const rootReducer: Reducer = combineReducers({
  [SLICE_NAMES.UI]: uiSliceReducer,
  [SLICE_NAMES.MARKETPLACE]: marketplaceSliceReducer,
  [SLICE_NAMES.AUTH]: authSliceReducer,
  [SLICE_NAMES.COLLECTION]: collectionSliceReducer,
  [SLICE_NAMES.NOTIFICATION]: notificationSliceReducer,
  [SLICE_NAMES.NFT_ITEM]: nftItemSliceReducer,
  [SLICE_NAMES.LISTING]: listingSliceReducer,
  [SLICE_NAMES.PROFILE]: profileReducer,
  [SLICE_NAMES.BID]: bidReducer,
});

const persistConfig = {
  key: PERSIST_CONFIG_KEY_NAME,
  version: 1,
  storage,
  blacklist: [
    SLICE_NAMES.MARKETPLACE,
    SLICE_NAMES.COLLECTION,
    SLICE_NAMES.NOTIFICATION,
    SLICE_NAMES.NFT_ITEM,
    SLICE_NAMES.LISTING,
    SLICE_NAMES.PROFILE,
    SLICE_NAMES.BID,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: IGNORED_ACTIONS,
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type RootReducer = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
