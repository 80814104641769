import React, { useCallback, useMemo } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// components
import Modal from 'components/Modal';
// hooks
import useFetchNftItemThumbnail from 'hooks/useFetchNftItemThumbnail';
// store
import { uiLocaleSelector } from 'store/ui';
import { Bid, Item } from 'store/types';
import { useAppDispatch } from 'store';
import { approveBid } from 'store/bid';
import { setNotification } from 'store/notification';
// helpers
import { roundTo2Digits } from 'helpers/renderHelpers';
// constants
import { IntlKeys } from 'localization/keys';
import { COINMERCE_FEE_PERCENT, EURO_SIGN } from 'constants/general';
// styled
import {
  Wrapper,
  Image,
  ItemContainer,
  ItemInfoContainer,
  ItemName,
  InfoContainer,
  BigTitle,
  CommonContainer,
  SmallDescription,
  BigValue,
  SmallTitle,
  SmallValue,
  Divider,
  ButtonsContainer,
  PrimaryButton,
  SecondaryButton,
  Container,
} from '../shared/styles/modals';
import { NavLink } from '../styled';
import { ValuesContainer } from './styled';
import { fetchNFTItem } from '../../../store/nftItem';

export interface AcceptBidProps {
  isOpen: boolean;
  onClose: () => void;
  selectedBid: Bid | null;
  item: Item;
}

const AcceptBid: React.FC<AcceptBidProps> = ({ isOpen, onClose, selectedBid, item }) => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id: itemId } = useParams();

  const locale = useSelector(uiLocaleSelector);

  const { image } = useFetchNftItemThumbnail({ itemId: item?.id, thumbnailType: 'show', isMarketplaceItem: false });

  const coinmerceFee = useMemo(() => Number(selectedBid?.price) * 0.018, [selectedBid?.price]);

  const settlement = useMemo(
    () => roundTo2Digits(Number(selectedBid?.price) - coinmerceFee),
    [coinmerceFee, selectedBid?.price],
  );

  const handleAcceptBidClick = useCallback(() => {
    if (selectedBid && item.id) {
      dispatch(approveBid(selectedBid.id))
        .unwrap()
        .then(() => {
          if (itemId) {
            dispatch(setNotification({ message: formatMessage({ id: IntlKeys.toastBidApproved }), type: 'success' }));
            dispatch(fetchNFTItem(itemId));
          }
          onClose();
        })
        .then(() => {
          if (itemId) {
            navigate(`/${locale}/item-details/${itemId}`);
          }
        })
        .catch(() =>
          dispatch(
            setNotification({ message: formatMessage({ id: IntlKeys.toastSomethingWentWrong }), type: 'error' }),
          ),
        );
    }
  }, [dispatch, formatMessage, item.id, itemId, locale, navigate, onClose, selectedBid]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Wrapper>
        <Container>
          <ItemContainer>
            <Image src={image} />
            <ItemInfoContainer>
              <ItemName>{item?.name}</ItemName>
              <NavLink to={`/${locale}/my-collections/${item?.collection?.id}`}>{item?.collection?.name}</NavLink>
            </ItemInfoContainer>
          </ItemContainer>
          <InfoContainer>
            <CommonContainer>
              <BigTitle>
                {formatMessage({ id: IntlKeys.listingAcceptBidModalSettlement })}
                <SmallDescription>
                  {formatMessage({ id: IntlKeys.listingAcceptBidModalSettlementDescription })}
                </SmallDescription>
              </BigTitle>
              <BigValue>
                {EURO_SIGN}
                {settlement}
              </BigValue>
            </CommonContainer>

            <Divider />

            <ValuesContainer>
              <CommonContainer>
                <SmallTitle>
                  {formatMessage({ id: IntlKeys.listingAcceptBidModalBidAmount })}
                  <SmallDescription>
                    {formatMessage({ id: IntlKeys.listingAcceptBidModalBidAmountDescription })}
                  </SmallDescription>
                </SmallTitle>
                <SmallValue>
                  {EURO_SIGN}
                  {roundTo2Digits(Number(selectedBid?.price))}
                </SmallValue>
              </CommonContainer>

              <CommonContainer>
                <SmallTitle>
                  {formatMessage({ id: IntlKeys.listingAcceptBidModalCoinmerceFee })}
                  <SmallDescription>
                    {formatMessage(
                      { id: IntlKeys.listingAcceptBidModalCoinmerceFeeDescription },
                      { percentage: COINMERCE_FEE_PERCENT },
                    )}
                  </SmallDescription>
                </SmallTitle>
                <SmallValue>
                  {EURO_SIGN}
                  {roundTo2Digits(coinmerceFee)}
                </SmallValue>
              </CommonContainer>
            </ValuesContainer>
          </InfoContainer>
        </Container>
        <ButtonsContainer>
          <PrimaryButton size="large" onClick={handleAcceptBidClick}>
            {formatMessage({ id: IntlKeys.commonAccept })}
          </PrimaryButton>
          <SecondaryButton size="large" onClick={onClose}>
            {formatMessage({ id: IntlKeys.commonCancel })}
          </SecondaryButton>
        </ButtonsContainer>
      </Wrapper>
    </Modal>
  );
};

export default AcceptBid;
