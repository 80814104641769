import styled from '@emotion/styled/macro';

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.common.brilliantLicorice50};
  margin-top: 24px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
