import React, { useEffect, useCallback, useState, ReactElement } from 'react';
import { flushSync } from 'react-dom';
// constants
import { CardStatus } from './constants';
// styled
import { Card, CardCarousel, Indicator, IndicatorsContainer, Wrapper } from './styled';

interface OnCardChangeParams {
  previousIndex: number;
  currentIndex: number;
  nextIndex: number;
}

export interface CarouselProps {
  showSummary?: boolean;
  style?: Record<string, unknown>;
  onCardChange?: ({ previousIndex, currentIndex, nextIndex }: OnCardChangeParams) => void;
  containerClassName?: string;
  cardClassName?: string;
  leftButton?: JSX.Element;
  rightButton?: JSX.Element;
  autoRotate?: boolean;
  rotationInterval?: number;
  children: ReactElement[];
}

const transition = {
  type: 'spring',
};

const item = {
  active: { scale: 1, x: 0, y: 0, opacity: 1, transition },
  prev: { scale: 0.6, x: '-30%', opacity: 0.4, transition },
  next: { scale: 0.6, x: '30%', opacity: 0.4, transition },
  inactive: { scale: 0.1, x: 0, opacity: 0, transition },
};

const Carousel: React.FC<CarouselProps> = ({
  style,
  onCardChange,
  children,
  autoRotate = true,
  rotationInterval = 5000,
}) => {
  const cardItems = children;

  const [isIterating, setIsIterating] = useState(false);
  const [indexes, setIndexes] = useState({
    previousIndex: cardItems.length - 1,
    currentIndex: 0,
    nextIndex: 1,
  });

  const setCardStatus = useCallback((indexes: OnCardChangeParams, cardIndex: number) => {
    if (indexes.currentIndex === cardIndex) {
      return CardStatus.Active;
    } else if (indexes.nextIndex === cardIndex) {
      return CardStatus.Next;
    } else if (indexes.previousIndex === cardIndex) {
      return CardStatus.Prev;
    }
    return CardStatus.Inactive;
  }, []);

  const handleRightCardClick = useCallback(() => {
    if (indexes.currentIndex >= cardItems.length - 1) {
      setIndexes({
        previousIndex: cardItems.length - 1,
        currentIndex: 0,
        nextIndex: 1,
      });
    } else {
      setIndexes((prevState) => ({
        previousIndex: prevState.currentIndex,
        currentIndex: prevState.currentIndex + 1,
        nextIndex: prevState.currentIndex + 2 === cardItems.length ? 0 : prevState.currentIndex + 2,
      }));
    }
  }, [cardItems.length, indexes.currentIndex]);

  const handleLeftCardClick = useCallback(() => {
    if (indexes.currentIndex <= 0) {
      setIndexes({
        previousIndex: cardItems.length - 2,
        currentIndex: cardItems.length - 1,
        nextIndex: 0,
      });
    } else {
      setIndexes((prevState) => ({
        nextIndex: prevState.currentIndex,
        currentIndex: prevState.currentIndex - 1,
        previousIndex: prevState.currentIndex - 1 <= 0 ? cardItems.length - 1 : prevState.currentIndex - 2,
      }));
    }
  }, [cardItems.length, indexes.currentIndex]);

  const longTask = async (callback: () => void, isFirstIteration = false) => {
    return new Promise((resolve) => {
      setTimeout(
        () => {
          resolve(callback());
        },
        isFirstIteration ? 0 : 250,
      );
    });
  };

  const handleIndicatorClick = (newIndex: number) => async () => {
    setIsIterating(true);

    if (newIndex <= 0 && indexes.currentIndex === cardItems.length - 1) {
      setIndexes({
        previousIndex: cardItems.length - 1,
        currentIndex: 0,
        nextIndex: 1,
      });

      return setIsIterating(false);
    }

    if (newIndex >= cardItems.length - 1 && indexes.currentIndex === 0) {
      setIndexes({
        previousIndex: cardItems.length - 2,
        currentIndex: cardItems.length - 1,
        nextIndex: 0,
      });

      return setIsIterating(false);
    }

    if (indexes.currentIndex > newIndex) {
      const array = Array.from({ length: indexes.currentIndex - newIndex });

      for (const [index] of array.entries()) {
        const isFirstIteration = index === 0;
        await longTask(() => flushSync(() => handleLeftCardClick()), isFirstIteration);
      }
    }

    if (indexes.currentIndex < newIndex) {
      const array = Array.from({ length: newIndex - indexes.currentIndex });

      for (const [index] of array.entries()) {
        const isFirstIteration = index === 0;
        await longTask(() => flushSync(() => handleRightCardClick()), isFirstIteration);
      }
    }

    return setIsIterating(false);
  };

  const setCardClickHandler = useCallback(
    (indexes: OnCardChangeParams, cardIndex: number) => {
      if (indexes.currentIndex === cardIndex) {
        return () => {};
      } else if (indexes.nextIndex === cardIndex) {
        return handleRightCardClick;
      } else if (indexes.previousIndex === cardIndex) {
        return handleLeftCardClick;
      }
      return () => {};
    },
    [handleRightCardClick, handleLeftCardClick],
  );

  useEffect(() => {
    onCardChange && onCardChange(indexes);
    const transitionInterval = setInterval(() => {
      autoRotate && handleRightCardClick();
    }, rotationInterval);
    return () => clearInterval(transitionInterval);
  }, [handleRightCardClick, indexes, autoRotate, onCardChange, rotationInterval]);

  return (
    <Wrapper>
      <CardCarousel style={{ ...style }}>
        {cardItems.map((card, index) => (
          <Card
            key={`key${index}`}
            cardStatus={setCardStatus(indexes, index)}
            onClick={setCardClickHandler(indexes, index)}
            variants={item}
            animate={setCardStatus(indexes, index)}
          >
            {card}
          </Card>
        ))}
      </CardCarousel>
      <IndicatorsContainer>
        {Array.from({ length: cardItems?.length }).map((_, index) => (
          <Indicator
            key={index}
            isActive={index === indexes.currentIndex}
            onClick={isIterating ? () => {} : handleIndicatorClick(index)}
          ></Indicator>
        ))}
      </IndicatorsContainer>
    </Wrapper>
  );
};

export default Carousel;
