// material-ui
import { MenuItem, ToggleButtonGroup } from '@mui/material';
// styled
import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
  `}
`;

export const MainContentWrapper = styled.div`
  width: 100%;
  padding: 40px 40px 120px;
  background: linear-gradient(270deg, #fbfbfb 0%, #ffffff 99.06%);

  ${({ theme }) => theme.media.md`
    padding: 8px 16px 120px;
  `}
`;

export const CardsWrapper = styled.div<{ isSmall: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  transition: all 0.3s ease-out;

  ${({ isSmall }) => isSmall && `gap: 24px;`}
`;

export const NumberOfItemsCollectionContainer = styled.div`
  display: flex;
  align-self: center;
`;

export const CollectionTitle = styled.div`
  font-size: 36px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
  color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 12px;
  line-height: 14px;
`;

export const NumberOfItems = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF Regular', Arial, sans-serif;
`;

export const FilterTagsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  ${({ theme }) => theme.media.sm`
    display: none;
  `}
`;

export const CommonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-of-type(2) {
    margin: 40px 0 24px;
  }

  ${({ theme }) => theme.media.md`
    & .MuiToggleButtonGroup-root {
      margin-left: auto;
    }

    &:nth-of-type(2) {
      justify-content: flex-start;
    }
  `}

  ${({ theme }) => theme.media.sm`
    & .MuiToggleButtonGroup-root {
      display: none;
    }

    &:nth-of-type(2) {
      margin: 16px 0 40px;
    }
  `}
`;

export const MobileFilterButton = styled.button`
  width: 100%;
  max-width: 158px;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  padding: 12px 14px;
  border-radius: 3px;
  margin-right: 16px;
  font-family: 'VisbyRoundCF SemiBold', Arial, sans-serif;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.common.brescianBlue};
  display: none;

  ${({ theme }) => theme.media.md`
    display: block;
  `}

  ${({ theme }) => theme.media.sm`
    margin: 0;
    max-width: 100%;
  `}
`;

export const ClearButton = styled.div`
  cursor: pointer;
`;

export const SelectContainer = styled.div`
  width: 250px;
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const ToggleCardGridButtons = styled(ToggleButtonGroup)`
  display: block;
  min-width: 82px;
  align-self: flex-start;

  & .MuiButtonBase-root {
    border-color: ${({ theme }) => theme.palette.common.snowBank};
    color: ${({ theme }) => theme.palette.common.blackSpace};
    padding: 9px 6px;
  }

  & .Mui-selected {
    color: #0082ff;
    background-color: rgba(0, 130, 255, 0.1);
  }
`;

export const NoItemsFoundText = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  align-self: center;
  font-size: 24px;
  font-weight: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;
