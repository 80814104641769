import React, { useCallback, useMemo } from 'react';
// libs
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { format, parseISO, isValid } from 'date-fns';
import { useIntl } from 'react-intl';
// components
import Modal from 'components/Modal';
// hooks
import useFetchNftItemThumbnail from 'hooks/useFetchNftItemThumbnail';
// helpers
import { roundTo2Digits } from 'helpers/renderHelpers';
// store
import { CreateListingPayload, Item } from 'store/types';
import { listingSelector } from 'store/listing';
import { uiLocaleSelector } from 'store/ui';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import {
  FieldContainer,
  FieldsContainer,
  Label,
  LabelBig,
  SubmitButton,
  Value,
  ValueBig,
  Wrapper,
  Image,
  ItemContainer,
  ItemInfoContainer,
  ItemName,
  CancelButton,
  ButtonsContainer,
  Divider,
} from './styles';
import { NavLink } from '../styled';

export interface ReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  item: Item;
}

const DATE_FORMAT = 'dd-MM-yyyy';

const ReviewModal: React.FC<ReviewModalProps> = ({ isOpen, onClose, item }) => {
  const { formatMessage } = useIntl();

  const locale = useSelector(uiLocaleSelector);
  // const { item } = useSelector(listingSelector);
  const { values, submitForm } = useFormikContext<CreateListingPayload & { dateRange: string }>();

  const { image } = useFetchNftItemThumbnail({ itemId: item?.id, thumbnailType: 'show', isMarketplaceItem: false });

  const dateRange = useMemo(() => {
    switch (values.dateRange) {
      case '3':
        return formatMessage({ id: IntlKeys.listingReviewModalDateRangeThreeMonths });
      case '6':
        return formatMessage({ id: IntlKeys.listingReviewModalDateRangeSixMonths });
      case '9':
        return formatMessage({ id: IntlKeys.listingReviewModalDateRangeNineMonths });
      case '12':
        return formatMessage({ id: IntlKeys.listingReviewModalDateRangeTwelveMonths });
      case 'custom':
      default:
        return formatMessage({ id: IntlKeys.listingReviewModalDateRangeCustomRange });
    }
  }, [formatMessage, values.dateRange]);

  const formatData = useCallback((dateString: string) => {
    const parsedDate = parseISO(dateString);

    if (isValid(parsedDate)) {
      return format(parsedDate, DATE_FORMAT);
    }

    return '';
  }, []);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Wrapper>
        <ItemContainer>
          <Image src={image} />
          <ItemInfoContainer>
            <ItemName>{item?.name}</ItemName>
            <NavLink to={`/${locale}/my-collections/${item?.collection?.id}`}>{item?.collection?.name}</NavLink>
          </ItemInfoContainer>
        </ItemContainer>
        <FieldsContainer>
          <FieldContainer>
            <LabelBig>{formatMessage({ id: IntlKeys.listingReviewModalBuyNowPrice })}</LabelBig>
            <ValueBig>€{roundTo2Digits(values.buyoutPrice)}</ValueBig>
          </FieldContainer>
          <Divider />
          <FieldContainer>
            <Label>{formatMessage({ id: IntlKeys.listingReviewModalStartingPrice })}</Label>
            <Value>€{roundTo2Digits(values.price)}</Value>
          </FieldContainer>
          <FieldContainer>
            <Label>{formatMessage({ id: IntlKeys.listingReviewModalBidIncrement })}</Label>
            <Value>€{roundTo2Digits(values.bidStepSize)}</Value>
          </FieldContainer>
          <FieldContainer>
            <Label>{formatMessage({ id: IntlKeys.listingReviewModalDuration })}</Label>
            <Value>
              {formatMessage(
                { id: IntlKeys.listingReviewModalDateRangeInfo },
                { dateRange, dateStart: formatData(values.dateTimeStart), dateEnd: formatData(values.dateTimeEnd) },
              )}
            </Value>
          </FieldContainer>
        </FieldsContainer>
        <ButtonsContainer>
          <SubmitButton size="large" type="submit" onClick={submitForm}>
            {formatMessage({ id: IntlKeys.listingReviewModalSubmitListingButton })}
          </SubmitButton>
          <CancelButton size="large" type="submit" onClick={onClose}>
            {formatMessage({ id: IntlKeys.listingReviewModalCancelButton })}
          </CancelButton>
        </ButtonsContainer>
      </Wrapper>
    </Modal>
  );
};

export default ReviewModal;
