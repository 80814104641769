import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  border-radius: 2px;
  width: calc(33.33% - 27px);
  @media (min-width: 991px) and (max-width: 1180px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: 'VisbyRoundCF SemiBold';
  text-align: center;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  font-family: 'VisbyRoundCF SemiBold';
  text-align: center;
`;

export const Link = styled.a`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  font-family: 'VisbyRoundCF SemiBold';
`;
