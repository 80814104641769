import React, { useCallback, useMemo } from 'react';
// libs
import { useStickyBox } from 'react-sticky-box';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
// components
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
// hooks
import useVisibility from 'hooks/useVisible';
import useWindowSize from 'hooks/useWindowSize';
// constants
import { breakpoints } from 'themes/mediaQueries';
import { IntlKeys } from 'localization/keys';
// icons
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrow-forward.svg';
import { ReactComponent as ArrowBackwardIcon } from 'assets/icons/arrow-backward.svg';
// styled
import {
  Nav,
  Wrapper,
  Container,
  PrimaryNavTab,
  SecondaryNavTab,
  SideContent,
  LeftColumnWrapper,
  BuyButtonsContainer,
  BuyButtonsContainerStatic,
  ButtonBuy,
} from './styles';

function SingleCoinViewPage() {
  const { formatMessage } = useIntl();

  const [isButtonsContainerVisible, buttonsContainerElement] = useVisibility<HTMLDivElement>(0, 0);
  const [isLeftColumnVisible, leftColumnElement] = useVisibility<HTMLDivElement>(40, 0, 'bottom', true);

  const stickyRef = useStickyBox({ offsetTop: 20, offsetBottom: 20 });
  const [width] = useWindowSize();

  const smallerThanMd = useMemo(() => width <= breakpoints.md, [width]);

  const hideButtons = useMemo(
    () => isButtonsContainerVisible || isLeftColumnVisible,
    [isButtonsContainerVisible, isLeftColumnVisible],
  );

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, []);

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>
      <Wrapper>
        <Nav>
          {smallerThanMd ? (
            <ArrowBackwardIcon />
          ) : (
            <>
              <PrimaryNavTab>{formatMessage({ id: IntlKeys.websiteSinglecoinBreadcrumbCoins })}</PrimaryNavTab>
              <ArrowForwardIcon width="11px" height="11px" />
              <SecondaryNavTab>Verasity</SecondaryNavTab>
            </>
          )}
        </Nav>
        <Container>
          {smallerThanMd ? (
            <LeftColumnWrapper ref={leftColumnElement}>
              <LeftColumn />
            </LeftColumnWrapper>
          ) : (
            <SideContent ref={stickyRef}>
              <LeftColumn />
            </SideContent>
          )}
          <RightColumn />
        </Container>
        <BuyButtonsContainer containerWidth={buttonsContainerElement.current?.offsetWidth} isHidden={hideButtons}>
          <ButtonBuy onClick={scrollToTop}>Koop nu VRA</ButtonBuy>
        </BuyButtonsContainer>
        {smallerThanMd && (
          <BuyButtonsContainerStatic ref={buttonsContainerElement}>
            <ButtonBuy onClick={scrollToTop}>
              {formatMessage({ id: IntlKeys.websiteSinglecoinBuyButtonCoin }, { coin: 'VRA' })}
            </ButtonBuy>
          </BuyButtonsContainerStatic>
        )}
      </Wrapper>
    </>
  );
}

export default SingleCoinViewPage;
