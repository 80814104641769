import React, { useCallback, useEffect, useState } from 'react';
// libs
import { useMediaQuery } from 'react-responsive';
// components
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import WalletsTable from './components/WalletsTable';
import CustomCheckbox from './components/CustomCheckbox';
// providers
import { DashboardState, useDashboardContext } from 'providers/DashboardProvider';
// constants
import { breakpoints } from 'themes/mediaQueries';
// styled
import {
  CheckboxesContainer,
  Container,
  ConvertButton,
  ConvertButtonWrapper,
  WalletsTableContainer,
  WalletsTableTitle,
  WalletsTableWrapper,
  Wrapper,
} from './styled';

const DashboardPage: React.FC = () => {
  const { dashboardState } = useDashboardContext();

  const [showOnlyFullWallets, setShowOnlyFullWallets] = useState(true);

  const handleShowOnlyFullWalletsChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOnlyFullWallets(event.target.checked);
  }, []);

  const isXs = useMediaQuery({ maxWidth: breakpoints.xs });
  const isSm = useMediaQuery({ maxWidth: breakpoints.sm });

  return (
    <Wrapper>
      <Container>
        <LeftColumn />
        <RightColumn />
      </Container>
      <WalletsTableWrapper>
        <WalletsTableContainer>
          <WalletsTableTitle>Wallets</WalletsTableTitle>
          {!isSm && (
            <CheckboxesContainer>
              <CustomCheckbox defaultChecked label="Laat dust zien" />
              <CustomCheckbox
                checked={showOnlyFullWallets}
                onChange={handleShowOnlyFullWalletsChange}
                label="Laat alleen bezittingen zien"
              />
            </CheckboxesContainer>
          )}
        </WalletsTableContainer>
        <WalletsTable showOnlyFullWallets={showOnlyFullWallets} />
      </WalletsTableWrapper>
      <ConvertButtonWrapper>
        {!isXs && dashboardState === DashboardState.InUse && <ConvertButton>Convert small assets to CM</ConvertButton>}
      </ConvertButtonWrapper>
    </Wrapper>
  );
};

export default DashboardPage;
