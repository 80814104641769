import React from 'react';
import styled from '@emotion/styled/macro';
// libs
import { Link } from 'react-router-dom';
// components
import Img from 'components/Img';
// material
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@mui/material';

export const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))`
  background-color: ${({ theme }) => theme.palette.common.snowBank};

  &:before {
    display: none;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 12px 8px;

  & .MuiAccordionSummary-content {
    margin: 0;
    font-size: 16px;
    line-height: 19px;
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
    color: ${({ theme }) => theme.palette.common.brilliantLicorice};
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 16px 0 0;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const GoToCollectionLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  font-family: 'VisbyRoundCF SemiBold', Arial, sans-serif;
`;

export const CollectionImage = styled(Img)`
  width: calc(20% - 13px);
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};

  ${({ theme }) => theme.media.sm`
    width: calc(25% - 13px);
  `}

  ${({ theme }) => theme.media.xs`
    width: calc(33.33% - 13.5px);
  `}
`;

export const CollectionImagePlaceholder = styled.div`
  width: 180px;
  height: 180px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};

  ${({ theme }) => theme.media.xs`
    width: 100px;
    height: 100px;
  `}
`;

export const CollectionImageWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  ${({ theme }) => theme.media.xs`
    gap: 20px;
  `}
`;

export const NoItemsText = styled.div`
  font-size: 24px;
  text-align: center;
`;
