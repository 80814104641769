import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 240px;

  ${({ theme }) => theme.media.lg`
    padding-left: 16px;
    padding-right: 16px;
  `}

  ${({ theme }) => theme.media.sm`
    margin-top: 104px;
  `}
`;

export const Container = styled.div`
  width: 1004px;
  max-width: 1004px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 48px;
  line-height: 36px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};

  ${({ theme }) => theme.media.sm`
    font-size: 36px;
    line-height: 43.2px;
  `}

  ${({ theme }) => theme.media.xs`
    width: 300px;
  `}
`;

export const AccordionsContainer = styled.div`
  width: 100%;
  margin-top: 64px;

  ${({ theme }) => theme.media.sm`
    margin-top: 58px;
  `}
`;
