import React, { useEffect, useMemo } from 'react';
// libs
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components
import Content from '../Content';
// hooks
import useItemTags from 'hooks/useItemTags';
// store
import { useAppDispatch } from 'store';
import { fetchListing, listingMetaSelector, listingSelector } from 'store/listing';
import { fetchProfile, profileUserIdSelector } from 'store/profile';

const Update: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const listing = useSelector(listingSelector);
  const listingMeta = useSelector(listingMetaSelector);
  const userId = useSelector(profileUserIdSelector);

  const { item } = listing;

  const isOwner = useMemo(() => userId === item?.ownerId, [item?.ownerId, userId]);

  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(fetchListing(id));
    }
  }, [dispatch, id]);

  const tags = useItemTags({ isOwner, listingStatus: item?.activeListing?.status });

  return <Content isLoading={listingMeta.isLoading} item={item} tags={tags} listing={listing} />;
};

export default Update;
