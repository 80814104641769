import React, { useCallback, useEffect, useMemo, useState } from 'react';
// libs
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
// hooks
import useFetchCollectionItemsImages from '../hooks/useFetchNftItemsImages';
// store
import { uiLocaleSelector } from 'store/ui';
import { Item } from 'store/types';
import { useAppDispatch } from 'store';
import { collectionsItemsSelector, fetchCollectionsItems } from 'store/collection';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import { ExpandMore } from '@mui/icons-material';
// styled
import {
  CollectionImage,
  CollectionImagePlaceholder,
  CollectionImageWrapper,
  GoToCollectionLink,
  NoItemsText,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './styled';

type AccordionProps = {
  title: string;
  link: string;
  collectionId: number | string;
  defaultExpanded?: boolean;
};

const Accordion: React.FC<AccordionProps> = ({ title, link, collectionId, defaultExpanded = false }) => {
  const { formatMessage } = useIntl();

  const [expanded, setExpanded] = useState(defaultExpanded);
  const [collectionItems, setCollectionItems] = useState<Item[]>([]);
  const { images, isLoading } = useFetchCollectionItemsImages(collectionItems);

  const collectionsItems = useSelector(collectionsItemsSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const locale = useSelector(uiLocaleSelector);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => {
    setExpanded(expanded);
  };

  const handleCollectionImageClick = useCallback(
    (id: string | number) => () => navigate(`/${locale}/item-details/${id}`, { state: { isOwner: true } }),
    [locale, navigate],
  );

  const hasCurrentCollection = useMemo(
    () => collectionsItems.some((item) => item.collectionId === collectionId),
    [collectionId, collectionsItems],
  );

  useEffect(() => {
    if (!hasCurrentCollection && expanded) {
      dispatch(fetchCollectionsItems(collectionId));
    }
  }, [collectionId, collectionsItems, dispatch, expanded, hasCurrentCollection]);

  useEffect(() => {
    if (hasCurrentCollection) {
      const items = collectionsItems.find((item) => item.collectionId === collectionId)?.items;
      setCollectionItems(items || []);
    }
  }, [collectionId, collectionsItems, hasCurrentCollection]);

  return (
    <StyledAccordion expanded={expanded} onChange={handleChange}>
      <StyledAccordionSummary expandIcon={<ExpandMore />}>{title}</StyledAccordionSummary>

      <StyledAccordionDetails>
        {!!collectionItems?.length ? (
          <>
            <GoToCollectionLink to={link}>
              {formatMessage({ id: IntlKeys.myCollectionsGoToCustomCollection })}
            </GoToCollectionLink>

            <CollectionImageWrapper>
              {isLoading
                ? collectionItems?.map(({ id }) => <CollectionImagePlaceholder key={id} />)
                : images.map(({ id, image, name }) => (
                    <CollectionImage key={id} onClick={handleCollectionImageClick(id)} alt={name} src={image} />
                  ))}
            </CollectionImageWrapper>
          </>
        ) : (
          <NoItemsText>{formatMessage({ id: IntlKeys.myCollectionsGoToCustomCollection })}</NoItemsText>
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default Accordion;
