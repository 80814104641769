import { IntlKeys } from '../keys';

export const en = {
  // common
  [IntlKeys.commonClose]: 'Close',
  [IntlKeys.commonLorem]: 'Lorem ipsum',
  [IntlKeys.commonAccept]: 'Accept',
  [IntlKeys.commonCancel]: 'Cancel',
  [IntlKeys.commonCreate]: 'Create',

  // header
  [IntlKeys.headerHome]: 'Home',
  [IntlKeys.headerCoins]: 'Coins',
  [IntlKeys.headerNfts]: 'NFTs',
  [IntlKeys.headerWallets]: 'Wallets',
  [IntlKeys.headerOrders]: 'Orders',
  [IntlKeys.headerExplore]: 'Explore',
  [IntlKeys.headerExplorePopoverTitle]: 'An all-in-one platform for the future of money',
  [IntlKeys.headerExplorePopoverProducts]: 'Products',
  [IntlKeys.headerExplorePopoverAbout]: 'About',
  [IntlKeys.headerExplorePopoverProductItemsProofTitle]: 'Proof of State',
  [IntlKeys.headerExplorePopoverProductItemsBotsTitle]: 'COINMERCE Bots',
  [IntlKeys.headerExplorePopoverProductItemsBeginnersTitle]: "Beginner's Trading",
  [IntlKeys.headerExplorePopoverProductItemsAdvancedTitle]: 'Advanced Tools',
  [IntlKeys.headerExplorePopoverProductItemsGiftcardsTitle]: 'Giftcards',
  [IntlKeys.headerExplorePopoverProductItemsProofDescription]: 'Grow your holding',
  [IntlKeys.headerExplorePopoverProductItemsBotsDescription]: 'AI assisted algorithms',
  [IntlKeys.headerExplorePopoverProductItemsBeginnersDescription]: 'Start with Bitcoin and Crypto',
  [IntlKeys.headerExplorePopoverProductItemsAdvancedDescription]: 'Limits, repeating orders, more',
  [IntlKeys.headerExplorePopoverProductItemsGiftcardsDescription]: 'Give crypto as a gift!',
  [IntlKeys.headerExplorePopoverAboutItemsAboutTitle]: 'About Coinmerce',
  [IntlKeys.headerExplorePopoverAboutItemsCryptoTitle]: 'Crypto Information Center',
  [IntlKeys.headerExplorePopoverAboutItemsNewsTitle]: 'News',
  [IntlKeys.headerExplorePopoverAboutItemsLearnTitle]: 'Learn',
  [IntlKeys.headerExplorePopoverAboutItemsHelpTitle]: 'Need Help?',
  [IntlKeys.headerExplorePopoverAboutItemsVacanciesTitle]: 'Vacancies',
  [IntlKeys.headerExplorePopoverAboutItemsAboutDescription]: 'Team, Values, Goals',
  [IntlKeys.headerExplorePopoverAboutItemsCryptoDescription]: 'Our crypto shop',
  [IntlKeys.headerExplorePopoverAboutItemsNewsDescription]: 'The latest about crypto',
  [IntlKeys.headerExplorePopoverAboutItemsLearnDescription]: 'Tutorials and Protips',
  [IntlKeys.headerExplorePopoverAboutItemsHelpDescription]: 'Support and Contact',
  [IntlKeys.headerExplorePopoverAboutItemsVacanciesDescription]: 'Available jobs',
  [IntlKeys.headerAccountPopoverTitle]: 'My Account',
  [IntlKeys.headerAccountPopoverProfile]: 'Profile',
  [IntlKeys.headerAccountPopoverAccountLevel]: 'Account Level',
  [IntlKeys.headerAccountPopoverSecurity]: 'Security',
  [IntlKeys.headerAccountPopoverSettings]: 'Settings',
  [IntlKeys.headerAccountPopoverFriends]: 'Friends',
  [IntlKeys.headerAccountPopoverWithdrawAddresses]: 'Withdraw Addresses',
  [IntlKeys.headerAccountPopoverLogout]: 'Logout',
  [IntlKeys.headerNFTPopoverCreate]: 'Create',
  [IntlKeys.headerNFTPopoverMarketplace]: 'Marketplace',
  [IntlKeys.headerNFTPopoverMyCollections]: 'My Collections',
  [IntlKeys.headerNFTPopoverLaunchpad]: 'Launchpad',

  // footer
  [IntlKeys.footerLoginTitle]: 'Crypto is easy now.',
  [IntlKeys.footerLoginButton]: 'Log In',
  [IntlKeys.footerSignupButton]: 'Sign Up',
  [IntlKeys.footerListGeneral]: 'General',
  [IntlKeys.footerListGeneralAboutUs]: 'About us',
  [IntlKeys.footerListGeneralCareers]: 'Careers',
  [IntlKeys.footerListGeneralCoins]: 'Coins',
  [IntlKeys.footerListGeneralFraudPrevention]: 'Fraud prevention',
  [IntlKeys.footerListGeneralBugFound]: 'Bug found?',
  [IntlKeys.footerListGeneralApi]: 'API',
  [IntlKeys.footerListGeneralSitemap]: 'Sitemap',
  [IntlKeys.footerListPopular]: 'Popular',
  [IntlKeys.footerListPopularBuyBitcoin]: 'Buy Bitcoin',
  [IntlKeys.footerListPopularBitcoinPrice]: 'Bitcoin Price',
  [IntlKeys.footerListPopularBuyEthereum]: 'Buy Ethereum',
  [IntlKeys.footerListPopularEthereumPrice]: 'Ethereum Price',
  [IntlKeys.footerListPopularBuyRipple]: 'Buy Ripple',
  [IntlKeys.footerListPopularRipplePrice]: 'Ripple Price',
  [IntlKeys.footerListPopularBuyLota]: 'Buy lota',
  [IntlKeys.footerListPopularLotaPrice]: 'lota Price',
  [IntlKeys.footerListLegal]: 'Legal',
  [IntlKeys.footerListLegalTermsAndConditions]: 'Terms & Conditions',
  [IntlKeys.footerListLegalPrivacyPolicy]: 'Privacy Policy',
  [IntlKeys.footerListLegalRiskDisclaimer]: 'Risk Disclaimer',
  [IntlKeys.footerListJumpTo]: 'Jump to',
  [IntlKeys.footerListJumpToRegister]: 'Register',
  [IntlKeys.footerListJumpToLogin]: 'Login',
  [IntlKeys.footerListJumpToNeedHelp]: 'Need help?',
  [IntlKeys.footerListJumpToLearn]: 'Learn',
  [IntlKeys.footerListJumpToNewsAndBlog]: 'News & Blog',
  [IntlKeys.footerStore]: 'Store',
  [IntlKeys.footerOffice]: 'Office',

  // single coin view
  [IntlKeys.websiteSinglecoinBreadcrumbCoins]: 'Coins',
  [IntlKeys.websiteSinglecoinIntro]: 'Buy {coin} safely with SEPA, iDEAL, Bancontact, SEPA, Sofort or credit card on Coinmerce.',
  [IntlKeys.websiteSinglecoinUsp1]: 'Safely buy 200+ cryptocurrencies',
  [IntlKeys.websiteSinglecoinUsp2]: 'EUR platform founded in 2017',
  [IntlKeys.websiteSinglecoinUsp3]: '100,000+ users preceded you',
  [IntlKeys.websiteSinglecoinUsp4]: 'Helpful support team',
  [IntlKeys.websiteSinglecoinH1]: 'Buy {coin} with iDEAL or SEPA',
  [IntlKeys.websiteSinglecoinH2Price]: '{coin} price',
  [IntlKeys.websiteSinglecoinH2PriceCoin]: '{coin} price in euro',
  [IntlKeys.websiteSinglecoinH2PriceVolume]: '{coin} volume (24h)',
  [IntlKeys.websiteSinglecoinH2PriceMarketcap]: 'Market cap',
  [IntlKeys.websiteSinglecoinEarnH2]: 'Earn {coin}',
  [IntlKeys.websiteSinglecoinEarnRewards]: 'Est. yearly rewards',
  [IntlKeys.websiteSinglecoinEarnMinimum]: 'Minimum holding',
  [IntlKeys.websiteSinglecoinEarnFrequencyTitle]: 'Pay frequency',
  [IntlKeys.websiteSinglecoinEarnFrequencyTime]: 'Weekly',
  [IntlKeys.websiteSinglecoinEarnCalculateRewards]: 'Calculate Earn rewards',
  [IntlKeys.websiteSinglecoinEarn1Year]: '1 year',
  [IntlKeys.websiteSinglecoinEarn2Year]: '2 years',
  [IntlKeys.websiteSinglecoinEarn5Year]: '5 years',
  [IntlKeys.websiteSinglecoinEarnAsterisk]: '*Rewards are calculated weekly. Percentages are not guaranteed.',
  [IntlKeys.websiteSinglecoinEarnButton]: 'Activate Earn now',
  [IntlKeys.websiteSinglecoinStakingH2]: 'Stake {amount}',
  [IntlKeys.websiteSinglecoinStakingRewards]: 'Est. yearly rewards',
  [IntlKeys.websiteSinglecoinStakingMinimum]: 'Minimum holding',
  [IntlKeys.websiteSinglecoinStakingFrequencyTitle]: 'Pay frequency',
  [IntlKeys.websiteSinglecoinStakingFrequencyTime]: 'Weekly',
  [IntlKeys.websiteSinglecoinStakingCalculateRewards]: 'Calculate staking rewards',
  [IntlKeys.websiteSinglecoinStaking1Year]: '1 year',
  [IntlKeys.websiteSinglecoinStaking2Year]: '2 years',
  [IntlKeys.websiteSinglecoinStaking5Year]: '5 years',
  [IntlKeys.websiteSinglecoinStakingAsterisk]: '*Rewards are calculated weekly. Percentages are not guaranteed.',
  [IntlKeys.websiteSinglecoinStakingButton]: 'Start flexible staking now',
  [IntlKeys.websiteSinglecoinAboutTitle]: 'About {coin}',
  [IntlKeys.websiteSinglecoinAboutWhat]: 'What is {coin}?',
  [IntlKeys.websiteSinglecoinReadmore]: 'Read more',
  [IntlKeys.websiteSinglecoinAblutHow]: 'How does {coin} work?',
  [IntlKeys.websiteSinglecoinAblutBuy]: 'How to buy {coin}?',
  [IntlKeys.websiteSinglecoinPopularTitle]: 'Popular assets',
  [IntlKeys.websiteSinglecoinPopularDescription]: 'Find the most traded assets on Coinmerce below or view {link}.',
  [IntlKeys.websiteSinglecoinPopularDescriptionAllCrypto]: 'all cryptocurrencies',
  [IntlKeys.websiteSinglecoinPopularAsset]: 'Asset',
  [IntlKeys.websiteSinglecoinPopularBalance]: 'Balance',
  [IntlKeys.websiteSinglecoinPopularPrice]: 'Price',
  [IntlKeys.websiteSinglecoinPopularChange]: 'Change',
  [IntlKeys.websiteSinglecoinPopularType]: 'Type',
  [IntlKeys.websiteSinglecoinPopularActiveEarn]: 'Activate Earn',
  [IntlKeys.websiteSinglecoinPopularActiveStaking]: 'Activate Staking',
  [IntlKeys.websiteSinglecoinPopularBuy]: 'Buy',
  [IntlKeys.websiteSinglecoinPopularSell]: 'Sell',
  [IntlKeys.websiteSinglecoinPopularDeposit]: 'Deposit',
  [IntlKeys.websiteSinglecoinPopularWithdraw]: 'Withdraw',
  [IntlKeys.websiteSinglecoinPopularViewAllCrypto]: 'View all cryptocurrencies',
  [IntlKeys.websiteSinglecoinBuyButtonMobile]: 'View all cryptocurrencies',
  [IntlKeys.websiteSinglecoinBuyItem]: 'Buy',
  [IntlKeys.websiteSinglecoinSellItem]: 'Sell',
  [IntlKeys.websiteSinglecoinBuyButton]: 'Buy now',
  [IntlKeys.websiteSinglecoinSellButton]: 'Sell now',
  [IntlKeys.websiteSinglecoinBuyButtonCoin]: 'Buy {coin} now',
  [IntlKeys.websiteSinglecoinOrderLimit]: 'Order stop limit',
  [IntlKeys.websiteSinglecoinRepeatOrder]: 'Repeat order',
  [IntlKeys.websiteSinglecoinSwapItem]: 'Swap',
  [IntlKeys.websiteSinglecoinSwapButton]: 'Swap now',

  // single bundle view
  [IntlKeys.websiteBundleviewBreadcrumbBundles]: 'Bundles',
  [IntlKeys.websiteBundleviewDescription]: 'Buy the {bundle} bundle ({bundleShort}) with iDEAL, Bancontact, credit card or Coinmerce Coins (CM).',
  [IntlKeys.websiteBundleviewBuyItem]: 'Buy',
  [IntlKeys.websiteBundleviewSellItem]: 'Sell',
  [IntlKeys.websiteBundleviewBuyButton]: 'Buy now',
  [IntlKeys.websiteBundleviewSellButton]: 'Sell now',
  [IntlKeys.websiteBundleviewBuyButtonMobile]: 'Buy {bundle} now',
  [IntlKeys.websiteBundleviewMoreButtonMobile]: 'More',
  [IntlKeys.websiteBundleviewMax]: 'Max',
  [IntlKeys.websiteBundleviewUsp1]: 'Diversified investing',
  [IntlKeys.websiteBundleviewUsp2]: 'Automatically rebalanced',
  [IntlKeys.websiteBundleviewUsp3]: '4.2/5 based on {link}',
  [IntlKeys.websiteBundleviewUsp3Link]: '350 reviews',
  [IntlKeys.websiteBundleviewUsp4]: 'Trusted by 100,000+ users',
  [IntlKeys.websiteBundleviewOtherBundlesTitle]: 'Other bundles',
  [IntlKeys.websiteBundleviewOtherBundlesDescription]: 'A selection of available bundles at Coinmerce. Reduce risk through diversification. Easily invest in selection of a specific cryptocurrency category. ',
  [IntlKeys.websiteBundleviewOtherBundlesBundle]: 'Bundle',
  [IntlKeys.websiteBundleviewOtherBundlesChange]: 'Change',
  [IntlKeys.websiteBundleviewOtherBundlesCoins]: 'Coins included',
  [IntlKeys.websiteBundleviewOtherBundlesBuyButton]: 'Buy now',
  [IntlKeys.websiteBundleviewOtherBundlesOtherCoins]: '+{amount} others',
  [IntlKeys.websiteBundleviewH1]: '{bundle} bundle rate',
  [IntlKeys.websiteBundleviewH2]: 'Redistribute Bundles',

  // item details page
  [IntlKeys.itemDetailsTags]: 'Tags',
  [IntlKeys.itemDetailsDescription]: 'Description',
  [IntlKeys.itemDetailsLink]: 'Link',
  [IntlKeys.itemDetailsBuyNowPrice]: 'Buy Now Price',
  [IntlKeys.itemDetailsBuyNowPriceDescription]: '(when this amount is reached we will automatically notify the seller)',
  [IntlKeys.itemDetailsThisSaleEndsOn]: 'This sale ends on {value}',
  [IntlKeys.itemDetailsHighestBid]: 'Highest Bid',
  [IntlKeys.itemDetailsHighestBidHoorayMessage]: 'Hooray! Your bid is the highest bid!',
  [IntlKeys.itemDetailsThisItemIsNotListed]: 'This item is not listed',
  [IntlKeys.itemDetailsLastPriceSold]: 'Last Price Sold',
  [IntlKeys.itemDetailsEditSale]: 'Edit Sale',
  [IntlKeys.itemDetailsRemovePosition]: 'Remove Position',
  [IntlKeys.itemDetailsSell]: 'Sell',
  [IntlKeys.itemDetailsWithdraw]: 'Withdraw',
  [IntlKeys.itemDetailsAcceptHighestBid]: 'Accept Highest Bid',
  [IntlKeys.itemDetailsDeclineAllBids]: 'Decline All Bids',
  [IntlKeys.itemDetailsPlaceBid]: 'Place Bid',
  [IntlKeys.itemDetailsCancelBid]: 'Cancel Bid',
  [IntlKeys.itemDetailsBids]: 'Bids',
  [IntlKeys.itemDetailsYourBid]: 'Your Bid*',
  [IntlKeys.itemDetailsYourBidDescription]: '(this is the amount including fees that you will pay if your offer is accepted)',
  [IntlKeys.itemDetailsReviewOffer]: 'Review Offer',
  [IntlKeys.itemDetailsBidsTablePriceHeader]: 'Price',
  [IntlKeys.itemDetailsBidsTableDateHeader]: 'Date',
  [IntlKeys.itemDetailsBidsTableStatusHeader]: 'Status',
  [IntlKeys.itemDetailsRemovePositionModalTitle]: 'Remove Position',
  [IntlKeys.itemDetailsRemovePositionModalDescription]: 'This sale will be cancelled. The item will continue to be visible in your collection and you will be able to sell it again or withdraw it to an external wallet in the future.',
  [IntlKeys.itemDetailsRemovePositionModalButtonRemove]: 'Remove Position',
  [IntlKeys.itemDetailsRemovePositionModalButtonClose]: 'Close',
  [IntlKeys.itemDetailsReviewModalBuyNowPrice]: 'Buy Now Price',
  [IntlKeys.itemDetailsReviewModalYourBid]: 'Your Bid',
  [IntlKeys.itemDetailsReviewModalBalanceAfterBid]: 'Your Balance After the Bid',
  [IntlKeys.itemDetailsReviewModalBalanceAfterBidDescription]: '(this is an estimate of your balance in Coinmerce coins after the purchase)',
  [IntlKeys.itemDetailsReviewModalButtonSubmitOffer]: 'Submit Offer',
  [IntlKeys.itemDetailsReviewModalButtonCancel]: 'Cancel',
  [IntlKeys.itemDetailsReviewModalYouDontHaveFunds]: 'Your Euro balance is {balance}. You don’t have sufficient funds',
  [IntlKeys.itemDetailsPayNow]: 'Pay Now',
  [IntlKeys.itemDetailsPurchasePrice]: 'Purchase Price',
  [IntlKeys.itemDetailsYourBidAccepted]: 'Great news! Your bid was accepted.',
  [IntlKeys.itemDetailsPayModalSelectCollectionLabel]: 'Select Custom Collection*',
  [IntlKeys.itemDetailsYourBalanceAfterPurchase]: 'Your Balance After the Purchase',
  [IntlKeys.itemDetailsYourBalanceAfterPurchaseDescription]: '(this is an estimate of your balance in Coinmerce coins after the purchase)',

  // contdown
  [IntlKeys.countdownSeconds]: 'Seconds',
  [IntlKeys.countdownMinutes]: 'Minutes',
  [IntlKeys.countdownHours]: 'Hours',
  [IntlKeys.countdownDays]: 'Days',
  [IntlKeys.countdownThisSaleIsOver]: 'This Sale Is Over',
  [IntlKeys.countdownFiveMinutesLeft]: 'When bids are made in the last minute of the sale the timer automatically adds 5 minutes',

  // listing page
  [IntlKeys.listingTags]: 'Tags',
  [IntlKeys.listingDescription]: 'Description',
  [IntlKeys.listingLink]: 'Link',
  [IntlKeys.listingFormStartingPrice]: 'Start Price*',
  [IntlKeys.listingFormStartingPriceDescription]: '(this is the price that we will show when your item is listed on our marketplace)',
  [IntlKeys.listingFormBidIncrement]: 'Bid Increment*',
  [IntlKeys.listingFormBidIncrementDescription]: '(this is the is the minimum amount an auction bid must be raised)',
  [IntlKeys.listingFormBuyPrice]: 'Buy Price',
  [IntlKeys.listingFormBuyPriceDescription]: '(when a bid reaches this amount we will notify you via email)',
  [IntlKeys.listingFormDuration]: 'Duration',
  [IntlKeys.listingFormFees]: 'Fees',
  [IntlKeys.listingFormCoinmerceFee]: 'Coinmerce Fee',
  [IntlKeys.listingFormReviewAndSubmitButton]: 'Reviw and submit',
  [IntlKeys.listingDurationModalTitle]: 'Duration',
  [IntlKeys.listingDurationModalDateRange]: 'Date Range',
  [IntlKeys.listingDurationModalDateRangeThreeMonths]: '3 months',
  [IntlKeys.listingDurationModalDateRangeSixMonths]: '6 months',
  [IntlKeys.listingDurationModalDateRangeNineMonths]: '9 months',
  [IntlKeys.listingDurationModalDateRangeTwelveMonths]: '12 months',
  [IntlKeys.listingDurationModalDateRangeCustom]: 'Custom',
  [IntlKeys.listingDurationModalStartDate]: 'Start Date',
  [IntlKeys.listingDurationModalEndDate]: 'End Date',
  [IntlKeys.listingDurationModalStartTime]: 'Start Time',
  [IntlKeys.listingDurationModalEndTime]: 'End Time',
  [IntlKeys.listingDurationModalDoneButton]: 'Done',
  [IntlKeys.listingReviewModalDateRangeThreeMonths]: '3 months',
  [IntlKeys.listingReviewModalDateRangeSixMonths]: '6 months',
  [IntlKeys.listingReviewModalDateRangeNineMonths]: '9 months',
  [IntlKeys.listingReviewModalDateRangeTwelveMonths]: '12 months',
  [IntlKeys.listingReviewModalDateRangeCustomRange]: 'Custom range',
  [IntlKeys.listingReviewModalBuyNowPrice]: 'Buy Now Price',
  [IntlKeys.listingReviewModalStartingPrice]: 'Starting Price',
  [IntlKeys.listingReviewModalBidIncrement]: 'Bid Increment',
  [IntlKeys.listingReviewModalDuration]: 'Duration',
  [IntlKeys.listingReviewModalDateRangeInfo]: '{dateRange}, starts on {dateStart}, ends on {dateEnd}',
  [IntlKeys.listingReviewModalSubmitListingButton]: 'Submit listing',
  [IntlKeys.listingReviewModalCancelButton]: 'Cancel',
  [IntlKeys.listingAcceptBidModalSettlement]: 'Settlement',
  [IntlKeys.listingAcceptBidModalSettlementDescription]: '(this is the amount that we will transfer to your wallet in Coinmerce coins)',
  [IntlKeys.listingAcceptBidModalBidAmount]: 'Bid Amount',
  [IntlKeys.listingAcceptBidModalBidAmountDescription]: '(this is the amount offered by the buyer)',
  [IntlKeys.listingAcceptBidModalCoinmerceFee]: 'Coinmerce Fee',
  [IntlKeys.listingAcceptBidModalCoinmerceFeeDescription]: '(transaction fees are at {percentage}% of the sale value)',
  [IntlKeys.listingDeclineBidDescription]: 'This bid will be declined and we will notify the buyer. This action is irreversible, once a bid is declined you won’t be able to accept it anymore.',
  [IntlKeys.listingDeclineBidDeclineBidButton]: 'Decline Bid',
  [IntlKeys.listingDeclineAllBidsDescription]: 'All bids will be declined and we will notify the buyers. This action is irreversible, once a bid is declined you won’t be able to accept it anymore.',
  [IntlKeys.listingDeclineAllBidsDeclineAllButton]: 'Decline All Bids',

  // launchpad
  [IntlKeys.launchpadHeaderCoins]: 'Coins',
  [IntlKeys.launchpadHeaderProducts]: 'Products',
  [IntlKeys.launchpadHeaderAbout]: 'About',
  [IntlKeys.launchpadHeaderGetTheApp]: 'Get the app',
  [IntlKeys.launchpadNftLaunchpad]: 'NFT Launchpad',
  [IntlKeys.launchpadDescription]: "Welcome to the Coinmerce NFT Launchpad. The place where you can find and buy the newest NFT collections. Are you ready to invest in NFT's? Discover the newest NFT collections here!",
  [IntlKeys.launchpadDiscoverButton]: 'Discover',
  [IntlKeys.launchpadNavigateCategories]: 'Navigate through the categories',
  [IntlKeys.launchpadFaqTitle]: "Frequently Asked Questions - NFT's",
  [IntlKeys.launchpadItemsTitle]: "Featured NFT's",
  [IntlKeys.launchpadItemsTitleDescription]: "View the top collections of this week.",

  // categories
  [IntlKeys.categoryArt]: 'Art',
  [IntlKeys.categoryCollectibles]: 'Collectibles',
  [IntlKeys.categoryDomainNames]: 'Domain Names',
  [IntlKeys.categoryMusic]: 'Music',
  [IntlKeys.categoryPhotography]: 'Photography',
  [IntlKeys.categorySports]: 'Sports',
  [IntlKeys.categoryTradingCards]: 'Trading Cards',
  [IntlKeys.categoryUtility]: 'Utility',
  [IntlKeys.categoryVirtualWorlds]: 'Virtual Worlds',

  // types
  [IntlKeys.typesAudio]: 'Audio',
  [IntlKeys.typesDocument]: 'Documents',
  [IntlKeys.typesImage]: 'Image',
  [IntlKeys.typesModel]: 'Model',
  [IntlKeys.typesVideo]: 'Video',

  // filters
  [IntlKeys.filtersOnSaleOnly]: 'On Sale Only',
  [IntlKeys.filterSearchBoxPlaceholder]: 'Search item or collection name',
  [IntlKeys.filterStatusTitle]: 'Status',
  [IntlKeys.filterPriceTitle]: 'Price',
  [IntlKeys.filterCategoriesTitle]: 'Categories',
  [IntlKeys.filterTypesTitle]: 'Types',
  [IntlKeys.filterAllTitle]: 'All',
  [IntlKeys.filterPriceTo]: 'to',
  [IntlKeys.filterPriceFrom]: 'from',

  // marketplace collection page
  [IntlKeys.marketplaceCollectionNumberOfItems]: 'items',
  [IntlKeys.marketplaceCollectionOrderingNewest]: 'Newest',
  [IntlKeys.marketplaceCollectionOrderingOldest]: 'Oldest',
  [IntlKeys.marketplaceCollectionOrderingLowToHigh]: 'Price Low to High',
  [IntlKeys.marketplaceCollectionOrderingHighToLow]: 'Price High to Low',
  [IntlKeys.marketplaceCollectionFilterTitle]: 'Filter',
  [IntlKeys.marketplaceCollectionFilterClearAll]: 'Clear All',
  [IntlKeys.marketplaceCollectionOnSale]: 'On Sale',
  [IntlKeys.marketplaceCollectionOwn]: 'Own',
  [IntlKeys.marketplaceCollectionPrice]: 'Price',
  [IntlKeys.marketplaceCollectionView]: 'View',
  [IntlKeys.marketplaceCollectionNoItemsFound]: 'No items found!',

  // my-collections page
  [IntlKeys.myCollectionsTitle]: 'My Collections',
  [IntlKeys.myCollectionsNewCollection]: 'New Collection',
  [IntlKeys.myCollectionsNoCollectionsFound]: 'No collections found! Click "New Collection" button to create one',
  [IntlKeys.myCollectionsNameOfCollection]: 'Name of collection',
  [IntlKeys.myCollectionsBlockchain]: 'Blockchain',
  [IntlKeys.myCollectionsCustomCollectionsTitle]: 'My Custom Collections',
  [IntlKeys.myCollectionsNewCustomCollection]: 'My Custom Collections',
  [IntlKeys.myCollectionsGoToCustomCollection]: 'Go to Custom Collection',
  [IntlKeys.myCollectionsNoItemsYet]: 'There are no items yet!',
  [IntlKeys.myCollectionsNameOfCustomCollection]: 'Name of custom collection',

  // toasts
  [IntlKeys.toastBidSubmitted]: 'Bid is successfully submitted',
  [IntlKeys.toastSomethingWentWrong]: 'Something went wrong',
  [IntlKeys.toastBidCancelled]: 'Bid is successfully cancelled',
  [IntlKeys.toastListingCreated]: 'Listing is successfully created',
  [IntlKeys.toastListingUpdated]: 'Listing is successfully updated',
  [IntlKeys.toastBidApproved]: 'Bid is successfully approved',
  [IntlKeys.toastAllBidsDeclined]: 'All active bids are successfully declined',
  [IntlKeys.toastCollectionCreated]: 'Collection is successfully created',
  [IntlKeys.toastCollectionUpdated]: 'Collection is successfully updated',
  [IntlKeys.toastCollectionNameUpdated]: 'Collection name is successfuly updated',
  [IntlKeys.toastListingRemoved]: 'Listing is successfully removed',

  // validation
  [IntlKeys.validationRequired]: 'Required',
  [IntlKeys.validationBidShouldBeEqualOrBigger]: 'Bid should be equal or bigger than {value}',
  [IntlKeys.validationShouldBeBiggerThanStartingPrice]: 'Should be bigger than starting price',
};
