import React from 'react';
// libs
import { useIntl } from 'react-intl';
// material-ui
import { Stack } from '@mui/material';
// components
import PassiveTag from 'components/PassiveTag';
// hooks
import useItemTags from 'hooks/useItemTags';
import useFetchNftItemThumbnail from 'hooks/useFetchNftItemThumbnail';
// store
import { Item } from 'store/types';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import {
  ContentWrapper,
  DescriptionText,
  DescriptionTitle,
  ExternalLink,
  Image,
  ImagePlaceholder,
  LinkTitle,
  TagsTitle,
} from '../styled';

export interface LeftColumnProps {
  item: Item;
  isOwner: boolean;
  isMarketplaceItem: boolean | undefined;
}

const LeftColumn: React.FC<LeftColumnProps> = ({ item, isOwner, isMarketplaceItem }) => {
  const { formatMessage } = useIntl();
  const { externalLink, description, activeListing, id } = item;
  const { image, isLoading } = useFetchNftItemThumbnail({
    itemId: id,
    thumbnailType: 'show',
    isMarketplaceItem: !!isMarketplaceItem,
  });

  const tags = useItemTags({ isOwner, listingStatus: activeListing?.status });

  return (
    <ContentWrapper>
      {isLoading ? <ImagePlaceholder /> : <Image src={image} />}

      <TagsTitle>{formatMessage({ id: IntlKeys.itemDetailsTags })}</TagsTitle>
      <Stack direction="row" spacing={1}>
        {tags.map(({ label, variant }, index) => (
          <PassiveTag key={`${label}-${index}`} label={label} variant={variant} />
        ))}
      </Stack>

      <DescriptionTitle>{formatMessage({ id: IntlKeys.itemDetailsDescription })}</DescriptionTitle>
      <DescriptionText>{description}</DescriptionText>

      <LinkTitle>{formatMessage({ id: IntlKeys.itemDetailsLink })}</LinkTitle>
      <ExternalLink href={externalLink}>{externalLink}</ExternalLink>
    </ContentWrapper>
  );
};

export default LeftColumn;
