import styled from '@emotion/styled/macro';
import Img from 'components/Img';

export const Wrapper = styled.div<{ isSmall: boolean }>`
  width: calc(25% - 30px);
  min-height: 320px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 8px 20px 0 rgba(0, 35, 68, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:hover {
    box-shadow: 0 8px 40px 0 rgba(0, 35, 68, 0.25);
  }

  ${({ isSmall }) =>
    isSmall
      ? `
          width: calc(16.66% - 20px);
          min-height: 232px;
          
          ${Column}:last-of-type {
            justify-content: flex-end;
          }

          ${StyledImage} {
            height: calc(100% - 78px);
          }
          
          ${ImagePlaceholder} {
            height: calc(100% - 78px);
          }

          ${PriceTitle} {
            display: none;
          }

          ${PriceValue} {
            margin-top: 4px;
            font-size: 14px;
            line-height: 20px;
          }

          ${CardInfo} {
            height: 72px;
          }
          
          ${ItemName} {
            font-size: 12px;
          }
        `
      : `
          &:hover {
            ${StyledImage} {
              height: calc(100% - 118px);
            }

            ${BuyButton} {
              opacity: 1;
            }
          }
        `}

  ${({ isSmall, theme }) => theme.media.lg`
    ${
      isSmall
        ? `
        width: calc(20.09% - 20px);
        height: 232px;
      `
        : `
        width: calc(33.73% - 30px);
      `
    }
  `}

  ${({ isSmall, theme }) => theme.media.md`
    ${
      isSmall
        ? `
        width: calc(20.08% - 20px);
        height: 232px;
      `
        : `
        width: calc(33.68% - 30px);
      `
    }
  `}

  ${({ isSmall, theme }) => theme.media.sm`
    ${
      isSmall
        ? `
        width: calc(25% - 18px);
      `
        : `
        min-height: 258px;
      `
    }

    ${StyledImage} {
      height: calc(100% - 80px);
    }
    
    ${ImagePlaceholder} {
      height: calc(100% - 80px);
    }

    ${PriceTitle} {
      display: none;
    }

    ${PriceValue} {
      margin-top: 4px;
    }

    &:hover {
      ${StyledImage} {
        height: calc(100% - 80px);
      }

      ${BuyButton} {
        opacity: 0;
      }
    }
  `}

  ${({ isSmall, theme }) => theme.media.xs`
    ${
      isSmall
        ? `
        width: calc(50% - 12px);
      `
        : `
        width: calc(50% - 20px);
      `
    }

    height: 238px;
  `}
`;

export const StyledImage = styled(Img)`
  width: 100%;
  height: calc(100% - 56px);
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: all 0.3s ease-out;
`;

export const ImagePlaceholder = styled.div`
  width: 100%;
  height: calc(100% - 56px);
`;

export const BuyButton = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 19.2px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.common.brescianBlue};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  opacity: 0;
  transition: opacity 0.3s ease-out;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 12px;
`;

export const CreatorName = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const ItemName = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: 'VisbyRoundCF Medium', sans-serif;
  white-space: nowrap;
`;

export const PriceTitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  align-self: flex-end;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: 'VisbyRoundCF Medium', sans-serif;
`;

export const PriceValue = styled.span`
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: 'VisbyRoundCF Medium', sans-serif;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: flex-start;
  gap: 12px;
`;

export const CardInfo = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 6px 8px 12px;
  transition: all 0.3s ease-out;
`;

export const OnSaleTag = styled.div`
  width: fit-content;
  padding: 4px 8px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.common.waystoneGreen10};
  color: ${({ theme }) => theme.palette.common.waystoneGreen};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  font-size: 10px;
  line-height: 12px;
`;
