import React from 'react';
// libs
import { Outlet } from 'react-router-dom';
// components
import UnauthHeader from '../headers/UnauthHeader';
import Footer from '../MainLayout/Footer';
import useIsAuth from 'hooks/useIsAuth';
// styled
import { Wrapper } from './styled';
import AuthHeader from '../headers/AuthHeader';

const Layout = () => {
  const isAuth = useIsAuth();

  return (
    <>
      {isAuth ? <AuthHeader /> : <UnauthHeader />}

      <Wrapper>
        <Outlet />
      </Wrapper>

      <Footer />
    </>
  );
};

export default Layout;
