import React from 'react';
// libs
import { useIntl } from 'react-intl';
// material-ui
import { InputAdornment } from '@mui/material';
// components
import Accordion from './Accordion';
import CheckboxesGroup from './CheckboxesGroup';
// hooks
import useFilters from './hooks/useFilters';
// constants
import { CATEGORIES_FILTER_VALUES, FILTERS, ITEM_TYPE_FILTER_VALUES, STATUS_FILTER_VALUES } from 'constants/filters';
import { EURO_SIGN } from 'constants/general';
import { IntlKeys } from 'localization/keys';
// icons
import { Search, FilterAlt } from '@mui/icons-material';
// styled
import {
  SearchInput,
  Wrapper,
  PriceWrapper,
  PriceText,
  PriceTextField,
  FilterTitle,
  FilterTitleWrapper,
} from './styled';

type FilterProps = {
  isMyCollection?: boolean;
};

const SEARCH_INPUT_PROPS = {
  startAdornment: (
    <InputAdornment position="start">
      <Search />
    </InputAdornment>
  ),
};

const PRICE_INPUT_PROPS = {
  startAdornment: <InputAdornment position="start">{EURO_SIGN}</InputAdornment>,
};

const Filter: React.FC<FilterProps> = ({ isMyCollection = false }) => {
  const { formatMessage } = useIntl();
  const { values, handlers } = useFilters();

  const { searchParams, searchName, priceMin, priceMax } = values;
  const { handleFilterCheckboxChange, handleFilterNameChange, handleFilterPriceMinChange, handleFilterPriceMaxChange } =
    handlers;

  return (
    <Wrapper>
      <SearchInput
        name={FILTERS.name}
        size="medium"
        placeholder={formatMessage({ id: IntlKeys.filterSearchBoxPlaceholder })}
        InputProps={SEARCH_INPUT_PROPS}
        value={searchName}
        onChange={handleFilterNameChange}
      />

      <FilterTitleWrapper>
        <FilterAlt />
        <FilterTitle>{formatMessage({ id: IntlKeys.marketplaceCollectionFilterTitle })}</FilterTitle>
      </FilterTitleWrapper>

      {isMyCollection && (
        <Accordion
          summaryTitle={formatMessage({ id: IntlKeys.marketplaceCollectionFilterTitle })}
          defaultExpanded
          detailsContent={
            <CheckboxesGroup
              label={IntlKeys.filterStatusTitle}
              tagStyleCheckbox
              showAllCheckbox={false}
              name={FILTERS.statuses}
              onChange={handleFilterCheckboxChange}
              checked={searchParams.getAll(FILTERS.statuses)}
              checkboxes={STATUS_FILTER_VALUES}
            />
          }
        />
      )}

      <Accordion
        summaryTitle={formatMessage({ id: IntlKeys.filterPriceTitle })}
        defaultExpanded
        detailsContent={
          <PriceWrapper>
            <PriceTextField
              size="small"
              name={FILTERS.priceMin}
              InputProps={PRICE_INPUT_PROPS}
              value={priceMin}
              onChange={handleFilterPriceMinChange}
              type="number"
            />
            <PriceText>{formatMessage({ id: IntlKeys.filterPriceTo })}</PriceText>
            <PriceTextField
              size="small"
              name={FILTERS.priceMax}
              InputProps={PRICE_INPUT_PROPS}
              onChange={handleFilterPriceMaxChange}
              value={priceMax}
              type="number"
            />
          </PriceWrapper>
        }
      />

      <Accordion
        summaryTitle={formatMessage({ id: IntlKeys.filterCategoriesTitle })}
        defaultExpanded
        detailsContent={
          <CheckboxesGroup
            label={IntlKeys.filterAllTitle}
            name={FILTERS.categories}
            onChange={handleFilterCheckboxChange}
            checked={searchParams.getAll(FILTERS.categories)}
            checkboxes={CATEGORIES_FILTER_VALUES}
          />
        }
      />

      <Accordion
        summaryTitle={formatMessage({ id: IntlKeys.filterTypesTitle })}
        defaultExpanded
        detailsContent={
          <CheckboxesGroup
            label={IntlKeys.filterAllTitle}
            name={FILTERS.itemTypes}
            onChange={handleFilterCheckboxChange}
            checked={searchParams.getAll(FILTERS.itemTypes)}
            checkboxes={ITEM_TYPE_FILTER_VALUES}
          />
        }
      />
    </Wrapper>
  );
};

export default Filter;
