import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Button, Theme } from '@mui/material';

export const AssetCellContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AssetNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const AssetName = styled.div`
  position: relative;
  top: -4px;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};

  @media (max-width: 800px) {
    display: none;
  }
`;

export const AssetSubName = styled.div`
  position: relative;
  top: 2px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.ultimateGrey};

  ${({ theme }) => theme.media.sm`
    top: -2px;
  `}
`;

export const AssetIconWrapper = styled.div`
  margin-right: 8px;
`;

export const PositivePercentage = styled.div`
  margin-top: 4px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.pedestrianGreen};

  ${({ theme }) => theme.media.xs`
    position: relative;
    top: 3px;
  `}
`;

export const NegativePercentage = styled.div`
  margin-top: 4px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.grenadinePink};

  ${({ theme }) => theme.media.xs`
    position: relative;
    top: 3px;
  `}
`;

export const BuyButton = styled(Button)`
  width: fit-content;
  min-width: 72px;
  text-align: right;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  background-color: ${({ theme }) => theme.palette.common.blackSpace};
  padding: 4px 14px;
  white-space: nowrap;
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.blackSpace};
  }
`;

export const DepositButton = styled(Button)`
  width: fit-content;
  min-width: 80px;
  text-align: right;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  background-color: ${({ theme }) => theme.palette.common.bleachedSilk};
  padding: 4px 14px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.bleachedSilk};
  }
`;

export const MoreIconWrapper = styled.div`
  cursor: pointer;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  gap: 16px;
`;

export const Course = styled.div`
  margin-top: 6px;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;

export const TypeContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const EarnButton = styled(Button)`
  width: fit-content;
  min-width: 72px;
  //text-align: right;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  padding: 4px 14px;
  white-space: nowrap;
`;

export const Type = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  border-radius: 6px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  background-color: ${({ theme }) => theme.palette.common.kodamaWhite};
  font-size: 13.33px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
`;

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Amount = styled.div<{ isBig?: boolean }>`
  position: relative;
  top: -2px;
  font-size: 12px;
  line-height: 14.4px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;

  ${({ isBig }) =>
    isBig &&
    css`
      font-size: 16px;
      line-height: 19px;
      top: 8px;
    `};
`;

export const Rate = styled.div`
  position: relative;
  top: 2px;
  font-size: 12px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};

  ${({ theme }) => theme.media.sm`
    top: -2px;
  `}
`;

export const ShowMore = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  text-align: center;
  margin: 6px 0 20px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;

// table

export const THead = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  text-align: left;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  padding-bottom: 8px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  & > div {
    display: inline-block;
    position: relative;
    top: 2px;
    margin-left: 8px;
  }
`;

const sortingIconWrapperStyled = (isSortedDesc: boolean | undefined, theme: Theme) => {
  if (isSortedDesc === undefined) return '';
  if (isSortedDesc) {
    return css`
      & > svg {
        path:nth-of-type(1) {
          fill: ${theme.palette.common.ultimateGrey};
        }
      }
    `;
  }
  if (!isSortedDesc) {
    return css`
      & > svg {
        path:nth-of-type(2) {
          fill: ${theme.palette.common.ultimateGrey};
        }
      }
    `;
  }
};

export const SortingIconWrapper = styled.div<{ isSortedDesc?: boolean }>`
  ${({ isSortedDesc, theme }) => sortingIconWrapperStyled(isSortedDesc, theme)}
`;

export const THeaderContainer = styled.div``;

export const TCell = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  margin: 0;
  padding: 16px 0;

  :last-child {
    border-right: 0;
  }
`;

export const TRow = styled.div`
  :last-child {
    ${TCell} {
      border-bottom: 0;
    }
  }
`;

export const THeadRow = styled(TRow)``;

export const TBody = styled.div``;

export const FlexibleTable = styled.div`
  border-spacing: 0;
  max-width: 100%;
`;
