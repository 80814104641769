import { useEffect, useCallback, useState, useMemo } from 'react';
import { http } from 'utils/http';

export interface Params {
  itemId: string | number | undefined;
  thumbnailType: 'list' | 'show';
  isMarketplaceItem: boolean;
}

const useFetchMarketplaceThumbnail = ({ itemId, thumbnailType, isMarketplaceItem }: Params) => {
  const [image, setImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const url = useMemo(
    () => `${isMarketplaceItem ? 'marketplace/' : ''}items/${itemId}/file/${thumbnailType}`,
    [itemId, isMarketplaceItem, thumbnailType],
  );

  const fetchNftItemThumbnail = useCallback(async () => {
    const response = await http.get(url, {
      headers: { 'Content-Type': 'image' },
      responseType: 'blob',
    });
    const { data } = await response;
    return URL.createObjectURL(data);
  }, [url]);

  const getListingItemImage = useCallback(async () => await fetchNftItemThumbnail(), [fetchNftItemThumbnail]);

  useEffect(() => {
    if (itemId) {
      setIsLoading(true);
      getListingItemImage()
        .then((image) => {
          setImage(image);
        })
        .catch(() => {
          setImage('');
        })
        .finally(() => setIsLoading(false));
    }
  }, [getListingItemImage, itemId]);

  return { image, isLoading };
};

export default useFetchMarketplaceThumbnail;
