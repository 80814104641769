import styled from '@emotion/styled/macro';
import { Button as MaterialButton } from '@mui/material';

export const Wrapper = styled.div`
  padding: 40px;
  width: 880px;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  gap: 60px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 40px 24px;
  }
`;

export const Title = styled.div`
  font-size: 36px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-family: 'VisbyRoundCF Regular', sans-serif;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 18px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const CloseButton = styled(MaterialButton)`
  width: 180px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.snowBank};
  }
`;
