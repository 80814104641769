import { Status } from '../store/types';
import { useMemo } from 'react';
import { TagVariants } from '../components/PassiveTag';
import { useIntl } from 'react-intl';
import { IntlKeys } from '../localization/keys';

export interface UseItemTagsParams {
  isOwner: boolean;
  listingStatus: Status | undefined;
}

const useItemTags = ({ isOwner, listingStatus }: UseItemTagsParams) => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    const ownStatuses = [Status.PUBLISHED, Status.ACTIVE, Status.SOLD];
    const saleStatuses = [Status.PUBLISHED, Status.ACTIVE];
    const tagsArray = [];
    if (isOwner && listingStatus && !ownStatuses.includes(listingStatus)) {
      tagsArray.push({
        label: formatMessage({ id: IntlKeys.marketplaceCollectionOwn }),
        variant: 'primary',
      });
    }
    if (listingStatus && saleStatuses.includes(listingStatus)) {
      tagsArray.push({
        label: formatMessage({ id: IntlKeys.marketplaceCollectionOnSale }),
        variant: 'success',
      });
    }
    return tagsArray;
  }, [formatMessage, isOwner, listingStatus]) as { label: string; variant: TagVariants }[];
};

export default useItemTags;
