import { NotificationType } from 'components/Notification';

// auth
export type LoginPayload = { email: string; password: string };

// auth response
export type SignUpResponse = {
  idToken: string;
  refreshToken: string;
  expiresIn: string;
};

// user
export type User = {
  id: string | number;
  firstName: string;
  lastName: string;
  isActive: boolean;
  email: string;
  collections: Collection[];
  reservedListings: Listing[];
  createdAt: Date;
  updatedAt: Date;
};

// profile
export type Profile = {
  firstName: string | null;
  lastName: string | null;
  id: number;
};

// listing
export enum Status {
  UNPUBLISHED = 'unpublished',
  PUBLISHED = 'published',
  ACTIVE = 'active',
  PURCHASE_PENDING = 'purchasePending',
  AUCTION_EXPIRED = 'auctionExpired',
  CLOSED = 'closed',
  SOLD = 'sold',
}

export type Listing = {
  id: string;
  priceType: string;
  price: number;
  buyoutPrice: number;
  bidStepSize: number;
  dateTimeStart: string;
  dateTimeEnd: string;
  isReserved: boolean;
  tags: string;
  itemId: number | undefined;
  reservedForId: number | undefined;
  status: Status;
  item: Item;
  topBid: Bid | null;
  owner: Profile;
};

export type CreateListingPayload = {
  priceType: string;
  price: number;
  buyoutPrice: number;
  bidStepSize: number;
  dateTimeStart: string;
  dateTimeEnd: string;
  isReserved: boolean;
  tags: string;
  itemId: number | undefined;
  reservedForId: number | undefined;
  status: Status;
  dateRange: string;
};

// item
enum ItemType {
  AUDIO = 'audio',
  VIDEO = 'video',
  IMAGE = 'image',
  DOCUMENT = 'document',
}

export type Item = {
  id: string | number;
  name: string;
  description: string;
  externalLink: string;
  isNsfw: boolean;
  saveOriginalMetadata: boolean;
  properties: { propertyName: string; propertyValue: string }[];
  collection: Collection;
  collectionId: string;
  fileUrl: string;
  itemType: ItemType;
  ownerId: number;
  price: number | null;
  activeListing: Listing | null;
};

export type CreateNFTItemPayload = {
  name: string;
  description: string;
  externalLink: string;
  isNsfw: boolean;
  saveOriginalMetadata: boolean;
  collectionId: number | undefined;
  properties: { propertyName: string; propertyValue: string }[];
  file: File | null;
};

// collection
enum BlockchainType {
  POLYGON = 'polygon',
}

export type Collection = {
  id: string | number;
  blockChainType: BlockchainType;
  name: string;
};

export type CreateCollectionPayload = {
  name: string;
  blockchainType: BlockchainType;
};

// notification
export type NotificationPayload = {
  message: string;
  type: NotificationType;
};

// bid
export enum BidStatus {
  ACTIVE = 'active',
  APPROVED = 'approved',
  CANCELED = 'canceled',
  REJECTED = 'rejected',
}

export type Bid = {
  id: number;
  listingId: number;
  owner: Profile;
  ownerId: number;
  price: number;
  status: BidStatus;
  createdAt: string;
  canceledAt: string | null;
  rejectedAt: string | null;
  updatedAt: string | null;
};

export type MakeBidPayload = {
  listingId: number;
  price: number;
};
