import React from 'react';
import { StyledLoader, Wrapper } from './styled';

function LoadingSpinner() {
  return (
    <Wrapper>
      <StyledLoader />
    </Wrapper>
  );
}

export default LoadingSpinner;
