import React, { useEffect, useMemo } from 'react';
// libs
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components
import Content from '../Content';
// hooks
import useItemTags from 'hooks/useItemTags';
// store
import { useAppDispatch } from 'store';
import { fetchNFTItem, itemMetaSelector, itemSelector } from 'store/nftItem';
import { uiLocaleSelector } from 'store/ui';
import { fetchProfile, profileUserIdSelector } from 'store/profile';

const Create: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as { itemId: string | undefined };

  const item = useSelector(itemSelector);
  const itemMeta = useSelector(itemMetaSelector);
  const locale = useSelector(uiLocaleSelector);
  const userId = useSelector(profileUserIdSelector);

  const isOwner = useMemo(() => userId === item?.ownerId, [item?.ownerId, userId]);

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  useEffect(() => {
    if (!state?.itemId) {
      navigate(`/${locale}/marketplace`, { replace: true });
    }
  }, [locale, navigate, state?.itemId]);

  useEffect(() => {
    if (state?.itemId) {
      dispatch(fetchNFTItem(state?.itemId));
    }
  }, [dispatch, state?.itemId]);

  const tags = useItemTags({ isOwner, listingStatus: item?.activeListing?.status });

  return <Content isLoading={itemMeta.isLoading} item={item} tags={tags} itemId={Number(state?.itemId)} />;
};

export default Create;
