import React from 'react';
// styled
import { Input, Label, Wrapper } from '../styled';

export interface TagProps {
  label: string;
  name?: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function Tag({ label, checked, name, onChange }: TagProps) {
  return (
    <Wrapper isPassive={false}>
      <Label control={<Input name={name ? name : ''} checked={checked} onChange={onChange} />} label={label} />
    </Wrapper>
  );
}

export default Tag;
