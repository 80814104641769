import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  border-radius: 2px;
`;

export const Title = styled.div`
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: 'VisbyRoundCF SemiBold';
  white-space: nowrap;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  font-family: 'VisbyRoundCF SemiBold';
  white-space: nowrap;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @media (min-width: 991px) and (max-width: 1422px) {
    flex-direction: column;
    gap: 0;
    row-gap: 24px;
  }
  @media (max-width: 856px) {
    flex-direction: column;
    gap: 0;
    row-gap: 24px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
