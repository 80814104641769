import React, { useCallback } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// components
import Modal from 'components/Modal';
// hooks
import useFetchNftItemThumbnail from 'hooks/useFetchNftItemThumbnail';
// store
import { Bid, Item } from 'store/types';
import { uiLocaleSelector } from 'store/ui';
import { declineBid, listingBidsSelector } from 'store/bid';
import { setNotification } from 'store/notification';
import { useAppDispatch } from 'store';
import { fetchNFTItem } from 'store/nftItem';
// helpers
import { roundTo2Digits } from 'helpers/renderHelpers';
// constants
import { IntlKeys } from 'localization/keys';
import { EURO_SIGN } from 'constants/general';
// styled
import {
  BigTitle,
  BigValue,
  ButtonsContainer,
  CommonContainer,
  Container,
  Description,
  ErrorButton,
  Image,
  ItemContainer,
  ItemInfoContainer,
  ItemName,
  SecondaryButton,
  SmallDescription,
  Wrapper,
} from '../shared/styles/modals';
import { NavLink } from '../styled';

export interface DeclineBidModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedBid: Bid | null;
  item: Item;
}

export const DeclineBidModal: React.FC<DeclineBidModalProps> = ({ isOpen, onClose, selectedBid, item }) => {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const locale = useSelector(uiLocaleSelector);

  const { image } = useFetchNftItemThumbnail({ itemId: item?.id, thumbnailType: 'show', isMarketplaceItem: false });

  const bids = useSelector(listingBidsSelector);

  const { activeListing, id: itemId } = item;
  const { id: listingId } = activeListing ?? {};

  const handleDeclineBidClick = useCallback(() => {
    if (bids.length && selectedBid?.id && listingId) {
      dispatch(declineBid(selectedBid.id))
        .unwrap()
        .then(() => {
          if (itemId) {
            dispatch(
              setNotification({ message: formatMessage({ id: IntlKeys.toastAllBidsDeclined }), type: 'success' }),
            );
            dispatch(fetchNFTItem(itemId));
          }
        })
        .then(() => {
          if (itemId) {
            navigate(`/${locale}/item-details/${itemId}`);
          }
        })
        .catch(() =>
          dispatch(
            setNotification({ message: formatMessage({ id: IntlKeys.toastSomethingWentWrong }), type: 'error' }),
          ),
        );
    }
  }, [bids, dispatch, formatMessage, itemId, listingId, locale, navigate, selectedBid]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Wrapper>
        <Container>
          <ItemContainer>
            <Image src={image} />
            <ItemInfoContainer>
              <ItemName>{item?.name}</ItemName>
              <NavLink to={`/${locale}/my-collections/${item?.collection?.id}`}>{item?.collection?.name}</NavLink>
            </ItemInfoContainer>
          </ItemContainer>
          <Description>{formatMessage({ id: IntlKeys.listingDeclineBidDescription })}</Description>
          <CommonContainer>
            <BigTitle>
              {formatMessage({ id: IntlKeys.listingAcceptBidModalBidAmount })}
              <SmallDescription>
                {formatMessage({ id: IntlKeys.listingAcceptBidModalBidAmountDescription })}
              </SmallDescription>
            </BigTitle>
            <BigValue>
              {EURO_SIGN}
              {roundTo2Digits(Number(selectedBid?.price))}
            </BigValue>
          </CommonContainer>
        </Container>
        <ButtonsContainer>
          <ErrorButton size="large" onClick={handleDeclineBidClick}>
            {formatMessage({ id: IntlKeys.listingDeclineBidDeclineBidButton })}
          </ErrorButton>
          <SecondaryButton size="large" onClick={onClose}>
            {formatMessage({ id: IntlKeys.commonCancel })}
          </SecondaryButton>
        </ButtonsContainer>
      </Wrapper>
    </Modal>
  );
};

export default DeclineBidModal;
