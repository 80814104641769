import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

export const PERSIST_CONFIG_KEY_NAME = 'root';

export const IGNORED_ACTIONS = [
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  'marketplace/fetchMarketplaceItems/fulfilled',
  'marketplace/fetchMarketplaceItemById/fulfilled',
  'auth/login/fulfilled',
  'collection/createCollection/fulfilled',
  'collection/fetchCollection/fulfilled',
  'collection/fetchCollections/fulfilled',
  'item/fetch/fulfilled',
  'listing/create/fulfilled',
  'listing/fetch/fulfilled',
  'listing/update/fulfilled',
  'itemByCollectionId/fetch/fulfilled',
  'auth/refreshToken/fulfilled',
  'profile/fetch/fulfilled',
  'bid/post/fulfilled',
  'bid/getListingBids/fulfilled',
  'collection/fetchCollectionIntoCollections/fulfilled',
  'bid/cancel/fulfilled',
  'bid/approve/fulfilled',
];

export const SLICE_NAMES = {
  AUTH: 'auth',
  BID: 'bid',
  COLLECTION: 'collection',
  NFT_ITEM: 'nftItem',
  LISTING: 'listing',
  MARKETPLACE: 'marketplace',
  NOTIFICATION: 'notification',
  PROFILE: 'profile',
  UI: 'ui',
} as const;
