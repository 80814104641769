import styled from '@emotion/styled/macro';
import { CircularProgress } from '@mui/material';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background-color: ${({ theme }) => theme.palette.common.white30};
`;

export const StyledLoader = styled(CircularProgress)`
  & .MuiCircularProgress-svg .MuiCircularProgress-circle {
    color: ${({ theme }) => theme.palette.primary.main} !important;
  }
`;
