import React, { useCallback, useMemo } from 'react';
// libs
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
// components
import Carousel from 'components/Carousel';
// hooks
import useFetchCarouselImages from '../hooks/useFetchCarouselImages';
// store
import { uiLocaleSelector } from 'store/ui';
import { dashboardItemsSelector } from 'store/nftItem';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import { Container, LeftContainer, RightContainer, Text, Title, Wrapper, StyledButton } from './styled';

const CarouselSection: React.FC = () => {
  const locale = useSelector(uiLocaleSelector);
  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  const { randomItems } = useSelector(dashboardItemsSelector);

  const { images } = useFetchCarouselImages(randomItems);

  const handleButtonClick = useCallback(() => navigate(`/${locale}/marketplace`), [locale, navigate]);

  const carouselImages = useMemo(
    () =>
      images.map(({ image, id, name }) => {
        return (
          <div key={id}>
            <img src={image} alt={name} />
          </div>
        );
      }),
    [images],
  );

  return (
    <Wrapper>
      <Container>
        <LeftContainer>
          <Title>{formatMessage({ id: IntlKeys.launchpadNftLaunchpad })}</Title>
          <Text>{formatMessage({ id: IntlKeys.launchpadDescription })}</Text>
          <StyledButton disableRipple onClick={handleButtonClick}>
            {formatMessage({ id: IntlKeys.launchpadDiscoverButton })}
          </StyledButton>
        </LeftContainer>
        <RightContainer>
          <Carousel autoRotate>{carouselImages}</Carousel>
        </RightContainer>
      </Container>
    </Wrapper>
  );
};

export default CarouselSection;
