import React from 'react';
// libs
import { Routes as ReactRouterDomRoutes, Route, Navigate } from 'react-router-dom';
// hooks
import useScrollToTop from 'hooks/useScrollToTop';
// components
import MainLayout from 'components/layouts/MainLayout';
import LandingLayout from 'components/layouts/LandingLayout';
import AuthRoute from '../AuthRoute';
import UnauthorizedRoute from '../UnauthorizedRoute';
import LangRoute from '../LangRoute';
// pages
import LoginPage from 'pages/LoginPage';
import MarketplaceCollectionPage from 'pages/MarketplaceCollectionPage';
import ItemDetailsPage from 'pages/ItemDetailsPage';
import MyCollectionsPage from 'pages/MyCollectionsPage';
import ListingCreatePage from 'pages/ListingPage/Create';
import ListingUpdatePage from 'pages/ListingPage/Update';
import LandingPage from 'pages/LandingPage';
// import CreateNFTPage from 'pages/CreateNFTPage';

// cm redesign pages
import BundlesPage from 'pages/BundlesPage';
import SingleCoinViewPage from 'pages/SingleCoinViewPage';
import DashboardPage from 'pages/DashboardPage';

const Routes = () => {
  useScrollToTop();

  return (
    <ReactRouterDomRoutes>
      <Route path="/">
        <Route index element={<Navigate to={'en'} replace />} />
        <Route path=":locale" element={<LangRoute />}>
          <Route element={<LandingLayout />}>
            <Route index element={<LandingPage />} />

            {/*<Route path="marketplace">*/}
            {/*  <Route index element={<MarketplaceCollectionPage />} />*/}
            {/*  <Route path=":id" element={<ItemDetailsPage isMarketplaceItem />} />*/}
            {/*</Route>*/}
          </Route>

          <Route element={<UnauthorizedRoute />}>
            <Route path="login" element={<LoginPage />} />
          </Route>

          <Route element={<AuthRoute />}>
            <Route element={<MainLayout />}>
              <Route path="bundles" element={<BundlesPage />} />
              <Route path="coins" element={<SingleCoinViewPage />} />
              <Route path="dashboard" element={<DashboardPage />} />

              <Route path="marketplace">
                <Route index element={<MarketplaceCollectionPage />} />
                <Route path=":id" element={<ItemDetailsPage isMarketplaceItem />} />
              </Route>

              <Route path="item-details">
                <Route path=":id" element={<ItemDetailsPage />} />
              </Route>

              <Route path="my-collections">
                <Route index element={<MyCollectionsPage />} />
                <Route path=":collectionId" element={<MarketplaceCollectionPage isMyCollection />} />
              </Route>

              <Route path="listing">
                <Route path="create" element={<ListingCreatePage />} />
                <Route path="edit/:id" element={<ListingUpdatePage />} />
              </Route>

              {/*<Route path="create-nft" element={<CreateNFTPage />} />*/}
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Navigate to={'marketplace'} replace />} />
    </ReactRouterDomRoutes>
  );
};

export default Routes;
