import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { http } from 'utils/http';
import { RootState } from '../index';
import { Bid, MakeBidPayload } from '../types';
import { SLICE_NAMES } from '../constants';
import { FILTERS } from '../../constants/filters';

type BidState = {
  meta: {
    isLoading: boolean;
    isLoaded: boolean;
  };
  listingBids: Bid[];
};

const initialState: BidState = { meta: { isLoading: false, isLoaded: false }, listingBids: [] };

export const postBid = createAsyncThunk('bid/post', async (payload: MakeBidPayload) => {
  return http
    .post<MakeBidPayload>('marketplace/bids', payload)
    .catch((error) => error)
    .then(({ data }) => {
      return data as Bid;
    })
    .catch((error) => {
      return error;
    });
});

export const getListingBids = createAsyncThunk(
  'bid/getListingBids',
  async ({ listingId }: { listingId: string | number }) => {
    return http.get<Bid[]>(`marketplace/bids/?${FILTERS.listingId}=${listingId}`).catch((error) => error);
  },
);

export const approveBid = createAsyncThunk('bid/approve', async (id: string | number) => {
  return http.put(`bids/${id}/approve`).catch((error) => {
    return error;
  });
});

export const cancelBid = createAsyncThunk('bid/cancel', async (id: string | number) => {
  return http.put(`marketplace/bids/${id}/cancel`).catch((error) => {
    return error;
  });
});

export const declineBid = createAsyncThunk('bid/reject', async (id: string | number) => {
  return http.put(`bids/${id}/reject`).catch((error) => {
    return error;
  });
});

export const declineAllBids = createAsyncThunk('bid/declineAllBids', async (listingId: string | number) => {
  return http
    .put(`listings/${listingId}/reject_all_bids`)
    .then(({ data }) => {
      return data as { itemId: number };
    })
    .catch((error) => {
      return error;
    });
});

const bidSlice = createSlice({
  name: SLICE_NAMES.BID,
  initialState,
  reducers: {},
  extraReducers: {
    [postBid.pending.type]: () => {},
    [postBid.fulfilled.type]: () => {},
    [postBid.rejected.type]: () => {},
    // bid create
    [getListingBids.pending.type]: (state) => {
      // state.listingBids = initialState.listingBids;
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [getListingBids.fulfilled.type]: (state, { payload }) => {
      const { data }: { data: Bid[] } = payload;
      state.listingBids = data;
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [getListingBids.rejected.type]: (state) => {
      // state.listingBids = initialState.listingBids;
      state.meta = initialState.meta;
    },
    // bid approve
    [approveBid.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [approveBid.fulfilled.type]: (state) => {
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [approveBid.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },
    // decline highest bid
    [declineAllBids.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [declineAllBids.fulfilled.type]: (state) => {
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [declineAllBids.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },
    // bid cancel
    [cancelBid.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [cancelBid.fulfilled.type]: (state) => {
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [cancelBid.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },
    // reject bid
    [declineAllBids.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [declineAllBids.fulfilled.type]: (state) => {
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [declineAllBids.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },
  },
});

const bidSelector = (state: RootState): BidState => state[SLICE_NAMES.BID];

export const listingBidsSelector = createSelector(bidSelector, (bid) => bid.listingBids);

export default bidSlice.reducer;
