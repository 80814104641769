import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const LoginForm = styled.form`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: white;
  box-shadow: 0px 8px 20px 0px rgba(0, 35, 68, 0.1);
  padding: 30px;
  border-radius: 10px;
`;

export const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextFieldLabel = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-bottom: 10px;
`;
