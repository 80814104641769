import React, { useCallback } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// material-ui
import { Button } from '@mui/material';
// components
import Modal from 'components/Modal';
// store
import { useAppDispatch } from 'store';
import { Item, Status } from 'store/types';
import { uiLocaleSelector } from 'store/ui';
import { updateListing } from 'store/listing';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import { ButtonsContainer, CloseButton, Description, Title, Wrapper } from './styled';
import { omit } from 'lodash';
import { setNotification } from '../../../store/notification';
import { fetchNFTItem } from '../../../store/nftItem';

export interface RemovePositionModalProps {
  isOpen: boolean;
  onClose: () => void;
  item: Item;
}

const RemovePositionModal: React.FC<RemovePositionModalProps> = ({ isOpen, onClose, item }) => {
  const { formatMessage } = useIntl();

  const locale = useSelector(uiLocaleSelector);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { activeListing, id: itemId } = item;
  const { id: listingId } = activeListing ?? {};

  const handleRemoveClick = useCallback(() => {
    if (activeListing && listingId) {
      const updatedListing = omit({ ...activeListing, status: Status.CLOSED }, ['id', 'item', 'topBid', 'owner']);
      dispatch(updateListing({ submitValues: updatedListing, listingId }))
        .unwrap()
        .then((listing) => {
          dispatch(setNotification({ message: formatMessage({ id: IntlKeys.toastListingRemoved }), type: 'success' }));
          dispatch(fetchNFTItem(itemId));
          onClose();
          return listing;
        })
        .then((listing) => navigate(`/${locale}/item-details/${listing.itemId}`))
        .catch(() =>
          dispatch(
            setNotification({ message: formatMessage({ id: IntlKeys.toastSomethingWentWrong }), type: 'error' }),
          ),
        );
    }
  }, [activeListing, dispatch, formatMessage, itemId, listingId, locale, navigate, onClose]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Wrapper>
        <Title>{formatMessage({ id: IntlKeys.itemDetailsRemovePositionModalTitle })}</Title>
        <Description>{formatMessage({ id: IntlKeys.itemDetailsRemovePositionModalDescription })}</Description>
        <ButtonsContainer>
          <Button size="large" onClick={handleRemoveClick}>
            {formatMessage({ id: IntlKeys.itemDetailsRemovePositionModalButtonRemove })}
          </Button>
          <CloseButton size="large" onClick={onClose}>
            {formatMessage({ id: IntlKeys.itemDetailsRemovePositionModalButtonClose })}
          </CloseButton>
        </ButtonsContainer>
      </Wrapper>
    </Modal>
  );
};

export default RemovePositionModal;
