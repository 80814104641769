import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  width: calc(50% - 20px);
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  padding: 24px;
  background-color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;

  & > svg:first-of-type {
    width: 20px;
    height: 20px;
  }

  & > svg:nth-of-type(2) {
    position: relative;
    top: -6px;
  }
`;

export const Title = styled.div`
  font-size: 21px;
  line-height: 25.5px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const Amount = styled.div`
  font-size: 21px;
  line-height: 25px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const ActionButton = styled(Button)`
  width: fit-content;
  padding: 8px 32px;
`;
