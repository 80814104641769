import React from 'react';
// libs
import { useIntl } from 'react-intl';
// hocs
import withThumbnail from 'hocs/withThumbnail';
// helpers
import { roundTo2Digits } from 'helpers/renderHelpers';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import {
  Wrapper,
  CardInfo,
  ItemName,
  Column,
  PriceTitle,
  PriceValue,
  BuyButton,
  OnSaleTag,
  StyledImage,
  ImagePlaceholder,
} from './styled';

type CardProps = {
  image: string;
  itemName: string;
  isOnSale?: boolean;
  price?: string | number;
  isLoading?: boolean;
  isSmall?: boolean;
  onClick?: () => void;
  buttonText?: string;
  currencySymbol?: string;
  className?: string;
};

const DEFAULT_BUTTON_TEXT = 'View';

const Card: React.FC<CardProps> = ({
  image,
  isOnSale,
  itemName,
  price,
  onClick,
  currencySymbol,
  className,
  isLoading,
  isSmall = true,
  buttonText = DEFAULT_BUTTON_TEXT,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper isSmall={isSmall} onClick={onClick} className={className}>
      {isLoading ? <ImagePlaceholder /> : <StyledImage src={image} />}

      <CardInfo>
        {isSmall ? (
          <Column>
            <ItemName>{itemName}</ItemName>
            {isOnSale && <OnSaleTag>{formatMessage({ id: IntlKeys.marketplaceCollectionOnSale })}</OnSaleTag>}
          </Column>
        ) : (
          <Column>
            {isOnSale && <OnSaleTag>{formatMessage({ id: IntlKeys.marketplaceCollectionOnSale })}</OnSaleTag>}
            <ItemName>{itemName}</ItemName>
          </Column>
        )}

        <Column>
          {price && (
            <>
              <PriceTitle>{formatMessage({ id: IntlKeys.marketplaceCollectionPrice })}</PriceTitle>
              <PriceValue>
                {currencySymbol ? currencySymbol : null}
                {roundTo2Digits(Number(price))}
              </PriceValue>
            </>
          )}
        </Column>
      </CardInfo>

      {!isSmall && <BuyButton>{formatMessage({ id: IntlKeys.marketplaceCollectionView })}</BuyButton>}
    </Wrapper>
  );
};

export default Card;

export const CardWithThumbnail = withThumbnail(Card);
