import React, { useCallback } from 'react';
import noPreviewImage from 'assets/images/no-preview.jpeg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export interface ImgProps {
  src: string;
  className?: string;
  alt?: string;
  errorImage?: string;
  onError?: () => void;
  onClick?: () => void;
}

const Img: React.FC<ImgProps> = ({ src, alt, onError, className, onClick, errorImage = noPreviewImage }) => {
  const handleImageError = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (onError) {
        onError();
      }

      return (event.currentTarget.src = errorImage);
    },
    [errorImage, onError],
  );

  return <LazyLoadImage src={src} onError={handleImageError} onClick={onClick} className={className} alt={alt || ''} />;
};

export default Img;
