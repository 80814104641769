import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${({ theme }) => theme.media.md`
    gap: 16px;
  `}
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 351px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 2px;
  position: relative;
`;

export const InfoCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  ${({ theme }) => theme.media.xs`
    gap: 16px;
  `}
`;
