import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Languages } from 'localization';
import { RootState } from '../index';
import { SLICE_NAMES } from '../constants';

type UIState = {
  locale: keyof typeof Languages;
  isSmallCard: boolean;
};

const initialState: UIState = { locale: 'en', isSmallCard: false };

const uiSlice = createSlice({
  name: SLICE_NAMES.UI,
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<UIState['locale']>) => {
      state.locale = action.payload;
    },
    setIsSmallCard: (state, action: PayloadAction<boolean>) => {
      state.isSmallCard = action.payload;
    },
  },
});

const uiSelector = (state: RootState): UIState => state[SLICE_NAMES.UI];

export const uiLocaleSelector = createSelector(uiSelector, (ui) => ui.locale);
export const uiIsSmallCardSelector = createSelector(uiSelector, (ui) => ui.isSmallCard);

export const { setLocale, setIsSmallCard } = uiSlice.actions;

export default uiSlice.reducer;
