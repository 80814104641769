import React from 'react';
import { AccordionProps as MUIAccordionProps } from '@mui/material';

import { ReactComponent as DropDownArrowIcon } from 'assets/icons/drop-down-arrow.svg';

import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './styled';

export interface AccordionProps extends Omit<MUIAccordionProps, 'children'> {
  title: string;
  details: string;
  link?: string;
}

const Accordion: React.FC<AccordionProps> = ({ title, details, link, ...rest }) => {
  return (
    <StyledAccordion {...rest}>
      <StyledAccordionSummary expandIcon={<DropDownArrowIcon />}>{title}</StyledAccordionSummary>
      <StyledAccordionDetails>{!!link ? <a href={link}>{details}</a> : details}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default Accordion;
