import styled from '@emotion/styled/macro';
import { Button, TextField } from '@mui/material';
import Slider from './Slider';

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  padding: 24px;
  background-color: ${({ theme }) => theme.palette.common.white};
  //
  height: 476.5px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 28.8px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin-top: 16px;
  position: relative;
`;

export const HelperText = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  position: absolute;
  right: 0;
  bottom: -22px;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;

  & .MuiOutlinedInput-input {
    height: auto;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 26px;
    color: ${({ theme }) => theme.palette.common.blackSpace};
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  }

  & .MuiInputAdornment-root > p {
    margin-right: 8px;
    font-size: 21px;
    color: ${({ theme }) => theme.palette.common.blackSpace};
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  }
`;

export const StyledSlider = styled(Slider)`
  margin-top: 68px;
`;

export const SwapButton = styled(Button)`
  margin-top: 48px;
  height: 51px;
  font-size: 16px;
  line-height: 19px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;
