import React from 'react';
// helpers
import { roundTo5Digits } from 'helpers/renderHelpers';
// styled
import { Description, Price, Wrapper } from './styled';

export interface PriceCardProps {
  price: number | string;
  description: string;
  digitName?: string;
  currencySymbol?: string;
}

const PriceCard: React.FC<PriceCardProps> = ({ price, description, digitName = '', currencySymbol = '€' }) => {
  return (
    <Wrapper>
      <Price>
        {currencySymbol}
        {typeof price === 'string' ? price : roundTo5Digits(price)}
        {digitName}
      </Price>
      <Description>{description}</Description>
    </Wrapper>
  );
};

export default PriceCard;
