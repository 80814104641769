import React from 'react';
// libs
import { Outlet } from 'react-router-dom';
// components
import AuthHeader from '../headers/AuthHeader';
import Footer from './Footer';
import UnauthHeader from '../headers/UnauthHeader';
// hooks
import useIsAuth from 'hooks/useIsAuth';
// styled
import { Wrapper } from './styled';

const Layout = () => {
  const isAuth = useIsAuth();

  return (
    <>
      {isAuth ? <AuthHeader /> : <UnauthHeader />}

      <Wrapper>
        <Outlet />
      </Wrapper>

      <Footer />
    </>
  );
};

export default Layout;
