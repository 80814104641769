import React from 'react';
// styled
import {
  CardInfo,
  Column,
  CreatorNameSkeleton,
  ImageSkeleton,
  ItemNameSkeleton,
  PriceTitleSkeleton,
  PriceValueSkeleton,
  Wrapper,
} from './styled';

export interface CardSkeletonProps {
  isSmall?: boolean;
}

function CardSkeleton({ isSmall = false }: CardSkeletonProps) {
  return (
    <Wrapper isSmall={isSmall}>
      <ImageSkeleton />
      <CardInfo>
        <Column>
          <CreatorNameSkeleton />
          <ItemNameSkeleton />
        </Column>
        <Column>
          <PriceTitleSkeleton />
          <PriceValueSkeleton />
        </Column>
      </CardInfo>
    </Wrapper>
  );
}

export default CardSkeleton;
