import { useCallback, useEffect, useState } from 'react';
// store
import { Item } from 'store/types';
// utils
import { http } from 'utils/http';

const useFetchCollectionItemsImages = (items: Item[] | undefined) => {
  const [images, setImages] = useState<{ id: string | number; name: string; image: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchNftListItemThumbnail = async (id: string | number) => {
    const response = await http.get(`marketplace/items/${id}/file/show`, {
      headers: { 'Content-Type': 'image' },
      responseType: 'blob',
    });
    const { data } = await response;
    return URL.createObjectURL(data);
  };

  const getCollectionImages = useCallback(async () => {
    if (items) {
      return await Promise.all(
        items.map(async ({ id, name }) => {
          const image = await fetchNftListItemThumbnail(id);
          return { id, name, image };
        }),
      );
    }

    return [];
  }, [items]);

  useEffect(() => {
    if (items) {
      setIsLoading(true);
      getCollectionImages().then((images) => {
        setImages(images);
        setIsLoading(false);
      });
    }
  }, [getCollectionImages, items]);

  return { images, isLoading };
};

export default useFetchCollectionItemsImages;
