// images
import art from 'assets/images/launchpad/categories/art.png';
import collectibles from 'assets/images/launchpad/categories/collectibles.png';
import domainNames from 'assets/images/launchpad/categories/domain-names.png';
import music from 'assets/images/launchpad/categories/music.png';
import photography from 'assets/images/launchpad/categories/photography.png';
import sports from 'assets/images/launchpad/categories/sports.png';
import tradingCards from 'assets/images/launchpad/categories/trading-cards.png';
import utility from 'assets/images/launchpad/categories/utility.png';
import virtualWorlds from 'assets/images/launchpad/categories/virtual-worlds.png';
// constants
import { IntlKeys } from 'localization/keys';

export const CATEGORIES = [
  { id: '1', image: art, name: IntlKeys.categoryArt, value: 'art' },
  { id: '2', image: collectibles, name: IntlKeys.categoryCollectibles, value: 'collectibles' },
  { id: '3', image: domainNames, name: IntlKeys.categoryDomainNames, value: 'domain_names' },
  { id: '4', image: music, name: IntlKeys.categoryMusic, value: 'music' },
  { id: '5', image: photography, name: IntlKeys.categoryPhotography, value: 'photography' },
  { id: '6', image: sports, name: IntlKeys.categorySports, value: 'sports' },
  { id: '7', image: tradingCards, name: IntlKeys.categoryTradingCards, value: 'trading_cards' },
  { id: '8', image: utility, name: IntlKeys.categoryUtility, value: 'utility' },
  { id: '9', image: virtualWorlds, name: IntlKeys.categoryVirtualWorlds, value: 'virtual_worlds' },
] as const;
