import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { http } from 'utils/http';
import { FILTERS } from 'constants/filters';
import { RootState } from '../index';
import { CreateNFTItemPayload, Item } from '../types';
import { SLICE_NAMES } from '../constants';

type ItemState = {
  meta: {
    isLoading: boolean;
    isLoaded: boolean;
  };
  items: Item[];
  item: Item;
  dashboardItems: { recentItems: Item[]; randomItems: Item[] };
};

const initialState: ItemState = {
  meta: { isLoading: false, isLoaded: false },
  items: [],
  item: {} as Item,
  dashboardItems: {
    recentItems: [],
    randomItems: [],
  },
};

export const createNFTItem = createAsyncThunk('item/create', async (payload: CreateNFTItemPayload) => {
  return http.post<CreateNFTItemPayload>('items', payload).catch((error) => {
    return error;
  });
});

export const fetchNFTItems = createAsyncThunk('items/fetch', async () => {
  return http.get<CreateNFTItemPayload>('items').catch((error) => error);
});

export const fetchNFTItem = createAsyncThunk('item/fetch', async (id: string | number) => {
  return http.get<Item>(`items/${id}`).catch((error) => error);
});

export const fetchItemsByCollectionId = createAsyncThunk(
  'itemByCollectionId/fetch',
  async ({ collectionId, params }: { collectionId: string; params: string }) => {
    return http.get<Item>(`items?${FILTERS.collectionId}=${collectionId}&${params}`).catch((error) => error);
  },
);

export const fetchNFTDashboardItems = createAsyncThunk('dashboardItems/fetch', async () => {
  return http.get<CreateNFTItemPayload>('items/dashboard').catch((error) => error);
});

const itemSlice = createSlice({
  name: SLICE_NAMES.NFT_ITEM,
  initialState,
  reducers: {
    setInitialItemMeta: (state) => {
      state.meta = initialState.meta;
    },
    setNFTItem: (state, action: PayloadAction<Item>) => {
      state.item = action.payload;
    },
  },
  extraReducers: {
    [createNFTItem.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [createNFTItem.fulfilled.type]: (state, { payload }) => {
      const { data } = payload;
      state.item = data;
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [createNFTItem.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },

    [fetchNFTItems.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [fetchNFTItems.fulfilled.type]: (state, { payload }) => {
      const { data } = payload;
      state.items = data;
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [fetchNFTItems.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },

    [fetchNFTItem.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [fetchNFTItem.fulfilled.type]: (state, { payload }) => {
      const { data } = payload;
      state.item = data;
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [fetchNFTItem.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },

    [fetchItemsByCollectionId.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [fetchItemsByCollectionId.fulfilled.type]: (state, { payload }) => {
      const { data } = payload;
      state.items = data;
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [fetchItemsByCollectionId.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },
    // fetch dashboard items
    [fetchNFTDashboardItems.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [fetchNFTDashboardItems.fulfilled.type]: (state, { payload }) => {
      const { data } = payload;
      // state.dashboardItems = data?.recentItems;
      console.log(data);
      state.dashboardItems.randomItems = data?.randomItems;
      state.dashboardItems.recentItems = data?.recentItems;
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [fetchNFTDashboardItems.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },
  },
});

const itemStateSelector = (state: RootState): ItemState => state[SLICE_NAMES.NFT_ITEM];
export const itemMetaSelector = createSelector(itemStateSelector, (item) => item.meta);
export const itemsSelector = createSelector(itemStateSelector, (item) => item.items);
export const itemSelector = createSelector(itemStateSelector, (item) => item.item);
export const dashboardItemsSelector = createSelector(itemStateSelector, (item) => item.dashboardItems);

export const { setInitialItemMeta, setNFTItem } = itemSlice.actions;

export default itemSlice.reducer;
