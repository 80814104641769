import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { CardStatus } from './constants';

const cardStatusStyles = (status: CardStatus) => {
  switch (status) {
    case CardStatus.Active:
      return css`
        opacity: 1;
        z-index: 3;
      `;
    case CardStatus.Next:
      return css`
        z-index: 2;
      `;
    case CardStatus.Prev:
      return css`
        z-index: 1;
      `;
    case CardStatus.Inactive:
      return css`
        z-index: 1;
      `;
  }
};

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
  user-select: none;
`;

export const CardCarousel = styled.ul`
  list-style: none;
  padding: 0;
  position: relative;

  width: 460px;
  height: 280px;
  margin: 20px;
  user-select: none;

  ${({ theme }) => theme.media.md`
    display: flex;
    justify-content: center;
  `}

  ${({ theme }) => theme.media.sm`
    width: 460px;
    height: 280px;
  `}

  ${({ theme }) => theme.media.xs`
    width: 300px;
    height: 196px;
  `}
`;

export const Card = styled(motion.li)<{ cardStatus: CardStatus }>`
  width: fit-content;
  height: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  border-radius: 16px;

  box-shadow: rgb(24 26 32 / 10%) 0 0 1px, rgb(71 77 87 / 4%) 0 3px 6px, rgb(24 26 32 / 4%) 0 1px 2px;

  ${({ cardStatus }) => cardStatusStyles(cardStatus)};

  & img {
    width: 460px;
    height: 280px;
    object-fit: cover;

    transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }

  &:hover img {
    transform: scale(1.03);
  }

  user-select: none;

  ${({ theme }) => theme.media.sm`
    & img {
      width: 460px;
      height: 280px;
    }
  `}

  ${({ theme }) => theme.media.xs`
    & img {
      width: 300px;
      height: 196px;
    }
  `}
`;

export const IndicatorsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: fit-content;
  position: absolute;
  bottom: 40px;

  user-select: none;

  ${({ theme }) => theme.media.sm`
    bottom: 12px;
  `}

  ${({ theme }) => theme.media.xs`
    bottom: 28px;
  `}
`;

export const Indicator = styled.div<{ isActive: boolean }>`
  background-color: #b7bdc6;
  cursor: pointer;
  border-radius: 2px;
  width: 18px;
  height: 4px;
  transition: all 0.3s ease;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #0082ff;
      width: 42px;
      transform: scale(1.05);
    `}

  user-select: none;
`;
