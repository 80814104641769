import React, { useCallback, useMemo } from 'react';
// libs
import { Field, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { differenceInSeconds, format, parseISO } from 'date-fns';
import { useIntl } from 'react-intl';
// material-ui
import { InputAdornment } from '@mui/material';
// components
import Countdown from 'components/Countdown';
import BidsTable from '../BidsTable';
// store
import { Bid, Item, MakeBidPayload, Status } from 'store/types';
import { cancelBid, listingBidsSelector } from 'store/bid';
import { ProfileState } from 'store/profile';
import { uiLocaleSelector } from 'store/ui';
import { useAppDispatch } from 'store';
import { fetchMarketplaceItemById } from 'store/marketplace';
import { setNotification } from 'store/notification';
// helpers
import { roundTo2Digits } from 'helpers/renderHelpers';
// constants
import { EURO_SIGN } from 'constants/general';
import { IntlKeys } from 'localization/keys';
// styled
import {
  BidsTableTitle,
  BidsTableWrapper,
  Button,
  ButtonsContainer,
  ContentWrapper,
  CountdownWrapper,
  Divider,
  HelperText,
  HoorayText,
  ItemName,
  NavLink,
  PriceTitle,
  PriceTitleHelper,
  PriceValue,
  ReviewButton,
  SaleDateEnd,
  SecondaryButton,
  StyledField,
  YourBidAccepted,
  YourBidLabel,
} from '../styled';

export type BooleanSetStateType = React.Dispatch<React.SetStateAction<boolean>>;
export type BidSetStateType = React.Dispatch<React.SetStateAction<Bid | null>>;

export interface RightColumnProps {
  item: Item;
  profile: Omit<ProfileState, 'firstName' | 'lastName'>;
  isOwner: boolean;
  isBidButtonClicked: boolean;
  setIsRemovePosModalOpened: BooleanSetStateType;
  setIsBidButtonClicked: BooleanSetStateType;
  setIsReviewModalOpened: BooleanSetStateType;
  setIsAcceptBidModalOpened: BooleanSetStateType;
  setIsDeclineBidModalOpened: BooleanSetStateType;
  setIsDeclineAllBidsOpened: BooleanSetStateType;
  setIsPayModalOpened: BooleanSetStateType;
  setSelectedBid: BidSetStateType;
}

const PRICE_INPUT_PROPS = {
  startAdornment: <InputAdornment position="start">{EURO_SIGN}</InputAdornment>,
};

// TODO refactor RightColumn(split RightColumn into smaller components)
const RightColumn: React.FC<RightColumnProps> = ({
  item,
  profile,
  isOwner,
  isBidButtonClicked,
  setIsRemovePosModalOpened,
  setIsBidButtonClicked,
  setIsReviewModalOpened,
  setIsAcceptBidModalOpened,
  setIsDeclineBidModalOpened,
  setIsDeclineAllBidsOpened,
  setIsPayModalOpened,
  setSelectedBid,
}) => {
  const { formatMessage } = useIntl();
  const { submitForm, validateForm, setFieldTouched } = useFormikContext<MakeBidPayload>();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { id } = useParams();

  const locale = useSelector(uiLocaleSelector);
  const bids = useSelector(listingBidsSelector);

  const { collection, name, activeListing, id: itemId, price } = item;
  const { userId } = profile;
  const { topBid } = activeListing ?? {};

  const isSaleEnded = useMemo(() => {
    if (activeListing?.dateTimeEnd) {
      const parsedDate = parseISO(activeListing.dateTimeEnd);
      return differenceInSeconds(parsedDate, Date.now()) < 0;
    }
    return true;
  }, [activeListing?.dateTimeEnd]);

  const showBidInput = useMemo(
    () => !isOwner && activeListing && isBidButtonClicked,
    [isBidButtonClicked, isOwner, activeListing],
  );

  const formattedSaleEndDate = useMemo(() => {
    if (activeListing?.dateTimeEnd) {
      const parsedDate = parseISO(activeListing.dateTimeEnd);
      return `${format(parsedDate, 'do')} of ${format(parsedDate, 'MMMM yyyy')} at ${format(parsedDate, 'hh:mm O')}`;
    }
  }, [activeListing?.dateTimeEnd]);

  const handleUpdateListingButtonClick = () => navigate(`/${locale}/listing/edit/${activeListing?.id}`);

  const handleSellButtonClick = () => navigate(`/${locale}/listing/create`, { state: { itemId: id } });

  const handleRemovePosModalOpen = () => setIsRemovePosModalOpened(true);

  const handleBidButtonClick = () => setIsBidButtonClicked(true);

  const handlePayNowClick = () => setIsPayModalOpened(true);

  const getHighestBid = () => {
    if (bids.length) {
      return bids.reduce(function (prev, current) {
        return prev?.price > current?.price ? prev : current;
      });
    }

    return null;
  };

  const handleAcceptHighestBidClick = () => {
    const highestBid = getHighestBid();
    setSelectedBid(highestBid);
    setIsAcceptBidModalOpened(true);
  };

  const validate = useCallback(() => {
    validateForm().then((errors) => {
      const fieldsWithErrors = Object.keys(errors).map((fieldName) => fieldName);
      return fieldsWithErrors.length
        ? fieldsWithErrors.forEach((fieldName) => setFieldTouched(fieldName, true, true))
        : setIsReviewModalOpened(true);
    });
  }, [setFieldTouched, setIsReviewModalOpened, validateForm]);

  const handleDeclineAllBidsClick = () => {
    setIsDeclineAllBidsOpened(true);
  };

  const handleCancelBidClick = () => {
    if (topBid?.id) {
      dispatch(cancelBid(topBid?.id))
        .unwrap()
        .then(() => {
          if (itemId) {
            dispatch(setNotification({ message: formatMessage({ id: IntlKeys.toastBidCancelled }), type: 'success' }));
            dispatch(fetchMarketplaceItemById(itemId));
          }
        })
        .catch(() =>
          dispatch(
            setNotification({ message: formatMessage({ id: IntlKeys.toastSomethingWentWrong }), type: 'error' }),
          ),
        );
    }
  };

  const showCountdown = useMemo(() => {
    const ignoredStatuses = [Status.CLOSED, Status.SOLD, Status.UNPUBLISHED];
    return activeListing && !ignoredStatuses.includes(activeListing.status);
  }, [activeListing]);

  return (
    <ContentWrapper>
      <ItemName>{name}</ItemName>
      {isOwner && <NavLink to={`/${locale}/my-collections/${collection.id}`}>{collection.name}</NavLink>}
      {!isOwner && activeListing && activeListing?.status !== Status.PURCHASE_PENDING ? (
        <>
          <PriceTitle>
            {formatMessage({ id: IntlKeys.itemDetailsBuyNowPrice })}
            <PriceTitleHelper>{formatMessage({ id: IntlKeys.itemDetailsBuyNowPriceDescription })}</PriceTitleHelper>
          </PriceTitle>

          <PriceValue>{activeListing.buyoutPrice ? `€${roundTo2Digits(activeListing.buyoutPrice)}` : '-'}</PriceValue>

          {!isSaleEnded && (
            <SaleDateEnd>
              {formatMessage({ id: IntlKeys.itemDetailsThisSaleEndsOn }, { value: formattedSaleEndDate })}
            </SaleDateEnd>
          )}

          <PriceTitle>{formatMessage({ id: IntlKeys.itemDetailsHighestBid })}</PriceTitle>
          <PriceValue>
            {activeListing?.topBid?.price ? `€${roundTo2Digits(activeListing.topBid.price)}` : '-'}
          </PriceValue>
          {topBid?.ownerId === userId && (
            <HoorayText>{formatMessage({ id: IntlKeys.itemDetailsHighestBidHoorayMessage })}</HoorayText>
          )}
        </>
      ) : null}

      {isOwner && !activeListing ? (
        <>
          <PriceTitle>{formatMessage({ id: IntlKeys.itemDetailsBuyNowPrice })}</PriceTitle>
          <PriceValue>{formatMessage({ id: IntlKeys.itemDetailsThisItemIsNotListed })}</PriceValue>

          <PriceTitle>{formatMessage({ id: IntlKeys.itemDetailsLastPriceSold })}</PriceTitle>
          <PriceValue>-</PriceValue>
        </>
      ) : null}

      {isOwner && activeListing ? (
        <>
          <PriceTitle>{formatMessage({ id: IntlKeys.itemDetailsBuyNowPrice })}</PriceTitle>
          <PriceValue>{activeListing.buyoutPrice ? `€${roundTo2Digits(activeListing.buyoutPrice)}` : '-'}</PriceValue>

          {activeListing?.status !== Status.PURCHASE_PENDING && !isOwner ? (
            <SaleDateEnd>
              {formatMessage({ id: IntlKeys.itemDetailsThisSaleEndsOn }, { value: formattedSaleEndDate })}
            </SaleDateEnd>
          ) : null}

          {activeListing?.status !== Status.PURCHASE_PENDING ? (
            <>
              <PriceTitle>{formatMessage({ id: IntlKeys.itemDetailsHighestBid })}</PriceTitle>
              <PriceValue>
                {activeListing?.topBid?.price ? `€${roundTo2Digits(activeListing?.topBid?.price)}` : '-'}
              </PriceValue>
            </>
          ) : (
            <>
              <PriceTitle>{formatMessage({ id: IntlKeys.itemDetailsPurchasePrice })}</PriceTitle>
              <PriceValue>{price ? `€${roundTo2Digits(price)}` : '-'}</PriceValue>
            </>
          )}
        </>
      ) : null}

      {!isOwner && activeListing && activeListing?.status === Status.PURCHASE_PENDING ? (
        <>
          <YourBidAccepted>{formatMessage({ id: IntlKeys.itemDetailsYourBidAccepted })}</YourBidAccepted>
          <PriceTitle>{formatMessage({ id: IntlKeys.itemDetailsPurchasePrice })}</PriceTitle>
          <PriceValue>{price ? `€${roundTo2Digits(price)}` : '-'}</PriceValue>
        </>
      ) : null}

      {(topBid?.price && isOwner) || (isOwner && activeListing?.status === Status.PURCHASE_PENDING)
        ? null
        : showCountdown && (
            <CountdownWrapper>
              <Countdown
                isEnded={activeListing?.status === Status.PURCHASE_PENDING}
                date={
                  activeListing?.status === Status.PURCHASE_PENDING
                    ? Date.now()
                    : parseISO(activeListing?.dateTimeEnd || '')
                }
              />
            </CountdownWrapper>
          )}

      {isOwner && activeListing?.status === Status.PURCHASE_PENDING ? (
        <CountdownWrapper>
          <Countdown isEnded date={Date.now()} />
        </CountdownWrapper>
      ) : null}

      <ButtonsContainer>
        {isOwner && activeListing && !topBid?.price && activeListing?.status !== Status.PURCHASE_PENDING && (
          <>
            <Button
              fullWidth
              size="large"
              disabled={activeListing?.status === Status.ACTIVE}
              onClick={handleUpdateListingButtonClick}
            >
              {formatMessage({ id: IntlKeys.itemDetailsEditSale })}
            </Button>

            <SecondaryButton fullWidth size="large" color="secondary" onClick={handleRemovePosModalOpen}>
              {formatMessage({ id: IntlKeys.itemDetailsRemovePosition })}
            </SecondaryButton>
          </>
        )}

        {isOwner && !activeListing && (
          <>
            <Button fullWidth size="large" onClick={handleSellButtonClick}>
              {formatMessage({ id: IntlKeys.itemDetailsSell })}
            </Button>

            <SecondaryButton fullWidth size="large" color="secondary">
              {formatMessage({ id: IntlKeys.itemDetailsWithdraw })}
            </SecondaryButton>
          </>
        )}

        {isOwner && activeListing && topBid?.price && activeListing?.status !== Status.PURCHASE_PENDING && (
          <>
            <Button fullWidth size="large" onClick={handleAcceptHighestBidClick}>
              {formatMessage({ id: IntlKeys.itemDetailsAcceptHighestBid })}
            </Button>

            <SecondaryButton fullWidth size="large" color="secondary" onClick={handleDeclineAllBidsClick}>
              {formatMessage({ id: IntlKeys.itemDetailsDeclineAllBids })}
            </SecondaryButton>
          </>
        )}

        {!isOwner && activeListing && !isBidButtonClicked && activeListing?.status !== Status.PURCHASE_PENDING && (
          <Button fullWidth size="large" onClick={handleBidButtonClick}>
            {formatMessage({ id: IntlKeys.itemDetailsPlaceBid })}
          </Button>
        )}

        {!isOwner && activeListing && activeListing?.status === Status.PURCHASE_PENDING && (
          <Button fullWidth size="large" onClick={handlePayNowClick}>
            {formatMessage({ id: IntlKeys.itemDetailsPayNow })}
          </Button>
        )}

        {!isOwner &&
          activeListing &&
          !isBidButtonClicked &&
          topBid?.ownerId === userId &&
          activeListing?.status !== Status.PURCHASE_PENDING && (
            <SecondaryButton fullWidth size="large" color="secondary" onClick={handleCancelBidClick}>
              {formatMessage({ id: IntlKeys.itemDetailsCancelBid })}
            </SecondaryButton>
          )}
      </ButtonsContainer>

      {isOwner && activeListing && bids && activeListing?.status !== Status.PURCHASE_PENDING && (
        <>
          <Divider />
          <BidsTableWrapper>
            <BidsTableTitle>{formatMessage({ id: IntlKeys.itemDetailsBids })}</BidsTableTitle>
            <BidsTable
              bids={bids}
              setIsAcceptBidModalOpened={setIsAcceptBidModalOpened}
              setIsDeclineBidModalOpened={setIsDeclineBidModalOpened}
              setSelectedBid={setSelectedBid}
            />
          </BidsTableWrapper>
        </>
      )}

      <form onSubmit={submitForm}>
        {showBidInput && (
          <YourBidLabel>
            {formatMessage({ id: IntlKeys.itemDetailsYourBid })}
            <HelperText>{formatMessage({ id: IntlKeys.itemDetailsYourBidDescription })}</HelperText>
            <Field name="price" component={StyledField} InputProps={PRICE_INPUT_PROPS} type="number" required />
          </YourBidLabel>
        )}
      </form>

      {showBidInput && (
        <ReviewButton size="large" onClick={validate}>
          {formatMessage({ id: IntlKeys.itemDetailsReviewOffer })}
        </ReviewButton>
      )}
    </ContentWrapper>
  );
};

export default RightColumn;
