import styled from '@emotion/styled/macro';

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
  margin: 56px 0;
`;
