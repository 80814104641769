import React from 'react';
// material-ui
import { TextField, TextFieldProps } from '@mui/material';
// styled
import { SelectContainer, SelectLabel, StyledMenuItem, StyledSelect } from './styled';

export type SelectProps = Omit<TextFieldProps, 'label' | 'variant' | 'children' | 'defaultValue'> & {
  label?: string;
  onMenuItemClick?: (value: string) => void;
  options: { value: string; label: string }[];
};

function Select({ label, options, onMenuItemClick, ...rest }: SelectProps) {
  return (
    <SelectContainer>
      {label && <SelectLabel>{label}</SelectLabel>}
      <StyledSelect size="small" variant="outlined" defaultValue={options[0]?.value || ''} select {...rest}>
        {options.map((option) => (
          <StyledMenuItem
            key={option.value}
            value={option.value}
            onClick={onMenuItemClick ? () => onMenuItemClick(option.value) : () => {}}
            divider
          >
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </SelectContainer>
  );
}

export default Select;
