import React from 'react';
// icons
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
// store
import { Bid } from 'store/types';
// types
import { BidSetStateType, BooleanSetStateType } from '../../RightColumn';
// styled
import { Wrapper, StyledMenu, StyledMenuItem, MoreIconWrapper } from './styled';

export interface CoinDropdownProps {
  bid: Bid;
  setIsAcceptBidModalOpened: BooleanSetStateType;
  setIsDeclineBidModalOpened: BooleanSetStateType;
  setSelectedBid: BidSetStateType;
}

const BidMenu: React.FC<CoinDropdownProps> = ({
  bid,
  setIsAcceptBidModalOpened,
  setIsDeclineBidModalOpened,
  setSelectedBid,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleIconClick = (event: any) => setAnchorEl(event?.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleAcceptClick = () => {
    setSelectedBid(bid);
    setIsAcceptBidModalOpened(true);
    handleClose();
  };

  const handleDeclineClick = () => {
    setSelectedBid(bid);
    setIsDeclineBidModalOpened(true);
    handleClose();
  };

  return (
    <Wrapper>
      <MoreIconWrapper onClick={handleIconClick}>
        <MoreIcon />
      </MoreIconWrapper>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <StyledMenuItem onClick={handleAcceptClick}>Accept</StyledMenuItem>
        <StyledMenuItem onClick={handleDeclineClick}>Decline</StyledMenuItem>
      </StyledMenu>
    </Wrapper>
  );
};

export default BidMenu;
