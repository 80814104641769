import React, { useCallback, useMemo } from 'react';
// libs
import { Column, useFlexLayout, useSortBy, useTable } from 'react-table';
import { format, isValid, parseISO } from 'date-fns';
import { useIntl } from 'react-intl';
// components
import BidMenu from './BidMenu';
// store
import { Bid, BidStatus } from 'store/types';
// helpers
import { roundTo2Digits } from 'helpers/renderHelpers';
// constants
import { EURO_SIGN } from 'constants/general';
import { IntlKeys } from 'localization/keys';
// types
import { BidSetStateType, BooleanSetStateType } from '../RightColumn';
// styled
import {
  ActionsContainer,
  CellValue,
  FlexibleTable,
  StatusTag,
  TBody,
  TCell,
  THead,
  THeaderContainer,
  THeadRow,
  TRow,
} from './styled';

export interface BidsTableProps {
  bids: Bid[];
  setIsAcceptBidModalOpened: BooleanSetStateType;
  setIsDeclineBidModalOpened: BooleanSetStateType;
  setSelectedBid: BidSetStateType;
}

const DATE_FORMAT = 'd MMM yyyy, HH:mm';

const BidsTable: React.FC<BidsTableProps> = ({
  bids,
  setIsAcceptBidModalOpened,
  setIsDeclineBidModalOpened,
  setSelectedBid,
}) => {
  const { formatMessage } = useIntl();

  const formatData = useCallback((dateString: string) => {
    const parsedDate = parseISO(dateString);

    if (isValid(parsedDate)) {
      return format(parsedDate, DATE_FORMAT);
    }

    return '';
  }, []);

  const data = useMemo(() => {
    return bids.map((bid) => {
      const date = formatData(bid.createdAt);
      const formattedPrice = roundTo2Digits(bid.price);
      let status = BidStatus.ACTIVE;
      if (bid.canceledAt) {
        status = BidStatus.CANCELED;
      }
      if (bid.rejectedAt) {
        status = BidStatus.REJECTED;
      }

      return {
        action: bid,
        price: formattedPrice,
        date,
        status,
      };
    });
  }, [bids, formatData]);

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        width: 34,
        Cell: (props) => (
          <ActionsContainer>
            <BidMenu
              bid={props.value}
              setIsAcceptBidModalOpened={setIsAcceptBidModalOpened}
              setIsDeclineBidModalOpened={setIsDeclineBidModalOpened}
              setSelectedBid={setSelectedBid}
            />
          </ActionsContainer>
        ),
      },
      {
        Header: formatMessage({ id: IntlKeys.itemDetailsBidsTablePriceHeader }),
        accessor: 'price',
        width: 120,
        disableSortBy: true,
        Cell: (props) => (
          <CellValue>
            {EURO_SIGN}
            {props.value}
          </CellValue>
        ),
        sortType: (a, b) => Number(a.values) - Number(b.values),
      },
      {
        Header: formatMessage({ id: IntlKeys.itemDetailsBidsTableDateHeader }),
        accessor: 'date',
        width: 120,
        disableSortBy: true,
        Cell: (props) => <CellValue>{props.value}</CellValue>,
      },
      {
        Header: formatMessage({ id: IntlKeys.itemDetailsBidsTableStatusHeader }),
        accessor: 'status',
        width: 80,
        disableSortBy: true,
        Cell: (props) => <StatusTag status={props.value}>{props.value}</StatusTag>,
      },
    ],
    [formatMessage, setIsAcceptBidModalOpened, setIsDeclineBidModalOpened, setSelectedBid],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
    useSortBy,
  );

  // const renderSortingIcon = useCallback((isSortedDesc: boolean | undefined) => {
  //   return (
  //     <SortingIconWrapper isSortedDesc={isSortedDesc}>
  //       <SortingIcon />
  //     </SortingIconWrapper>
  //   );
  // }, []);

  return (
    <>
      <FlexibleTable {...getTableProps()}>
        <THeaderContainer>
          {headerGroups.map((headerGroup, index) => (
            <THeadRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, i) => (
                <THead {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                  {column.render('Header')}
                  {/*{!column.disableSortBy && renderSortingIcon(column.isSortedDesc)}*/}
                </THead>
              ))}
            </THeadRow>
          ))}
        </THeaderContainer>

        <TBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TRow {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <TCell {...cell.getCellProps()} key={index}>
                      {cell.render('Cell')}
                    </TCell>
                  );
                })}
              </TRow>
            );
          })}
        </TBody>
      </FlexibleTable>
    </>
  );
};

export default BidsTable;
