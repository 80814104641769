import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { http } from 'utils/http';
import { RootState } from '../index';
import { Item } from '../types';
import { SLICE_NAMES } from '../constants';

type MarketplaceState = {
  items: Item[];
  item: Item;
  meta: {
    isLoading: boolean;
    isLoaded: boolean;
  };
};

const initialState: MarketplaceState = {
  items: [],
  item: {} as Item,
  meta: { isLoading: false, isLoaded: false },
};

export const fetchMarketplaceItems = createAsyncThunk(
  'marketplace/fetchMarketplaceItems',
  async ({ params }: { params: string }) => {
    return http.get<Item[]>(`marketplace/items?${params}`).catch((error) => error);
  },
);

export const fetchMarketplaceItemById = createAsyncThunk(
  'marketplace/fetchMarketplaceItemById',
  async (id: string | number) => {
    return http.get<Item>(`marketplace/items/${id}`).catch((error) => error);
  },
);

const marketplaceSlice = createSlice({
  name: SLICE_NAMES.MARKETPLACE,
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMarketplaceItems.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
      state.items = initialState.items;
    },
    [fetchMarketplaceItems.fulfilled.type]: (state, { payload }) => {
      const { data } = payload;
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
      state.items = data;
    },
    [fetchMarketplaceItems.rejected.type]: (state) => {
      state.meta = initialState.meta;
      state.items = initialState.items;
    },

    [fetchMarketplaceItemById.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
      state.item = initialState.item;
    },
    [fetchMarketplaceItemById.fulfilled.type]: (state, { payload }) => {
      const { data } = payload;
      state.item = data;
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [fetchMarketplaceItemById.rejected.type]: (state) => {
      state.meta = initialState.meta;
      state.item = initialState.item;
    },
  },
});

const marketplaceSelector = (state: RootState): MarketplaceState => state[SLICE_NAMES.MARKETPLACE];

export const marketplaceMetaSelector = createSelector(marketplaceSelector, (marketplace) => marketplace.meta);
export const marketplaceItemsSelector = createSelector(marketplaceSelector, (marketplace) => marketplace.items);
export const marketplaceItemSelector = createSelector(marketplaceSelector, (marketplace) => marketplace.item);

export default marketplaceSlice.reducer;
