import styled from '@emotion/styled/macro';
import { Slider } from '@mui/material';

export const CustomSlider = styled(Slider)`
  width: 98.4%;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  height: 2px;
  padding: 15px 0;
  margin: 12px 0;
  text-align: left;

  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    box-shadow: none !important;
  }

  & .MuiSlider-thumb {
    width: 10px;
    height: 10px;
    color: ${({ theme }) => theme.palette.common.brescianBlue};
    box-shadow: none;

    &:before,
    &:after,
    &:focus,
    &:hover,
    &.Mui-active {
      box-shadow: none;
      @media (hover: none) {
        box-shadow: none;
      }
    }
  }

  & .MuiSlider-valueLabel {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.common.brescianBlue};
    background-color: unset;
    font-weight: bold;
    top: 36px;
  }

  & .MuiSlider-track {
    border: none;
  }

  & .MuiSlider-rail {
    background-color: ${({ theme }) => theme.palette.common.snowBank};
    opacity: 1;
  }

  & .MuiSlider-mark {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.palette.common.orochimaru};
    transform: translateX(-5px) translateY(-5px);
    opacity: 1;

    &.MuiSlider-markActive {
      opacity: 1;
      background-color: currentColor;
    }
  }

  & .MuiSlider-markLabel {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.common.ultimateGrey};
    background-color: unset;
    font-weight: bold;
    top: -12px;
    opacity: 1;

    &.MuiSlider-markLabelActive {
      color: ${({ theme }) => theme.palette.common.brescianBlue};
    }
  }
`;
