import { useCallback, useEffect, useState } from 'react';
import { isArray } from 'lodash';
import { useFilterContext } from 'components/Filter/FilterProvider';
import { FILTERS, ORDERING } from 'constants/filters';

type FilterTag = {
  id: string;
  title: string;
  onClick: () => void;
};

const queryParamsToIgnore = [ORDERING.price, ORDERING.createdAt, FILTERS.statuses, FILTERS.categories];

const useFilterTags = () => {
  const { searchParamsObj, handleFilterValuesChange, clearGivenFilters } = useFilterContext();
  const [tags, setTags] = useState<FilterTag[]>([]);

  const makeTags = useCallback(() => {
    const filteredTags = [] as FilterTag[];

    Object.entries(searchParamsObj).forEach(([key, value]) => {
      const pushDefaultTag = (titlePrefix = '') => {
        if (isArray(value)) {
          value.forEach((_, index) =>
            filteredTags.push({
              id: key,
              title: `${titlePrefix}${value[index]}`,
              onClick: () =>
                handleFilterValuesChange(
                  key,
                  (searchParamsObj[key] as unknown as []).filter((filterName) => filterName !== value[index]),
                ),
            }),
          );
        } else {
          filteredTags.push({
            id: key,
            title: `${titlePrefix}${value}`,
            onClick: () => handleFilterValuesChange(key, ''),
          });
        }
      };

      const pushPriceTag = (priceFrom?: string, priceTo?: string) => {
        const filtersToClear = [FILTERS.priceMin, FILTERS.priceMax];

        if (filteredTags.some((tag) => tag.id === 'price')) {
          const priceTagIndex = filteredTags.findIndex((tag) => tag.id === 'price');
          return (filteredTags[priceTagIndex] = {
            id: 'price',
            title: `${priceFrom ? `from ${priceFrom}€` : ''} ${priceTo ? `to ${priceTo}€` : ''}`,
            onClick: () => clearGivenFilters(filtersToClear),
          });
        }

        return filteredTags.push({
          id: 'price',
          title: `${priceFrom ? `from ${priceFrom}€` : ''} ${priceTo ? `to ${priceTo}€` : ''}`,
          onClick: () => clearGivenFilters(filtersToClear),
        });
      };

      const makeSpecificTag = () => {
        switch (key) {
          case FILTERS.name:
            pushDefaultTag('Name: ');
            break;
          case FILTERS.priceMin:
          case FILTERS.priceMax:
            pushPriceTag(searchParamsObj[FILTERS.priceMin], searchParamsObj[FILTERS.priceMax]);
            break;
          default:
            pushDefaultTag();
            break;
        }
      };

      if (!queryParamsToIgnore.includes(key)) {
        makeSpecificTag();
      }
    });
    return filteredTags;
  }, [clearGivenFilters, handleFilterValuesChange, searchParamsObj]);

  useEffect(() => {
    setTags(makeTags());
  }, [makeTags, searchParamsObj]);

  return tags;
};

export default useFilterTags;
