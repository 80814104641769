import React from 'react';
// libs
import { parseISO } from 'date-fns';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
// components
import Modal from 'components/Modal';
import Countdown from 'components/Countdown';
// hooks
import useFetchNftItemThumbnail from 'hooks/useFetchNftItemThumbnail';
// store
import { Item, MakeBidPayload } from 'store/types';
import { profileWalletBalanceSelector } from 'store/profile';
// helpers
import { roundTo2Digits } from 'helpers/renderHelpers';
// constants
import { EURO_SIGN } from 'constants/general';
import { IntlKeys } from 'localization/keys';
// styled
import {
  BigTitle,
  BigValue,
  ButtonsContainer,
  CancelButton,
  CommonContainer,
  Divider,
  Image,
  InfoContainer,
  InfoText,
  ItemContainer,
  ItemInfoContainer,
  ItemName,
  SmallTitle,
  SmallValue,
  SubmitButton,
  Warning,
  Wrapper,
} from './styled';

export interface ReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  item: Item;
}

const ReviewModal: React.FC<ReviewModalProps> = ({ isOpen, item, onClose }) => {
  const { formatMessage } = useIntl();

  const { values, submitForm } = useFormikContext<MakeBidPayload>();
  const { activeListing, name, id } = item;
  const walletBalance = useSelector(profileWalletBalanceSelector);

  const { image } = useFetchNftItemThumbnail({ itemId: id, thumbnailType: 'list', isMarketplaceItem: true });

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Wrapper>
        <ItemContainer>
          <Image src={image} />
          <ItemInfoContainer>
            <SmallTitle>{formatMessage({ id: IntlKeys.itemDetailsReviewModalBuyNowPrice })}</SmallTitle>
            <SmallValue>
              {EURO_SIGN}
              {roundTo2Digits(activeListing?.buyoutPrice || 0)}
            </SmallValue>
            <ItemName>{name}</ItemName>
          </ItemInfoContainer>
        </ItemContainer>

        <Countdown date={parseISO(activeListing?.dateTimeEnd || '')} />

        <InfoContainer>
          <CommonContainer>
            <BigTitle>{formatMessage({ id: IntlKeys.itemDetailsReviewModalYourBid })}</BigTitle>
            <BigValue>
              {EURO_SIGN}
              {roundTo2Digits(values.price)}
            </BigValue>
          </CommonContainer>

          <Divider />

          <CommonContainer>
            <SmallTitle>
              {formatMessage({ id: IntlKeys.itemDetailsReviewModalBalanceAfterBid })}{' '}
              <InfoText>{formatMessage({ id: IntlKeys.itemDetailsReviewModalBalanceAfterBidDescription })}</InfoText>
            </SmallTitle>
            {Number(walletBalance) - values.price < 0 ? (
              <Warning>
                {formatMessage(
                  { id: IntlKeys.itemDetailsReviewModalYouDontHaveFunds },
                  { balance: roundTo2Digits(Number(walletBalance)) },
                )}
              </Warning>
            ) : null}
            <SmallValue>
              {EURO_SIGN}
              {roundTo2Digits(Number(walletBalance) - values.price)}
            </SmallValue>
          </CommonContainer>
          <ButtonsContainer>
            <SubmitButton size="large" onClick={submitForm}>
              {formatMessage({ id: IntlKeys.itemDetailsReviewModalButtonSubmitOffer })}
            </SubmitButton>
            <CancelButton size="large" onClick={onClose}>
              {formatMessage({ id: IntlKeys.itemDetailsReviewModalButtonCancel })}
            </CancelButton>
          </ButtonsContainer>
        </InfoContainer>
      </Wrapper>
    </Modal>
  );
};

export default ReviewModal;
