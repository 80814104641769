import styled from '@emotion/styled/macro';

// TODO: test min-height 100vh on different screens
export const Wrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  position: relative;
  margin: 0 auto;

  min-height: 100vh;
`;
