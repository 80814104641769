import React, { useMemo } from 'react';
// libs
import { Outlet, Navigate, useParams } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import atLocale from 'date-fns/locale/de-AT';
import nlLocale from 'date-fns/locale/nl';
import enLocale from 'date-fns/locale/en-GB';
// store
import { useAppDispatch } from 'store';
import { setLocale, uiLocaleSelector } from 'store/ui';
// other
import localization, { Languages } from 'localization';
// styled

type RouteParams = {
  locale: keyof typeof Languages;
};

const dateFnsLocaleMap = {
  en: enLocale,
  nl: nlLocale,
  'de-at': atLocale,
};

const LangRoute = () => {
  const dispatch = useAppDispatch();
  const { locale: localeFromParams } = useParams<RouteParams>();

  const localeFromStore = useSelector(uiLocaleSelector);

  const shouldRedirect = useMemo(() => !localeFromParams || !Languages[localeFromParams], [localeFromParams]);

  const { locale, messageLocale } = useMemo(() => {
    if (localeFromParams && Languages[localeFromParams]) {
      dispatch(setLocale(localeFromParams));

      if (localeFromParams === 'at') {
        return { locale: 'de-at', messageLocale: localeFromParams };
      }

      return { locale: localeFromParams, messageLocale: localeFromParams };
    }

    return { locale: localeFromStore, messageLocale: localeFromStore };
  }, [dispatch, localeFromParams, localeFromStore]);

  return (
    <IntlProvider locale={locale} defaultLocale={localeFromStore} messages={localization[messageLocale]}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={dateFnsLocaleMap[locale as keyof typeof dateFnsLocaleMap]}
      >
        <Outlet />

        {shouldRedirect && <Navigate to={`/${localeFromStore}/marketplace`} replace />}
      </LocalizationProvider>
    </IntlProvider>
  );
};

export default LangRoute;
