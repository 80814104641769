import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { http } from 'utils/http';
import { RootState } from '../index';
import { CreateListingPayload, Listing } from '../types';
import { SLICE_NAMES } from '../constants';

type ListingState = {
  meta: {
    isLoading: boolean;
    isLoaded: boolean;
  };
  listings: Listing[];
  listing: Listing;
};

const initialState: ListingState = {
  meta: { isLoading: false, isLoaded: false },
  listings: [],
  listing: {} as Listing,
};

export const createListing = createAsyncThunk(
  'listing/create',
  async (payload: Omit<CreateListingPayload, 'dateRange'>) => {
    return http
      .post<Listing>('listings', payload)
      .then(({ data }) => {
        return data as Listing;
      })
      .catch((error) => {
        return error;
      });
  },
);

export const updateListing = createAsyncThunk(
  'listing/update',
  async ({ submitValues, listingId }: { submitValues: Omit<CreateListingPayload, 'dateRange'>; listingId: string }) => {
    return http
      .put<Listing>(`listings/${listingId}`, submitValues)
      .then(({ data }) => {
        return data as Listing;
      })
      .catch((error) => {
        return error;
      });
  },
);

export const removeListing = createAsyncThunk('listing/update', async (listingId: string | number) => {
  return http
    .delete(`listings/${listingId}`)
    .then(({ data }) => {
      return data as Listing;
    })
    .catch((error) => {
      return error;
    });
});

export const fetchListing = createAsyncThunk('listing/fetch', async (id: string) => {
  return http.get<Listing>(`listings/${id}`);
});

const listingSlice = createSlice({
  name: SLICE_NAMES.LISTING,
  initialState,
  reducers: {
    setInitialItemMeta: (state) => {
      state.meta = initialState.meta;
    },
  },
  extraReducers: {
    [createListing.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [createListing.fulfilled.type]: (state) => {
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [createListing.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },

    [fetchListing.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [fetchListing.fulfilled.type]: (state, { payload }) => {
      const { data } = payload;
      state.listing = data;
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [fetchListing.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },
    // remove listing
    [removeListing.pending.type]: (state) => {
      state.meta = {
        isLoading: true,
        isLoaded: false,
      };
    },
    [removeListing.fulfilled.type]: (state) => {
      state.meta = {
        isLoading: false,
        isLoaded: true,
      };
    },
    [removeListing.rejected.type]: (state) => {
      state.meta = initialState.meta;
    },
  },
});

const listingStateSelector = (state: RootState): ListingState => state[SLICE_NAMES.LISTING];
export const listingMetaSelector = createSelector(listingStateSelector, (listing) => listing.meta);
export const listingSelector = createSelector(listingStateSelector, (listing) => listing.listing);

export const { setInitialItemMeta } = listingSlice.actions;

export default listingSlice.reducer;
