import styled from '@emotion/styled/macro';
import { MenuItem, TextField } from '@mui/material';

export const SelectLabel = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  margin-bottom: 10px;
`;

export const SelectContainer = styled.label`
  display: flex;
  flex-direction: column;
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const StyledSelect = styled(TextField)`
  & .MuiSelect-select {
    font-size: 16px;
    color: ${({ theme }) => theme.palette.common.blackSpace};
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  }

  &:hover .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.common.blackSpace};
  }

  & .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
    color: ${({ theme }) => theme.palette.common.blackSpace};
  }
`;
