import React, { useEffect, useState } from 'react';
import { useFilterContext } from '../FilterProvider';
import { FILTERS } from 'constants/filters';

const useFilters = () => {
  const { searchParams, searchParamsObj, handleFilterValuesChange } = useFilterContext();
  const [searchName, setSearchName] = useState('');
  const [priceMin, setPriceMin] = useState('');
  const [priceMax, setPriceMax] = useState('');

  const handleFilterCheckboxChange = (filterName: string, filterValues: string[]) =>
    handleFilterValuesChange(filterName, filterValues);

  const handleFilterNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
    handleFilterValuesChange(event.target.name, event.target.value);
  };

  const handleFilterPriceMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriceMin(event.target.value);
    handleFilterValuesChange(event.target.name, event.target.value);
  };

  const handleFilterPriceMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriceMax(event.target.value);
    handleFilterValuesChange(event.target.name, event.target.value);
  };

  useEffect(() => {
    if (searchParamsObj?.[FILTERS.name]) {
      setSearchName(searchParamsObj?.[FILTERS.name]);
    }

    if (searchParamsObj?.[FILTERS.priceMin]) {
      setPriceMin(searchParamsObj?.[FILTERS.priceMin]);
    }

    if (searchParamsObj?.[FILTERS.priceMax]) {
      setPriceMax(searchParamsObj?.[FILTERS.priceMax]);
    }
  }, [searchParamsObj]);

  useEffect(() => {
    if (!searchParamsObj?.[FILTERS.name]) {
      setSearchName('');
    }

    if (!searchParamsObj?.[FILTERS.priceMin]) {
      setPriceMin('');
    }

    if (!searchParamsObj?.[FILTERS.priceMax]) {
      setPriceMax('');
    }
  }, [searchParamsObj, searchParamsObj.name]);

  return {
    values: {
      searchParams,
      searchParamsObj,
      searchName,
      priceMin,
      priceMax,
    },
    handlers: {
      handleFilterCheckboxChange,
      handleFilterNameChange,
      handleFilterPriceMinChange,
      handleFilterPriceMaxChange,
    },
  };
};

export default useFilters;
