// styled
import styled from '@emotion/styled/macro';
import { FilterTitleWrapper } from '../styled';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.common.whiteOut};

  ${({ theme }) => theme.media.md`
    & ${FilterTitleWrapper} {
      display: flex;
    }

    & .MuiAccordion-root {
      display: block;
    }
  `}
`;

export const CloseButton = styled.button`
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  padding: 12px 14px;
  margin-top: 15px;
  border-radius: 3px;
  font-family: 'VisbyRoundCF SemiBold', Arial, sans-serif;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.common.brescianBlue};
`;
