import { IntlKeys } from 'localization/keys';

export const DATE_RANGE_OPTIONS = [
  {
    value: '3',
    label: IntlKeys.listingDurationModalDateRangeThreeMonths,
  },
  {
    value: '6',
    label: IntlKeys.listingDurationModalDateRangeSixMonths,
  },
  {
    value: '9',
    label: IntlKeys.listingDurationModalDateRangeNineMonths,
  },
  {
    value: '12',
    label: IntlKeys.listingDurationModalDateRangeTwelveMonths,
  },
  {
    value: 'custom',
    label: IntlKeys.listingDurationModalDateRangeCustom,
  },
];
