import React from 'react';
// libs
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
// store
import { authAccessTokenSelector } from 'store/auth';
import { uiLocaleSelector } from 'store/ui';

export interface AuthRouteProps {}

function AuthRoute() {
  const accessToken = useSelector(authAccessTokenSelector);
  const localeFromStore = useSelector(uiLocaleSelector);

  if (!accessToken) {
    return <Navigate to={`/${localeFromStore}/login`} replace />;
  }

  return <Outlet />;
}

export default AuthRoute;
