import styled from '@emotion/styled/macro';
// material
import { Checkbox, FormControlLabel } from '@mui/material';
// other
import { focusVisibleStyles } from 'components/styled/common';

export const Input = styled(Checkbox)`
  display: none;
`;

export const Label = styled(FormControlLabel)`
  margin: 0;

  & .MuiTypography-root {
    display: inline-block;
    border-radius: 2px;
    padding: 8px;
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    background-color: rgba(222, 227, 230, 0.5);
    color: ${({ theme }) => theme.palette.common.blackSpace};
  }

  & .Mui-checked + .MuiTypography-root {
    background-color: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &:focus-visible {
    ${({ theme }) => focusVisibleStyles(theme)}
  }
`;

export const Wrapper = styled.div<{ isPassive: boolean }>`
  pointer-events: ${({ isPassive }) => (isPassive ? 'none' : 'auto')};
`;
