import React from 'react';
// styled
import { Wrapper, Image, CategoryNameWrapper, CategoryName } from './styled';

export interface CategoryCardProps {
  image: string;
  categoryName: string;
  onClick: () => void;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ image, categoryName, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Image src={image} />
      <CategoryNameWrapper>
        <CategoryName>{categoryName}</CategoryName>
      </CategoryNameWrapper>
    </Wrapper>
  );
};

export default CategoryCard;
