import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.sm`
    align-items: center;
  `}
`;

export const ContentTitle = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  margin-bottom: 16px;
`;

export const ContentDescription = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-bottom: 16px;
`;

export const BuyBitcoinTitle = styled(ContentTitle)``;

export const BuyBitcoinDescription = styled(ContentDescription)``;

export const ChartTitle = styled(ContentTitle)``;

export const ChartContainer = styled.div`
  width: 100%;
  height: 351px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-bottom: 16px;
  border-radius: 2px;
  position: relative;
`;

export const BitcoinEarnTitle = styled(ContentTitle)``;

export const BitcoinEarnDescription = styled(ContentDescription)`
  margin-bottom: 30px;
`;

export const BitcoinEarnContainer = styled.div`
  display: flex;
  column-gap: 40px;
  @media (min-width: 991px) and (max-width: 1422px) {
    flex-direction: column;
    column-gap: 0;
    row-gap: 16px;
    width: 100%;
  }
  @media (max-width: 856px) {
    flex-direction: column;
    column-gap: 0;
    row-gap: 16px;
    width: 100%;
  }
`;

export const AboutCoinCardWrapper = styled.div`
  width: 253px;
  @media (min-width: 991px) and (max-width: 1422px) {
    width: 100%;
  }
  @media (max-width: 856px) {
    width: 100%;
  }
`;

export const OverBitcoinTitle = styled(ContentTitle)`
  margin-top: 40px;
`;

export const OverBitcoinDescription = styled(ContentDescription)`
  margin-bottom: 30px;
`;

export const TableTitle = styled(ContentTitle)``;

export const TableDescription = styled(ContentDescription)``;

export const TableDescriptionLink = styled.a`
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
`;

export const PopularAssetsTableWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 24px;
  padding-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  border-radius: 2px;
  justify-self: center;
  ${({ theme }) => theme.media.sm`
    align-self: center;
  `}
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  width: 100%;
  margin-bottom: 40px;
  @media (min-width: 991px) and (max-width: 1180px) {
    gap: 0;
    row-gap: 16px;
  }
  @media (max-width: 768px) {
    gap: 0;
    row-gap: 16px;
  }
`;

export const CoinsCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  width: 100%;
  margin-bottom: 40px;
  @media (min-width: 991px) and (max-width: 1180px) {
    gap: 0;
    row-gap: 16px;
  }
  @media (max-width: 768px) {
    gap: 0;
    row-gap: 16px;
  }
`;

export const StartStakingButton = styled(Button)`
  height: 51px;
  width: 100%;
  margin-top: 40px;
`;
