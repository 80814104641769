import styled from '@emotion/styled/macro';
// material-ui
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: fit-content;
  background-color: ${({ theme }) => theme.palette.common.whiteOut};
`;

export const Container = styled.div`
  max-width: 1440px;
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  padding: 130px 150px 130px 200px;

  ${({ theme }) => theme.media.xl`
    padding: 130px 120px 130px 140px;
  `}

  ${({ theme }) => theme.media.lg`
    padding: 56px 16px 80px;
  `}

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    padding: 56px 16px 80px;
  `}
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 40px;

  ${({ theme }) => theme.media.md`
     width: 560px;
     align-self: center;
     margin-left: 8px;
  `}

  ${({ theme }) => theme.media.xs`
    width: 100%;
  `}
`;

export const RightContainer = styled.div`
  width: 560px;
  height: 400px;
  display: flex;
  justify-content: flex-end;
  border-radius: 10px;

  ${({ theme }) => theme.media.md`
    width: 100%;
    justify-content: center;
  `}

  ${({ theme }) => theme.media.sm`
    height: 340px;
  `}

  ${({ theme }) => theme.media.xs`
    height: 280px;
  `}
`;

export const Title = styled.div`
  font-size: 60px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 72px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};

  ${({ theme }) => theme.media.sm`
    font-size: 40px;
    line-height: 48px;
  `}
`;

export const Text = styled.div`
  max-width: 464px;
  font-size: 24px;
  font-family: 'VisbyRoundCF Medium', sans-serif;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.common.blackSpace};

  ${({ theme }) => theme.media.sm`
    font-size: 20px;
    line-height: 24px;
  `}
`;

export const StyledButton = styled(Button)`
  max-width: 162px;
  width: 162px;
  height: 44px;
  transition: all 400ms ease;
  position: relative;
  font-size: 16px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.brescianBlue};
    transform: translateY(-8px);
    box-shadow: 0 8px 16px 0 #0062bf66;
    cursor: pointer;
  }
`;
