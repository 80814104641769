import React from 'react';
import useFetchMarketplaceThumbnail from '../hooks/useFetchNftItemThumbnail';

export interface WithThumbnailProps {
  thumbnailProps: {
    itemId: string | number | undefined;
    thumbnailType: 'list' | 'show';
    isMarketplaceItem: boolean;
  };
}

const withThumbnail = <T extends object>(Component: React.ComponentType<T>) => {
  return (hocProps: Omit<T, 'image' | 'isLoading'> & WithThumbnailProps) => {
    const { itemId, thumbnailType, isMarketplaceItem } = hocProps.thumbnailProps;
    const { image, isLoading } = useFetchMarketplaceThumbnail({ itemId, thumbnailType, isMarketplaceItem });
    return <Component {...(hocProps as T)} image={image} isLoading={isLoading} />;
  };
};

export default withThumbnail;
