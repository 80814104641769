import React from 'react';
import styled from '@emotion/styled/macro';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@mui/material';

export const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.blackSpace};

  &:before {
    display: none;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 12px 8px;

  & .MuiAccordionSummary-content {
    margin: 0;
    font-size: 20px;
    line-height: 24px;
    font-family: 'VisbyRoundCF SemiBold';
    color: ${({ theme }) => theme.palette.common.blackSpace};
  }

  ${({ theme }) => theme.media.sm`
    font-size: 16px;
    line-height: 19.2px;
  `}
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 16px 8px;
  font-size: 20px;
  line-height: 24px;
  font-family: 'VisbyRoundCF Medium';
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.common.blackSpace};

  ${({ theme }) => theme.media.sm`
    font-size: 16px;
    line-height: 19.2px;
  `}
`;
