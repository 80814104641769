import React, { useMemo } from 'react';
// components
import PortfolioChart from '../components/PortfolioChart';
import InfoCard from '../components/InfoCard';
// providers
import { DashboardState, useDashboardContext } from 'providers/DashboardProvider';
// icons
import { ReactComponent as StackIcon } from 'assets/icons/stack.svg';
import { ReactComponent as SignalIcon } from 'assets/icons/signal.svg';
import { ReactComponent as StakingRewardIcon } from 'assets/icons/staking-reward.svg';
import { ReactComponent as BotIcon } from 'assets/icons/bot.svg';
// styled
import { ChartContainer, Container, InfoCardsContainer, Wrapper } from './styled';
import { ButtonProps } from '@mui/material';

const RightColumn: React.FC = () => {
  const { dashboardState } = useDashboardContext();

  const infoCards = useMemo(
    () => [
      {
        id: '1',
        icon: <StackIcon />,
        title: 'Crypto',
        buttonTitle: dashboardState === DashboardState.Verified ? 'Crypto kopen' : 'Bekijk alle coins',
        amount: dashboardState === DashboardState.InUse ? '14.306,02' : undefined,
        buttonColor: dashboardState === DashboardState.Verified && 'primary',
      },
      {
        id: '2',
        icon: <SignalIcon />,
        title: 'Earn',
        buttonTitle: 'Ga naar Earn',
        tooltipText: 'Lorem ipsum',
        amount: dashboardState === DashboardState.InUse ? '7.000,50' : undefined,
      },
      {
        id: '3',
        icon: <StakingRewardIcon />,
        title: 'Staking',
        buttonTitle: 'Ga naar Staking',
        tooltipText: 'Lorem ipsum',
        amount: dashboardState === DashboardState.InUse ? '10,40' : undefined,
      },
      { id: '4', icon: <BotIcon />, title: 'Bots', buttonTitle: 'Ga naar Bots', tooltipText: 'Lorem ipsum' },
    ],
    [dashboardState],
  );

  return (
    <Wrapper>
      <Container>
        <ChartContainer>
          <PortfolioChart />
        </ChartContainer>
        <InfoCardsContainer>
          {infoCards.map(({ icon, title, buttonTitle, tooltipText, id, buttonColor, amount }) => (
            <InfoCard
              icon={icon}
              title={title}
              buttonTitle={buttonTitle}
              tooltipText={tooltipText}
              key={id}
              buttonColor={buttonColor ? (buttonColor as ButtonProps['color']) : undefined}
              amount={amount}
            />
          ))}
        </InfoCardsContainer>
      </Container>
    </Wrapper>
  );
};

export default RightColumn;
