// libs
import { Link } from 'react-router-dom';
// components
import FormTextField from 'components/formik/FormTextField';
import Img from 'components/Img';
// styled
import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 99px 16px 80px;

  ${({ theme }) => theme.media.sm`
    padding: 24px 8px 48px;
    background-color: ${theme.palette.common.white};
  `}
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1048px;
  padding: 4px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  background-color: ${({ theme }) => theme.palette.common.white};

  & > div:first-of-type {
    max-width: 400px;
  }

  ${({ theme }) => theme.media.sm`
    padding: 0;
    border: 0;
    flex-direction: column;
    
    & > div:first-of-type {
      max-width: 100%;
    }
  `}
`;

export const Image = styled(Img)`
  width: 360px;
  height: 360px;
  margin-bottom: 28px;
  object-fit: cover;

  ${({ theme }) => theme.media.sm`
    width: 100%;
    height: 480px;
    border-radius: 10px;
    margin-bottom: 24px;
    filter: drop-shadow(0px 8px 20px rgba(0, 35, 68, 0.1));
  `}

  ${({ theme }) => theme.media.xs`
    height: auto;
  `}
`;

export const ImagePlaceholder = styled.div`
  width: 360px;
  height: 360px;
  margin-bottom: 28px;

  ${({ theme }) => theme.media.sm`
    width: 100%;
    height: 480px;
    border-radius: 10px;
    margin-bottom: 24px;
    filter: drop-shadow(0px 8px 20px rgba(0, 35, 68, 0.1));
  `}

  ${({ theme }) => theme.media.xs`
    height: auto;
  `}
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const TagsTitle = styled(Title)``;

export const DescriptionTitle = styled(Title)`
  margin-top: 40px;
`;

export const LinkTitle = styled(Title)`
  margin-top: 8px;
`;

export const DescriptionText = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF Regular', sans-serif;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const ItemName = styled.div`
  font-size: 24px;
  line-height: 29px;
  padding: 7px 8px 7px 0;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const ChipsWrapper = styled.div`
  margin-top: 20px;
`;

export const PriceFieldLabel = styled.label`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 4px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};

  ${({ theme }) => theme.media.sm`
    margin-top: 32px;
  `}
`;

export const OwnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OwnerText = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-family: 'VisbyRoundCF Regular', Arial, sans-serif;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
  padding: 12px 8px;
`;

export const DurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  width: 100%;
`;

export const DurationFieldLabel = styled.div`
  font-size: 21px;
  line-height: 25px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  margin-top: 48px;
  gap: 8px;

  ${({ theme }) => theme.media.sm`
    padding: 0 8px;
  `}
`;

export const UserNameFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
`;

export const UserNameFieldLabel = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
  margin-bottom: 8px;

  ${({ theme }) => theme.media.sm`
    padding: 0 8px;
  `}
`;

export const FeesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 48px;
  gap: 8px;
`;

export const FeesTitle = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const FeeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FeeText = styled.div`
  font-size: 16px;
`;

export const CompleteButtonWrapper = styled.div`
  width: 272px;
  margin-top: 52px;
  margin-bottom: 40px;
  //min-height: 100vh;

  ${({ theme }) => theme.media.sm`
    width: 100%;
  `}
`;

export const ContentWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;

  &:not(:first-of-type) {
    width: 100%;
  }

  & > a:last-of-type {
    padding: 0 8px;
  }

  ${({ theme }) => theme.media.sm`
    padding: 16px;
  `}
`;

export const NavLink = styled(Link)`
  padding: 0 2px !important;
  line-height: 19px;
  text-decoration: underline;
  font-family: 'VisbyRoundCF Regular', Arial, sans-serif;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
`;

export const ExternalLink = styled.a`
  line-height: 19px;
  text-decoration: underline;
  font-family: 'VisbyRoundCF Regular', Arial, sans-serif;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  word-break: break-all;
  padding: 0 !important;
`;

export const PriceField = styled(FormTextField)`
  & .MuiOutlinedInput-root .MuiInputAdornment-root .MuiTypography-root {
    font-size: 28px;
  }
  & .MuiOutlinedInput-input {
    text-align: left;
  }

  ${({ theme }) => theme.media.sm`
    max-width: 100%;
  `}
`;

export const PriceLabelInfo = styled.span`
  font-size: 12px;
  font-family: 'VisbyRoundCF Regular', sans-serif;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const PriceFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 42px 0;
  gap: 12px;
`;
