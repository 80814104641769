import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// libs
import qs from 'qs';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { isArray } from 'lodash';
// constants
import { FILTERS } from 'constants/filters';

export interface IFilterContext {
  paramsString: string;
  searchParamsObj: Record<string, string>;
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?: { replace?: boolean | undefined; state?: any } | undefined,
  ) => void;
  handleFilterValuesChange: (
    filterName: string,
    filterValues: string[] | string,
    filterNamesToClear?: string[],
  ) => void;
  clearGivenFilters: (filterNamesToClear: string[]) => void;
}

export interface FilterProviderProps {
  children: React.ReactNode;
}

const FilterContext = React.createContext<IFilterContext>(null as unknown as IFilterContext);

export const useFilterContext = () => useContext(FilterContext);

const arrayFilters = [FILTERS.itemTypes, FILTERS.categories, FILTERS.statuses];

function FilterProvider({ children }: FilterProviderProps) {
  const [searchParams, setSearchParams] = useSearchParams({});

  const [searchParamsObj, setSearchParamsObj] = useState({});

  const paramsString = useMemo(() => qs.stringify(searchParamsObj), [searchParamsObj]);

  const searchParamsToFilterObj = useCallback(() => {
    const filterObject = {} as Record<string, string[] | string>;

    searchParams.forEach((value, key) => {
      if (arrayFilters.includes(key)) {
        filterObject[key] = [...(isArray(filterObject[key]) ? filterObject[key] : []), value];
      } else {
        filterObject[key] = value;
      }
    });

    return filterObject;
  }, [searchParams]);

  const handleFilterValuesChange = useCallback(
    (filterName: string, filterValues: string[] | string, filterNamesToClear: string[] | undefined) => {
      const filterObject = searchParamsToFilterObj();

      if (filterNamesToClear) {
        filterNamesToClear.forEach((name) => {
          filterObject[name] = [];
        });
      }

      if (filterValues) {
        return setSearchParams({ ...filterObject, [filterName]: filterValues });
      }

      if (!filterValues) {
        return setSearchParams({ ...filterObject, [filterName]: [] });
      }
    },
    [searchParamsToFilterObj, setSearchParams],
  );

  const clearGivenFilters = useCallback(
    (filterNamesToClear: string[]) => {
      const filterObject = searchParamsToFilterObj();

      filterNamesToClear.forEach((name) => {
        filterObject[name] = [];
      });

      setSearchParams(filterObject);
    },
    [searchParamsToFilterObj, setSearchParams],
  );

  useEffect(() => {
    setSearchParamsObj(searchParamsToFilterObj());
  }, [searchParamsToFilterObj, searchParams]);

  return (
    <FilterContext.Provider
      value={{
        searchParams,
        setSearchParams,
        handleFilterValuesChange,
        paramsString,
        searchParamsObj,
        clearGivenFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export default FilterProvider;
