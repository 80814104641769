import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  padding: 40px;
  width: 560px;
  min-height: 382px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 40px 24px;
  }
`;

export const Title = styled.div`
  font-size: 36px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 60px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 40px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CreateButtonWrapper = styled.div`
  width: 272px;
  margin-top: 60px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;
