import React from 'react';
// icons
import { ReactComponent as POSIcon } from 'assets/icons/proof-of-stake.svg';
import { ReactComponent as BotsIcon } from 'assets/icons/bots.svg';
import { ReactComponent as BeginnerIcon } from 'assets/icons/beginner.svg';
import { ReactComponent as AdvancedIcon } from 'assets/icons/advanced.svg';
import { ReactComponent as AboutIcon } from 'assets/icons/about.svg';
import { ReactComponent as NewsIcon } from 'assets/icons/news.svg';
import { ReactComponent as LearnIcon } from 'assets/icons/learn.svg';
// other
import { IntlKeys } from 'localization/keys';

export type ListItemsType = {
  title: IntlKeys;
  description: IntlKeys;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  link: string;
}[];

export const PRODUCTS_ITEMS = [
  {
    title: IntlKeys.headerExplorePopoverProductItemsProofTitle,
    description: IntlKeys.headerExplorePopoverProductItemsProofDescription,
    icon: POSIcon,
    link: 'proof-of-stake',
  },
  // TODO: add small bot icon when it will be available
  {
    title: IntlKeys.headerExplorePopoverProductItemsBotsTitle,
    description: IntlKeys.headerExplorePopoverProductItemsBotsDescription,
    icon: BotsIcon,
    link: 'coinmerce-bots',
  },
  {
    title: IntlKeys.headerExplorePopoverProductItemsBeginnersTitle,
    description: IntlKeys.headerExplorePopoverProductItemsBeginnersDescription,
    icon: BeginnerIcon,
    link: 'beginner',
  },
  {
    title: IntlKeys.headerExplorePopoverProductItemsAdvancedTitle,
    description: IntlKeys.headerExplorePopoverProductItemsAdvancedDescription,
    icon: AdvancedIcon,
    link: 'advanced',
  },
  {
    title: IntlKeys.headerExplorePopoverProductItemsGiftcardsTitle,
    description: IntlKeys.headerExplorePopoverProductItemsGiftcardsDescription,
    icon: AboutIcon,
    link: 'giftcards',
  },
] as ListItemsType;

export const ABOUT_ITEMS = [
  {
    title: IntlKeys.headerExplorePopoverAboutItemsAboutTitle,
    description: IntlKeys.headerExplorePopoverAboutItemsAboutDescription,
    icon: AboutIcon,
    link: 'about-us',
  },
  // TODO: there is no appropriate icon for the item, change it when you have appropriate one
  {
    title: IntlKeys.headerExplorePopoverAboutItemsCryptoTitle,
    description: IntlKeys.headerExplorePopoverAboutItemsCryptoDescription,
    icon: BotsIcon,
    link: 'store',
  },
  {
    title: IntlKeys.headerExplorePopoverAboutItemsNewsTitle,
    description: IntlKeys.headerExplorePopoverAboutItemsNewsDescription,
    icon: NewsIcon,
    link: 'news',
  },
  {
    title: IntlKeys.headerExplorePopoverAboutItemsLearnTitle,
    description: IntlKeys.headerExplorePopoverAboutItemsLearnDescription,
    icon: LearnIcon,
    link: 'learn',
  },
  {
    title: IntlKeys.headerExplorePopoverAboutItemsHelpTitle,
    description: IntlKeys.headerExplorePopoverAboutItemsHelpDescription,
    icon: LearnIcon,
    link: 'need-help',
  },
  // TODO: there is no appropriate icon for the item, change it when you have appropriate one
  {
    title: IntlKeys.headerExplorePopoverAboutItemsVacanciesDescription,
    description: IntlKeys.headerExplorePopoverAboutItemsVacanciesDescription,
    icon: BotsIcon,
    link: 'vacancies',
  },
] as ListItemsType;
