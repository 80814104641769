import React from 'react';
// libs
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
// components
import CategoryCard from './CategoryCard';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { FILTERS } from 'constants/filters';
import { IntlKeys } from 'localization/keys';
import { CATEGORIES } from './constants';
// styled
import { CardsContainer, Container, Title, Wrapper } from './styled';

const CategoriesSection: React.FC = () => {
  const navigate = useNavigate();
  const locale = useSelector(uiLocaleSelector);

  const { formatMessage } = useIntl();

  const handleCategoryCardClick = (value: string) => () => {
    const categoryParam = { [FILTERS.categories]: value };
    const stringifiedParam = qs.stringify(categoryParam);
    navigate(`/${locale}/marketplace?${stringifiedParam}`);
  };

  return (
    <Wrapper>
      <Container>
        <Title>{formatMessage({ id: IntlKeys.launchpadNavigateCategories })}</Title>
        <CardsContainer>
          {CATEGORIES.map(({ image, name, value, id }) => (
            <CategoryCard
              image={image}
              categoryName={formatMessage({ id: name })}
              key={id}
              onClick={handleCategoryCardClick(value)}
            />
          ))}
        </CardsContainer>
      </Container>
    </Wrapper>
  );
};

export default CategoriesSection;
