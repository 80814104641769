import { IntlKeys } from '../keys';

export const at = {
  // common
  [IntlKeys.commonClose]: 'Close',
  [IntlKeys.commonLorem]: 'Lorem ipsum',
  [IntlKeys.commonAccept]: 'Accept',
  [IntlKeys.commonCancel]: 'Cancel',
  [IntlKeys.commonCreate]: 'Create',

  // header
  [IntlKeys.headerHome]: 'Home',
  [IntlKeys.headerCoins]: 'Coins',
  [IntlKeys.headerNfts]: 'NFTs',
  [IntlKeys.headerWallets]: 'Wallets',
  [IntlKeys.headerOrders]: 'Orders',
  [IntlKeys.headerExplore]: 'Erkunden',
  [IntlKeys.headerExplorePopoverTitle]: 'Eine All-in-One-Plattform für die Zukunft des Geldes',
  [IntlKeys.headerExplorePopoverProducts]: 'Produkte',
  [IntlKeys.headerExplorePopoverAbout]: 'Über',
  [IntlKeys.headerExplorePopoverProductItemsProofTitle]: 'Proof of Stake',
  [IntlKeys.headerExplorePopoverProductItemsBotsTitle]: 'COINMERCE Bots',
  [IntlKeys.headerExplorePopoverProductItemsBeginnersTitle]: 'Trading für Anfänger',
  [IntlKeys.headerExplorePopoverProductItemsAdvancedTitle]: 'Erweiterte Tools',
  [IntlKeys.headerExplorePopoverProductItemsGiftcardsTitle]: 'Geschenkkarten',
  [IntlKeys.headerExplorePopoverProductItemsProofDescription]: 'Erweitern Sie Ihre Bestände',
  [IntlKeys.headerExplorePopoverProductItemsBotsDescription]: 'KI-unterstützte Algorithmen',
  [IntlKeys.headerExplorePopoverProductItemsBeginnersDescription]: 'Beginnen Sie mit Bitcoin und Krypto',
  [IntlKeys.headerExplorePopoverProductItemsAdvancedDescription]: 'Limits, wiederkehrende Bestellungen, mehr',
  [IntlKeys.headerExplorePopoverProductItemsGiftcardsDescription]: 'Krypto verschenken!',
  [IntlKeys.headerExplorePopoverAboutItemsAboutTitle]: 'Über Coinmerce',
  [IntlKeys.headerExplorePopoverAboutItemsCryptoTitle]: 'Crypto Information Center',
  [IntlKeys.headerExplorePopoverAboutItemsNewsTitle]: 'Nachrichten',
  [IntlKeys.headerExplorePopoverAboutItemsLearnTitle]: 'Lernen',
  [IntlKeys.headerExplorePopoverAboutItemsHelpTitle]: 'Hilfe?',
  [IntlKeys.headerExplorePopoverAboutItemsVacanciesTitle]: 'Stellenangebote',
  [IntlKeys.headerExplorePopoverAboutItemsAboutDescription]: 'Team, Werte, Ziele',
  [IntlKeys.headerExplorePopoverAboutItemsCryptoDescription]: 'Unser Krypto-Shop',
  [IntlKeys.headerExplorePopoverAboutItemsNewsDescription]: 'Das Neueste über Krypto',
  [IntlKeys.headerExplorePopoverAboutItemsLearnDescription]: 'Tutorials und Tipps',
  [IntlKeys.headerExplorePopoverAboutItemsHelpDescription]: 'Support und Kontakt',
  [IntlKeys.headerExplorePopoverAboutItemsVacanciesDescription]: 'Verfügbare Berufe',
  [IntlKeys.headerAccountPopoverTitle]: 'Mein Account',
  [IntlKeys.headerAccountPopoverProfile]: 'Profil',
  [IntlKeys.headerAccountPopoverAccountLevel]: 'Kontoebene',
  [IntlKeys.headerAccountPopoverSecurity]: 'Sicherheit',
  [IntlKeys.headerAccountPopoverSettings]: 'Einstellungen',
  [IntlKeys.headerAccountPopoverFriends]: 'Freunde',
  [IntlKeys.headerAccountPopoverWithdrawAddresses]: 'Opname addressen',
  [IntlKeys.headerAccountPopoverLogout]: 'Abmelden',
  [IntlKeys.headerNFTPopoverCreate]: 'Erstellen',
  [IntlKeys.headerNFTPopoverMarketplace]: 'Marktplatz',
  [IntlKeys.headerNFTPopoverMyCollections]: 'Meine Sammlung',
  [IntlKeys.headerNFTPopoverLaunchpad]: 'Launchpad',

  // footer
  [IntlKeys.footerLoginTitle]: 'Krypto ist jetzt einfach.',
  [IntlKeys.footerLoginButton]: 'Einloggen',
  [IntlKeys.footerSignupButton]: 'Anmeldung',
  [IntlKeys.footerListGeneral]: 'Allgemein',
  [IntlKeys.footerListGeneralAboutUs]: 'Über uns',
  [IntlKeys.footerListGeneralCareers]: 'Karriere',
  [IntlKeys.footerListGeneralCoins]: 'Münzen',
  [IntlKeys.footerListGeneralFraudPrevention]: 'Betrugsprävention',
  [IntlKeys.footerListGeneralBugFound]: 'Fehler gefunden?',
  [IntlKeys.footerListGeneralApi]: 'API',
  [IntlKeys.footerListGeneralSitemap]: 'Seitenverzeichnis',
  [IntlKeys.footerListPopular]: 'Beliebt',
  [IntlKeys.footerListPopularBuyBitcoin]: 'Bitcoin Kaufen',
  [IntlKeys.footerListPopularBitcoinPrice]: 'Bitcoin Preis',
  [IntlKeys.footerListPopularBuyEthereum]: 'Ethereum Kaufen',
  [IntlKeys.footerListPopularEthereumPrice]: 'Ethereum Preis',
  [IntlKeys.footerListPopularBuyRipple]: 'Rippel Kaufen',
  [IntlKeys.footerListPopularRipplePrice]: 'Ripple Preis',
  [IntlKeys.footerListPopularBuyLota]: 'lota kaufen',
  [IntlKeys.footerListPopularLotaPrice]: 'lota Preis',
  [IntlKeys.footerListLegal]: 'Gesetzlich',
  [IntlKeys.footerListLegalTermsAndConditions]: 'Geschäftsbedingungen',
  [IntlKeys.footerListLegalPrivacyPolicy]: 'Datenschutz Bestimmungen',
  [IntlKeys.footerListLegalRiskDisclaimer]: 'Risikohinweis',
  [IntlKeys.footerListJumpTo]: 'Springen zu',
  [IntlKeys.footerListJumpToRegister]: 'Registrieren',
  [IntlKeys.footerListJumpToLogin]: 'Anmeldung',
  [IntlKeys.footerListJumpToNeedHelp]: 'Brauchen Sie Hilfe?',
  [IntlKeys.footerListJumpToLearn]: 'Lernen',
  [IntlKeys.footerListJumpToNewsAndBlog]: 'Nachrichten & Blog',
  [IntlKeys.footerStore]: 'Speichern',
  [IntlKeys.footerOffice]: 'Sekretariat',

  // single coin view
  [IntlKeys.websiteSinglecoinBreadcrumbCoins]: 'Münzen',
  [IntlKeys.websiteSinglecoinIntro]: 'Kaufen Sie sicher {coin} mit SEPA, Sofort, iDEAL, Bancontact oder Kreditkarte über Coinmerce.',
  [IntlKeys.websiteSinglecoinUsp1]: 'Kaufen Sie 200+ Kryptowährungen sicher',
  [IntlKeys.websiteSinglecoinUsp2]: 'Europäische Plattform 2017 eingerichtet',
  [IntlKeys.websiteSinglecoinUsp3]: 'Von 100.000+ Nutzern als vertrauenswürdig bewertet',
  [IntlKeys.websiteSinglecoinUsp4]: 'Hilfsbereiter Support',
  [IntlKeys.websiteSinglecoinH1]: 'Kaufen Sie {coin} mit iDEAL oder SEPA',
  [IntlKeys.websiteSinglecoinH2Price]: '{coin}-Preis',
  [IntlKeys.websiteSinglecoinH2PriceCoin]: '{coin} Preis in Euro',
  [IntlKeys.websiteSinglecoinH2PriceVolume]: '{coin} Volumen (24h)',
  [IntlKeys.websiteSinglecoinH2PriceMarketcap]: 'Marktkapitalisierung',
  [IntlKeys.websiteSinglecoinEarnH2]: 'Earn {coin}',
  [IntlKeys.websiteSinglecoinEarnRewards]: 'Gesch. jährliche Belohnung',
  [IntlKeys.websiteSinglecoinEarnMinimum]: 'Minimaler Bestand',
  [IntlKeys.websiteSinglecoinEarnFrequencyTitle]: 'Auszahlungshäufigkeit',
  [IntlKeys.websiteSinglecoinEarnFrequencyTime]: 'Wöchentlich',
  [IntlKeys.websiteSinglecoinEarnCalculateRewards]: 'Earn-Prämien berechnen',
  [IntlKeys.websiteSinglecoinEarn1Year]: '1 Jahr',
  [IntlKeys.websiteSinglecoinEarn2Year]: '2 Jahre',
  [IntlKeys.websiteSinglecoinEarn5Year]: '5 Jahre',
  [IntlKeys.websiteSinglecoinEarnAsterisk]: '*Belohnungen werden wöchentlich berechnet und die Prozentsätze sind nicht garantiert.',
  [IntlKeys.websiteSinglecoinEarnButton]: 'Earn jetzt aktivieren',
  [IntlKeys.websiteSinglecoinStakingH2]: '{amount} staken',
  [IntlKeys.websiteSinglecoinStakingRewards]: 'Gesch. jährliche Vergütung',
  [IntlKeys.websiteSinglecoinStakingMinimum]: 'Minimaler Bestand',
  [IntlKeys.websiteSinglecoinStakingFrequencyTitle]: 'Auszahlungshäufigkeit',
  [IntlKeys.websiteSinglecoinStakingFrequencyTime]: 'Wöchentlich',
  [IntlKeys.websiteSinglecoinStakingCalculateRewards]: 'Staking-Vergütungen berechnen',
  [IntlKeys.websiteSinglecoinStaking1Year]: '1 Jahr',
  [IntlKeys.websiteSinglecoinStaking2Year]: '2 Jahre',
  [IntlKeys.websiteSinglecoinStaking5Year]: '5 Jahre',
  [IntlKeys.websiteSinglecoinStakingAsterisk]: '*Belohnungen werden wöchentlich berechnet. Die Prozentsätze sind nicht garantiert.',
  [IntlKeys.websiteSinglecoinStakingButton]: 'Beginnen Sie jetzt mit flexiblem Staking',
  [IntlKeys.websiteSinglecoinAboutTitle]: 'Ungefähr {coin}',
  [IntlKeys.websiteSinglecoinAboutWhat]: 'Was ist {coin}?',
  [IntlKeys.websiteSinglecoinReadmore]: 'Weiterlesen',
  [IntlKeys.websiteSinglecoinAblutHow]: 'Wie funktioniert {coin}?',
  [IntlKeys.websiteSinglecoinAblutBuy]: 'Wie kaufe ich {coin}?',
  [IntlKeys.websiteSinglecoinPopularTitle]: 'Beliebte Vermögenswerte',
  [IntlKeys.websiteSinglecoinPopularDescription]: 'Die am meisten gehandelten Vermögenswerte auf der Coinmerce-Plattform. Alle {link} finden Sie hier.',
  [IntlKeys.websiteSinglecoinPopularDescriptionAllCrypto]: 'Kryptowährungen',
  [IntlKeys.websiteSinglecoinPopularAsset]: 'Aktivposten',
  [IntlKeys.websiteSinglecoinPopularBalance]: 'Kontostand',
  [IntlKeys.websiteSinglecoinPopularPrice]: 'Kurs',
  [IntlKeys.websiteSinglecoinPopularChange]: 'Veränderung',
  [IntlKeys.websiteSinglecoinPopularType]: 'Typ',
  [IntlKeys.websiteSinglecoinPopularActiveEarn]: 'Earn aktivieren',
  [IntlKeys.websiteSinglecoinPopularActiveStaking]: 'Staking aktivieren',
  [IntlKeys.websiteSinglecoinPopularBuy]: 'Kaufen',
  [IntlKeys.websiteSinglecoinPopularSell]: 'Verkaufen',
  [IntlKeys.websiteSinglecoinPopularDeposit]: 'Einzahlen',
  [IntlKeys.websiteSinglecoinPopularWithdraw]: 'Abheben',
  [IntlKeys.websiteSinglecoinPopularViewAllCrypto]: 'Alle Kryptowährungen anzeigen',
  [IntlKeys.websiteSinglecoinBuyButtonMobile]: 'View all cryptocurrencies',
  [IntlKeys.websiteSinglecoinBuyItem]: 'Kaufen',
  [IntlKeys.websiteSinglecoinSellItem]: 'Verkaufen',
  [IntlKeys.websiteSinglecoinBuyButton]: 'Jetzt kaufen',
  [IntlKeys.websiteSinglecoinSellButton]: 'Jetzt verkaufen',
  [IntlKeys.websiteSinglecoinBuyButtonCoin]: 'Kaufen Sie jetzt {coin}',
  [IntlKeys.websiteSinglecoinOrderLimit]: 'Order-Stopp-Limit',
  [IntlKeys.websiteSinglecoinRepeatOrder]: 'Bestellung wiederholen',
  [IntlKeys.websiteSinglecoinSwapItem]: 'Tauschen',
  [IntlKeys.websiteSinglecoinSwapButton]: 'Jetzt tauschen',

  // single bundle view
  [IntlKeys.websiteBundleviewBreadcrumbBundles]: 'Bündel',
  [IntlKeys.websiteBundleviewDescription]: 'Kaufen Sie {bundle}-Bündel ({bundleShort}) mit SEPA, Sofort, iDEAL, Bancontact oder Kreditkarte über Coinmerce.',
  [IntlKeys.websiteBundleviewBuyItem]: 'Kaufen',
  [IntlKeys.websiteBundleviewSellItem]: 'Verkaufen',
  [IntlKeys.websiteBundleviewBuyButton]: 'Jetzt kaufen',
  [IntlKeys.websiteBundleviewSellButton]: 'Jetzt verkaufen',
  [IntlKeys.websiteBundleviewBuyButtonMobile]: 'Kaufen Sie jetzt {bundle}',
  [IntlKeys.websiteBundleviewMoreButtonMobile]: 'Mehr',
  [IntlKeys.websiteBundleviewMax]: 'Max',
  [IntlKeys.websiteBundleviewUsp1]: 'Diversifiziert investieren',
  [IntlKeys.websiteBundleviewUsp2]: 'Automatisch angepasst',
  [IntlKeys.websiteBundleviewUsp3]: '4.2/5 basierend auf {link}',
  [IntlKeys.websiteBundleviewUsp3Link]: '350 Bewertungen',
  [IntlKeys.websiteBundleviewUsp4]: 'Von 100.000+ Nutzern als vertrauenswürdig bewertet',
  [IntlKeys.websiteBundleviewOtherBundlesTitle]: 'Weitere Bündel',
  [IntlKeys.websiteBundleviewOtherBundlesDescription]: 'Im Folgenden finden Sie eine Auswahl an verfügbaren Bündeln bei Coinmerce. Mit Bündeln reduzieren Sie Risiken durch Diversifizierung. Investieren Sie unkompliziert in eine Auswahl einer bestimmten Krypto-Kategorie.',
  [IntlKeys.websiteBundleviewOtherBundlesBundle]: 'Bündel',
  [IntlKeys.websiteBundleviewOtherBundlesChange]: 'Veränderung',
  [IntlKeys.websiteBundleviewOtherBundlesCoins]: 'Münzen im Bündel',
  [IntlKeys.websiteBundleviewOtherBundlesBuyButton]: 'Jetzt kaufen',
  [IntlKeys.websiteBundleviewOtherBundlesOtherCoins]: '+{amount} andere Münzen',
  [IntlKeys.websiteBundleviewH1]: '{bundle} Paketpreis',
  [IntlKeys.websiteBundleviewH2]: 'Bundles neu verteilen',

  // item details page
  [IntlKeys.itemDetailsTags]: 'Tags',
  [IntlKeys.itemDetailsDescription]: 'Description',
  [IntlKeys.itemDetailsLink]: 'Link',
  [IntlKeys.itemDetailsBuyNowPrice]: 'Buy Now Price',
  [IntlKeys.itemDetailsBuyNowPriceDescription]: '(when this amount is reached we will automatically notify the seller)',
  [IntlKeys.itemDetailsThisSaleEndsOn]: 'This sale ends on {value}',
  [IntlKeys.itemDetailsHighestBid]: 'Highest Bid',
  [IntlKeys.itemDetailsHighestBidHoorayMessage]: 'Hooray! Your bid is the highest bid!',
  [IntlKeys.itemDetailsThisItemIsNotListed]: 'This item is not listed',
  [IntlKeys.itemDetailsLastPriceSold]: 'Last Price Sold',
  [IntlKeys.itemDetailsEditSale]: 'Edit Sale',
  [IntlKeys.itemDetailsRemovePosition]: 'Remove Position',
  [IntlKeys.itemDetailsSell]: 'Sell',
  [IntlKeys.itemDetailsWithdraw]: 'Withdraw',
  [IntlKeys.itemDetailsAcceptHighestBid]: 'Accept Highest Bid',
  [IntlKeys.itemDetailsDeclineAllBids]: 'Decline All Bids',
  [IntlKeys.itemDetailsPlaceBid]: 'Place Bid',
  [IntlKeys.itemDetailsCancelBid]: 'Cancel Bid',
  [IntlKeys.itemDetailsBids]: 'Bids',
  [IntlKeys.itemDetailsYourBid]: 'Your Bid*',
  [IntlKeys.itemDetailsYourBidDescription]: '(this is the amount including fees that you will pay if your offer is accepted)',
  [IntlKeys.itemDetailsReviewOffer]: 'Review Offer',
  [IntlKeys.itemDetailsBidsTablePriceHeader]: 'Price',
  [IntlKeys.itemDetailsBidsTableDateHeader]: 'Date',
  [IntlKeys.itemDetailsBidsTableStatusHeader]: 'Status',
  [IntlKeys.itemDetailsRemovePositionModalTitle]: 'Remove Position',
  [IntlKeys.itemDetailsRemovePositionModalDescription]: 'This sale will be cancelled. The item will continue to be visible in your collection and you will be able to sell it again or withdraw it to an external wallet in the future.',
  [IntlKeys.itemDetailsRemovePositionModalButtonRemove]: 'Remove Position',
  [IntlKeys.itemDetailsRemovePositionModalButtonClose]: 'Close',
  [IntlKeys.itemDetailsReviewModalBuyNowPrice]: 'Buy Now Price',
  [IntlKeys.itemDetailsReviewModalYourBid]: 'Your Bid',
  [IntlKeys.itemDetailsReviewModalBalanceAfterBid]: 'Your Balance After the Bid',
  [IntlKeys.itemDetailsReviewModalBalanceAfterBidDescription]: '(this is an estimate of your balance in Coinmerce coins after the purchase)',
  [IntlKeys.itemDetailsReviewModalButtonSubmitOffer]: 'Submit Offer',
  [IntlKeys.itemDetailsReviewModalButtonCancel]: 'Cancel',
  [IntlKeys.itemDetailsReviewModalYouDontHaveFunds]: 'Your Euro balance is {balance}. You don’t have sufficient funds',
  [IntlKeys.itemDetailsPayNow]: 'Pay Now',
  [IntlKeys.itemDetailsPurchasePrice]: 'Purchase Price',
  [IntlKeys.itemDetailsYourBidAccepted]: 'Great news! Your bid was accepted.',
  [IntlKeys.itemDetailsPayModalSelectCollectionLabel]: 'Select Custom Collection*',
  [IntlKeys.itemDetailsYourBalanceAfterPurchase]: 'Your Balance After the Purchase',
  [IntlKeys.itemDetailsYourBalanceAfterPurchaseDescription]: '(this is an estimate of your balance in Coinmerce coins after the purchase)',

  // contdown
  [IntlKeys.countdownSeconds]: 'Seconds',
  [IntlKeys.countdownMinutes]: 'Minutes',
  [IntlKeys.countdownHours]: 'Hours',
  [IntlKeys.countdownDays]: 'Days',
  [IntlKeys.countdownThisSaleIsOver]: 'This Sale Is Over',
  [IntlKeys.countdownFiveMinutesLeft]: 'When bids are made in the last minute of the sale the timer automatically adds 5 minutes',

  // listing page
  [IntlKeys.listingTags]: 'Tags',
  [IntlKeys.listingDescription]: 'Description',
  [IntlKeys.listingLink]: 'Link',
  [IntlKeys.listingFormStartingPrice]: 'Start Price*',
  [IntlKeys.listingFormStartingPriceDescription]: '(this is the price that we will show when your item is listed on our marketplace)',
  [IntlKeys.listingFormBidIncrement]: 'Bid Increment*',
  [IntlKeys.listingFormBidIncrementDescription]: '(this is the is the minimum amount an auction bid must be raised)',
  [IntlKeys.listingFormBuyPrice]: 'Buy Price',
  [IntlKeys.listingFormBuyPriceDescription]: '(when a bid reaches this amount we will notify you via email)',
  [IntlKeys.listingFormDuration]: 'Duration',
  [IntlKeys.listingFormFees]: 'Fees',
  [IntlKeys.listingFormCoinmerceFee]: 'Coinmerce Fee',
  [IntlKeys.listingFormReviewAndSubmitButton]: 'Reviw and submit',
  [IntlKeys.listingDurationModalTitle]: 'Duration',
  [IntlKeys.listingDurationModalDateRange]: 'Date Range',
  [IntlKeys.listingDurationModalDateRangeThreeMonths]: '3 months',
  [IntlKeys.listingDurationModalDateRangeSixMonths]: '6 months',
  [IntlKeys.listingDurationModalDateRangeNineMonths]: '9 months',
  [IntlKeys.listingDurationModalDateRangeTwelveMonths]: '12 months',
  [IntlKeys.listingDurationModalDateRangeCustom]: 'Custom',
  [IntlKeys.listingDurationModalStartDate]: 'Start Date',
  [IntlKeys.listingDurationModalEndDate]: 'End Date',
  [IntlKeys.listingDurationModalStartTime]: 'Start Time',
  [IntlKeys.listingDurationModalEndTime]: 'End Time',
  [IntlKeys.listingDurationModalDoneButton]: 'Done',
  [IntlKeys.listingReviewModalDateRangeThreeMonths]: '3 months',
  [IntlKeys.listingReviewModalDateRangeSixMonths]: '6 months',
  [IntlKeys.listingReviewModalDateRangeNineMonths]: '9 months',
  [IntlKeys.listingReviewModalDateRangeTwelveMonths]: '12 months',
  [IntlKeys.listingReviewModalDateRangeCustomRange]: 'Custom range',
  [IntlKeys.listingReviewModalBuyNowPrice]: 'Buy Now Price',
  [IntlKeys.listingReviewModalStartingPrice]: 'Starting Price',
  [IntlKeys.listingReviewModalBidIncrement]: 'Bid Increment',
  [IntlKeys.listingReviewModalDuration]: 'Duration',
  [IntlKeys.listingReviewModalDateRangeInfo]: '{dateRange}, starts on {dateStart}, ends on {dateEnd}',
  [IntlKeys.listingReviewModalSubmitListingButton]: 'Submit listing',
  [IntlKeys.listingReviewModalCancelButton]: 'Cancel',
  [IntlKeys.listingAcceptBidModalSettlement]: 'Settlement',
  [IntlKeys.listingAcceptBidModalSettlementDescription]: '(this is the amount that we will transfer to your wallet in Coinmerce coins)',
  [IntlKeys.listingAcceptBidModalBidAmount]: 'Bid Amount',
  [IntlKeys.listingAcceptBidModalBidAmountDescription]: '(this is the amount offered by the buyer)',
  [IntlKeys.listingAcceptBidModalCoinmerceFee]: 'Coinmerce Fee',
  [IntlKeys.listingAcceptBidModalCoinmerceFeeDescription]: '(transaction fees are at {percentage}% of the sale value)',
  [IntlKeys.listingDeclineBidDescription]: 'This bid will be declined and we will notify the buyer. This action is irreversible, once a bid is declined you won’t be able to accept it anymore.',
  [IntlKeys.listingDeclineBidDeclineBidButton]: 'Decline Bid',
  [IntlKeys.listingDeclineAllBidsDescription]: 'All bids will be declined and we will notify the buyers. This action is irreversible, once a bid is declined you won’t be able to accept it anymore.',
  [IntlKeys.listingDeclineAllBidsDeclineAllButton]: 'Decline All Bids',

  // launchpad
  [IntlKeys.launchpadHeaderCoins]: 'Coins',
  [IntlKeys.launchpadHeaderProducts]: 'Products',
  [IntlKeys.launchpadHeaderAbout]: 'About',
  [IntlKeys.launchpadHeaderGetTheApp]: 'Get the app',
  [IntlKeys.launchpadNftLaunchpad]: 'NFT Launchpad',
  [IntlKeys.launchpadDescription]: "Welkom bij de NFT Launchpad van Coinmerce, de plek waar jij nieuwe NFT collecties als eerste kan ontdekken & kopen. Klaar om te investeren in NFT's? Ontdek direct alle opkomende projecten.",
  [IntlKeys.launchpadDiscoverButton]: 'Ontdekken',
  [IntlKeys.launchpadNavigateCategories]: 'Navigeer door de categorieën',
  [IntlKeys.launchpadFaqTitle]: "Veelgestelde vragen - NFT's",
  [IntlKeys.launchpadItemsTitle]: "Uitgelichte NFT's",
  [IntlKeys.launchpadItemsTitleDescription]: "Bekijk de top collecties van deze week.",

  // categories
  [IntlKeys.categoryArt]: 'Art',
  [IntlKeys.categoryCollectibles]: 'Collectibles',
  [IntlKeys.categoryDomainNames]: 'Domain Names',
  [IntlKeys.categoryMusic]: 'Music',
  [IntlKeys.categoryPhotography]: 'Photography',
  [IntlKeys.categorySports]: 'Sports',
  [IntlKeys.categoryTradingCards]: 'Trading Cards',
  [IntlKeys.categoryUtility]: 'Utility',
  [IntlKeys.categoryVirtualWorlds]: 'Virtual Worlds',

  // types
  [IntlKeys.typesAudio]: 'Audio',
  [IntlKeys.typesDocument]: 'Documents',
  [IntlKeys.typesImage]: 'Image',
  [IntlKeys.typesModel]: 'Model',
  [IntlKeys.typesVideo]: 'Video',

  // filters
  [IntlKeys.filtersOnSaleOnly]: 'On Sale Only',
  [IntlKeys.filterSearchBoxPlaceholder]: 'Search item or collection name',
  [IntlKeys.filterStatusTitle]: 'Status',
  [IntlKeys.filterPriceTitle]: 'Price',
  [IntlKeys.filterCategoriesTitle]: 'Categories',
  [IntlKeys.filterTypesTitle]: 'Types',
  [IntlKeys.filterAllTitle]: 'All',
  [IntlKeys.filterPriceTo]: 'to',
  [IntlKeys.filterPriceFrom]: 'from',

  // marketplace collection page
  [IntlKeys.marketplaceCollectionNumberOfItems]: 'items',
  [IntlKeys.marketplaceCollectionOrderingNewest]: 'Newest',
  [IntlKeys.marketplaceCollectionOrderingOldest]: 'Oldest',
  [IntlKeys.marketplaceCollectionOrderingLowToHigh]: 'Price Low to High',
  [IntlKeys.marketplaceCollectionOrderingHighToLow]: 'Price High to Low',
  [IntlKeys.marketplaceCollectionFilterTitle]: 'Filter',
  [IntlKeys.marketplaceCollectionFilterClearAll]: 'Clear All',
  [IntlKeys.marketplaceCollectionOnSale]: 'On Sale',
  [IntlKeys.marketplaceCollectionOwn]: 'Own',
  [IntlKeys.marketplaceCollectionPrice]: 'Price',
  [IntlKeys.marketplaceCollectionView]: 'View',
  [IntlKeys.marketplaceCollectionNoItemsFound]: 'No items found!',

  // my-collections page
  [IntlKeys.myCollectionsTitle]: 'My Collections',
  [IntlKeys.myCollectionsNewCollection]: 'New Collection',
  [IntlKeys.myCollectionsNoCollectionsFound]: 'No collections found! Click "New Collection" button to create one',
  [IntlKeys.myCollectionsNameOfCollection]: 'Name of collection',
  [IntlKeys.myCollectionsBlockchain]: 'Blockchain',
  [IntlKeys.myCollectionsCustomCollectionsTitle]: 'My Custom Collections',
  [IntlKeys.myCollectionsNewCustomCollection]: 'My Custom Collections',
  [IntlKeys.myCollectionsGoToCustomCollection]: 'Go to Custom Collection',
  [IntlKeys.myCollectionsNoItemsYet]: 'There are no items yet!',
  [IntlKeys.myCollectionsNameOfCustomCollection]: 'Name of custom collection',

  // toasts
  [IntlKeys.toastBidSubmitted]: 'Bid is successfully submitted',
  [IntlKeys.toastSomethingWentWrong]: 'Something went wrong',
  [IntlKeys.toastBidCancelled]: 'Bid is successfully cancelled',
  [IntlKeys.toastListingCreated]: 'Listing is successfully created',
  [IntlKeys.toastListingUpdated]: 'Listing is successfully updated',
  [IntlKeys.toastBidApproved]: 'Bid is successfully approved',
  [IntlKeys.toastAllBidsDeclined]: 'All active bids are successfully declined',
  [IntlKeys.toastCollectionCreated]: 'Collection is successfully created',
  [IntlKeys.toastCollectionUpdated]: 'Collection is successfully updated',
  [IntlKeys.toastCollectionNameUpdated]: 'Collection name is successfuly updated',
  [IntlKeys.toastListingRemoved]: 'Listing is successfully removed',

  // validation
  [IntlKeys.validationRequired]: 'Required',
  [IntlKeys.validationBidShouldBeEqualOrBigger]: 'Bid should be equal or bigger than {value}',
  [IntlKeys.validationShouldBeBiggerThanStartingPrice]: 'Should be bigger than starting price',
};
