import { useCallback, useEffect, useMemo } from 'react';
// libs
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// store
import { useAppDispatch } from 'store';
import { fetchMarketplaceItemById, marketplaceItemSelector } from 'store/marketplace';
import { fetchNFTItem, itemSelector } from 'store/nftItem';
import { Item } from 'store/types';
import { fetchProfile, ProfileState, profileUserIdSelector, profileWalletBalanceSelector } from 'store/profile';

type Profile = Omit<ProfileState, 'firstName' | 'lastName'>;

type UseDetailsReturn = { item: Item; profile: Profile };

const useDetails = (isMarketplaceItem: boolean | undefined): UseDetailsReturn => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const marketplaceItem = useSelector(marketplaceItemSelector);
  const item = useSelector(itemSelector);
  const userId = useSelector(profileUserIdSelector);
  const walletBalance = useSelector(profileWalletBalanceSelector);

  const fetchData = useCallback(() => {
    dispatch(fetchProfile());

    if (id) {
      console.log(id);
      if (!isMarketplaceItem) {
        return dispatch(fetchNFTItem(id));
      }

      return dispatch(fetchMarketplaceItemById(id));
    }
  }, [dispatch, id, isMarketplaceItem]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [fetchData, id]);

  return useMemo(() => {
    if (!isMarketplaceItem) {
      return { item, profile: { userId, walletBalance } };
    }
    return { item: marketplaceItem, profile: { userId, walletBalance } };
  }, [isMarketplaceItem, item, marketplaceItem, userId, walletBalance]);
};

export default useDetails;
