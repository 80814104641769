import styled from '@emotion/styled/macro';
// material-ui
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  padding: 40px;
  width: 880px;
  height: 600px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 40px 24px;
  }
`;

export const Title = styled.div`
  font-size: 36px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 38px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 18px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 24px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DateRangeLabel = styled.div`
  font-size: 21px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-bottom: 6px;
`;

export const TextFieldLabel = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-bottom: 6px;
`;

export const DoneButton = styled(Button)`
  width: 272px;
  margin-top: 60px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;
