import React from 'react';
// libs
import styled from '@emotion/styled/macro';
// material
import { Button, ButtonProps } from '@mui/material';
// icons
import { Close } from '@mui/icons-material';

// TODO: refactor component from button to div, since it`s filter tag, close icon must have button for clear filter
const FilterButton = styled((props: ButtonProps) => <Button endIcon={<Close />} {...props} />)`
  font-size: 16px;
  line-height: 20px;
  padding: 7px 3px 7px 10px;
  background-color: rgba(0, 130, 255, 0.1);
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};

  & .MuiButton-endIcon {
    margin-right: 0;
    margin-left: 15px;

    & svg {
      font-size: 24px;
      color: ${({ theme }) => theme.palette.common.blackSpace};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export default FilterButton;
