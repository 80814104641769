import { useEffect, useMemo } from 'react';
// libs
import { useAppDispatch } from 'store';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// store
import { collectionSelector, fetchCollection } from 'store/collection';
import { fetchItemsByCollectionId, itemMetaSelector, itemsSelector } from 'store/nftItem';
import { fetchMarketplaceItems, marketplaceItemsSelector, marketplaceMetaSelector } from 'store/marketplace';
import { Item } from 'store/types';
// contexts
import { useFilterContext } from 'components/Filter/FilterProvider';

type Meta = {
  isLoading: boolean;
  isLoaded: boolean;
};

interface ReturnData {
  marketplaceItems: Item[];
  collectionMeta: Meta;
  itemsMeta: Meta;
  collectionName?: string;
}

function useFetchMarketplaceCollectionData(): ReturnData {
  const dispatch = useAppDispatch();
  const { collectionId } = useParams();
  const { paramsString } = useFilterContext();

  const collection = useSelector(collectionSelector);
  const items = useSelector(marketplaceItemsSelector);
  const collectionItems = useSelector(itemsSelector);
  const collectionMeta = useSelector(itemMetaSelector);
  const itemsMeta = useSelector(marketplaceMetaSelector);

  useEffect(() => {
    if (collectionId) {
      dispatch(fetchItemsByCollectionId({ collectionId, params: paramsString }));
      dispatch(fetchCollection(collectionId));
    }
    if (!collectionId) {
      dispatch(fetchMarketplaceItems({ params: paramsString }));
    }
  }, [collectionId, dispatch, paramsString]);

  const { marketplaceItems, collectionName } = useMemo(() => {
    if (collectionId) {
      return { marketplaceItems: collectionItems || [], collectionName: collection?.name };
    }

    return { marketplaceItems: items || [] };
  }, [collection?.name, collectionId, collectionItems, items]);

  return { marketplaceItems, collectionMeta, itemsMeta, collectionName };
}

export default useFetchMarketplaceCollectionData;
