import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 99px 16px 80px;
  min-height: 100vh;

  ${({ theme }) => theme.media.sm`
    padding: 40px 24px 80px;
    background-color: ${theme.palette.common.white};
  `}
`;

export const Container = styled.div`
  padding: 24px 24px 64px;
  width: 100%;
  max-width: 1048px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.sm`
    padding: 0;
    border: 0;
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const HeaderTitle = styled.div`
  font-size: 36px;
  line-height: 58px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
`;

export const NewCollectionButton = styled(Button)`
  max-width: 272px;
  padding: 12px 10px;
  font-size: 16px;
  line-height: 20px;

  ${({ theme }) => theme.media.sm`
    max-width: 100%;
    margin-top: 40px;
  `}
`;

export const AccordionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 74px;

  ${({ theme }) => theme.media.sm`
    margin-top: 40px;
  `}
`;

export const NoCollectionFoundText = styled.div`
  font-size: 24px;
`;
