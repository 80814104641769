import styled from '@emotion/styled/macro';
import { Button as MaterialButton, Button } from '@mui/material';
import Img from 'components/Img';

export const Wrapper = styled.div`
  padding: 40px;
  max-width: 560px;
  min-height: 680px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 40px 24px;
  }
`;

export const Title = styled.div`
  font-size: 36px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 12px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 12px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 60px auto;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LabelBig = styled.div`
  font-size: 24px;
  line-height: 29px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const Label = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const ValueBig = styled.div`
  font-size: 24px;
  line-height: 29px;
  font-family: 'VisbyRoundCF Regular', sans-serif;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const Value = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-family: 'VisbyRoundCF Regular', sans-serif;
  color: ${({ theme }) => theme.palette.common.brilliantLicorice};
`;

export const ItemContainer = styled.div`
  display: flex;
  gap: 40px;
`;

export const Image = styled(Img)`
  width: 120px;
  height: 120px;
`;

export const ItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SmallTitle = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
`;

export const ItemName = styled.div`
  font-size: 20px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
`;

export const SubmitButton = styled(Button)`
  min-width: 180px;
`;

export const CancelButton = styled(MaterialButton)`
  min-width: 180px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.snowBank};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.common.brilliantLicorice50};
`;
