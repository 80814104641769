import styled from '@emotion/styled/macro';
import { Button, Menu, MenuItem } from '@mui/material';
import { StyledTextField } from '../styled';

export const ActionButton = styled(Button)`
  font-size: 16px;
  & .MuiButton-endIcon {
    margin-top: 4px;
    font-size: 16px;
  }
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 51px;
`;

export const CryptoContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

// export const CryptoLabel = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-width: 102px;
//   padding: 16px;
//   font-size: 16px;
//   color: ${({ theme }) => theme.palette.common.blackSpace};
//   border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
//   font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
//   height: 50px;
//   border-radius: 3px;
//   & > img {
//     margin-right: 16px;
//   }
// `;

export const CryptoLabel = styled(Button)`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  color: ${({ theme }) => theme.palette.common.blackSpace};
  height: 50px;
  width: 102px;
  & .MuiButton-startIcon {
    margin-right: 14px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const StyledMenu = styled(Menu)`
  & .MuiMenu-paper {
    width: 102px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.blackSpace};
  padding: 12px 16px;
  & > img {
    margin-right: 14px;
  }
`;

export const CryptoLogo = styled.img`
  width: 20px;
  height: 20px;
`;

export const CryptoTextField = styled(StyledTextField)`
  margin-bottom: 20px;
  margin-right: 16px;
  & .MuiInputAdornment-root > p {
    margin-bottom: 3.5px;
  }
`;

export const CurrencyTextField = styled(StyledTextField)`
  margin-bottom: 20px;
  & .MuiInputAdornment-root > p {
    margin-bottom: 1.5px;
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin-right: 8px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
`;

export const FooterItem = styled.div`
  font-family: 'VisbyRoundCF SemiBold';
  font-size: 16px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
`;
