import { IntlKeys } from '../localization/keys';

export const FILTERS = {
  itemTypes: 'filter[itemTypes]',
  priceMax: 'filter[priceMax]',
  priceMin: 'filter[priceMin]',
  name: 'filter[name]',
  categories: 'filter[categories]',
  collectionId: 'filter[collectionId]',
  statuses: 'filter[statuses]',
  listingId: 'filter[listingId]',
};

export const ORDERING = {
  createdAt: 'orderBy[createdAt]',
  price: 'orderBy[price]',
};

export enum Ordering {
  CreatedAtDesc,
  CreatedAtAsc,
  PriceAsc,
  PriceDesc,
}

export const ORDERING_TYPES = ['createdAt-desc', 'createdAt-asc', 'price-asc', 'price-desc'];

export const ORDERING_OPTIONS = [
  {
    value: ORDERING_TYPES[Ordering.CreatedAtDesc],
    label: IntlKeys.marketplaceCollectionOrderingNewest,
  },
  {
    value: ORDERING_TYPES[Ordering.CreatedAtAsc],
    label: IntlKeys.marketplaceCollectionOrderingOldest,
  },
  {
    value: ORDERING_TYPES[Ordering.PriceAsc],
    label: IntlKeys.marketplaceCollectionOrderingLowToHigh,
  },
  {
    value: ORDERING_TYPES[Ordering.PriceDesc],
    label: IntlKeys.marketplaceCollectionOrderingHighToLow,
  },
];

export const ITEM_TYPE_FILTER_VALUES = [
  { name: 'audio', label: IntlKeys.typesAudio },
  { name: 'document', label: IntlKeys.typesDocument },
  { name: 'image', label: IntlKeys.typesImage },
  { name: 'model', label: IntlKeys.typesModel },
  { name: 'video', label: IntlKeys.typesVideo },
];

export const CATEGORIES_FILTER_VALUES = [
  { name: 'art', label: IntlKeys.categoryArt },
  { name: 'collectibles', label: IntlKeys.categoryCollectibles },
  { name: 'domain_names', label: IntlKeys.categoryDomainNames },
  { name: 'music', label: IntlKeys.categoryMusic },
  { name: 'photography', label: IntlKeys.categoryPhotography },
  { name: 'sports', label: IntlKeys.categorySports },
  { name: 'trading_cards', label: IntlKeys.categoryTradingCards },
  { name: 'utility', label: IntlKeys.categoryUtility },
  { name: 'virtual_worlds', label: IntlKeys.categoryVirtualWorlds },
];

export const STATUS_FILTER_VALUES = [{ name: 'onSale', label: IntlKeys.filtersOnSaleOnly }];
