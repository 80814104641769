// TODO: check keys with dictionary so it match number of lines and
// each key match its place in keys and dictionary files
export enum IntlKeys {
  // common
  commonClose = 'common.close',
  commonLorem = 'common.lorem',
  commonAccept = 'common.accept',
  commonCancel = 'common.cancel',
  commonCreate = 'common.create',

  // header
  headerHome = 'header.home',
  headerCoins = 'header.coins',
  headerNfts = 'header.nfts',
  headerWallets = 'header.wallets',
  headerOrders = 'header.orders',
  headerExplore = 'header.explore',
  headerExplorePopoverTitle = 'header.explorePopoverTitle',
  headerExplorePopoverProducts = 'header.explorePopoverProducts',
  headerExplorePopoverAbout = 'header.explorePopoverAbout',
  headerExplorePopoverProductItemsProofTitle = 'header.explorePopoverProductItemsProofTitle',
  headerExplorePopoverProductItemsBotsTitle = 'header.explorePopoverProductItemsBotsTitle',
  headerExplorePopoverProductItemsBeginnersTitle = 'header.explorePopoverProductItemsBeginnersTitle',
  headerExplorePopoverProductItemsAdvancedTitle = 'header.explorePopoverProductItemsAdvancedTitle',
  headerExplorePopoverProductItemsGiftcardsTitle = 'header.explorePopoverProductItemsGiftcardsTitle',
  headerExplorePopoverProductItemsProofDescription = 'header.explorePopoverProductItemsProofDescription',
  headerExplorePopoverProductItemsBotsDescription = 'header.explorePopoverProductItemsBotsDescription',
  headerExplorePopoverProductItemsBeginnersDescription = 'header.explorePopoverProductItemsBeginnersDescription',
  headerExplorePopoverProductItemsAdvancedDescription = 'header.explorePopoverProductItemsAdvancedDescription',
  headerExplorePopoverProductItemsGiftcardsDescription = 'header.explorePopoverProductItemsGiftcardsDescription',
  headerExplorePopoverAboutItemsAboutTitle = 'header.explorePopoverAboutItemsAboutTitle',
  headerExplorePopoverAboutItemsCryptoTitle = 'header.explorePopoverAboutItemsCryptoTitle',
  headerExplorePopoverAboutItemsNewsTitle = 'header.explorePopoverAboutItemsNewsTitle',
  headerExplorePopoverAboutItemsLearnTitle = 'header.explorePopoverAboutItemsLearnTitle',
  headerExplorePopoverAboutItemsHelpTitle = 'header.explorePopoverAboutItemsHelpTitle',
  headerExplorePopoverAboutItemsVacanciesTitle = 'header.explorePopoverAboutItemsVacanciesTitle',
  headerExplorePopoverAboutItemsAboutDescription = 'header.explorePopoverAboutItemsAboutDescription',
  headerExplorePopoverAboutItemsCryptoDescription = 'header.explorePopoverAboutItemsCryptoDescription',
  headerExplorePopoverAboutItemsNewsDescription = 'header.explorePopoverAboutItemsNewsDescription',
  headerExplorePopoverAboutItemsLearnDescription = 'header.explorePopoverAboutItemsLearnDescription',
  headerExplorePopoverAboutItemsHelpDescription = 'header.explorePopoverAboutItemsHelpDescription',
  headerExplorePopoverAboutItemsVacanciesDescription = 'header.explorePopoverAboutItemsVacanciesDescription',
  headerAccountPopoverTitle = 'header.accountPopoverTitle',
  headerAccountPopoverProfile = 'header.accountPopoverProfile',
  headerAccountPopoverAccountLevel = 'header.accountPopoverAccountLevel',
  headerAccountPopoverSecurity = 'header.accountPopoverSecurity',
  headerAccountPopoverSettings = 'header.accountPopoverSettings',
  headerAccountPopoverFriends = 'header.accountPopoverFriends',
  headerAccountPopoverWithdrawAddresses = 'header.accountPopoverWithdrawAddresses',
  headerAccountPopoverLogout = 'header.accountPopoverLogout',
  headerNFTPopoverCreate = 'header.nftPopoverCreate',
  headerNFTPopoverMarketplace = 'header.nftPopoverMarketplace',
  headerNFTPopoverMyCollections = 'header.nftPopoverMyCollection',
  headerNFTPopoverLaunchpad = 'header.nftPopoverLaunchpad',

  // footer
  footerLoginTitle = 'footer.loginTitle',
  footerLoginButton = 'footer.loginButton',
  footerSignupButton = 'footer.signupButton',
  footerListGeneral = 'footer.listGeneral',
  footerListGeneralAboutUs = 'footer.listGeneralAboutUs',
  footerListGeneralCareers = 'footer.listGeneralCareers',
  footerListGeneralCoins = 'footer.listGeneralCoins',
  footerListGeneralFraudPrevention = 'footer.listGeneralFraudPrevention',
  footerListGeneralBugFound = 'footer.listGeneralBugFound',
  footerListGeneralApi = 'footer.listGeneralApi',
  footerListGeneralSitemap = 'footer.listGeneralSitemap',
  footerListPopular = 'footer.listPopular',
  footerListPopularBuyBitcoin = 'footer.listPopularBuyBitcoin',
  footerListPopularBitcoinPrice = 'footer.listPopularBitcoinPrice',
  footerListPopularBuyEthereum = 'footer.listPopularBuyEthereum',
  footerListPopularEthereumPrice = 'footer.listPopularEthereumPrice',
  footerListPopularBuyRipple = 'footer.listPopularBuyRipple',
  footerListPopularRipplePrice = 'footer.listPopularRipplePrice',
  footerListPopularBuyLota = 'footer.listPopularBuyLota',
  footerListPopularLotaPrice = 'footer.listPopularLotaPrice',
  footerListLegal = 'footer.listLegal',
  footerListLegalTermsAndConditions = 'footer.listLegalTermsAndConditions',
  footerListLegalPrivacyPolicy = 'footer.listLegalPrivacyPolicy',
  footerListLegalRiskDisclaimer = 'footer.listLegalRiskDisclaimer',
  footerListJumpTo = 'footer.listJumpTo',
  footerListJumpToRegister = 'footer.listJumpToRegister',
  footerListJumpToLogin = 'footer.listJumpToLogin',
  footerListJumpToNeedHelp = 'footer.listJumpToNeedHelp',
  footerListJumpToLearn = 'footer.listJumpToLearn',
  footerListJumpToNewsAndBlog = 'footer.listJumpToNewsAndBlog',
  footerStore = 'footer.store',
  footerOffice = 'footer.office',

  // single coin view
  websiteSinglecoinBreadcrumbCoins = 'websiteSingloecoin.bredcrumbCoins',
  websiteSinglecoinIntro = 'websiteSingloecoin.intro',
  websiteSinglecoinUsp1 = 'websiteSingloecoin.usp1',
  websiteSinglecoinUsp2 = 'websiteSingloecoin.usp2',
  websiteSinglecoinUsp3 = 'websiteSingloecoin.usp3',
  websiteSinglecoinUsp4 = 'websiteSingloecoin.usp4',
  websiteSinglecoinH1 = 'websiteSingloecoin.h1',
  websiteSinglecoinH2Price = 'websiteSingloecoin.h2Price',
  websiteSinglecoinH2PriceCoin = 'websiteSingloecoin.h2PriceCoin',
  websiteSinglecoinH2PriceVolume = 'websiteSingloecoin.h2PriceVolume',
  websiteSinglecoinH2PriceMarketcap = 'websiteSingloecoin.h2PriceMarketcap',
  websiteSinglecoinEarnH2 = 'websiteSingloecoin.earnH2',
  websiteSinglecoinEarnRewards = 'websiteSingloecoin.earnRewards',
  websiteSinglecoinEarnMinimum = 'websiteSingloecoin.earnMinimum',
  websiteSinglecoinEarnFrequencyTitle = 'websiteSingloecoin.earnFrequencyTitle',
  websiteSinglecoinEarnFrequencyTime = 'websiteSingloecoin.earnFrequencyTime',
  websiteSinglecoinEarnCalculateRewards = 'websiteSingloecoin.earnCalculateRewards',
  websiteSinglecoinEarn1Year = 'websiteSingloecoin.earn1Year',
  websiteSinglecoinEarn2Year = 'websiteSingloecoin.earn2Year',
  websiteSinglecoinEarn5Year = 'websiteSingloecoin.earn5Year',
  websiteSinglecoinEarnAsterisk = 'websiteSingloecoin.earnAsterisk',
  websiteSinglecoinEarnButton = 'websiteSingloecoin.earnButton',
  websiteSinglecoinStakingH2 = 'websiteSingloecoin.stakingH2',
  websiteSinglecoinStakingRewards = 'websiteSingloecoin.stakingRewards',
  websiteSinglecoinStakingMinimum = 'websiteSingloecoin.stakingMinimum',
  websiteSinglecoinStakingFrequencyTitle = 'websiteSingloecoin.stakingFrequencyTitle',
  websiteSinglecoinStakingFrequencyTime = 'websiteSingloecoin.stakingFrequencyTime',
  websiteSinglecoinStakingCalculateRewards = 'websiteSingloecoin.stakingCalculateRewards',
  websiteSinglecoinStaking1Year = 'websiteSingloecoin.staking1Year',
  websiteSinglecoinStaking2Year = 'websiteSingloecoin.staking2Year',
  websiteSinglecoinStaking5Year = 'websiteSingloecoin.staking5Year',
  websiteSinglecoinStakingAsterisk = 'websiteSingloecoin.stakingAsterisk',
  websiteSinglecoinStakingButton = 'websiteSingloecoin.stakingButton',
  websiteSinglecoinAboutTitle = 'websiteSingloecoin.aboutTitle',
  websiteSinglecoinAboutWhat = 'websiteSingloecoin.aboutWhat',
  websiteSinglecoinReadmore = 'websiteSingloecoin.readmore',
  websiteSinglecoinAblutHow = 'websiteSingloecoin.aboutHow',
  websiteSinglecoinAblutBuy = 'websiteSingloecoin.aboutBuy',
  websiteSinglecoinPopularTitle = 'websiteSingloecoin.popularTitle',
  websiteSinglecoinPopularDescription = 'websiteSingloecoin.popularDescription',
  websiteSinglecoinPopularDescriptionAllCrypto = 'websiteSingloecoin.popularDescriptionAllCrypto',
  websiteSinglecoinPopularAsset = 'websiteSingloecoin.populaAsset',
  websiteSinglecoinPopularBalance = 'websiteSingloecoin.popularBalance',
  websiteSinglecoinPopularPrice = 'websiteSingloecoin.popularPrice',
  websiteSinglecoinPopularChange = 'websiteSingloecoin.popularChange',
  websiteSinglecoinPopularType = 'websiteSingloecoin.popularType',
  websiteSinglecoinPopularActiveStaking = 'websiteSingloecoin.popularActiveStaking',
  websiteSinglecoinPopularActiveEarn = 'websiteSingloecoin.popularActiveEarn',
  websiteSinglecoinPopularBuy = 'websiteSingloecoin.popularBuy',
  websiteSinglecoinPopularSell = 'websiteSingloecoin.popularSell',
  websiteSinglecoinPopularDeposit = 'websiteSingloecoin.popularDeposit',
  websiteSinglecoinPopularWithdraw = 'websiteSingloecoin.popularWithdraw',
  websiteSinglecoinPopularViewAllCrypto = 'websiteSingloecoin.popularViewAllCrypto',
  websiteSinglecoinBuyButtonMobile = 'websiteSinglecoin.buyButtonMobile',
  websiteSinglecoinBuyItem = 'websiteSinglecoin.buyItem',
  websiteSinglecoinSellItem = 'websiteSinglecoin.sellItem',
  websiteSinglecoinBuyButton = 'websiteSinglecoin.buyButton',
  websiteSinglecoinSellButton = 'websiteSinglecoin.sellButton',
  websiteSinglecoinBuyButtonCoin = 'websiteSinglecoin.buyButtonCoin',
  websiteSinglecoinOrderLimit = 'websiteSinglecoin.orderLimit',
  websiteSinglecoinRepeatOrder = 'websiteSinglecoin.repeatOrder',
  websiteSinglecoinSwapItem = 'websiteSinglecoin.swapItem',
  websiteSinglecoinSwapButton = 'websiteSinglecoin.swapItem',

  // single bundle view
  websiteBundleviewBreadcrumbBundles = 'websiteBundleview.BreadcrumbBundles',
  websiteBundleviewDescription = 'websiteBundleview.description',
  websiteBundleviewBuyItem = 'websiteBundleview.buyItem',
  websiteBundleviewSellItem = 'websiteBundleview.sellItem',
  websiteBundleviewBuyButton = 'websiteBundleview.buyButton',
  websiteBundleviewSellButton = 'websiteBundleview.sellButton',
  websiteBundleviewBuyButtonMobile = 'websiteBundleview.buyButtonMobile',
  websiteBundleviewMoreButtonMobile = 'websiteBundleview.moreButtonMobile',
  websiteBundleviewMax = 'websiteBundleview.max',
  websiteBundleviewUsp1 = 'websiteBundleview.usp1',
  websiteBundleviewUsp2 = 'websiteBundleview.usp2',
  websiteBundleviewUsp3 = 'websiteBundleview.usp3',
  websiteBundleviewUsp3Link = 'websiteBundleview.usp3Link',
  websiteBundleviewUsp4 = 'websiteBundleview.usp4',
  websiteBundleviewOtherBundlesTitle = 'websiteBundleview.otherBundlesTitle',
  websiteBundleviewOtherBundlesDescription = 'websiteBundleview.otherBundlesDescription',
  websiteBundleviewOtherBundlesBundle = 'websiteBundleview.otherBundlesBundle',
  websiteBundleviewOtherBundlesChange = 'websiteBundleview.otherBundlesChange',
  websiteBundleviewOtherBundlesCoins = 'websiteBundleview.otherBundlesCoins',
  websiteBundleviewOtherBundlesBuyButton = 'websiteBundleview.otherBundlesBuyButton',
  websiteBundleviewOtherBundlesOtherCoins = 'websiteBundleview.otherBundlesOtherCoins',
  websiteBundleviewH1 = 'websiteBundleview.h1',
  websiteBundleviewH2 = 'websiteBundleview.h2',

  // item details page
  itemDetailsTags = 'itemDetails.tags',
  itemDetailsDescription = 'itemDetails.description',
  itemDetailsLink = 'itemDetails.link',
  itemDetailsBuyNowPrice = 'itemDetails.buyNowPrice',
  itemDetailsBuyNowPriceDescription = 'itemDetails.buyNowPriceDescription',
  itemDetailsThisSaleEndsOn = 'itemDetails.thisSaleEndsOn',
  itemDetailsHighestBid = 'itemDetails.highestBid',
  itemDetailsHighestBidHoorayMessage = 'itemDetails.highestBidHoorayMessage',
  itemDetailsThisItemIsNotListed = 'itemDetails.thisItemIsNotListed',
  itemDetailsLastPriceSold = 'itemDetails.lastPriceSold',
  itemDetailsEditSale = 'itemDetails.editSale',
  itemDetailsRemovePosition = 'itemDetails.removePosition',
  itemDetailsSell = 'itemDetails.sell',
  itemDetailsWithdraw = 'itemDetails.withdraw',
  itemDetailsAcceptHighestBid = 'itemDetails.acceptHighestBid',
  itemDetailsDeclineAllBids = 'itemDetails.declineAllBids',
  itemDetailsPlaceBid = 'itemDetails.placeBid',
  itemDetailsCancelBid = 'itemDetails.cancelBid',
  itemDetailsBids = 'itemDetails.bids',
  itemDetailsYourBid = 'itemDetails.yourBid',
  itemDetailsYourBidDescription = 'itemDetails.yourBidDescription',
  itemDetailsReviewOffer = 'itemDetails.reviewOffer',
  itemDetailsBidsTablePriceHeader = 'itemDetails.bidsTablePriceHeader',
  itemDetailsBidsTableDateHeader = 'itemDetails.bidsTableDateHeader',
  itemDetailsBidsTableStatusHeader = 'itemDetails.bidsTableStatusHeader',
  itemDetailsRemovePositionModalTitle = 'itemDetails.removePositionModalTitle',
  itemDetailsRemovePositionModalDescription = 'itemDetails.removePositionModalDescription',
  itemDetailsRemovePositionModalButtonRemove = 'itemDetails.removePositionModalButtonRemove',
  itemDetailsRemovePositionModalButtonClose = 'itemDetails.removePositionModalButtonClose',
  itemDetailsReviewModalBuyNowPrice = 'itemDetails.reviewModalBuyNowPrice',
  itemDetailsReviewModalYourBid = 'itemDetails.reviewModalYourBid',
  itemDetailsReviewModalBalanceAfterBid = 'itemDetails.reviewModalBalanceAfterBid',
  itemDetailsReviewModalBalanceAfterBidDescription = 'itemDetails.reviewModalBalanceAfterBidDescription',
  itemDetailsReviewModalButtonSubmitOffer = 'itemDetails.reviewModalButtonSubmitOffer',
  itemDetailsReviewModalButtonCancel = 'itemDetails.reviewModalButtonCancel',
  itemDetailsReviewModalYouDontHaveFunds = 'itemDetails.reviewModalYouDontHaveFunds',
  itemDetailsPayNow = 'itemDetails.payNow',
  itemDetailsPurchasePrice = 'itemDetails.purchasePrice',
  itemDetailsYourBidAccepted = 'itemDetails.yourBidAccepted',
  itemDetailsPayModalSelectCollectionLabel = 'itemDetails.payModalSelectCollectionLabel',
  itemDetailsYourBalanceAfterPurchase = 'itemDetails.yourBalanceAfterPurchase',
  itemDetailsYourBalanceAfterPurchaseDescription = 'itemDetails.yourBalanceAfterPurchaseDescription',

  // countdown
  countdownSeconds = 'countdown.seconds',
  countdownMinutes = 'countdown.minutes',
  countdownHours = 'countdown.hours',
  countdownDays = 'countdown.days',
  countdownThisSaleIsOver = 'countdown.thisSaleIsOver',
  countdownFiveMinutesLeft = 'countdown.fiveMinutesLeft',

  // listing page
  listingTags = 'listing.tags',
  listingDescription = 'listing.description',
  listingLink = 'listing.link',
  listingFormStartingPrice = 'listing.formStartingPrice',
  listingFormStartingPriceDescription = 'listing.formStartingPriceDescription',
  listingFormBidIncrement = 'listing.formBidIncrement',
  listingFormBidIncrementDescription = 'listing.formBidIncrementDescription',
  listingFormBuyPrice = 'listing.formBuyPrice',
  listingFormBuyPriceDescription = 'listing.formBuyPriceDescription',
  listingFormDuration = 'listing.formDuration',
  listingFormFees = 'listing.formFees',
  listingFormCoinmerceFee = 'listing.formCoinmerceFee',
  listingFormReviewAndSubmitButton = 'listing.formReviewAndSubmitButton',
  listingDurationModalTitle = 'listing.durationModalTitle',
  listingDurationModalDateRange = 'listing.durationModalDateRange',
  listingDurationModalDateRangeThreeMonths = 'listing.durationModalDateRangeThreeMonths',
  listingDurationModalDateRangeSixMonths = 'listing.durationModalDateRangeSixMonths',
  listingDurationModalDateRangeNineMonths = 'listing.durationModalDateRangeNineMonths',
  listingDurationModalDateRangeTwelveMonths = 'listing.durationModalDateRangeTwelveMonths',
  listingDurationModalDateRangeCustom = 'listing.durationModalDateRangeCustom',
  listingDurationModalStartDate = 'listing.durationModalStartDate',
  listingDurationModalEndDate = 'listing.durationModalEndDate',
  listingDurationModalStartTime = 'listing.durationModalStartTime',
  listingDurationModalEndTime = 'listing.durationModalEndTime',
  listingDurationModalDoneButton = 'listing.durationModalDoneButton',
  listingReviewModalDateRangeThreeMonths = 'listing.reviewModalDateRangeThreeMonths',
  listingReviewModalDateRangeSixMonths = 'listing.reviewModalDateRangeSixMonths',
  listingReviewModalDateRangeNineMonths = 'listing.reviewModalDateRangeNineMonths',
  listingReviewModalDateRangeTwelveMonths = 'listing.reviewModalDateRangeTwelveMonths',
  listingReviewModalDateRangeCustomRange = 'listing.reviewModalDateRangeCustomRange',
  listingReviewModalBuyNowPrice = 'listing.reviewModalBuyNowPrice',
  listingReviewModalStartingPrice = 'listing.reviewModalStartingPrice',
  listingReviewModalBidIncrement = 'listing.reviewModalBidIncrement',
  listingReviewModalDuration = 'listing.reviewModalDuration',
  listingReviewModalDateRangeInfo = 'listing.reviewModalDateRangeInfo',
  listingReviewModalSubmitListingButton = 'listing.reviewModalSubmitListingButton',
  listingReviewModalCancelButton = 'listing.reviewModalCancelButton',
  listingAcceptBidModalSettlement = 'listing.acceptBidModalSettlement',
  listingAcceptBidModalSettlementDescription = 'listing.acceptBidModalSettlementDescription',
  listingAcceptBidModalBidAmount = 'listing.acceptBidModalBidAmount',
  listingAcceptBidModalBidAmountDescription = 'listing.acceptBidModalBidAmountDescription',
  listingAcceptBidModalCoinmerceFee = 'listing.acceptBidModalCoinmerceFee',
  listingAcceptBidModalCoinmerceFeeDescription = 'listing.acceptBidModalCoinmerceFeeDescription',
  listingDeclineBidDescription = 'listing.declineBidDescription',
  listingDeclineBidDeclineBidButton = 'listing.declineBidDeclineBidButton',
  listingDeclineAllBidsDescription = 'listing.declineAllBidsDescription',
  listingDeclineAllBidsDeclineAllButton = 'listing.declineAllBidsDeclineAllButton',

  // launchpad
  launchpadHeaderCoins = 'launchpad.headerCoins',
  launchpadHeaderProducts = 'launchpad.headerProducts',
  launchpadHeaderAbout = 'launchpad.headerAbout',
  launchpadHeaderGetTheApp = 'launchpad.headerGetTheApp',
  launchpadNftLaunchpad = 'launchpad.nftLaunchpad',
  launchpadDescription = 'launchpad.description',
  launchpadDiscoverButton = 'launchpad.discoverButton',
  launchpadNavigateCategories = 'launchpad.navigateCategories',
  launchpadFaqTitle = 'launchpad.faqTitle',
  launchpadItemsTitle = 'launchpad.itemsTitle',
  launchpadItemsTitleDescription = 'launchpad.itemsTitleDescription',

  // categories
  categoryArt = 'category.art',
  categoryCollectibles = 'category.collectibles',
  categoryDomainNames = 'category.domainNames',
  categoryMusic = 'category.music',
  categoryPhotography = 'category.photography',
  categorySports = 'category.storts',
  categoryTradingCards = 'category.tradingCards',
  categoryUtility = 'category.utility',
  categoryVirtualWorlds = 'category.virtualWorlds',

  // types
  typesAudio = 'types.audio',
  typesDocument = 'types.document',
  typesImage = 'types.image',
  typesModel = 'types.model',
  typesVideo = 'types.video',

  // filters
  filtersOnSaleOnly = 'filters.onSaleOnly',
  filterSearchBoxPlaceholder = 'filters.searchBoxPlaceholder',
  filterStatusTitle = 'filters.statusTitle',
  filterPriceTitle = 'filters.priceTitle',
  filterCategoriesTitle = 'filters.priceTitle',
  filterTypesTitle = 'filters.typesTitle',
  filterAllTitle = 'filters.allTitle',
  filterPriceTo = 'filters.priceTo',
  filterPriceFrom = 'filters.priceFrom',

  // marketplace collection page
  marketplaceCollectionNumberOfItems = 'marketplaceCollection.numberOfItems',
  marketplaceCollectionOrderingNewest = 'marketplaceCollection.orderingNewest',
  marketplaceCollectionOrderingOldest = 'marketplaceCollection.orderingOldest',
  marketplaceCollectionOrderingLowToHigh = 'marketplaceCollection.orderingOldest',
  marketplaceCollectionOrderingHighToLow = 'marketplaceCollection.orderingHighToLow',
  marketplaceCollectionFilterTitle = 'marketplaceCollection.filterTitle',
  marketplaceCollectionFilterClearAll = 'marketplaceCollection.filterClearAll',
  marketplaceCollectionOnSale = 'marketplaceCollection.onSale',
  marketplaceCollectionOwn = 'marketplaceCollection.own',
  marketplaceCollectionPrice = 'marketplaceCollection.Price',
  marketplaceCollectionView = 'marketplaceCollection.view',
  marketplaceCollectionNoItemsFound = 'marketplaceCollection.noItemsFound',

  // my-collections page
  myCollectionsTitle = 'myCollections.title',
  myCollectionsNewCollection = 'myCollections.newCollection',
  myCollectionsNoCollectionsFound = 'myCollections.noCollectionsFound',
  myCollectionsNameOfCollection = 'myCollections.nameOfCollection',
  myCollectionsBlockchain = 'myCollections.blockchain',
  myCollectionsCustomCollectionsTitle = 'myCollections.customCollectionsTitle',
  myCollectionsNewCustomCollection = 'myCollections.newCustomCollection',
  myCollectionsGoToCustomCollection = 'myCollections.goToCustomCollection',
  myCollectionsNoItemsYet = 'myCollections.noItemsYet',
  myCollectionsNameOfCustomCollection = 'myCollections.nameOfCustomCollection',

  // toasts
  toastBidSubmitted = 'toast.bidSubmitted',
  toastSomethingWentWrong = 'toast.somethingWentWrong',
  toastBidCancelled = 'toast.bidCancelled',
  toastListingCreated = 'toast.listingCreated',
  toastListingUpdated = 'toast.listingUpdated',
  toastBidApproved = 'toast.bidApproved',
  toastAllBidsDeclined = 'toast.allBidsDeclined',
  toastCollectionCreated = 'toast.collectionCreated',
  toastCollectionUpdated = 'toast.collectionUpdated',
  toastCollectionNameUpdated = 'toast.collectionNameUpdated',
  toastListingRemoved = 'toast.listingRemoved',

  // validation
  validationRequired = 'validation.required',
  validationBidShouldBeEqualOrBigger = 'validation.bidShouldBeEqualOrBigger',
  validationShouldBeBiggerThanStartingPrice = 'validation.shouldBeBiggerThanStartingPrice',
}
