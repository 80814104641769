import React from 'react';
// material-ui
import { InputBaseComponentProps } from '@mui/material';
// styled
import { TextFieldContainer, TextFieldLabel, TextFiled } from './styled';

export interface FormTextFieldProps extends InputBaseComponentProps {}

function FormTextField({
  field: _field,
  form,
  className,
  label,
  defaultValue = '',
  size = 'small',
  ...rest
}: FormTextFieldProps) {
  const { value = defaultValue, ...field } = _field;
  const { getFieldMeta } = form;

  const { touched, error } = getFieldMeta(field.name);
  const _error = touched && error;

  return (
    <TextFieldContainer>
      {label && <TextFieldLabel>{label}</TextFieldLabel>}
      <TextFiled
        className={className}
        variant="outlined"
        value={value}
        size={size}
        error={Boolean(_error)}
        helperText={_error}
        {...field}
        {...rest}
      />
    </TextFieldContainer>
  );
}

export default React.memo(FormTextField);
