import React from 'react';
// libs
import { useFormik } from 'formik';
import { Button, TextField } from '@mui/material';
// store
import { useAppDispatch } from 'store';
import { signIn } from 'store/auth';
// styled
import { Wrapper, TextFieldWrapper, TextFieldLabel, LoginForm } from './styled';

function TestPage() {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      dispatch(signIn(values));
    },
  });

  return (
    <Wrapper>
      <LoginForm onSubmit={formik.handleSubmit}>
        <TextFieldWrapper>
          <TextFieldLabel>Email</TextFieldLabel>
          <TextField name="email" size="small" placeholder="Login" onChange={formik.handleChange} required />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <TextFieldLabel>Password</TextFieldLabel>
          <TextField
            name="password"
            size="small"
            placeholder="Password"
            onChange={formik.handleChange}
            type="password"
            required
          />
        </TextFieldWrapper>
        <Button size="large" type="submit">
          Login
        </Button>
      </LoginForm>
    </Wrapper>
  );
}

export default TestPage;
