import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Button, ButtonProps } from '@mui/material';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';

const PopoverStyles = css`
  position: absolute;
  top: 40px;
  right: 0;
  box-shadow: 0 10px 10px 0 rgb(0 0 0 / 10%);
  z-index: 1;
`;

export const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 10px 16px 10px 24px;
  background-color: ${({ theme }) => theme.palette.common.whiteOut};
`;

export const Container = styled.div``;

export const LogoLink = styled.a`
  display: flex;
  align-items: center;
`;

export const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 43px;

  ${({ theme }) => theme.media.lg`
    display: none;
  `}
`;

export const NavLink = styled.a`
  color: ${({ theme }) => theme.palette.common.blackSpace};
  transition: color 0.2s ease-in-out;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.common.ultimateGrey};
  }
`;

export const NavButton = styled.button`
  display: flex;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
  transition: color 0.2s ease-in-out;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  position: relative;
  color: ${({ theme }) => theme.palette.common.blackSpace};

  & > svg {
    margin-left: 8px;
    margin-top: 3px;
    color: ${({ theme }) => theme.palette.common.blackSpace};
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.common.ultimateGrey};
    & > svg {
      color: ${({ theme }) => theme.palette.common.blackSpace};
    }
  }
`;

export const GetAppNavButton = styled(NavButton)`
  padding: 12px 15.5px;
  border: 1px solid ${({ theme }) => theme.palette.common.blackSpace75};
  border-radius: 10px;
  transition: all 300ms ease;
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.blackSpace};
    color: ${({ theme }) => theme.palette.common.white};
    & > svg {
      fill: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export const ProductsPopoverWrapper = styled.div`
  ${PopoverStyles};
`;

export const AboutPopoverWrapper = styled.div`
  ${PopoverStyles};
`;

export const GetAppPopoverWrapper = styled.div`
  position: absolute;
  top: 56px;
  left: 0;
  box-shadow: 0 10px 10px 0 rgb(0 0 0 / 10%);
  z-index: 1;
`;

export const LoginButton = styled((props: ButtonProps) => (
  <Button disableRipple variant="text" color="secondary" startIcon={<LockIcon />} {...props} />
))`
  height: 40px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const SignUpButton = styled((props: ButtonProps) => (
  <Button disableRipple startIcon={<LockIcon />} {...props} />
))`
  height: 40px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;

  ${({ theme }) => theme.media.lg`
    display: none;
  `}
`;

export const MobileMenuIconWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ theme }) => theme.media.lg`
    display: flex;
  `}
`;
