import styled from '@emotion/styled/macro';
import { CardWithThumbnail } from 'components/Card';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
`;

export const Title = styled.div`
  font-size: 48px;
  line-height: 36px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brescianBlue};

  ${({ theme }) => theme.media.sm`
    font-size: 36px;
  `}
`;

export const Description = styled.div`
  font-size: 18px;
  line-height: 36px;
  font-family: 'VisbyRoundCF Medium', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-top: 40px;

  ${({ theme }) => theme.media.sm`
    line-height: 21.6px;
  `}
`;

export const CardsContainer = styled.div`
  width: 1080px;
  display: flex;
  justify-content: center;
  column-gap: 40.33px;
  margin-top: 104px;

  ${({ theme }) => theme.media.lg`
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  `}

  ${({ theme }) => theme.media.lg`
    padding-left: 16px;
    padding-right: 16px;
    column-gap: 24px;
  `}

  ${({ theme }) => theme.media.sm`
    width: 100%;
    margin-top: 72px;
    flex-wrap: wrap;
    gap: 24px;
  `}
`;

export const StyledCard = styled(CardWithThumbnail)`
  ${({ theme }) => theme.media.md`
    width: calc(25% - 24px);
  `}

  ${({ theme }) => theme.media.xs`
    width: calc(50% - 16px);
  `}
`;
